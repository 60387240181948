import type { SelectTypes } from '@smst/ui'

import type { Operator } from '@/api/__generated__/api.schema'
import { getOperatorName } from '@/utils/getOperatorName'

export const getOperatorOptions = (
  operatorsData: Operator[] | undefined
): SelectTypes.Option[] => {
  if (!operatorsData) return []

  return operatorsData.map((operator) => ({
    value: operator.operatorId,
    label: getOperatorName(operator),
  }))
}
