import type { TableTypes } from '@smst/ui'

import type { Report } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

const { t } = i18n.global

const tFields = (field: string) => t(`requestedReports.fields.${field}`)

export const columns: Array<TableTypes.Column<Report>> = [
  {
    name: tFields('status'),
    prop: 'status',
  },
  {
    name: tFields('genDate'),
    prop: 'genDate',
  },
  {
    name: tFields('from'),
    prop: 'from',
  },
  {
    name: tFields('to'),
    prop: 'to',
  },
  {
    name: tFields('departments'),
    prop: 'departmentsList',
    width: '250px',
    wrap: true,
  },
  {
    name: tFields('phones'),
    prop: 'phones',
    width: '250px',
    wrap: true,
  },
  {
    name: tFields('originator'),
    prop: 'originator',
  },
  {
    name: tFields('type'),
    prop: 'type',
  },
  {
    name: tFields('channel'),
    prop: 'channel',
  },
  {
    name: tFields('deliveryStatus'),
    prop: 'deliveryStatus',
  },
  {
    name: tFields('batches'),
    prop: 'batchesList',
    width: '250px',
    wrap: true,
  },
  {
    name: tFields('format'),
    prop: 'format',
  },
]
