<template>
  <div v-if="expired" :class="$style.inner">
    <Text tag="h1">{{ t('expiredPasswordTitle') }}</Text>
    <Text :class="$style.subText" tag="h5">
      {{ t('expiredPasswordSubTitle') }}
    </Text>
  </div>
  <form autocomplete="off" @submit.prevent="onSubmit">
    <Grid>
      <Card :title="t('title')">
        <PasswordBlock withOldPassword />
      </Card>
    </Grid>
    <Button
      type="submit"
      :class="$style.submit"
      :loading="isChangePasswordLoading"
      :disabled="isSubmitDisabled"
    >
      {{ tForm('changeButton') }}
    </Button>
  </form>
</template>

<script lang="ts" setup>
import { Button, Text, toaster } from '@smst/ui'
import { computed } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import Card from '@/components/Card/Card.vue'
import { Grid } from '@/components/Grid'
import { initPassword } from '@/components/PasswordForm/Password.init'
import PasswordBlock from '@/components/PasswordForm/Password.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'
import { router } from '@/router'
import { getErrorMessage } from '@/utils/errors'
import { ProfileData } from '@/utils/profile/profile'

const t = useComponentI18n('settings.password')
const tForm = useComponentI18n('settings.password.form')

const { formValid, dataForSending, clearForm } = initPassword('changePassword')

const isSubmitDisabled = computed(() => !formValid.value)

const props = defineProps({
  expired: {
    type: Boolean,
    required: false,
  },
})

const handleExpiredPassword = async () => {
  if (props.expired) {
    await ProfileData.reloadProfile()
    void router.push({ name: RouteNames.Auth })
  }
  return
}

const { mutate: changePassword, isLoading: isChangePasswordLoading } =
  useMutation(apiClient.settings.changePassword_POST, {
    onSuccess: async () => {
      toaster.success(tForm('success'))
      await handleExpiredPassword()
    },
    onError: (err) => {
      toaster.error(getErrorMessage(err))
    },
    onSettled: async () => {
      await clearForm()
    },
  })

const onSubmit = () => {
  if (formValid.value) changePassword(dataForSending.value)
}
</script>

<style module>
.form {
  display: grid;
  row-gap: var(--gap-24);
}

.submit {
  margin-top: var(--gap-32);
}

.subText {
  margin-top: 12px;
  margin-left: 6px;
}

.inner {
  max-width: 600px;
  margin-bottom: 30px;
}
</style>
