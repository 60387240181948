import { NumberParam, StringParam } from 'serialize-query-params'
import { computed } from 'vue'

import type { FiltersResult } from '@/api/__generated__/api.schema'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import { i18n } from '@/i18n'
import { getOperatorName } from '@/utils/getOperatorName'

export type Value = string | number

export type Channel = (typeof channelsList)[number]

type ChannelsItem = {
  value: Value
  label: string
}

export const channelsList = ['sms', 'viber', 'whatsapp', 'vk'] as const

const { t } = i18n.global

export const defaultTab = {
  value: 'sms',
  label: t('channels.sms'),
}

export const tabsItems = computed<Array<{ value: string; label: string }>>(
  () => [
    defaultTab,
    {
      value: 'viber',
      label: t('channels.viber'),
    },
    {
      value: 'whatsapp',
      label: t('channels.whatsapp'),
    },
    {
      value: 'vk',
      label: t('channels.vk'),
    },
  ]
)
// getTabsItems: returns an array of tabs that are present in the channels array,
// if there are no tabs in channels, returns the default tab
export const getTabsItems = (
  channels?: Channel[]
): ChannelsItem[] | undefined => {
  const tabs: ChannelsItem[] = []
  tabsItems.value.forEach((item) => {
    channels?.forEach((channel) => {
      if (channel === item.value) tabs.push(item)
    })
  })
  return tabs.length > 0 ? tabs : [defaultTab]
}

export const filtersQueryConfig = {
  operatorId: NumberParam,
  originator: StringParam,
  trafficType: NumberParam,
  serviceId: StringParam,
  status: StringParam,
}

export const getFiltersControls = (
  channel: Channel,
  data?: FiltersResult
): ControlType[] => {
  if (!data) {
    return []
  }

  if (channel === 'sms') {
    const smsControls: ControlType[] = []

    data.smsOperators?.length &&
      smsControls.push({
        control: 'select',
        name: 'operatorId',
        label: t('templatesTable.operator'),
        options: data.smsOperators.map((operator) => ({
          value: operator.operatorId,
          label: getOperatorName(operator),
        })),
      })

    data.smsOriginators?.length &&
      smsControls.push({
        control: 'select',
        name: 'originator',
        label: t('templatesTable.originator'),
        options: data.smsOriginators.map((originator) => ({
          value: originator,
          label: originator,
        })),
      })

    data.smsTrafficTypes?.length &&
      smsControls.push({
        control: 'select',
        name: 'trafficType',
        label: t('templatesTable.type'),
        options: data.smsTrafficTypes.map((trafficType) => ({
          value: trafficType,
          label: t(`trafficTypes.${trafficType}`),
        })),
      })

    return smsControls
  }

  const messengerControls: ControlType[] = []

  data[`${channel}Services`]?.length &&
    messengerControls.push({
      control: 'select',
      name: 'serviceId',
      label: t('templatesTable.account'),
      options:
        data[`${channel}Services`]?.map((service) => ({
          value: service.serviceId,
          label: service.login,
        })) ?? [],
    })

  data.ottStatuses?.length &&
    messengerControls.push({
      control: 'select',
      name: 'status',
      label: t('templatesTable.status'),
      options: data.ottStatuses.map((status) => ({
        value: status,
        label: t(`templates.statuses.${status}`),
      })),
    })

  return messengerControls
}
