import { i18n } from '@/i18n'
import yup from '@/utils/yup'

import type { CreateOriginatorsFile } from './CreateOriginatorsFile.types'

const { t } = i18n.global

// @ts-expect-error next-line
// Ругался на совместимость file так как нам нужна только валидация на наличие файла, оставил
// Допустимые разрешения фильтруются через accept, размеры файла не проверяем
export const createOriginatorsSchemaFile: yup.SchemaOf<CreateOriginatorsFile> =
  yup.object({
    operatorId: yup.number().required(
      t('validation.required', {
        name: t('originators.createOriginator.controls.operatorId'),
      })
    ),
    file: yup.mixed().required(
      t('validation.required', {
        name: t('originators.createOriginator.controls.file.name'),
      })
    ),
  })
