<template>
  <Grid :class="$style.notificationForm">
    <form @submit="onSubmit">
      <Card :title="t('title')">
        <Text :class="$style.cardTitle" tag="p" weight="semibold">
          {{ t('label') }}
        </Text>
        <Text :class="$style.cardSubtitle" tag="p">
          {{ t('note') }}
        </Text>

        <FormInput name="controllerEmail" :disabled="emailsIsLoading" />
      </Card>

      <Button
        :loading="sendChangesIsLoading"
        :class="$style.changeAddressesButton"
        type="submit"
        :disabled="isSubmitDisabled"
      >
        {{ t('submitButton') }}
      </Button>
    </form>
  </Grid>
</template>

<script setup lang="ts">
import { Button, Text, toaster } from '@smst/ui'
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import Card from '@/components/Card/Card.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import Grid from '@/components/Grid/Grid.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'

const t = useComponentI18n('settings.security.form')
const queryClient = useQueryClient()

const SECURITY_KEY = 'securityForm'
const { data: emails, isLoading: emailsIsLoading } = useQuery(
  SECURITY_KEY,
  apiClient.settings.security_GET
)
const { mutate: saveFormChanges, isLoading: sendChangesIsLoading } =
  useMutation(apiClient.settings.security_PUT, {
    onSuccess: () => {
      toaster.success(t('success'))
      void queryClient.invalidateQueries(SECURITY_KEY)
    },
    onError: (err) => {
      toaster.error(getErrorMessage(err))
    },
  })

const initialValues = computed(() => ({
  controllerEmail: emails.value?.data.data?.controllerEmail ?? '',
}))

const { meta, handleSubmit } = useForm({
  initialValues,
})

const isSubmitDisabled = computed(() => !meta.value.dirty)

const onSubmit = handleSubmit((values) => {
  saveFormChanges(values)
})
</script>

<style module>
.notificationForm {
  margin-bottom: var(--gap-32);
}

.cardTitle {
  margin-bottom: var(--gap-8);
}

.cardSubtitle {
  margin-bottom: var(--gap-24);
}

.changeAddressesButton {
  margin-top: var(--gap-32);
}
</style>
