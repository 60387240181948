import { calculateSms } from '@/utils/sms'

type Message = {
  channel: string
  message: string
  buttonName?: string
  buttonUrl?: string
  fileUrl?: string
}

export const getMessages = (
  channels?: Record<
    string,
    {
      message: string
      buttonName?: string
      buttonUrl?: string
      fileUrl?: string
    }
  >
): Message[] | undefined => {
  if (!channels) return

  return Object.keys(channels).map((channel) => ({
    channel,
    message: channels[channel].message,
    buttonName: channels[channel]?.buttonName,
    buttonUrl: channels[channel]?.buttonUrl,
    fileUrl: channels[channel]?.fileUrl,
  }))
}

export const getSmsMessageCount = (
  phonesCount?: number,
  message?: string,
  rus?: boolean
) => {
  if (!phonesCount || !message) return

  const { sms } = calculateSms(message, Boolean(rus))

  return sms * phonesCount
}
