<template>
  <div>
    <Text tag="h1" :class="$style.title"> {{ t('title') }} </Text>

    <RouterTabs
      v-if="profileType !== 'subUser' && profileType !== 'departmentUser'"
      :items="tabsItems"
      :class="$style.tabs"
    />

    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import { onMounted } from 'vue'

import RouterTabs from '@/components/RouterTabs/RouterTabs.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { RouteNames } from '@/routeNames'
import { router } from '@/router'

const t = useComponentI18n('settings')

const { profileType } = useProfile()

onMounted(() => {
  if (profileType === 'subUser' || profileType === 'departmentUser') {
    void router.push({ name: RouteNames.SettingsPassword })
  }
})

const tabsItems = [
  {
    value: RouteNames.SettingsGeneral,
    label: t('tabs.general'),
  },
  {
    value: RouteNames.SettingsSecurity,
    label: t('tabs.security'),
  },
  {
    value: RouteNames.SettingsSmtp,
    label: t('tabs.smtp'),
  },
  {
    value: RouteNames.SettingsPassword,
    label: t('tabs.password'),
  },
]
</script>

<style module>
.title {
  margin-bottom: var(--gap-24);
}

.tabs {
  margin-bottom: var(--gap-52);
}
</style>
