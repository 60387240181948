<template>
  <Text tag="h1">
    {{ t('title') }}
  </Text>

  <RouterTabs :items="tabs" :class="$style.tabs" />

  <router-view />
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import type { TabsTypes } from '@smst/ui'

import RouterTabs from '@/components/RouterTabs/RouterTabs.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'

const t = useComponentI18n('messagesLog')

const tabs: TabsTypes.Item[] = [
  {
    value: RouteNames.MessagesLog,
    label: t('tabs.messagesLog'),
  },
  {
    value: RouteNames.RequestedReports,
    label: t('tabs.reports'),
  },
]
</script>

<style module>
.tabs {
  align-self: flex-start;
  margin-top: var(--gap-24);
  margin-bottom: var(--gap-12);
}
</style>
