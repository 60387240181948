import type { SelectTypes } from '@smst/ui'

import type { CountryResult } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

const { locale } = i18n.global

export const getCountryCodeOptions = (
  data?: CountryResult[]
): SelectTypes.Option[] => {
  if (!data) return []

  return data.map((country) => ({
    label: `${country.name[locale.value]} (+${country.countryPhoneCode})`,
    value: country.countryId,
  }))
}

export const getPhoneCode = (
  countryCode?: number,
  countries?: CountryResult[]
) => {
  if (!countryCode || !countries) return ''

  const countryByCountryCode = countries.find(
    (country) => country.countryId === countryCode
  )

  return `+${countryByCountryCode?.countryPhoneCode}`
}
