import { isDefined } from 'remeda'

const ruTranslitAlphabet = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'yo',
  ж: 'j',
  з: 'z',
  и: 'i',
  й: 'j',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'c',
  ч: 'ch',
  ш: 'sh',
  щ: 'sch',
  ъ: "'",
  ы: 'y',
  ь: "'",
  э: 'e',
  ю: 'yu',
  я: 'ya',
}

export const transliterate = (value: string): string => {
  return value
    .split('')
    .map((letter) => {
      const latinLetter =
        ruTranslitAlphabet[
          letter.toLowerCase() as keyof typeof ruTranslitAlphabet
        ]
      const hasLatinLetter = isDefined(latinLetter)

      if (!hasLatinLetter) {
        return letter
      }

      const isUppercase = letter === letter.toUpperCase()
      const isComplexLetter = latinLetter.length > 1

      if (isComplexLetter && isUppercase) {
        return latinLetter.charAt(0).toUpperCase() + latinLetter.slice(1)
      }

      if (isUppercase) {
        return latinLetter.toUpperCase()
      }

      return latinLetter
    })
    .join('')
}
