import { useField } from 'vee-validate'
import { computed, ref } from 'vue'

import { i18n } from '@/i18n'
import { passStrengthPolitics } from '@/utils/passwordValidation'

const { t } = i18n.global

const StrengthOptions = passStrengthPolitics.map((type) => ({
  value: type,
  label: computed(() => t(`accountForm.PassStrength.${type}.label`)),
}))

const label = computed(() => t('accountForm.PassStrength.formTitle'))
const placeholder = computed(() =>
  t('accountForm.PassStrength.formPlaceholder')
)

// состояние селекта - переключается если мы выбираем новый селект
export const strengthSelectedValue = ref('default')

const setStrengthSelectedValue = (newPolitic: string) => {
  strengthSelectedValue.value = newPolitic
}

export const getStrengthSelectedValue = () => strengthSelectedValue.value

const UseField = () => {
  return useField<string>('passStrengthPolitics', undefined, {
    // т.к. modelValue обязательный параметр и используется для вычисления withLabel
    initialValue: 'default',
  })
}

// инициализируем файл
export const initPassStrengthUtils = () => {
  return {
    useField: UseField,
    placeholder,
    label,
    StrengthOptions,
    passStrengthPolitics,
    strengthSelectedValue,
    setStrengthSelectedValue,
    getStrengthSelectedValue,
  }
}
