import { differenceInDays } from 'date-fns'
import { isDefined } from 'remeda'

import type { MessageType } from '@/api/__generated__/api.schema'
import { initLocale } from '@/utils/reactiveLocale'
import yup from '@/utils/yup'

import type { GroupBy } from './Filters.types'

export type StatisticFilters = {
  date: [Date, Date]
  messageType: MessageType | undefined
  channel: string | undefined
  departmentId: number | undefined
  batchId: number[] | undefined
  groupBy: GroupBy[]
  asArray: boolean | undefined
}

const locale = initLocale()

const getFilterErrorText = (key: string) =>
  locale(`statistics.filtersError.${key}`)

export const statisticFiltersSchema: yup.SchemaOf<StatisticFilters> =
  yup.object({
    date: yup
      .array(yup.date().required())
      .length(2)
      .test({
        message: getFilterErrorText('rangeMoreThanOneYear'),
        test(dates) {
          if (!dates?.every(isDefined)) return false

          const [from, to] = dates

          const daysBetween = differenceInDays(to, from)

          return daysBetween <= 365
        },
      })
      .test({
        message: getFilterErrorText('endDateLargerStartDate'),
        test(dates) {
          if (!dates?.every(isDefined)) return false

          const [from, to] = dates

          const daysBetween = differenceInDays(to, from)

          return daysBetween >= 0
        },
      }),
    messageType: yup.mixed().oneOf<MessageType[number]>(['MT', 'MO']),
    channel: yup.string(),
    departmentId: yup.number(),
    batchId: yup.mixed<number[]>(),
    groupBy: yup.array<GroupBy[]>().min(1, getFilterErrorText(`emptyGroup`)),
    asArray: yup.boolean(),
  })
