// import { confirmPassword, passwordRule } from './PasswordRules'
import { initPasswordRules } from './InitPasswordRules'

const { confirmPassword, passwordRule } = initPasswordRules()

export const schema = [
  {
    name: 'password',
    default: '',
    required: true,
    rules: [passwordRule],
  },
  {
    name: 'passwordConfirmations',
    default: '',
    required: true,
    rules: [confirmPassword],
  },
]
