<template>
  <form :id="id" @submit="onSubmit">
    <div v-if="loading" :class="$style.load">...loading</div>
    <FormSelect
      v-if="groups"
      :label="t('label')"
      :placeholder="t('placeholder')"
      :options="groups"
      :locale="{
        noResultsText: t('noResultsText'),
        noOptionsText: t('noOptionsText'),
      }"
      name="group"
      searchable
    />
  </form>
</template>

<script setup lang="ts">
import type { SelectTypes } from '@smst/ui'
import { toaster } from '@smst/ui'
import { useForm } from 'vee-validate'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { apiClient } from '@/api'
import FormSelect from '@/components/FormSelect/FormSelect.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'

import { transferValidationSchema } from './Transfer.utils'
import type { TransferFormValues } from './Transfer.utils'

defineProps({
  id: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['selectGroup', 'submit'])

const groups = ref<SelectTypes.Option[]>()

const route = useRoute()

const groupId =
  typeof route.params.groupId !== 'object' ? route.params.groupId : ''

const { values, handleSubmit } = useForm<TransferFormValues>({
  validationSchema: transferValidationSchema,
})

watch(
  () => values.group,
  (newGroup) => {
    const value = groups.value
      ? groups.value.find((i) => i.value === newGroup)
      : undefined

    emit('selectGroup', value)
  }
)

const t = useComponentI18n('group.transferForm')

const options = ref()
const loading = ref(false)

const getOptions = async () => {
  try {
    loading.value = true
    const { data } = await apiClient.groups.list_GET()

    options.value = data.list
      ? data.list
          .map((i) => ({ value: i.groupId, label: i.groupName }))
          .filter((i) => i.value !== Number(groupId))
      : []

    groups.value = [...options.value]

    loading.value = false
  } catch (e) {
    toaster.error(getErrorMessage(e))
    loading.value = false
  }
}

void getOptions()

const onSubmit = handleSubmit((v) => {
  emit('submit', v)
})
</script>

<style module>
.load {
  width: 100%;

  text-align: center;
}
</style>
