<template>
  <div :class="$style.root">
    <slot />
  </div>
</template>

<style module>
.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: var(--gap-16);

  background-color: var(--color-bg-heading);
  border-radius: 100%;
}
</style>
