<template>
  <FormControlBase v-slot="{ invalid }" :name="name">
    <Timepicker
      :modelValue="value"
      :label="label"
      :placeholder="placeholder"
      :invalid="invalid"
      :disabled="disabled"
      :optionsStep="optionsStep"
      @update:modelValue="handleChange"
      @blur="handleBlur"
    />
  </FormControlBase>
</template>

<script lang="ts">
import { Timepicker } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes } from 'vue'
import { defineComponent } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formTimepickerProps = {
  ...formControlProps,
  optionsStep: {
    type: Number,
    default: 15,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: String,
} as const

export type FormTimepickerProps = ExtractPropTypes<typeof formTimepickerProps>

export default defineComponent({
  components: { FormControlBase, Timepicker },
  props: formTimepickerProps,
  setup(props) {
    const { value, handleChange, handleBlur } = useField<string>(props.name)

    return {
      value,
      handleChange,
      handleBlur,
    }
  },
})
</script>
