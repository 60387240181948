<template>
  <div
    v-if="id === getStatusId"
    :class="[$style.inner, isIndex && $style.up]"
    @scroll="handleScroll"
  >
    <div :class="$style.close" @click="close"><IconClose></IconClose></div>
    <div v-show="dataStatusInfo.length > 0" :class="$style.content">
      <header>{{ t('reportInfo.table.header') }}</header>
      <div :class="[$style.containerGrid, $style.headerGrid]">
        <div>{{ t('reportInfo.table.name') }}</div>
        <div>{{ t('reportInfo.table.date') }}</div>
        <div>{{ t('reportInfo.table.event') }}</div>
      </div>
      <div
        v-for="(item, index) in dataStatusInfo"
        :key="index"
        :class="$style.containerGrid"
      >
        <div :class="$style.login">{{ item.clientLogin }}</div>
        <div>{{ item.createdAt.slice(-20, 10) }}</div>
        <div>{{ t(`reportInfo.eventStatus.${item.event}`) }}</div>
      </div>
    </div>
    <div
      v-if="!isLoading && dataStatusInfo.length === 0"
      :class="$style.noEvents"
    >
      {{ t(`reportInfo.table.noData`) }}
    </div>
    <Loader v-if="isLoading" size="m" :class="$style.center" />
  </div>
</template>

<script setup lang="ts">
import { IconClose, Loader } from '@smst/ui'
import { computed } from 'vue'

import { useComponentI18n } from '@/hooks/useComponentI18n'

import { initStatusInfo } from './StatusInfo.utils'

const t = useComponentI18n('requestedReports')

const {
  dataStatusInfo,
  totalPages,
  currentPage,
  isLoading,
  getStatusId,
  setStatusId,
  getReportlog,
} = initStatusInfo()

const props = defineProps({
  id: Number,
  index: Number,
})

const close = () => {
  setStatusId(0)
}
const isIndex = computed(() => {
  return props.index ? props.index > 3 : 0
})
const loadMore = () => {
  if (currentPage.value < totalPages.value) getReportlog()
}
const handleScroll = (e) => {
  const scrollTop = e.target.scrollTop
  const scrollHeight = e.target.scrollHeight
  const scrollDecision = scrollHeight - scrollTop

  if (scrollDecision < 300 && !isLoading.value) {
    loadMore()
  }
}
</script>

<style module>
.center {
  margin-left: 10px;
}

.login {
  max-width: calc(100% - 15px);
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.inner {
  position: absolute;
  top: -60px;
  left: 60px;
  z-index: 100;

  min-width: 320px;
  max-width: 320px;
  min-height: 230px;
  max-height: 230px;
  padding: 0;
  overflow: auto;

  color: var(--color-text-dark);
  font-size: 12px;

  background: white;
  border: solid 1px #ddd;
  border-radius: 5px;
  box-shadow: -1px 6px 9px #c1c1c1ad;

  animation: bounce-in 0.5s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.up {
  top: inherit;
  bottom: 0;
}

.content {
  margin-top: -25px;
}

.close {
  position: sticky;
  top: 4px;
  right: 4px;

  width: 30px;

  cursor: pointer;
}

.close:hover {
  opacity: 0.6;
}

header {
  padding: 4px;

  text-align: center;
}

.noEvents {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 172px;
}

.containerGrid {
  position: relative;

  display: grid;
  grid-template-columns: minmax(auto, 130px) minmax(auto, 95px) 55px;
  place-items: center center;
  gap: 8px 4px;

  padding-top: 4px;
  padding-bottom: 4px;

  border-bottom: solid 1px #ddd;
}

.headerGrid {
  position: sticky;
  top: 0;
  z-index: 10;

  min-height: 25px;
  margin-top: -2px;

  font-weight: 500;

  background: #c1c1c1ad;
  background-color: var(--color-bg-heading);
}
</style>
