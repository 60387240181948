import { parseISO } from 'date-fns'

import { globalTime } from '@/utils/localTimeFromServer/globalLocalTime'

import type { DateType } from './timezone'
import { serverTimeToUserTime } from './timezone'
// import { formatTimeToServerTime } from './formatDateToRequest'

export const formatDateToDisplay = (
  date: DateType,
  { tz, withTime = false }: { tz?: string; withTime?: boolean }
) => {
  const dateObj = typeof date === 'string' ? parseISO(date) : new Date(date)

  let dateString = dateObj.toLocaleDateString()
  let timeString = dateObj.toLocaleTimeString()

  if (tz) {
    const convertedDateToTargetTimezone = serverTimeToUserTime(date, tz)

    dateString = convertedDateToTargetTimezone.toLocaleDateString()
    timeString = convertedDateToTargetTimezone.toLocaleTimeString()
  }

  return withTime ? `${timeString} ${dateString}` : dateString
}

export const formatDateToServerDateTime = (date: Date) => {
  if (!(date instanceof Date && !isNaN(date))) return undefined
  return globalTime.formatDateToServerDateTime(date)
}

export const formatDateToDate = (date: Date) => {
  if (!(date instanceof Date && !isNaN(date))) return undefined
  return globalTime.formatDateToDateWithoutTime(date)
}
export const formatDateTolocalDateWithoutTime = (
  date: string | Date | null
) => {
  if (!date) return undefined
  if (typeof date === 'string') {
    const timedate = parseISO(date)
    return globalTime.formatDateTolocalDateWithoutTime(timedate)
  }
  if (!(date instanceof Date && !isNaN(date))) return undefined
  return globalTime.formatDateTolocalDateWithoutTime(date)
}
export const formatDateTolocalTime = (date: Date | string | null) => {
  if (!date) return undefined
  if (typeof date === 'string') {
    const timedate = parseISO(date)
    return globalTime.getDateFormatLocalTime(timedate)
  }
  if (!(date instanceof Date && !isNaN(date))) return undefined
  return globalTime.getDateFormatLocalTime(date)
}
export const formatDateTolocalDateTime = (date: string | null) => {
  if (!date) return undefined
  const timedate = globalTime.serverStringDateTimeToUserStringDateTime(date)

  return timedate
}
export const formatDateToTime = (date: string | Date | null) => {
  if (!date) return undefined
  if (typeof date === 'string') {
    const timedate = parseISO(date)
    return globalTime.formatDateToTime(timedate)
  }
  if (!(date instanceof Date && !isNaN(date))) return undefined
  return globalTime.formatDateToTime(date)
}
export const formatDateToDateWithoutTime = (date: Date) => {
  if (!(date instanceof Date && !isNaN(date))) return undefined
  return globalTime.formatDateToDateWithoutTime(date)
}
export const formatStringTimeToServerTime = (
  time: string,
  date: string | undefined
) => {
  if (time && date) {
    const dateTime = `${date} ${time}`
    const { hours, minutes } =
      globalTime.userStringDateTimeToObjectServerDateTime(dateTime)
    return `${hours}:${minutes}:00`
  }
  return
}
