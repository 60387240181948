<template>
  <Button
    :class="$style.button"
    view="text"
    :iconLeft="IconPlus"
    @click="handleOpen"
  >
    {{ tDelivery('createAnalysis') }}
  </Button>
  <Drawer v-model="isDrawerOpen" :closeText="'xxx'">
    <template #title>
      <Button view="text" :iconLeft="IconChevron" @click="handleClose">
        {{ tDelivery('back') }}
      </Button>
    </template>
    <form :id="formId" :class="$style.form" @submit.prevent="onSubmit">
      <FormControls :controls="formControls" />
      <Text tag="p" size="s" :class="$style.explanation">
        {{ tExp('explanation') }}
      </Text>
    </form>

    <template #buttons>
      <slot name="extra-button" />

      <Button type="submit" :form="formId">
        {{ tDelivery('create') }}
      </Button>
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import { Button, Drawer, IconChevron, IconPlus, Text } from '@smst/ui'
import { differenceInDays } from 'date-fns'
import { useForm } from 'vee-validate'
import type { ComputedRef } from 'vue'
import { computed, ref, watch } from 'vue'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import FormControls from '@/components/FormControls/FormControls.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useId } from '@/hooks/useId'

import { getOptionsBy } from '../Statistics/components/Filters/Filters.utils'

import {
  getInitialeValue,
  handleClose,
  handleOpen,
  initCreateAnalysis,
  isDrawerOpen,
} from './utils/CreateAnalysis.utils'
import { AnalysisGenerationSchema } from './utils/CreateAnasysis.schema'

const t = useComponentI18n('batches')
const tDelivery = useComponentI18n('DeliveryAnalysis')
const tExp = useComponentI18n('messagesLog.reportGeneration')
const { createAnalysis } = initCreateAnalysis()

const formId = `createForm-${useId()}`

const { data: filterOptions } = useQuery(
  'messages-filters',
  apiClient.messages.filters_GET
)
const failedDefault = ref(3)
const dataFailure = ref()

const failureInterval = () => {
  const data = []
  for (let i = 1; i < failedDefault.value; i++) {
    data.push({
      value: i,
      label: i,
    })
  }
  dataFailure.value = data
  return data
}
failureInterval()

const department = computed(() => {
  return getOptionsBy(filterOptions?.value?.data.departments, {
    labelKey: 'departmentName',
    valueKey: 'departmentId',
  })
})
const dateToday = new Date()
// const dateMax = dateToday.setDate(dateToday.getDate() - 1);

const formControls: ComputedRef = computed(() => [
  {
    control: 'datepicker',
    name: 'date',
    label: t('filters.date'),
    range: true,
    maxDate: dateToday,
    noToday: true,
    maxRange: 60,
    descriptions: tDelivery('dataDescriptions'),
  },
  {
    control: 'select',
    name: 'department',
    label: tDelivery('departments'),
    options: department.value,
    withOptionAll: true,
  },
  {
    control: 'select',
    name: 'failureInterval',
    label: tDelivery('failureInterval'),
    placeholder: 'active days 1',
    options: dataFailure.value,
    descriptions: tDelivery('failedDescriptions'),
  },
  {
    control: 'input',
    name: 'email',
    type: 'text',
    label: 'Email',
    placeholder: 'Email',
  },
])

const { handleSubmit, values, setFieldValue } = useForm({
  initialValues: getInitialeValue(),
  validationSchema: AnalysisGenerationSchema,
})

watch(
  () => values.date,
  async (newVal) => {
    const isFailure = values?.failureInterval
    const differ = differenceInDays(newVal.at(1), newVal.at(0))
    if (differ <= 9) {
      if (isFailure > differ) {
        failedDefault.value = Number(differ) + 2
        failureInterval()
        setFieldValue('failureInterval', Number(differ) + 1)
      } else {
        failedDefault.value = Number(differ) + 2
        failureInterval()
        // setFieldValue('failureInterval', Number(differ) + 1)
      }
    } else {
      failedDefault.value = 11
      failureInterval()
      if (!isFailure) setFieldValue('failureInterval', 3)
    }
  }
)

const onSubmit = handleSubmit((val) => {
  createAnalysis(val)
})
</script>

<style module>
.subtitle,
.form {
  margin-top: var(--gap-24);
}

.description,
.explanation {
  display: block;
  margin-top: var(--gap-8);
  margin-left: 15px;

  font-size: 12px;
}

.explanation {
  color: var(--color-text-grey);
}

.fieldsTitle {
  margin-top: var(--gap-40);
}

.dndList {
  display: grid;
  margin-top: var(--gap-24);
  row-gap: var(--gap-20);
}

.error {
  margin-top: var(--gap-8);
}

.errorText {
  position: absolute;

  color: var(--color-error);
}

.checkError {
  background-color: var(--color-error-light);
  outline: solid 6px var(--color-error-light);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  color: var(--color-bg-button-hover);
}

.button:hover {
  color: #828282;
}
</style>
