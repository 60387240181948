<template>
  <Table
    v-model:modelSort="sortingState"
    :data="sortedData"
    :columns="isDepUsers ? depUserColumns : accountColumns"
    :rowKey="rowKey"
  >
    <template #column:twoFactorAuth="{ item }">
      {{ getTwoFactorAuthStatus(item.twoFactorAuth) }}
    </template>
    <template #column:status="{ item }">
      <Text weight="semibold" :class="$style[`status-${item.status}`]">
        {{ t(`status.${item.status}`) }}
      </Text>
    </template>
    <template #column:addDate="{ item }">
      <TableDate :date="item.addDate" />
    </template>
    <template #column:editDate="{ item }">
      <TableDate :date="item.editDate" />
    </template>
    <template #rowAction="{ item }">
      <Dropdown
        :label="$t('actions')"
        :iconTrigger="IconActions"
        :items="getDropdownItems(item)"
      />
    </template>
  </Table>
</template>

<script setup lang="ts">
import type { DropdownTypes, TableTypes } from '@smst/ui'
import {
  Dropdown,
  IconActions,
  IconDelete,
  IconEdit,
  IconEventLog,
  IconUnblock,
  Table,
  Text,
} from '@smst/ui'
import type { PropType } from 'vue'
import { computed, ref } from 'vue'

import type {
  AccountShortResult,
  DepUserShortResult,
} from '@/api/__generated__/api.schema'
import TableDate from '@/components/TableDate/TableDate.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { usePermissions } from '@/hooks/usePermissions'
import { getTwoFactorAuthStatus } from '@/utils/getTwoFactorAuthStatus'

import { getSortedData, isDepUsersData } from './AccountsTable.utils'
import type { SortableAccount, SortableDepUser } from './AccountsTable.utils'

const props = defineProps({
  data: {
    type: Array as PropType<AccountShortResult[] | DepUserShortResult[]>,
    required: true,
  },
})

const isDepUsers = computed(() => isDepUsersData(props.data))
const permissions = usePermissions()
const isSmsPermission = computed(() => permissions.value.eventsLog.canView)

const t = useComponentI18n('accountsTable')
const tTable = useComponentI18n('accountsTable.columns')
const tActions = useComponentI18n('accountsTable.actions')

const rowKey = 'id'

const accountColumns: Array<TableTypes.Column<AccountShortResult>> = [
  {
    name: tTable('name'),
    prop: 'name',
    sortable: true,
    wrap: true,
  },
  {
    name: tTable('login'),
    prop: 'fullLogin',
    sortable: true,
  },
  {
    name: tTable('twoFactorAuth'),
    prop: 'twoFactorAuth',
    sortable: true,
  },
  {
    name: tTable('status'),
    prop: 'status',
    sortable: true,
  },
  {
    name: tTable('addDate'),
    prop: 'addDate',
    sortable: true,
  },
  {
    name: tTable('editDate'),
    prop: 'editDate',
    sortable: true,
  },
]

const depUserColumns = accountColumns.filter(
  (item) => item.prop !== 'twoFactorAuth'
) as unknown as Array<TableTypes.Column<DepUserShortResult>>

const sortingState = ref<
  TableTypes.ModelSort<SortableAccount | SortableDepUser>
>({})

const sortedData = computed(() => {
  if (sortingState.value.field && sortingState.value.direction) {
    return getSortedData(
      props.data,
      sortingState.value.field,
      sortingState.value.direction
    )
  }

  return props.data
})

const emit = defineEmits(['openEventLog', 'edit', 'unblock', 'delete'])

const getDropdownItems = (item: AccountShortResult | DepUserShortResult) => {
  const { id, name, status } = item

  const items: DropdownTypes.DropdownItem[] = [
    {
      icon: IconEdit,
      label: tActions('edit'),
      onClick: () => emit('edit', id),
    },
  ]

  if (!isDepUsers.value && isSmsPermission.value) {
    items.unshift({
      icon: IconEventLog,
      label: tActions('openEventLog'),
      onClick: () => emit('openEventLog', id),
    })
  }

  if (status === 'deleted' || status === 'blocked') {
    items.push({
      icon: IconUnblock,
      label: tActions('unblock'),
      onClick: () => emit('unblock', { id, name }),
    })
  }

  if (status === 'active' || status === 'blocked') {
    items.push({
      icon: IconDelete,
      label: tActions('delete'),
      onClick: () => emit('delete', { id, name }),
    })
  }
  return items
}
</script>

<style module>
.status-active {
  color: var(--color-success);
}

.status-deleted {
  color: var(--color-error);
}

.status-blocked {
  color: var(--color-text-grey);
}
</style>
