import { computed, ref } from 'vue'

import { apiClient } from '@/api'
import { i18n } from '@/i18n'
import { FetchClient } from '@/utils/fetchClient/FetchClient'
import { sortByObjectValue } from '@/utils/filters'

type Options = {
  value: number
  label: string
  utcOffset?: number | string
}
const { locale } = i18n.global

const countries = computed(() => FetchClient.getStoreData('countries', []))
const timezones = computed(() => FetchClient.getStoreData(`timezones`, []))

export const isTimezonesLoading = ref(false)
export const isCountriesLoading = ref(false)

export const countriesOptionsFiltered = countries
export const cityOptionsFiltered = timezones

function normalCountries(countr) {
  const data = countr?.data?.list?.map((country) => {
    return {
      value: country.countryId,
      // TODO Добавить типизацию для i18n
      label: country.name[locale.value],
    }
  })
  return sortByObjectValue<Options>(data, 'label')
}
function normalTimezones(timezon) {
  const data = timezon?.data.list?.map(({ id, region, utcOffset }) => {
    return {
      value: id,
      // TODO Добавить типизацию для i18n
      label: `${region[locale.value]} (GMT ${utcOffset})`,
      utcOffset,
    }
  })

  return sortByObjectValue<Options>(data, 'label')
}

export const fetchNewCity = async (countryId: number | undefined) => {
  await FetchClient.fetchData(
    `timezones`,
    () => apiClient.timezones.list_GET({ countryId }),
    normalTimezones
  )
}

export const useFetchCountries = async () => {
  isCountriesLoading.value = true
  await FetchClient.fetchData(
    'countries',
    apiClient.timezones.countries_GET,
    normalCountries
  )
  isCountriesLoading.value = false
}
export const useFetchTimezones = async (countryId: number | undefined) => {
  if (countryId && !isTimezonesLoading.value) {
    isTimezonesLoading.value = true
    await FetchClient.fetchData(
      `timezones`,
      () => apiClient.timezones.list_GET({ countryId }),
      normalTimezones
    )
    isTimezonesLoading.value = false
  }
}
