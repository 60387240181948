import { computed } from 'vue'

import { passwordOptions } from '@/utils/passwordValidation'

import { strengthSelectedValue } from '../SelectPassStrength/PassStrengthPolitics.utils'

import { InitPasswordRules } from './PasswordRules'

const regexRule = computed(() => passwordOptions[strengthSelectedValue.value])

const init = new InitPasswordRules(regexRule, strengthSelectedValue)

export const initPasswordRules = () => {
  return {
    passwordRule: init.passwordRule.bind(init),
    confirmPassword: init.confirmPassword.bind(init),
    getSavePassword: init.getSavePassword.bind(init),
  }
}
