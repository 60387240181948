<template>
  <Text tag="h1" :class="$style.title">{{ t('title') }}</Text>
  <BatchForm
    v-if="isFiltersSuccess && isGroupsSuccess"
    :isSmartDelivery="profile?.smartDelivery"
    :defaultBlackListId="profile?.defaultBlackListId"
    :defaultOriginator="profile?.defaultOriginator"
    :rusByDefaultDisabled="profile?.rusByDefaultDisabled"
    :routes="sendRoutes"
    :groups="groups"
    :testPhones="testPhones"
    :selectedGroups="selectedGroups"
    :selectedPhonesData="selectedPhonesData"
    :isLoading="isCreateBatchLoading"
    :error="createBatchError"
    :submitted="submitted"
    @test="onTest"
    @submit="onNext"
  />

  <div v-else :class="$style.container">
    <Loader v-if="isFiltersLoading || isGroupsLoading" size="m" />

    <ApiErrorMessage :error="filtersError || groupsError" />
  </div>

  <TestDrawer
    :isOpen="isTestDrawerOpen"
    :values="testDrawerValues"
    @close="handleTestDrawerClose"
  />

  <ConfirmationDrawer
    v-if="confirmationDrawerData"
    :isOpen="isOpenConfirmationDrawer"
    :data="confirmationDrawerData"
    :groups="groups"
    :displayDate="displayData"
    @close="handleCloseConfirmationDrawer"
    @submit="onSubmit"
  />
</template>

<script lang="ts" setup>
import { Loader, Text, toaster } from '@smst/ui'
import { computed, ref } from 'vue'
import { useMutation, useQuery } from 'vue-query'
import { useRoute } from 'vue-router'

import { apiClient } from '@/api'
import type { SendInfoResult } from '@/api/__generated__/api.schema'
import { dictionaryDataStaleTime } from '@/api/queryConfig'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { getErrorMessage } from '@/utils/errors'
import { formatDateTolocalDateTime } from '@/utils/formatDateToDisplay'

import { getQuery, queryStringToSelectedElements } from './BatchCreate.utils'
import type { SendFormCommonType } from './BatchCreate.utils'
import { displayData } from './components/BatchForm/BatchForm.utils'
import BatchForm from './components/BatchForm/BatchForm.vue'
import ConfirmationDrawer from './components/ConfirmationDrawer/ConfirmationDrawer.vue'
import TestDrawer from './components/TestDrawer/TestDrawer.vue'

const t = useComponentI18n('batchCreate')

const { profile } = useProfile()

const {
  isLoading: isFiltersLoading,
  isSuccess: isFiltersSuccess,
  data: filtersData,
  error: filtersError,
} = useQuery('sendFilters', apiClient.send.filters_GET, {
  staleTime: dictionaryDataStaleTime,
})

const sendRoutes = computed(() => filtersData.value?.data.data?.sendRoutes)
const testPhones = computed(
  () =>
    filtersData.value?.data.data?.testPhones.map((item) => ({
      label: String(item),
      value: String(item),
    })) ?? []
)

const {
  isLoading: isGroupsLoading,
  isSuccess: isGroupsSuccess,
  data: groupsData,
  error: groupsError,
} = useQuery(
  'groups',
  async () => await apiClient.groups.list_GET({ page: 1, size: 2000 }),
  {
    staleTime: dictionaryDataStaleTime,
  }
)

const groups = computed(() => {
  return groupsData.value?.data?.list ?? groupsData.value?.list
})

const submitted = ref<boolean>(false)

const {
  isLoading: isCreateBatchLoading,
  error: createBatchError,
  mutate: createBatch,
} = useMutation(
  (values: SendFormCommonType) => {
    return getQuery(values, route)
  },
  {
    onSuccess: ({ data }) => {
      if (data.data) {
        const messageCount = t('successMessage.count', {
          count: data.data.totalPhones,
        })
        const message = t('successMessage.message')
        const messageDate = formatDateTolocalDateTime(
          (data.data as SendInfoResult)?.estimatedEndDate
        )

        toaster.success(`${messageCount} ${message} ${messageDate ?? ''}`)
      }

      submitted.value = true
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)

const isOpenConfirmationDrawer = ref<boolean>(false)
const confirmationDrawerData = ref<SendFormCommonType>()

const handleCloseConfirmationDrawer = () => {
  isOpenConfirmationDrawer.value = false
  confirmationDrawerData.value = undefined
}

const onNext = (values: SendFormCommonType) => {
  confirmationDrawerData.value = values
  isOpenConfirmationDrawer.value = true
}

const onSubmit = (values: SendFormCommonType) => {
  handleCloseConfirmationDrawer()

  submitted.value = false
  createBatch(values)
}

const route = useRoute()

const selectedGroups = computed(() => {
  const querySelectedGroups = route.query.selectedGroups as string | undefined

  if (!querySelectedGroups) {
    return undefined
  }

  const result = queryStringToSelectedElements(querySelectedGroups)

  return result
})

const selectedPhonesData = computed(() => {
  const group = route.query.group as string
  const querySelectedGroups = route.query.selectedPhones as string | undefined

  if (!group || !querySelectedGroups) {
    return undefined
  }

  const result = queryStringToSelectedElements(querySelectedGroups)

  return {
    groupId: Number(group),
    selectedPhones: result,
  }
})

const isTestDrawerOpen = ref<boolean>(false)
const testDrawerValues = ref<SendFormCommonType>()

const handleTestDrawerClose = () => {
  isTestDrawerOpen.value = false
  testDrawerValues.value = undefined
}

const onTest = (values: SendFormCommonType) => {
  testDrawerValues.value = values
  isTestDrawerOpen.value = true
}
</script>

<style module>
.title {
  margin-bottom: var(--gap-24);
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
