<template>
  <form autocomplete="off" @submit.prevent="onSubmit">
    <Grid>
      <Row>
        <Card :title="tSection('title')">
          <PasswordBlock :passStrengthPolitics="politics" />
        </Card>
      </Row>
    </Grid>

    <Button
      type="submit"
      :class="$style.submit"
      :loading="isLoading"
      :disabled="isSubmitDisabled"
    >
      {{ t('buttonSubmit') }}
    </Button>
  </form>
</template>

<script lang="ts" setup>
import { Button } from '@smst/ui'
import { computed } from 'vue'

import Card from '@/components/Card/Card.vue'
import { Grid, Row } from '@/components/Grid'
import { initPassword } from '@/components/PasswordForm/Password.init'
import PasswordBlock from '@/components/PasswordForm/Password.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

defineProps({
  isLoading: Boolean,
  politics: { type: String, default: '' },
})

const t = useComponentI18n('editAccount')
const tSection = useComponentI18n('editAccount.sections.changePassword')

const { formValid, clearForm, dataForSending } =
  initPassword('changeUserPassword')

const isSubmitDisabled = computed(() => !formValid.value)

const emit = defineEmits(['submit'])

const onSubmit = () => {
  if (formValid.value) {
    emit('submit', dataForSending.value)
    void clearForm()
  }
}
</script>

<style module>
.section {
  & + & {
    margin-top: var(--gap-24);
  }
}

.description {
  margin-bottom: var(--gap-12);
}

.submit {
  margin-top: var(--gap-32);
}
</style>
