<template>
  <FormControlBase v-slot="{ invalid }" :name="name">
    <Checkbox
      :modelValue="checked"
      :value="value"
      :label="label"
      :name="name"
      :invalid="invalid"
      :disabled="disabled"
      @input="handleChange(value)"
    >
      <slot></slot>
    </Checkbox>
  </FormControlBase>
</template>

<script lang="ts">
import { Checkbox } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes, PropType } from 'vue'
import { defineComponent } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formCheckboxProps = {
  ...formControlProps,
  value: {
    type: [String, Number, Boolean] as PropType<string | number | boolean>,
    required: true,
  },
} as const

export type FormCheckboxProps = ExtractPropTypes<typeof formCheckboxProps>

export default defineComponent({
  components: { FormControlBase, Checkbox },
  props: formCheckboxProps,
  setup(props) {
    const { checked, handleChange } = useField(props.name, undefined, {
      type: 'checkbox',
      checkedValue: props.value,
    })

    return {
      checked,
      handleChange,
    }
  },
})
</script>
