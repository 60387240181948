// name - имя инпута для валидации
// rules - правила валидации по которым будет проводится
// default - значение инпута по умолчания или если оно уже было
// пример схемы :
// const schema = [
//     {
//       name: 'passwords',
//       default: '',
//       любое количество функций проверки:
//          - можно анонимные функции
//          - функция должны возвращать { valid: boolean, error: string }
//       rules: [
//         function (value) {
//           if (value.length < 8) return { valid: false, error: 'too big' }
//           if (value.length === 8) return { valid: false, error: 'too smoll' }
//           if (value.length > 8) return { valid: true }
//         },
//       ],
//     },
//     { name: 'passwordConfirmation', rules: [], default: '', valid?: false or true },
//   ]

// использование - передаем схему в initValidation
// - const { handlerValidate, meta } = initValidation(schema)
// - получаем handlerValidate передаем его в @input
// - meta - передает название поля и получаем value - error - valid для него
//  - - value - значение поля
//  - - error - сообщение ошыбки
//  - - valid - валидно или нет начальное значение зависит от required
//  - - required - обязательно поле или нет
//  - - dirty - false если в него нечего ниразу не писали - true елси хотя бы раз написали

import type { Ref } from 'vue'
import { computed, ref } from 'vue'

type SchemaType = {
  name: string
  rules: Array<() => []>
  default: string
  required: boolean
}
type Meta = { value: string; error: string; valid: boolean }

type ItemSchema = {
  value: string
  valid: boolean
  required: boolean
  error: string
  rules: Array<() => { valid: boolean; error: string }>
  validation: () => void
  handel: (arg0: string) => void
}

type CreateValidSchema = Record<string, ItemSchema>
type ValidationSchemaType = CreateValidSchema[]

class ValidationInput {
  validationSchema: Ref<ValidationSchemaType[]> | ValidationSchemaType[]
  schema: SchemaType[]

  constructor(schema: SchemaType[]) {
    this.validationSchema = ref([])
    this.schema = schema
  }
  createSchema = () => {
    this.validationSchema.value = this.schema.map((item: SchemaType) => {
      return {
        [item.name]: {
          value: item.default ?? '',
          valid: !item.required,
          required: item.required ?? false,
          error: '',
          dirty: false,
          rules: item.rules,
          validation() {
            if (!this.dirty) return
            this.rules.forEach((rule) => {
              const { valid, error } = rule(this.value)
              if (!valid) {
                this.valid = false
                this.error = typeof error === 'string' ? error : error.value
                return
              }
              this.valid = true
              this.error = ''
            })
            return
          },
          setError(errorMessage: string) {
            this.error = errorMessage
          },
          handel(val: string) {
            // console.log('handel val', val)
            this.dirty = true
            this.value = val
            this.validation()
          },
        },
      }
    })
  }
  handlerValidate(newValue: string, name: string) {
    this.validationSchema.value.forEach(
      (item: Record<string, unknown>, index: string | number) => {
        if (item[name])
          this.validationSchema.value[index][name].handel(newValue)
      }
    )
  }
  getMeta(name: string) {
    let meta: Meta | unknown = null

    this.validationSchema.value.forEach(
      (item: CreateValidSchema, index: number) => {
        if (item[name]) meta = this.validationSchema.value[index][name]
      }
    )

    return {
      value: meta?.value,
      error: meta?.error,
      valid: meta?.valid,
      required: meta?.required,
    }
  }
  getAllValid() {
    let valid = true
    let itemName = ''
    this.validationSchema.value.forEach((item: CreateValidSchema) => {
      // eslint-disable-next-line guard-for-in
      for (const nameKey in item) {
        itemName = nameKey
      }
      const schema = item[itemName]
      if (schema && !schema.valid) valid = false
    })

    return valid
  }
  getAllValues() {
    const values = {}
    this.validationSchema.value.forEach((item: CreateValidSchema) => {
      // eslint-disable-next-line guard-for-in
      for (const nameKey in item) {
        values[nameKey] = item[nameKey].value
      }
    })

    return values
  }
  clearForm() {
    this.validationSchema.value.forEach(
      (item: Record<string, unknown>, index: string | number) => {
        // eslint-disable-next-line guard-for-in
        for (const nameKey in item) {
          if (item[nameKey]) {
            this.validationSchema.value[index][nameKey].handel('')
            // this.validationSchema.value[index][nameKey].validation()
          }
        }
      }
    )
  }
  changeRules(name: string, rules: []) {
    this.validationSchema.value.forEach(
      (item: Record<string, unknown>, index: string | number) => {
        if (item[name]) {
          this.validationSchema.value[index][name].rules = rules
          this.validationSchema.value[index][name].validation()
        }
      }
    )
  }
  validateRules(name: string) {
    this.validationSchema.value.forEach(
      (item: Record<string, unknown>, index: string | number) => {
        if (item[name]) {
          this.validationSchema.value[index][name].validation()
        }
      }
    )
  }
}

export const initValidation = (schema: SchemaType[]) => {
  const instValidation = new ValidationInput(schema)

  instValidation.createSchema()

  return {
    handlerValidate: instValidation.handlerValidate.bind(instValidation),
    changeRules: instValidation.changeRules.bind(instValidation),
    validateRules: instValidation.validateRules.bind(instValidation),
    clearForm: instValidation.clearForm.bind(instValidation),
    info: computed(() => instValidation.getMeta.bind(instValidation)),
    valid: computed(() => instValidation.getAllValid()),
    values: computed(() => instValidation.getAllValues()),
  }
}
