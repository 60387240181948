<template>
  <div :class="$style.container">
    <Text :class="$style.text">
      {{ `${sentPhones} / ${totalPhones}` }}
    </Text>
    <div :class="$style.bar" :style="{ width: `${percent}%` }"></div>
  </div>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import { computed } from 'vue'

const props = defineProps({
  sentPhones: {
    type: Number,
    required: true,
  },
  totalPhones: {
    type: Number,
    required: true,
  },
})

const percent = computed<number>(
  () => (props.sentPhones / props.totalPhones) * 100
)
</script>

<style module>
.container {
  position: relative;

  background: var(--color-bg-white);
  border: 1px solid var(--color-stroke-form);
  border-radius: var(--border-radius-s);
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.bar {
  height: 32px;

  background: var(--color-bg-heading);
}
</style>
