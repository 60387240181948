import type { Message } from '@/api/__generated__/api.schema'
import type { FieldFilterItem } from '@/components/FilterFields/FilterFields.types'
import { useProfile } from '@/hooks/useProfile'
import { i18n } from '@/i18n'

import type { FieldName } from './MessagesLog.types'

const { profile } = useProfile()

type TableColumn = {
  prop: keyof Message
  name: string
}

const { t } = i18n.global

const getFieldsLabel = (key: string) => t(`messagesLog.fields.${key}`)

/** Список по дефолту выбранных, если на бэк не передать поля, то в респонсе будут данные для этих полей */
const defaultSelectedFields: FieldName[] = [
  'phone',
  'message',
  'submissionDate',
  'sendDate',
  'deliveryDate',
  'status',
  'error',
  'originator',
]

const fields = (): Array<{ value: string; label: string }> => {
  const userMoney = profile?.value?.accounting === 'money'
  const fieldItems = [
    {
      value: 'id',
      label: getFieldsLabel('id'),
    },
    {
      value: 'phone',
      label: getFieldsLabel('phone'),
    },
    {
      value: 'message',
      label: getFieldsLabel('message'),
    },
    {
      value: 'submissionDate',
      label: getFieldsLabel('submissionDate'),
    },
    {
      value: 'sendDate',
      label: getFieldsLabel('sendDate'),
    },
    {
      value: 'deliveryDate',
      label: getFieldsLabel('deliveryDate'),
    },
    {
      value: 'status',
      label: getFieldsLabel('status'),
    },
    {
      value: 'originator',
      label: getFieldsLabel('originator'),
    },
    {
      value: 'readDate',
      label: getFieldsLabel('readDate'),
    },
    {
      value: 'channel',
      label: getFieldsLabel('channel'),
    },
    {
      value: 'ip',
      label: getFieldsLabel('ip'),
    },
    {
      value: 'operatorName',
      label: getFieldsLabel('operatorName'),
    },
    {
      value: 'countryName',
      label: getFieldsLabel('countryName'),
    },
    {
      value: 'batchName',
      label: getFieldsLabel('batchName'),
    },
    {
      value: 'departmentName',
      label: getFieldsLabel('departmentName'),
    },
    {
      value: 'error',
      label: getFieldsLabel('error'),
    },
    {
      value: 'trafficType',
      label: getFieldsLabel('trafficType'),
    },
  ]
  if (userMoney)
    fieldItems.push({
      value: 'cost',
      label: getFieldsLabel('cost'),
    })
  return fieldItems
}

type QueryFieldState = Record<string, string | undefined>
type ParsedFieldState = Record<FieldName, boolean>

export const transformFieldsStateToQueryParam = (
  fieldItems: FieldFilterItem[]
) =>
  fieldItems.reduce(
    (acc, { value, checked }) => ({
      ...acc,
      /** 0 => false; 1 => true */
      [value]: checked ? 1 : 0,
    }),
    {}
  )

export const getFieldsStateFromQuery = (qsFieldsState?: QueryFieldState) => {
  if (!qsFieldsState) return

  return Object.entries(qsFieldsState).reduce<ParsedFieldState[]>(
    (acc, [value, checked]) => {
      if (checked) {
        acc.push({ [value]: Boolean(Number(checked)) } as ParsedFieldState)
      }

      return acc
    },
    []
  )
}

export const getCheckedFields = (qsFieldsState?: QueryFieldState) => {
  if (!qsFieldsState) return

  return Object.keys(qsFieldsState).filter(
    (fieldName) => Number(qsFieldsState[fieldName]) === 1
  ) as FieldName[]
}

type GetTableColumnsParams = {
  selectedFields?: FieldName[]
  sortableFields?: string[]
}

export const getTableColumns = ({
  selectedFields,
  sortableFields,
}: GetTableColumnsParams): TableColumn[] => {
  const getColumn = (filedName: FieldName) => ({
    prop: filedName,
    name: getFieldsLabel(filedName),
    sortable: sortableFields?.includes(filedName),
  })

  return (selectedFields ?? defaultSelectedFields)
    .map((item) => getColumn(item))
    .filter(({ prop }) => prop !== 'error')
}

/**
 * table - веб версия отчета
 * file - файл с отчетом
 */
type TypeOfUse = 'table' | 'file'

type GetReportFieldParams = {
  selectedFields?: ParsedFieldState[]
  type: TypeOfUse
}

const getFieldsByTypeOfUse = (type: TypeOfUse = 'table') => {
  if (type === 'file') {
    return fields()
  }

  // В таблице нет отдельного поля с ошибкой, поэтому его не выводим
  return fields().filter(({ value }) => value !== 'error')
}

export const getReportFields = (
  params?: GetReportFieldParams
): FieldFilterItem[] => {
  const availableFields = getFieldsByTypeOfUse(params?.type)

  // Возвращаем дефолтное состояние
  if (!params?.selectedFields) {
    return availableFields.map(({ value, label }) => ({
      id: value,
      value,
      label,
      checked: defaultSelectedFields.includes(value),
    }))
  }

  return params.selectedFields.map((item) => {
    const [value, checked] = Object.entries(item).flat() as [FieldName, boolean]

    return {
      id: value,
      value,
      label: availableFields.find((f) => f.value === value)?.label ?? '',
      checked,
    }
  })
}

export const getSelectedFields = (items: FieldFilterItem[]): FieldName[] => {
  const checkedItems = items
    .filter((item) => item.checked)
    .map((item) => item.value as FieldName)

  // Поле error не отображается в фильтре по полям но запрашивается вместе со статусом
  //  Т.к. в таблице используется для отображения в ячейке статус в tooltip
  if (checkedItems.includes('status')) {
    return [...checkedItems, 'error']
  }

  return checkedItems
}
