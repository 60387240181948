<template>
  <Confirmation
    v-if="isCodeConfirmation"
    :phone="phone"
    :error="errorConfirm"
    :disabled="isLoadingConfirm"
    @confirm="handleConfirmPhoneCode"
  >
    <!-- <FormRecaptcha
      v-if="errorActive"
      name="captchaCode"
      secondTime
      :class="$style.captcha"
      @reCaptcha="handleRecaptcha"
    /> -->
    <ResetCode :confirmationCode="confirmationCode" />
  </Confirmation>

  <template v-else>
    <Text tag="h1">
      {{ t('title') }}
    </Text>

    <Text tag="p" :class="$style.note">
      {{ t('note') }}
    </Text>

    <div :class="$style.content">
      <Select
        v-model="countryCode"
        :options="countryCodeOptions"
        searchable
        :class="$style.select"
        :disabled="isLoadingCountry"
        :canDeselect="false"
        :locale="{ noResultsText: $t('form.multiselect.noResultsText') }"
      />

      <!-- Не подошел ui-компонент т.к. не получается полностью контролировать состояние  -->
      <input
        v-model="phone"
        :class="$style.input"
        :disabled="isLoadingCountry"
      />
      <FormRecaptcha
        v-if="errorActive"
        name="captchaCode"
        secondTime
        :class="$style.captcha"
        @reCaptcha="handleRecaptcha"
      />
      <Button
        fullWidth
        :disabled="isButtonDisable"
        :class="$style.button"
        :loading="isLoading"
        @click="handleConfirmPhone"
      >
        {{ t('button') }}
      </Button>

      <ApiErrorMessage :error="error" />
    </div>
  </template>
</template>

<script lang="ts" setup>
import { Button, Select, Text } from '@smst/ui'
import { computed, nextTick, ref, watch } from 'vue'
import { useMutation, useQuery } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Confirmation from '@/components/Confirmation/Confirmation.vue'
import FormRecaptcha from '@/components/FormRecaptcha/FormRecaptcha.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'

import ResetCode from './components/ResetCode/ResetCode.vue'
import { getCountryCodeOptions, getPhoneCode } from './PhoneConfirmation.utils'

const t = useComponentI18n('phoneConfirmation')
const router = useRouter()
const responseRecaptcha = ref()

const handleRecaptcha = (res) => {
  responseRecaptcha.value = res
}

const props = defineProps({
  confirmationCode: {
    type: String,
    required: true,
  },
})

const errorActive = ref(true)

const reloadCaptcha = async () => {
  errorActive.value = false
  responseRecaptcha.value = ''
  await nextTick()
  errorActive.value = true
  responseRecaptcha.value = ''
}

const { data: countryData, isLoading: isLoadingCountry } = useQuery(
  'countries',
  apiClient.countries.list_GET
)

const countries = computed(() => countryData.value?.data.list)
const countryCodeOptions = computed(() =>
  getCountryCodeOptions(countries.value)
)

const MIN_NUMBERS = 10
const MAX_NUMBERS = 16

const isButtonDisable = computed(
  () =>
    isLoadingCountry.value ||
    phone.value.length < MIN_NUMBERS ||
    phone.value.length > MAX_NUMBERS ||
    !responseRecaptcha.value
)

const defaultCountryCode = 342
const countryCode = ref(defaultCountryCode)
const phoneCode = computed(() =>
  getPhoneCode(countryCode.value, countries.value)
)

const phone = ref<string>('')
const isCodeConfirmation = ref<boolean>()

watch(
  () => [phone.value, phoneCode.value],
  ([newPhone, newPhoneCode]) => {
    // Если не начинается с кода, то добавляем его.
    if (!newPhone.startsWith(phoneCode.value)) {
      phone.value = newPhoneCode
    }
  }
)

const {
  mutate: confirmPhone,
  error,
  isLoading,
} = useMutation(
  (values: { phone: string }) =>
    apiClient.registration.confirmPhone_POST(props.confirmationCode, values),
  {
    onSuccess: ({ data }) => {
      // Phone Confirmation Code
      data.phoneConfirmationCode &&
        console.info(`code: `, data.phoneConfirmationCode)

      isCodeConfirmation.value = true
    },
    onError: (err) => {
      if (err) {
        void reloadCaptcha()
      }
    },
  }
)

const handleConfirmPhone = () => {
  confirmPhone({
    phone: phone.value,
    verifyCode: responseRecaptcha.value,
  })
}

const {
  mutate: confirmPhoneCode,
  error: errorConfirm,
  isLoading: isLoadingConfirm,
} = useMutation(
  (values: { phoneConfirmationCode: string }) =>
    apiClient.registration.validateCode_POST(props.confirmationCode, values),
  {
    onSuccess: () => {
      void router.push({ name: RouteNames.PasswordConfirmation })
    },
    onError: (err) => {
      if (err) {
        void reloadCaptcha()
      }
    },
  }
)

const handleConfirmPhoneCode = (phoneConfirmationCode: string) => {
  confirmPhoneCode({
    phoneConfirmationCode,
  })
}
</script>

<style module>
.note {
  column-gap: var(--gap-16);
}

.select {
  text-align: left;
}

.content {
  display: grid;
  margin-top: var(--gap-32);
  row-gap: var(--gap-20);
}

.button {
  margin-top: var(--gap-32);
}

.input {
  width: 100%;
  padding: var(--gap-16) var(--gap-12);

  color: var(--color-text-dark);
  font-size: var(--font-size-m);
  font-family: var(--font-family);
  line-height: var(--line-height-m);

  background: var(--color-bg-white);
  border: 1px solid var(--color-stroke-form);
  border-radius: var(--border-radius-s);

  transition: var(--fast-transition);

  caret-color: var(--color-bg-button);

  appearance: textfield;
}

.input:hover:not(:disabled),
.input:focus {
  outline: none;
  box-shadow: var(--shadow-lvl-1);
}

.input:disabled {
  color: var(--color-text-grey);

  background: var(--color-bg-form-disabled);
}
</style>
