import { StoreData } from './storeData'

class Client extends StoreData {
  // eslint-disable-next-line @typescript-eslint/ban-types
  fetchData(
    saveKey: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    api: Function,
    // eslint-disable-next-line @typescript-eslint/ban-types
    modified: Function | undefined = undefined
  ) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const data = await api()
        if (modified) {
          const modData = modified(data)
          this.setStoreData(saveKey, modData)
        } else {
          this.setStoreData(saveKey, data)
        }

        resolve(this.getStoreData(saveKey))
      } catch (error) {
        reject(error)
      }
    })
  }
  //   fetchNextPage(key) {}
  //   fetchPageNumber(key) {}
}

export const FetchClient = new Client()
