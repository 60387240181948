<template>
  <Text tag="h1" :class="$style.title">
    {{ $t('statistics.title') }}
  </Text>

  <Filters
    :modelValue="formFiltersState"
    :class="$style.filters"
    @update:modelValue="handleUpdateFilters"
  >
    <template #extra-button="{ formValues }">
      <Button
        view="secondary"
        :iconLeft="IconDownload"
        :disabled="formValues.groupBy?.length === 0"
        :loading="downloadReportIsLoading"
        @click="handleDownloadReport(formValues as StatisticFilters)"
      >
        {{ $t('statistics.downloadButton') }}
      </Button>
    </template>
  </Filters>

  <div
    v-if="isEmptyFilters(formFiltersState, defaultFormFilterState)"
    :class="$style.container"
  >
    <NoResultsFound :variant="MessageType.noData" />
  </div>

  <div v-else-if="isFetching" :class="$style.container">
    <Loader size="m" />
  </div>

  <div v-else-if="error" :class="$style.container">
    <ApiErrorMessage :error="error" />
  </div>

  <div v-else-if="showEmptyResultLabel" :class="$style.container">
    <NoResultsFound :withAdvice="false" />
  </div>

  <TableScrollbar v-else-if="data" doubleScroll>
    <TreeTable
      :data="data"
      :columns="columns"
      :class="$style.table"
      :stickyColumns="showSumCosts ? 2 : 1"
      textAlign="right"
    >
      <template #column:metadata="{ item }">
        <Text tag="h1" :weight="item.id === 'summary' ? 'bold' : undefined">
          {{ item.name }}
        </Text>
      </template>
    </TreeTable>
  </TableScrollbar>
</template>

<script setup lang="ts">
import {
  Button,
  IconDownload,
  Loader,
  Text,
  toaster,
  TreeTable,
} from '@smst/ui'
import { endOfYesterday, startOfYesterday, sub } from 'date-fns'
import { computed, ref, toRaw } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import { MessageType } from '@/components/NoResultsFound/NoResultsFound.types'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import TableScrollbar from '@/components/TableScrollbar/TableScrollbar.vue'
import { useProfile } from '@/hooks/useProfile'
import { downloadFile } from '@/utils/downloadFile'
import { getErrorMessage } from '@/utils/errors'
import { formatDateToRequest } from '@/utils/formatDateToRequest'

import type { StatisticFilters } from './components/Filters/Filters.schema'
import { isEmptyFilters } from './components/Filters/Filters.utils'
import Filters from './components/Filters/Filters.vue'
import type { DownloadReport } from './Statistics.types'
import { staticColumns } from './Statistics.utils'
import type { UseStatisticTableItemsProps } from './useTableItems'
import { useStatisticsTableItems } from './useTableItems'

const { profile } = useProfile()

const showSumCosts = computed(() => profile.value?.accounting === 'money')

const columns = computed(() => staticColumns(firstLevelOption.value, profile))

const defaultFormFilterState: StatisticFilters = {
  date: [sub(startOfYesterday(), { days: 30 }), endOfYesterday()],
  messageType: undefined,
  departmentId: undefined,
  channel: undefined,
  batchId: undefined,
  groupBy: [],
  asArray: true,
}

const formFiltersState = ref<StatisticFilters>(defaultFormFilterState)

const groupBy = computed(() => {
  // Добавляем принудительную группировку по стране, если выбрана группировка по оператору
  if (formFiltersState.value.groupBy.includes('operatorId')) {
    return formFiltersState.value.groupBy.reduce<StatisticFilters['groupBy']>(
      (acc, current) => {
        if (current === 'operatorId') {
          acc.push('countryId', current)
        } else {
          acc.push(current)
        }

        return acc
      },
      []
    )
  }

  return formFiltersState.value.groupBy
})

function addCountyGroupBy(groupIs: string[]) {
  // Добавляем принудительную группировку по стране, если выбрана группировка по оператору
  if (groupIs.includes('operatorId')) {
    return groupIs.reduce<StatisticFilters['groupBy']>(
      (acc: string[], current: string) => {
        if (current === 'operatorId') {
          acc.push('countryId', current)
        } else {
          acc.push(current)
        }
        return acc
      },
      []
    )
  }
}
const firstLevelOption = computed(() => groupBy.value[0])

const requestParams = computed(() => {
  if (
    isEmptyFilters(formFiltersState.value, defaultFormFilterState) ||
    !firstLevelOption.value
  ) {
    return {
      filters: undefined,
      groupingOrder: [],
    }
  }

  const { date, ...rest } = formFiltersState.value

  return {
    filters: {
      ...rest,
      dateFrom: formatDateToRequest(date[0]),
      dateTo: formatDateToRequest(date[1]),
      groupBy: firstLevelOption.value,
    },
    groupingOrder: groupBy.value,
  } as UseStatisticTableItemsProps
})

const { data, error, isFetching } = useStatisticsTableItems(requestParams)
const { mutate: downloadReport, isLoading: downloadReportIsLoading } =
  useMutation(
    (values: DownloadReport) =>
      apiClient.statistics.download_GET(values, {
        format: 'blob',
      }),
    {
      onSuccess: (response, variables) => {
        void downloadFile({
          response,
          fileName: `statistic_${variables.dateFrom}-${variables.dateTo}`,
          extension: 'xlsx',
        })
      },

      onError: (err) => {
        toaster.error(getErrorMessage(err))
      },
    }
  )
const tableData = computed(() => data.value ?? [])
const hasTableData = computed(() => tableData.value.length > 0)
const showEmptyResultLabel = computed(
  () => Boolean(data.value) && !hasTableData.value
)

const handleUpdateFilters = (formValues: StatisticFilters | undefined) => {
  formFiltersState.value = formValues ?? defaultFormFilterState
}

const handleDownloadReport = (formValues: StatisticFilters) => {
  const { date, groupBy: isGroupBy, ...rest } = formValues
  const group = addCountyGroupBy(isGroupBy) ?? isGroupBy

  void downloadReport({
    ...rest,
    groupBy: group,
    dateFrom: formatDateToRequest(date[0]),
    dateTo: formatDateToRequest(date[1]),
  })
}
</script>

<style module>
.title {
  margin-bottom: var(--gap-24);
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.filters {
  margin-bottom: var(--gap-24);
}

.table {
  width: 100%;
  min-width: 1200px;
}
</style>
