import { computed, nextTick, ref } from 'vue'

import { useProfile } from '@/hooks/useProfile'
import { getRegexPolitic } from '@/utils/passwordValidation'

import { startValidate } from '../Validation.utils'

import { initOldPasswordRules } from './InitOldPasswordRules'
import { getOldSchema } from './OldPasswordForm.schema'

const reload = ref(true)
const {
  getSavePassword,
  setPolitics,
  setRegex,
  passwordRule,
  confirmPassword,
  oldPasswordRule,
} = initOldPasswordRules()

const setInitRules = () => {
  const { profile } = useProfile()

  const politic = profile.value?.passStrengthPolitics

  const regex = getRegexPolitic(politic)
  setPolitics(politic)
  setRegex(regex)
}

const schema = getOldSchema(oldPasswordRule, passwordRule, confirmPassword)
const { handlerValidate, info, valid, values, clearForm } = startValidate(
  schema,
  getSavePassword
)

const data = computed(() => {
  return {
    password: values.value.password,
    oldPassword: values.value.oldPassword,
  }
})
const clearFormN = async () => {
  clearForm()
  reload.value = false
  // eslint-disable-next-line @typescript-eslint/await-thenable
  await nextTick()
  reload.value = true
}

// инициализируем файл
export const initOldPasswordUtils = () => {
  setInitRules()
  return {
    passwordsValidate: handlerValidate,
    info,
    formValid: valid,
    dataForSending: data,
    clearForm: clearFormN,
    reload,
  }
}
