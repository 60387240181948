<template>
  <header :class="$style.header">
    <Text tag="h1">
      {{ t('title') }}
    </Text>

    <CreateOriginators />
  </header>

  <Filters
    v-model="filtersModel"
    :controls="filterControl"
    :class="$style.filter"
  />

  <template v-if="showTable">
    <LoaderWrapper :loading="isFetching" :class="$style.table">
      <Table
        v-model:modelSort="sortModel"
        :data="tableData"
        :columns="columns"
        rowKey="originatorId"
      >
        <template #column:status="{ item }">
          <OriginatorStatus :status="item.status" />
        </template>

        <template #column:addDate="{ item }">
          <TableDate :date="item.addDate" />
        </template>

        <template #column:approveDate="{ item }">
          <TableDate v-if="item.approveDate" :date="item.approveDate" />
        </template>

        <template #column:deleteDate="{ item }">
          <TableDate v-if="item.deleteDate" :date="item.deleteDate" />
        </template>

        <template #column:operator="{ item }">
          {{ getOperatorName(item.operator) }}
        </template>

        <template #rowAction="{ item }">
          <DeleteButton
            v-if="canDelete(item.status)"
            :status="item.status"
            @click="handleOpenDeleteConfirmation(item)"
          />
        </template>
      </Table>
    </LoaderWrapper>

    <PaginationPanel v-model="paginationModel" :totalItems="totalTableItems" />
  </template>

  <div v-else :class="$style.container">
    <Loader v-if="isLoading" size="m" />

    <ApiErrorMessage :error="error" />

    <NoResultsFound
      v-if="showEmptyResult"
      :variant="MessageType.noResult"
      :withAdvice="false"
    />
  </div>

  <Modal
    v-model="isDeleteConfirmationOpen"
    :title="
      t('deleteConfirmation.title', {
        originatorName: originatorShortDataToDelete?.originatorName,
        operatorName: originatorShortDataToDelete?.operatorName,
      })
    "
    :acceptButton="t('deleteConfirmation.acceptButton')"
    :cancelButton="t('deleteConfirmation.cancelButton')"
    @accept="handleDelete"
  />
</template>

<script setup lang="ts">
import { Loader, LoaderWrapper, Modal, Table, Text, toaster } from '@smst/ui'
import type { TableTypes } from '@smst/ui'
import { isDefined } from 'remeda'
import {
  createEnumParam,
  NumberParam,
  StringParam,
} from 'serialize-query-params'
import { computed, ref } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import type { Originator } from '@/api/__generated__/api.schema'
import { OriginatorStatus as Status } from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Filters from '@/components/Filters/Filters.vue'
import { MessageType } from '@/components/NoResultsFound/NoResultsFound.types'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import PaginationPanel from '@/components/PaginationPanel/PaginationPanel.vue'
import TableDate from '@/components/TableDate/TableDate.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { createSortQueryConfig, useTableRequest } from '@/hooks/useTableRequest'
import { getErrorMessage } from '@/utils/errors'
import { getOperatorName } from '@/utils/getOperatorName'
import { omitBy } from '@/utils/object'

import CreateOriginators from './components/CreateOriginators/CreateOriginators.vue'
import DeleteButton from './components/DeleteButton/DeleteButton.vue'
import OriginatorStatus from './components/OriginatorStatus/OriginatorStatus.vue'
import { getFilterControl, originatorsStatuses } from './Originators.utils'

const t = useComponentI18n('originators')

const sortConfig = createSortQueryConfig(['operatorId', 'status', 'addDate'])

const filtersConfig = {
  originator: StringParam,
  operatorId: NumberParam,
  status: createEnumParam(originatorsStatuses),
}

const { request, paginationModel, sortModel, filtersModel } = useTableRequest({
  filtersConfig,
  sortConfig,
})

const { data, isSuccess, isLoading, isFetching, error } = useQuery(
  ['originators', request],
  () => apiClient.originators.originators_GET(omitBy(request.value, isDefined)),
  {
    keepPreviousData: true,
  }
)

const { data: originatorFilters } = useQuery(
  'originatorsFilters',
  apiClient.originators.filters_GET
)

const tableData = computed(() => data.value?.data.list ?? [])
const showTable = computed(() => isSuccess.value && tableData.value.length > 0)
const totalTableItems = computed(
  () => data.value?.data.pageInfo?.totalItems ?? 0
)
const showEmptyResult = computed(
  () => isSuccess.value && tableData.value.length === 0
)

const columns: Array<TableTypes.Column<Originator>> = [
  {
    name: t('column.originator'),
    prop: 'originator',
  },
  {
    name: t('column.operator'),
    prop: 'operator',
    sortable: true,
    sortKey: 'operatorId',
  },
  {
    name: t('column.status'),
    prop: 'status',
    sortable: true,
  },
  {
    name: t('column.addDate'),
    prop: 'addDate',
    sortable: true,
  },
  {
    name: t('column.approveDate'),
    prop: 'approveDate',
  },
  {
    name: t('column.deleteDate'),
    prop: 'deleteDate',
  },
]

const deleteStatuses = [Status.Approved, Status.New]

const canDelete = (status: Status) => deleteStatuses.includes(status)

const filterControl = computed(() =>
  getFilterControl({ operators: originatorFilters.value?.data.operators })
)

const queryClient = useQueryClient()

const { mutate: deletedOriginator } = useMutation(
  apiClient.originator.originator_DELETE,
  {
    onSuccess: () => {
      toaster.success(t('deleteConfirmation.success'))
      void queryClient.invalidateQueries('originators')
    },

    onError: (deletedOriginatorError) => {
      toaster.error(getErrorMessage(deletedOriginatorError))
    },
  }
)

type OriginatorShortData = {
  originatorName: string
  operatorName: string
  originatorId: number
}

const isDeleteConfirmationOpen = ref(false)

const originatorShortDataToDelete = ref<OriginatorShortData | undefined>(
  undefined
)

const handleOpenDeleteConfirmation = ({
  originator,
  originatorId,
  operator,
}: Originator) => {
  isDeleteConfirmationOpen.value = true
  originatorShortDataToDelete.value = {
    originatorName: originator,
    operatorName: operator.operator,
    originatorId,
  }
}

const handleDelete = () => {
  if (originatorShortDataToDelete.value) {
    deletedOriginator(originatorShortDataToDelete.value.originatorId)
  }

  isDeleteConfirmationOpen.value = false
  originatorShortDataToDelete.value = undefined
}
</script>

<style module>
.table {
  margin-top: var(--gap-12);
}

.container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  align-items: baseline;

  column-gap: var(--gap-32);
}

.filter {
  margin-top: var(--gap-24);
}
</style>
