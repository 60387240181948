<template>
  <Text tag="h1">{{ $t('confirmation.title') }}</Text>

  <Text tag="p" weight="medium" :class="$style.note">
    {{ $t('confirmation.note', { phone }) }}
  </Text>

  <div :class="$style.content">
    <CodeInput v-model="code" :invalid="isError" :disabled="disabledInput" />
  </div>

  <ApiErrorMessage :error="error" />

  <slot />
</template>

<script setup lang="ts">
import { CodeInput, Text } from '@smst/ui'
import { computed, ref, watch } from 'vue'
import type { PropType } from 'vue'

import ApiErrorMessage from '@/components/ApiErrorMessage.vue'

const props = defineProps({
  phone: String,
  error: Object as PropType<unknown>,
  disabled: Boolean,
  reset: Boolean as PropType<boolean>,
})

const emits = defineEmits(['confirm', 'generate'])

const code = ref('')
const error = ref(props.error)

const disabledInput = ref(props.disabled)

watch(
  () => code.value,
  (codeValue) => {
    if (codeValue.length === 5) {
      emits('confirm', codeValue)
    }
  }
)

watch(
  () => props.reset,
  (reset) => {
    if (reset) {
      code.value = ''
      disabledInput.value = false
    }
  }
)
const isError = computed(() => code.value.length === 5 && Boolean(props.error))
</script>

<style module>
.content {
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 100%;
  margin: var(--gap-32) auto var(--gap-24);
}

.note {
  margin-top: var(--gap-16);

  white-space: pre-line;
}
</style>
