import type { SelectTypes } from '@smst/ui'
import { createEnumParam } from 'serialize-query-params'

import { DocumentStatus } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'
import { RangeDateParam } from '@/utils/date'

const { t } = i18n.global

export const documentStatuses = Object.values(DocumentStatus)

export const documentStatusOptions: SelectTypes.Option[] = documentStatuses.map(
  (status) => ({
    value: status,
    label: t(`accounting.documentStatus.${status}`),
  })
)

export const filtersConfig = {
  date: RangeDateParam,
  documentStatus: createEnumParam(documentStatuses),
}
