import type { SelectTypes } from '@smst/ui'
import { endOfDay, endOfToday, startOfDay } from 'date-fns'
import { isDefined, mapValues, merge } from 'remeda'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import type {
  BatchesFilterResult,
  MessagesFiltersResult,
} from '@/api/__generated__/api.schema'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import { i18n } from '@/i18n'
import { formatDateToRequest } from '@/utils/formatDateToRequest'
import { omitBy } from '@/utils/object'

import type { FiltersModel, FiltersState } from './useGetFiltersState.types'

const { t } = i18n.global

export const messageTypeOptions: ComputedRef<SelectTypes.Option[]> = computed(
  () => [
    {
      value: 'MT',
      label: t('messages.mt'),
    },
    {
      value: 'MO',
      label: t('messages.mo'),
    },
  ]
)

export const messageStatusOptions: ComputedRef<SelectTypes.Option[]> = computed(
  () => [
    {
      value: 'delivered',
      label: t('messagesLog.status.delivered'),
    },
    {
      value: 'not_delivered',
      label: t('messagesLog.status.notDelivered'),
    },
    {
      value: 'read',
      label: t('messagesLog.status.read'),
    },
    {
      value: 'clicked',
      label: t('messagesLog.status.clicked'),
    },
    {
      value: 'checked',
      label: t('messagesLog.status.checked'),
    },
  ]
)

export const channelOptions: SelectTypes.Option[] = [
  {
    value: 'sms',
    label: t('channels.sms'),
  },
  {
    value: 'viber',
    label: t('channels.viber'),
  },
  {
    value: 'whatsapp',
    label: t('channels.whatsapp'),
  },
  {
    value: 'vk',
    label: t('channels.vk'),
  },
  {
    value: 'ok',
    label: t('channels.ok'),
  },
  {
    value: 'push',
    label: t('channels.push'),
  },
]

export const getDepartmentOptions = (
  departments: MessagesFiltersResult['departments']
): SelectTypes.Option[] => {
  const requiredOptions = [
    {
      label: t('messagesLog.filters.mainLogin'),
      value: 0,
    },
  ]

  if (!departments) {
    return requiredOptions
  }
  const options = departments.map(({ departmentId, departmentName }) => ({
    label: departmentName,
    value: departmentId,
  }))

  return [...requiredOptions, ...options]
}

export const getBatchesOptions = (batches: BatchesFilterResult['list']) => {
  if (!batches) {
    return []
  }

  return batches.map(({ batchIds, batchName }) => ({
    label: batchName,
    value: batchIds.join(','),
  }))
}

export const getFormControls = (
  departmentOptions: SelectTypes.Option[],
  batchesOptions: SelectTypes.Option[],
  isSmartDelivery: boolean
): ControlType[] => {
  const controls: Array<ControlType | undefined> = [
    {
      control: 'phonesInput',
      label: t('messagesLog.filters.phones'),
      name: 'phones',
    },
    {
      control: 'datetimepicker',
      maxDate: endOfToday(),
      labels: {
        from: { time: t('form.from.time'), date: t('form.from.date') },
        to: { time: t('form.to.time'), date: t('form.to.date') },
      },
      name: 'date',
      range: true,
    },
    {
      control: 'input',
      label: t('messagesLog.filters.originator'),
      name: 'originator',
    },
    {
      control: 'select',
      label: t('messagesLog.filters.type'),
      name: 'type',
      withOptionAll: true,
      options: messageTypeOptions.value,
    },
    {
      control: 'select',
      label: t('messagesLog.filters.status'),
      name: 'status',
      withOptionAll: true,
      options: messageStatusOptions.value,
    },
    isSmartDelivery
      ? {
          control: 'select',
          label: t('messagesLog.filters.channel'),
          name: 'channel',
          withOptionAll: true,
          options: channelOptions,
        }
      : undefined,
    {
      control: 'multiselect',
      label: t('messagesLog.filters.department'),
      withOptionAll: true,
      name: 'departmentIds',
      options: departmentOptions,
    },
    {
      control: 'multiselect',
      label: t('messagesLog.filters.batch'),
      withOptionAll: true,
      name: 'batchIds',
      options: batchesOptions,
    },
  ]

  return controls.filter(isDefined)
}

export const defaultFilters: FiltersState = {
  phones: [],
  date: [startOfDay(new Date()), endOfDay(new Date())],
  originator: '',
  type: undefined,
  status: undefined,
  channel: undefined,
  departmentIds: undefined,
  batchIds: undefined,
}

type FormFilterValue = string | string[] | number | number[]

const isValidFilterModelValue = (formFilterValue?: FormFilterValue) => {
  const rules = {
    isDefined,
    isNotEmpty: (value?: FormFilterValue) => {
      if (Array.isArray(value)) {
        return value.length > 0
      }

      return value !== ''
    },
  }

  return Object.values(rules).every((rule) => rule(formFilterValue))
}

export const filtersModelToFiltersState = (
  filtersModel: FiltersModel
): FiltersState => {
  const { from, to, ...rest } = filtersModel

  /**
   * В URL содержатся только актуальные значения фильтров
   * Мержим их с дефолтными значениями для получения полного состояния формы
   * Состояние формы = состояние из URL + дефолтные значения для незаполненных полей
   */
  const initialFiltersState = merge(
    defaultFilters,
    omitBy(rest as FiltersModel, isDefined)
  )

  if (from && to) {
    initialFiltersState.date = [new Date(from), new Date(to)]
  }

  return initialFiltersState
}

export const filterStateToFiltersModel = (filterState: FiltersState) => {
  const { date, batchIds, ...values } = filterState

  const normalizeFilters = mapValues(values, (value) =>
    isValidFilterModelValue(value) ? value : undefined
  ) as FiltersState

  return {
    ...normalizeFilters,
    batchIds: batchIds
      ? batchIds.map((ids) => ids.split(',')).flat()
      : undefined,
    from: formatDateToRequest(date[0], { withTime: true }),
    to: formatDateToRequest(date[1], { withTime: true }),
  }
}
