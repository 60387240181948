import type { MultiselectTypes } from '@smst/ui'
import { differenceInMonths, parseISO } from 'date-fns'
import { isDefined, mapValues } from 'remeda'
import { useField, useForm } from 'vee-validate'
import { computed, onMounted, ref, watch } from 'vue'
import type { Ref } from 'vue'
import { useMutation, useQuery } from 'vue-query'

import { apiClient } from '@/api'
import { getFilterItemsDescription } from '@/components/Filters/Filters.utils'
import { useProfile } from '@/hooks/useProfile'
import { formatDateToRequest } from '@/utils/formatDateToRequest'
import { omitBy } from '@/utils/object'

import { validationSchema } from './filters.schema'
import type { FiltersModel } from './useGetFiltersState.types'
import {
  filtersModelToFiltersState,
  filterStateToFiltersModel,
  getBatchesOptions,
  getDepartmentOptions,
  getFormControls,
} from './useGetFiltersState.utils'

export const useGetFiltersState = (filtersModel: Ref<FiltersModel>) => {
  const { profile } = useProfile()

  onMounted(() => {
    const [from, to] = values.date

    fetchBatches({
      from: formatDateToRequest(from, {
        withTime: true,
        tz: profile.value?.timezone.name,
      }),
      to: formatDateToRequest(to, {
        withTime: true,
        tz: profile.value?.timezone.name,
      }),
    })
  })

  const isDrawerOpen = ref<boolean>(false)

  const toggleDrawer = (value: boolean) => {
    isDrawerOpen.value = value
  }

  const initialValues = ref(filtersModelToFiltersState(filtersModel.value))
  const { values, handleSubmit, setFieldValue } = useForm({
    initialValues,
    validationSchema,
  })
  const { value: inputPhoneSaved } = useField('inputPhoneSaved')
  // Сбрасываем состояние в ящике при открытии
  watch(
    () => isDrawerOpen.value,
    (newIsDrawerOpen) => {
      if (newIsDrawerOpen) {
        initialValues.value = filtersModelToFiltersState(filtersModel.value)
      }
    }
  )

  watch(
    () => [isDrawerOpen.value, values.date],
    ([newIsDrawerOpen, newDate]) => {
      if (!newIsDrawerOpen || !values.date) {
        return
      }

      const [from, to] = newDate as [Date, Date]

      // Запросить батчи можно только за период меньше 3-х месяцев
      if (differenceInMonths(to, from) < 3) {
        fetchBatches({
          from: formatDateToRequest(from, {
            withTime: true,
            tz: profile.value?.timezone.name,
          }),
          to: formatDateToRequest(to, {
            withTime: true,
            tz: profile.value?.timezone.name,
          }),
        })
      }

      if (!filtersModel.value.from || !filtersModel.value.to) {
        setFieldValue('batchIds', undefined)
        return
      }

      const queryDateFrom = new Date(filtersModel.value.from)
      const queryDateTo = new Date(filtersModel.value.to)

      /**
       * Если дата из query param отличается от локального состояния формы,
       * это означает, что в данный момент меняется состояние формы, но его еще не применили
       * в таком случае сбрасываем поле батчей
       */
      if (
        queryDateFrom.getTime() !== from.getTime() ||
        queryDateTo.getTime() !== to.getTime()
      ) {
        setFieldValue('batchIds', undefined)
      }
    }
  )

  const { data: filtersValues, isLoading: isFiltersValuesLoading } = useQuery(
    'messages-filters',
    apiClient.messages.filters_GET
  )

  const batchesOptions = ref<MultiselectTypes.Option[]>([])

  const { mutate: fetchBatches } = useMutation(
    apiClient.messages.filtersBatches_GET,
    {
      onSuccess: (res) => {
        batchesOptions.value = getBatchesOptions(res?.data?.list)
      },
    }
  )

  const formControls = computed(() =>
    getFormControls(
      getDepartmentOptions(filtersValues.value?.data.departments),
      batchesOptions.value,
      Boolean(profile.value?.smartDelivery)
    )
  )

  const activeFilters = computed(() => {
    if (isFiltersValuesLoading.value) {
      return []
    }

    const { from, to, ...rest } = filtersModel.value

    const preparedFiltersToGetDescriptions = omitBy(
      {
        ...rest,
        date: from && to ? [parseISO(from), parseISO(to)] : undefined,
      },
      isDefined
    )

    return getFilterItemsDescription(
      preparedFiltersToGetDescriptions,
      formControls.value
    )
  })

  const handleChangeFiltersModel = handleSubmit((formValues) => {
    filtersModel.value = filterStateToFiltersModel(formValues)
    toggleDrawer(false)
  })

  const handleClearFiltersModel = () => {
    filtersModel.value = mapValues(filtersModel.value, () => undefined)
  }

  return {
    values,
    inputPhoneSaved,
    setFieldValue,
    isDrawerOpen,
    toggleDrawer,

    activeFilters,
    formControls,

    handleChangeFiltersModel,
    handleClearFiltersModel,
  }
}
