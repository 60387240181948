import type { Ref } from 'vue'
import { computed, ref } from 'vue'

import { i18n } from '@/i18n'

const { t } = i18n.global

export class InitPasswordRules {
  regex: Ref<RegExp> | Ref<string>
  savePassword: Ref<string>
  politics: Ref<string>
  constructor(regex?: Ref<RegExp>, politics?: Ref<string>) {
    this.regex = regex ?? ref('')
    this.politics = politics ?? ref('')
    this.savePassword = ref('')
  }
  rules(password: string) {
    if (password.length > 25)
      return {
        valid: false,
        error: computed(() => t('accountForm.passwordRules.tooBig')),
      }
    if (password.length < 8)
      return {
        valid: false,
        error: computed(() => t('accountForm.passwordRules.tooSmall')),
      }
    if (this.regex.value && !this.regex.value.test(password))
      return {
        valid: false,
        error: computed(() =>
          t(`accountForm.PassStrength.${this.politics.value}.description`)
        ),
      }
    // eslint-disable-next-line no-constant-condition
    return { valid: true }
  }
  oldPasswordRule(password: string) {
    if (password.length === 0) {
      return {
        valid: false,
        error: computed(() => t(`accountForm.passwordRules.empty`)),
      }
    }
    return { valid: true }
  }
  passwordRule(password: string) {
    this.savePassword.value = password
    return this.rules(password)
  }
  confirmPassword(password: string) {
    if (password !== this.savePassword.value)
      return {
        valid: false,
        error: computed(() => t('accountForm.passwordRules.theSame')),
      }
    // eslint-disable-next-line no-constant-condition
    return { valid: true }
  }
  getSavePassword() {
    return this.savePassword
  }
  setRegex(reg: RegExp) {
    this.regex.value = reg
  }
  setPolitics(politic: string) {
    this.politics.value = politic
  }
}
