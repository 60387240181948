import { format, sub } from 'date-fns'

import { dateFormatWithoutTime } from '@/utils/date'

export const getEventLogDate = () => {
  const today = new Date()
  const weekAgo = sub(today, { weeks: 1 })

  return `${format(weekAgo, dateFormatWithoutTime)},${format(
    today,
    dateFormatWithoutTime
  )}`
}
