import { format, parseISO } from 'date-fns'

import { dateFormatWithoutTime, dateFormatWithTime } from './date'
import { globalTime } from './localTimeFromServer/globalLocalTime'
import type { DateType } from './timezone'
import { userTimeToServerTime } from './timezone'

export const formatDateToRequest = (
  userDate: DateType | string,
  opt?: { tz?: string; withTime?: boolean }
) => {
  // if (!(userDate instanceof Date && !isNaN(userDate))) return ''
  const dateFormat = opt?.withTime ? dateFormatWithTime : dateFormatWithoutTime
  const convertedDateToTargetTimezone = userTimeToServerTime(userDate, opt?.tz)

  const date = opt?.tz ? convertedDateToTargetTimezone : new Date(userDate)

  return format(date, dateFormat)
}

export const formatTimeToServerTime = (time: string) => {
  if (time) {
    const zonedDate = parseISO(`2018-09-01 ${time}:00.000`)
    return globalTime.getDateFormatLocalDateTime(zonedDate)
  }
  return
}
export const formatTimeToUserTime = (time: string) => {
  if (time) {
    const zonedDate = parseISO(`2018-09-01 ${time}:00.000`)
    return globalTime.formatDateToTime(zonedDate)
  }
  return
}
