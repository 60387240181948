<template>
  <Row v-if="routes">
    <Card :title="t('message.title')" :withRoundedCorners="false">
      <Text tag="p" weight="medium" :class="$style.marginBottom8">
        {{ t('message.description') }}
      </Text>

      <FormSelect
        v-if="formSelectActive"
        name="routeGroupId"
        :label="t('message.route.label')"
        :options="messageRouteOptions"
        :searchable="true"
        :canDeselect="false"
        :class="$style.marginBottom8"
        :defaultId="defaultId"
      />

      <Text
        tag="p"
        size="s"
        weight="medium"
        color="secondary"
        :class="$style.marginBottom24"
      >
        {{ t('message.route.description') }}
      </Text>

      <FormCheckbox
        :value="true"
        name="isSameMessages"
        :label="t('message.isSameMessages')"
        :class="$style.marginBottom24"
      />

      <Text
        v-if="!originatorDisable"
        tag="p"
        size="s"
        weight="medium"
        color="secondary"
        :class="$style.marginBottom8"
      >
        {{ t('message.originator.description') }}
      </Text>
      <FormInput
        name="originator"
        :label="t('message.originator.label')"
        :class="$style.marginBottom8"
        :disabled="originatorDisable"
      />
      <TimePickerForm name="congratulateStartTime" :startSetDate="startTime" />
    </Card>

    <Card
      v-for="(route, index) in routeGroup"
      :key="route"
      :view="route"
      :withRoundedCorners="false"
      :class="$style.channelCard"
    >
      <Text tag="p" size="xl" weight="semibold" :class="$style.channelTitle">
        {{ $t(`channels.${route}`) }}
      </Text>

      <Text size="s" weight="medium" color="secondary">{{
        tGroupForm('congratulate.birthdayMessage.hint')
      }}</Text>

      <Draft
        :isSameMessages="checkboxToBoolean(isSameMessages)"
        :isDependentRoute="checkboxToBoolean(isSameMessages) && index > 0"
        :commonDraft="commonDraft"
        :channel="getChannelForDraft(route)"
        :name="`channels.${route}.message`"
        :label="tGroupForm('congratulate.birthdayMessage.label')"
        :placeholder="tGroupForm('congratulate.birthdayMessage.initialValue')"
        :transliteration="route === Channel.Sms"
        :characterLimit="getCharacterLimit(route)"
        :badge="getBadge(route)"
        :class="$style.marginBottom8"
        :hint="getHint(route)"
        :hintValue="smsMessageValue"
        :draftFromFileOff="false"
        @commonDraftReset="handleCommonDraftReset"
      />

      <FormCheckbox
        v-if="route === Channel.Sms"
        name="transliterate"
        :label="t('message.channels.sms.rus')"
        :value="true"
      />

      <template v-if="route === Channel.Viber || route === Channel.Whatsapp">
        <Text
          tag="p"
          weight="semibold"
          :class="[$style.marginTop24, $style.marginBottom8]"
        >
          {{ t(`message.channels.${route}.image`) }}
        </Text>

        <FormUploadImage
          v-if="
            (route === 'viber' && !viberUrl) ||
            (route === 'whatsapp' && !whatsappUrl)
          "
          useUrl
          :name="`channels.${route}.fileUrl`"
          :locale="fileLocale"
          :maxSize="FILE_SIZE_KB"
          :descriptions="[
            tFile('acceptFiles', { types: FILE_EXT }),
            tFile('additional', { size: FILE_SIZE_KB / 1024 }),
          ]"
        />
        <div
          v-if="
            (route === 'viber' && viberUrl) ||
            (route === 'whatsapp' && whatsappUrl)
          "
          :class="$style.imgInner"
        >
          <IconClose
            :class="$style.close"
            @click="removeImg(route)"
          ></IconClose>
          <img :class="$style.uploadImg" :src="viberUrl ?? whatsappUrl" />
        </div>

        <template v-if="route === Channel.Viber">
          <Text
            tag="p"
            weight="semibold"
            :class="[$style.marginTop24, $style.marginBottom8]"
          >
            {{ t(`message.channels.viber.button`) }}
          </Text>

          <Text tag="p" weight="medium" :class="$style.marginBottom8">
            {{ t(`message.channels.viber.description`) }}
          </Text>

          <FormInput
            name="channels.viber.buttonName"
            :label="t('message.channels.viber.buttonName.label')"
            :placeholder="t('message.channels.viber.buttonName.placeholder')"
            :class="$style.marginBottom8"
          />

          <FormInput
            name="channels.viber.buttonUrl"
            :label="t('message.channels.viber.buttonUrl.label')"
            :placeholder="t('message.channels.viber.buttonUrl.placeholder')"
          />
        </template>
      </template>
    </Card>

    <template #right>
      <!-- <HintCard v-html="tHint('message')" /> -->
    </template>
  </Row>
</template>

<script lang="ts" setup>
import { IconClose, Text } from '@smst/ui'
import { useField, useFieldValue } from 'vee-validate'
import type { PropType } from 'vue'
import { computed, ref } from 'vue'
import { useQuery } from 'vue-query'
import { useRoute } from 'vue-router'

import { apiClient } from '@/api'
import type { Draft as DraftType } from '@/api/__generated__/api.schema'
// import { dictionaryDataStaleTime } from '@/api/queryConfig'
import Card from '@/components/Card/Card.vue'
import FormCheckbox from '@/components/FormCheckbox/FormCheckbox.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import FormSelect from '@/components/FormSelect/FormSelect.vue'
import { Row } from '@/components/Grid'
import TimePickerForm from '@/components/TimePickerForm/TimePickerForm.vue'
// import HintCard from '@/components/HintCard/HintCard.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { checkboxToBoolean } from '@/utils/checkbox'
import { getSmsFormData } from '@/utils/sms'

// import { defaultRoute } from '../../../BatchCreate/BatchCreate.utils'
import {
  Channel,
  channelsLimits,
  FILE_EXT,
  FILE_SIZE_KB,
} from '../../../BatchCreate/components/BatchForm/BatchForm.utils'
import type {
  Route,
  RoutesType,
} from '../../../BatchCreate/components/BatchForm/BatchForm.utils'
import Draft from '../../../BatchCreate/components/Draft/Draft.vue'
import FormUploadImage from '../../../BatchCreate/components/FormUploadImage/FormUploadImage.vue'
// import SelectDraft from '../../../BatchCreate/components/SelectDraft/SelectDraft.vue'
import ShortenUrlHint from '../../../BatchCreate/components/ShortenUrlHint/ShortenUrlHint.vue'

const t = useComponentI18n('batchCreate.form')
const tFile = useComponentI18n('batchCreate.form.message.file')
// const tHint = useComponentI18n('batchCreate.hint')
const tChannels = useComponentI18n('channels')
const tGroupForm = useComponentI18n('group.groupForm')
const { profile } = useProfile()

const originatorDisable = computed(() => {
  return profile?.value?.selfRegistered
})

const props = defineProps({
  routes: Object as PropType<RoutesType>,
  startTime: String,
})

const startTime = computed(() => {
  return props.startTime ? `02-03-2023 ${props.startTime}` : undefined
})

const fileLocale = {
  title: tFile('title'),
  deleteFile: tFile('delete'),
  errors: {
    WrongFormat: tFile('errors.WrongFormat'),
    FileTooLarge: tFile('errors.FileTooLarge'),
  },
}

const commonDraft = ref<DraftType | undefined>(undefined)
const handleCommonDraftReset = () => {
  commonDraft.value = undefined
}

const routeApp = useRoute()
const queryDraftId = routeApp.query.draftId as unknown as number | undefined
const isSameMessages = useFieldValue<boolean[]>('isSameMessages')

const defaultId = computed(() => {
  // eslint-disable-next-line @typescript-eslint/init-declarations
  let idRoute: string | undefined

  if (messageRouteOptions.value) {
    messageRouteOptions.value.forEach((item) => {
      if (item.label === 'SMS' && item.value === 'sms') {
        idRoute = '0'
      }
      if (item.label === 'SMS' && item.value !== 'sms') {
        idRoute = item.value
      }
    })
  }
  return idRoute
})

useQuery(
  ['initial-draft'],
  () => {
    if (queryDraftId) {
      return apiClient.draft.draft_GET_BY(queryDraftId)
    }
  },
  {
    enabled: Boolean(queryDraftId),
    onSuccess: (response) => {
      if (response) {
        commonDraft.value = response.data.draft
      }
    },
  }
)

const messageRouteOptions = computed(() => {
  if (props.routes) {
    const vv = Object.entries(props.routes).map(
      ([routeGroupId, routeGroup]) => ({
        value: routeGroupId,
        label: routeGroup.map((route) => tChannels(route)).join(' > '),
      })
    )
    return vv
  }
  return undefined
})

const routeGroupIdValue = useFieldValue<string>('routeGroupId')
const routeGroup = computed(() => props?.routes[routeGroupIdValue?.value])

const getChannelForDraft = (route: Route) => {
  if (route === 'ok') {
    return 'vk'
  }

  if (route === 'push' || route === 'email') {
    return 'all'
  }

  return route
}

const rusValue = useFieldValue<boolean[] | undefined>('rus')
const isCyrillic = computed(() => {
  /*
    Из-за особенности работы vee-validate с частями формы, которые
    появляются и скрываются, значение может быть undefined
  */
  if (rusValue.value === undefined) {
    return false
  }

  return checkboxToBoolean(rusValue.value)
})

const smsMessageValue = useFieldValue<string | undefined>(
  'channels.sms.message'
)
const { value: viberUrl, handleChange: viberResetImg } = useField<
  string | undefined
>('channels.viber.fileUrl')

const { value: whatsappUrl, handleChange: whatsappResetImg } = useField<
  string | undefined
>('channels.whatsapp.fileUrl')

const removeImg = (router: string) => {
  if (router === 'viber') {
    viberResetImg('')
  }
  if (router === 'whatsapp') {
    whatsappResetImg('')
  }
}

const smsFormData = computed(() =>
  getSmsFormData(smsMessageValue.value ?? '', isCyrillic.value)
)

const getCharacterLimit = (route: Route) => {
  if (route === Channel.Sms) {
    return smsFormData.value.characterLimit
  }

  return channelsLimits[route]
}

const getBadge = (route: Route) => {
  if (route === Channel.Sms) {
    return smsFormData.value.badge
  }

  return undefined
}

const isShortenUrl = computed(() => profile.value?.shortenUrl)

const formSelectActive = ref(true)

const getHint = (route: Route) => {
  if (route === Channel.Sms && isShortenUrl.value) {
    return ShortenUrlHint
  }
  return undefined
}
</script>

<style module>
.marginBottom8 {
  margin-bottom: var(--gap-8);
}

.marginTop24 {
  margin-top: var(--gap-24);
}

.marginBottom24 {
  margin-bottom: var(--gap-24);
}

.channelCard {
  margin-top: var(--gap-4);
}

.channelTitle {
  margin-bottom: var(--gap-12);
}

.uploadImg {
  width: 120px;
}

.imgInner {
  position: relative;

  width: fit-content;
}

.close {
  position: absolute;
  top: -4px;
  right: -20px;
  z-index: 999999;

  cursor: pointer;
}
</style>
