<template>
  <div :class="$style.content">
    <router-link :class="$style.logo" :to="{ name: RouteNames.Dashboard }">
      <Logo :withText="!isCollapsed" />
    </router-link>

    <router-link
      :to="{ name: RouteNames.Dashboard }"
      :class="[$style.link, $style.linkHome, isCollapsed && $style.onlyIcon]"
      :exactActiveClass="$style.isActive"
    >
      <span v-if="!isCollapsed" :class="$style.linkContent">
        <IconHome :class="$style.icon" />
        {{ $t('sidebar.menu.home') }}
      </span>
      <span v-else :class="$style.linkContent">
        <IconHome :class="$style.icon" />
      </span>
    </router-link>
    <div v-for="item in menu" :key="item.title" :class="$style.group">
      <span v-if="!isCollapsed" :class="$style.groupTitle">
        {{ item.title }}
      </span>
      <span v-else :class="$style.groupTitle"></span>
      <ul :class="$style.groupList">
        <li
          v-for="link in item.list"
          :key="link.title"
          :class="$style.listItem"
        >
          <router-link
            :to="link.to"
            :class="[$style.link, isCollapsed && $style.onlyIcon]"
            :activeClass="$style.isActive"
          >
            <span v-if="!isCollapsed" :class="$style.linkContent">
              <component :is="link.icon" :class="$style.icon" />
              {{ link.title }}
            </span>
            <span v-else :class="$style.linkContent">
              <component :is="link.icon" :class="$style.icon" />
            </span>
          </router-link>
        </li>
      </ul>
    </div>
    <router-link
      :to="{ name: RouteNames.SettingsGeneral }"
      :class="[
        $style.link,
        $style.linkSettings,
        isCollapsed && $style.onlyIcon,
      ]"
      :activeClass="$style.isActive"
    >
      <span v-if="!isCollapsed" :class="$style.linkContent">
        <IconCog :class="$style.icon" />
        {{ $t('sidebar.menu.settings') }}
      </span>
      <span v-else :class="$style.linkContent">
        <IconCog :class="$style.icon" />
      </span>
    </router-link>
    <LanguageSelector :class="$style.lang" :isCollapsed="isCollapsed" />
  </div>
</template>

<script setup lang="ts">
import type { IconProps } from '@smst/ui'
import {
  IconAttach,
  IconCheckbox,
  IconCog,
  IconDeferred,
  IconDoc,
  IconDraft,
  IconFind,
  IconHistory,
  IconHome,
  IconJournal,
  IconList,
  IconListOfTemp,
  IconNoData,
  IconRegistrationCard,
  IconSendMessage,
  IconStats,
  IconTest,
} from '@smst/ui'
import { isDefined } from 'remeda'
import type { DefineComponent } from 'vue'
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import LanguageSelector from '@/components/LanguageSelector/LanguageSelector.vue'
import Logo from '@/components/Logo/index.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { usePermissions } from '@/hooks/usePermissions'
import { useProfile } from '@/hooks/useProfile'
import { RouteNames } from '@/routeNames'

type Menu = Array<{
  title: string
  list: Array<{
    title: string
    to: RouteLocationRaw
    icon: DefineComponent<IconProps>
  }>
}>

defineProps<{ isCollapsed: boolean }>()

const permissions = usePermissions()
const { profileType, profile } = useProfile()
const hasReportOnRequest = profile.value?.showReportsOnRequest

const t = useComponentI18n('sidebar.menu')

const menu = computed<Menu>(() => {
  return [
    permissions.value.batches.canView || profileType === 'selfRegistered'
      ? {
          title: t('batches'),
          list: [
            {
              title: t('sendMessages'),
              to: { name: RouteNames.BatchCreate },
              icon: IconSendMessage,
            },
            {
              title: t('sendLists'),
              to: { name: RouteNames.Groups },
              icon: IconList,
            },
            {
              title: t('drafts'),
              to: { name: RouteNames.Drafts },
              icon: IconDraft,
            },
            {
              title: t('scheduledBatches'),
              to: { name: RouteNames.Batches },
              icon: IconDeferred,
            },
            {
              title: t('checkMobileOperator'),
              to: { name: RouteNames.OperatorByPhone },
              icon: IconFind,
            },
          ],
        }
      : undefined,
    permissions.value.templates.canView
      ? {
          title: t('templates'),
          list: [
            {
              title: t('templatesList'),
              to: { name: RouteNames.TemplatesList },
              icon: IconListOfTemp,
            },
            {
              title: t('templatesCheck'),
              to: { name: RouteNames.TemplatesCheck },
              icon: IconTest,
            },
            {
              title: t('templatesTest'),
              to: { name: RouteNames.TemplatesTest },
              icon: IconCheckbox,
            },
          ],
        }
      : undefined,
    permissions.value.reports.canView || profileType === 'selfRegistered'
      ? {
          title: t('reports'),
          list: [
            {
              title: t('log'),
              to: { name: RouteNames.MessagesLog },
              icon: IconJournal,
            },
            {
              title: t('statistics'),
              to: { name: RouteNames.Statistics },
              icon: IconStats,
            },
            {
              title: t('deliveryAnalysis'),
              to: { name: RouteNames.DeliveryAnalysis },
              icon: IconNoData,
            },
            hasReportOnRequest
              ? {
                  title: t('reportsOnRequest'),
                  to: { name: RouteNames.ReportsOnRequest },
                  icon: IconAttach,
                }
              : undefined,
          ].filter(isDefined),
        }
      : undefined,
    permissions.value.accounting.canView
      ? {
          title: t('accounting'),
          list: [
            {
              title: t('accountDetails'),
              to: { name: RouteNames.AccountingInfo },
              icon: IconRegistrationCard,
            },
            {
              title: t('accountsAndInvoices'),
              to: { name: RouteNames.Accounting },
              icon: IconDoc,
            },
            {
              title: t('transactionsHistory'),
              to: { name: RouteNames.TransactionsHistory },
              icon: IconHistory,
            },
          ],
        }
      : undefined,
  ].filter(isDefined)
})
</script>

<style module src="./Sidebar.module.css"></style>
