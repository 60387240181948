import { initOldPasswordUtils } from './PasswordForm/OldPasswordForm/OldPasswordForm.utils'
import { initPasswordUtils } from './PasswordForm/PasswordForm.utils'
import { initUserPasswordUtils } from './PasswordForm/UserPasswordForm/UserPasswordForm.utils'

// Key = { password: string; oldPassword: string; userPassword: string }

const KeysFactory = {
  createPassword: initPasswordUtils,
  changePassword: initOldPasswordUtils,
  changeUserPassword: initUserPasswordUtils,
}

export function initPassword(key: string) {
  if (!key) throw new Error('Provide key')
  const init = KeysFactory[key]
  return init()
}
