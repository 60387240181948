<template>
  <Drawer
    :modelValue="isOpen"
    :closeText="t('details.closeHint')"
    @update:modelValue="handleClose"
  >
    <template v-if="data" #title>
      <Button
        type="button"
        view="text"
        :iconLeft="IconChevron"
        @click="handleBack"
      >
        {{ t('details.title') }}
        {{ data?.name }}
      </Button>

      <Text tag="h3" :class="$style.header">
        {{ t('edit.title') }}
        {{ data?.name }}
      </Text>
    </template>

    <div :class="[$style.root, Boolean(data) && $style.hasData]">
      <template v-if="data">
        <EditForm
          formId="batch-edit"
          :data="data"
          :imagesData="imagesData"
          :error="editError"
          @metaChange="handleMetaChange"
          @submit="handleSubmit"
        />
      </template>

      <template v-else>
        <Loader v-if="isLoading" size="m" />

        <ApiErrorMessage :error="error" />
      </template>
    </div>

    <template v-if="data" #buttons>
      <Button
        type="submit"
        form="batch-edit"
        :loading="isEditBatchLoading"
        :disabled="isSubmitDisabled"
      >
        {{ t('edit.submitButton') }}
      </Button>
      <Button type="button" view="link" @click="handleBack">
        {{ t('edit.cancelButton') }}
      </Button>
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import { Button, Drawer, IconChevron, Loader, Text, toaster } from '@smst/ui'
import type { FormMeta } from 'vee-validate'
import { ref } from 'vue'
import type { ComputedRef, PropType } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import type {
  BatchEditForm,
  BatchExtResult,
} from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'

import type { ImageData } from '../../Batches.utils'

import type { FormValues } from './components/EditForm.utils'
import EditForm from './components/EditForm.vue'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  data: Object as PropType<BatchExtResult>,
  imagesData: {
    type: Array as PropType<ImageData[]>,
    required: true,
  },
  error: Object as PropType<unknown>,
})

const t = useComponentI18n('batches')

const emit = defineEmits(['close', 'back'])

const handleClose = () => {
  emit('close')
}

const handleBack = () => {
  emit('back')
}

const queryClient = useQueryClient()

const {
  mutate: editBatch,
  isLoading: isEditBatchLoading,
  error: editError,
} = useMutation(
  async (values: BatchEditForm) => {
    await apiClient.batches.batches_PUT(Number(props.data?.batchId), values)
  },
  {
    onSuccess: () => {
      toaster.success(t('edit.successEdit'))

      void queryClient.invalidateQueries([
        'batchDetails',
        String(props.data?.batchId),
      ])

      void queryClient.invalidateQueries('batches')

      handleBack()
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)

const isSubmitDisabled = ref(false)

const handleMetaChange = (meta: ComputedRef<FormMeta<FormValues>>) => {
  isSubmitDisabled.value = !meta.value.dirty
}

const handleSubmit = (values: BatchEditForm) => {
  editBatch(values)
}
</script>

<style module>
.header {
  margin-top: var(--gap-20);
}
</style>
