<template>
  <FormControlBase v-slot="{ invalid }" :name="name">
    <Radio
      :modelValue="modelValue"
      :value="value"
      :label="label"
      :name="name"
      :invalid="invalid"
      :disabled="disabled"
      @input="handleChange(value)"
    >
      <slot></slot>
    </Radio>
  </FormControlBase>
</template>

<script lang="ts">
import { Radio } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes } from 'vue'
import { defineComponent } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formRadioProps = {
  ...formControlProps,
  value: {
    type: String,
    required: true,
  },
} as const

export type FormRadioProps = ExtractPropTypes<typeof formRadioProps>

export default defineComponent({
  components: { FormControlBase, Radio },
  props: formRadioProps,
  setup(props) {
    const { value, handleChange } = useField(props.name, undefined, {
      type: 'radio',
      checkedValue: props.value,
    })

    return {
      modelValue: value,
      handleChange,
    }
  },
})
</script>
