<template>
  <FormControlBase :name="name">
    <VueRecaptcha
      :sitekey="siteKey"
      theme="light"
      size="normal"
      :tabindex="0"
      @verify="callbackVerify($event)"
    />
  </FormControlBase>
</template>

<script lang="ts">
import { useField } from 'vee-validate'
import type { ExtractPropTypes } from 'vue'
import { defineComponent } from 'vue'
import VueRecaptcha from 'vue3-recaptcha2'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formRecaptchaProps = {
  ...formControlProps,
  secondTime: Boolean,
} as const

export type FormRecaptchaProps = ExtractPropTypes<typeof formRecaptchaProps>

export default defineComponent({
  components: { FormControlBase, VueRecaptcha },
  props: formRecaptchaProps,
  setup(props, { emit }) {
    const { handleChange } = useField(props.name)

    const callbackVerify = (response: string) => {
      if (props.secondTime) emit('reCaptcha', response)
      else handleChange(response)
    }

    const siteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY as string

    return {
      callbackVerify,
      siteKey,
    }
  },
})
</script>
