<template>
  <form :id="formId" @submit="handleSubmit">
    <FormControls :controls="formControls" />
  </form>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import type { PropType } from 'vue'
import { watchEffect } from 'vue'

import FormControls from '@/components/FormControls/FormControls.vue'

import type { FormValues } from './EditMember.utils'
import { getFormControls, validationSchema } from './EditMember.utils'

const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  initialValues: Object as PropType<FormValues>,
})

const emit = defineEmits(['submit', 'changeMeta'])

const formControls = getFormControls()

const { handleSubmit: handleSubmitForm, meta } = useForm<FormValues>({
  initialValues: props.initialValues,
  validationSchema,
})

watchEffect(() => {
  emit('changeMeta', meta)
})

const handleSubmit = handleSubmitForm((values) => {
  emit('submit', values)
})
</script>
