<template>
  <div :class="[$style.main, $style[`size-${size}`]]">
    <slot />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export const gridSizes = ['s', 'm'] as const

type GridSize = (typeof gridSizes)[number]

export default defineComponent({
  props: {
    size: {
      type: String as PropType<GridSize>,
      default: 'm',
    },
  },
})
</script>

<style module>
.main {
  --left-column: 506px;
  --right-column: 414px;

  display: grid;
  grid-template-columns: var(--left-column) minmax(auto, var(--right-column));
  gap: var(--gap-12);
}

.size-s {
  --right-column: 345px;
}
</style>
