import { i18n } from '@/i18n'

type TwoFactorAuth = {
  enabled: boolean
  phone: number | null
}

const { t } = i18n.global

export const getTwoFactorAuthStatus = (twoFactorAuth: TwoFactorAuth) => {
  if (twoFactorAuth.enabled) {
    return twoFactorAuth.phone as number
  }

  return t('twoFactorAuth.notEnabled')
}
