<template>
  <div>
    <FiltersPanel
      :items="filterItems"
      @open="handleDrawerOpen"
      @clear="handleFilterClear"
    />

    <Drawer
      v-model="isDrawerOpen"
      :title="$t('filter.drawer.title')"
      :closeText="$t('filter.drawer.close')"
    >
      <FiltersForm
        :id="formId"
        v-model="filtersFormValues"
        :defaultValues="defaultValues"
        :controls="controls"
        :validationSchema="validationSchema"
        @submit="handleSubmit"
      />

      <template #buttons>
        <slot name="extra-button" :formValues="filtersFormValues" />
        <Button type="submit" :form="formId">
          {{ $t('filter.drawer.submit') }}
        </Button>
      </template>
    </Drawer>
  </div>
</template>

<script lang="ts">
import { Button, Drawer } from '@smst/ui'
import { mapValues } from 'remeda'
import { computed, defineComponent, ref, watch } from 'vue'

import FiltersPanel from '@/components/FiltersPanel/FiltersPanel.vue'
import { useId } from '@/hooks/useId'

import type { FiltersFormValues } from './Filters.types'
import { filtersProps } from './Filters.types'
import { getFilterItemsDescription } from './Filters.utils'
import FiltersForm from './FiltersForm.vue'

export default defineComponent({
  components: { FiltersForm, FiltersPanel, Drawer, Button },
  props: {
    ...filtersProps,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const filtersFormValues = ref(props.modelValue)
    const isDrawerOpen = ref<boolean>(false)

    const handleDrawerOpen = () => {
      isDrawerOpen.value = true
    }

    const filterItems = computed(() =>
      getFilterItemsDescription(props.modelValue, props.controls)
    )

    // Сбрасываем форму в ящике при открытии
    watch(
      () => isDrawerOpen.value,
      (newIsDrawerOpen) => {
        if (newIsDrawerOpen) {
          filtersFormValues.value = props.modelValue
        }
      }
    )

    const handleFilterClear = () => {
      const clearedValue = mapValues(props.modelValue, () => undefined)
      emit('update:modelValue', clearedValue)
    }

    const handleSubmit = (values: FiltersFormValues) => {
      isDrawerOpen.value = false

      emit(
        'update:modelValue',
        mapValues(values, (value) => {
          // Если пустая строка меням на undefined
          // иначе отправится запрос с пустой строкой и в панели фильтра отобразится только label
          if (value === '') {
            return undefined
          }

          return value
        })
      )
    }

    return {
      formId: `filtersForm-${useId()}`,
      isDrawerOpen,
      filterItems,
      filtersFormValues,
      handleSubmit,
      handleDrawerOpen,
      handleFilterClear,
    }
  },
})
</script>
