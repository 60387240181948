<template>
  <div :class="$style.downloadDocument">
    <div :class="$style.cell">
      <Button
        v-if="isDocumentTypeVisible(DocumentType.Schet)"
        view="link"
        :iconLeft="IconDownload"
        @click="handleDownload(DocumentType.Schet)"
      >
        {{ t('schet') }}
      </Button>
    </div>

    <div v-if="isDocumentTypeVisible(DocumentType.Act)" :class="$style.cell">
      <Button
        view="link"
        :iconLeft="IconDownload"
        @click="handleDownload(DocumentType.Act)"
      >
        {{ t('act') }}
      </Button>
    </div>

    <div
      v-if="isDocumentTypeVisible(DocumentType.Factura)"
      :class="$style.cell"
    >
      <Button
        view="link"
        :iconLeft="IconDownload"
        @click="handleDownload(DocumentType.Factura)"
      >
        {{ t('factura') }}
      </Button>
    </div>

    <div v-if="isAllDocumentTypeVisible()" :class="$style.cell">
      <Button
        view="link"
        :iconLeft="IconAllDownload"
        @click="handleAllDownload()"
      >
        {{ t('all') }}
      </Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Button, IconAllDownload, IconDownload, toaster } from '@smst/ui'
import { type PropType } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { DocumentType } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { downloadFile } from '@/utils/downloadFile'
import { getErrorMessage } from '@/utils/errors'

import type { DocumentsPrintAllParams } from '../../Accounting.types'

import type { ViewTypes } from './DownloadDocuments.types'

type DocumentFormat = 'pdf' | 'html'

const props = defineProps({
  documentId: {
    type: Number,
    required: true,
  },

  viewTypes: {
    type: Array as PropType<ViewTypes[]>,
    required: true,
  },

  documentFormat: {
    type: String as PropType<DocumentFormat>,
    default: 'pdf',
  },
})

const t = useComponentI18n('accounting.viewTypes')

const isDocumentTypeVisible = (type: ViewTypes) => {
  return props.viewTypes.includes(type)
}
const isAllDocumentTypeVisible = () => {
  return props.viewTypes.length > 1
}

type AccountingDocumentParams = {
  documentType: ViewTypes
  documentFormat: DocumentFormat
}

const { mutate: downloadDocument } = useMutation(
  async (values: AccountingDocumentParams) => {
    return await apiClient.accounting.document_GET_BY(
      props.documentId,
      values,
      {
        format: 'blob',
      }
    )
  },
  {
    onSuccess: (response, { documentType }) => {
      void downloadFile({
        response,
        fileName: `${documentType}-${props.documentId}`,
        extension: props.documentFormat,
      })
    },

    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)

const { mutate: documentsPrintAll } = useMutation(
  (values: DocumentsPrintAllParams) => {
    return apiClient.accounting.documentsPrintAll_POST(values, {
      format: 'blob',
    })
  },
  {
    onSuccess: (response) => {
      void downloadFile({
        response,
        fileName: String(props.documentId),
        extension: props.documentFormat,
      })
    },

    onError: (documentsPrintError) => {
      toaster.error(getErrorMessage(documentsPrintError))
    },
  }
)

const options = {
  all: false,
  include: [props.documentId],
  exclude: [],
}

const handleDownload = (type: ViewTypes) => {
  downloadDocument({
    documentType: type,
    documentFormat: props.documentFormat,
  })
}
const handleAllDownload = () => {
  documentsPrintAll({
    // Договоренность: пока только формат pdf
    format: 'pdf',
    selectedElements: options,
  })
}
</script>

<style module>
.downloadDocument {
  display: grid;
  grid-template-columns: repeat(4, 89px);

  column-gap: var(--gap-8);
}

.cell {
  display: flex;
  justify-content: center;
}
</style>
