type Value = string | number | Blob

export const getFormData = (values: Record<string, Value>): FormData => {
  const formData = new FormData()

  const getValue = (value: Value): Blob | string =>
    typeof value === 'number' ? String(value) : value

  for (const key in values) {
    if (values[key]) {
      formData.append(key, getValue(values[key]))
    }
  }

  return formData
}
