<template>
  <Dropdown
    :items="items"
    :classes="{
      dropdownItems: $style.dropdownItems,
    }"
  >
    <template #trigger>
      <DropdownMenuButton :class="$style.button">
        <IconGlobe :class="$style.iconLeft" />

        <span v-if="!isCollapsed" :class="$style.content">
          {{ title }}

          <IconDown :class="$style.iconRight" />
        </span>
      </DropdownMenuButton>
    </template>
  </Dropdown>
</template>

<script lang="ts">
import { Dropdown, DropdownMenuButton, IconDown, IconGlobe } from '@smst/ui'
import { computed, defineComponent } from 'vue'
import type { Locale } from 'vue-i18n'
import { useI18n } from 'vue-i18n'

import { useComponentI18n } from '@/hooks/useComponentI18n'

import type { LocaleLabel } from './LanguageSelector.types'

const LOCAL_STORAGE_LOCALE_KEY = 'locale'

export default defineComponent({
  components: { Dropdown, DropdownMenuButton, IconGlobe, IconDown },

  props: {
    isCollapsed: Boolean,
  },

  setup(props) {
    const t = useComponentI18n('languageSelector')
    const { locale, availableLocales } = useI18n()

    const locales: Record<Locale, LocaleLabel> = {
      ru: {
        long: t('ru.long'),
        short: t('ru.short'),
      },
      en: {
        long: t('en.long'),
        short: t('en.short'),
      },
    }

    const variant = props.isCollapsed ? 'short' : 'long'

    const items = availableLocales.map((item) => ({
      label: locales[item][variant],

      onClick: () => {
        if (locale.value !== item) {
          locale.value = item
          // Сохраняем новую локаль в Local Storage
          localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, item)
        }
      },
    }))

    const title = computed(() => locales[locale.value].long)

    return { locale, availableLocales, items, title }
  },
})
</script>

<style module>
.button {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 0;

  color: inherit;

  font-weight: 500;

  font-size: var(--font-size-m);
  line-height: var(--line-height-m);

  background-color: transparent;
  border: none;
  cursor: pointer;
}

.button:hover {
  color: var(--color-text-menu-grey);
}

.button:focus-visible {
  border-radius: var(--border-radius-s);
  outline: var(--focus-outline);
  outline-offset: var(--gap-4);
}

.iconLeft,
.iconRight {
  flex-shrink: 0;
}

.iconRight {
  margin-left: auto;
}

.content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-left: var(--gap-24);

  font-family: var(--font-family);
}

.dropdownItems {
  z-index: 2;

  width: 100%;
  min-width: 44px;
}
</style>
