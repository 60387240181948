import type {
  Operator,
  OperatorShortResult,
} from '@/api/__generated__/api.schema'

export const getOperatorName = (operator: Operator | OperatorShortResult) => {
  if ('operator' in operator) {
    return `${operator.country.country} - ${operator.operator}`
  }

  return `${operator.country.countryCode} - ${operator.name}`
}
