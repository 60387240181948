import { ApiError } from '@/api'
import { i18n } from '@/i18n'

const { t } = i18n.global

export const getErrorMessage = (error: unknown) => {
  if (error instanceof ApiError) {
    return t(`error.${error.code}`, t('error.general'))
  }

  if (error instanceof Error) {
    switch (error.message) {
      case 'Network Error':
        return t('error.network')
    }
  }

  return t('error.general')
}
