import { isDefined, values } from 'remeda'

export const omitBy = <T>(
  obj: Record<string, T>,
  fn: (val: T) => boolean
): Record<string, T> => {
  return Object.fromEntries(Object.entries(obj).filter(([, val]) => fn(val)))
}

export const hasValues = (obj: Record<PropertyKey, unknown>): boolean =>
  values(obj).some(isDefined)
