import type { TableTypes } from '@smst/ui'
import { computed } from 'vue'

import type { ClientDocument } from '@/api/__generated__/api.schema'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import { i18n } from '@/i18n'

import { documentStatusOptions } from './Accounting.utils'

const { t } = i18n.global
// const t = useComponentI18n('accounting')

export const controls: ControlType[] = [
  {
    control: 'datepicker',
    name: 'date',
    range: true,
    label: t('accounting.filters.date'),
  },
  {
    control: 'select',
    name: 'documentStatus',
    label: t('accounting.filters.documentStatus'),
    options: documentStatusOptions,
    withOptionAll: true,
  },
]

export const columns = computed<Array<TableTypes.Column<ClientDocument>>>(
  () => [
    {
      name: t('accounting.column.enterDate'),
      prop: 'enterDate',
      sortable: true,
    },
    {
      name: t('accounting.column.recordId'),
      prop: 'number',
      sortable: true,
    },
    {
      name: t('accounting.column.sumSNds'),
      prop: 'sumSNds',
      sortable: true,
    },
    {
      name: t('accounting.column.viewTypes'),
      prop: 'viewTypes',
    },
    {
      name: t('accounting.column.documentStatus'),
      prop: 'documentStatus',
    },
  ]
)
