<template>
  <FormControlBase v-slot="{ invalid }" :name="name">
    <Input
      v-model.trim="fieldValue"
      :type="type"
      :label="label"
      :placeholder="placeholder"
      :invalid="invalid"
      :disabled="disabled"
      v-bind="$attrs"
      @blur="handleBlur"
      @keyup.space="handleInputChange(fieldValue)"
      @keypress.prevent.enter="handleInputChange(fieldValue)"
    />
  </FormControlBase>

  <template v-if="fieldValues.length > 0">
    <div :class="$style.tags">
      <div
        v-for="option in fieldValues"
        :key="option"
        :class="[$style.tag, disabled && $style.tagDisabled]"
      >
        <span :class="$style.overflowText" :title="option">
          {{ option }}
        </span>
        <ButtonIcon
          title=""
          :class="$style.removeTag"
          :disabled="disabled"
          @click="handleRemove(option)"
        >
          <IconClose />
        </ButtonIcon>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { ButtonIcon, IconClose, Input } from '@smst/ui'
import { uniq } from 'remeda'
import { useField } from 'vee-validate'
import type { ExtractPropTypes } from 'vue'
import { defineComponent, ref } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formInputProps = {
  ...formControlProps,
  type: String,
  placeholder: String,
} as const

export type FormInputProps = ExtractPropTypes<typeof formInputProps>

export default defineComponent({
  components: { FormControlBase, Input, ButtonIcon, IconClose },
  inheritAttrs: false,
  props: formInputProps,
  setup(props) {
    const {
      value: fieldValues,
      setValue,
      handleBlur,
    } = useField<string[]>(props.name, undefined, {
      initialValue: [],
    })

    const fieldValue = ref<string>('')

    const handleInputChange = (value: string) => {
      if (value === '') return

      setValue(uniq([...fieldValues.value, value]))
      fieldValue.value = ''
    }

    const handleRemove = (targetValue: string) => {
      setValue(fieldValues.value.filter((val) => targetValue !== val))
    }

    return {
      fieldValue,
      fieldValues,

      handleBlur,
      handleInputChange,
      handleRemove,
    }
  },
})
</script>

<style module>
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-4);
  align-content: flex-start;
  width: 100%;
  margin-top: var(--gap-8);
  padding: var(--gap-4) 0;
}

.tag {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--gap-8);

  color: var(--color-text-dark);

  font-size: var(--font-size-s);
  line-height: var(--line-height-s);

  background-color: var(--color-bg-white);
  border: 1px solid var(--color-stroke-blocks);
  border-radius: 20px;
}

.tagDisabled {
  background-color: var(--color-bg-form-disabled);
}

.overflowText {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.removeTag {
  margin-left: var(--gap-4);

  color: var(--color-text-menu-grey);
}

.groupLabel {
  position: relative;

  padding: var(--gap-12);

  font-weight: 600;

  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
}

.groupLabel::after {
  position: absolute;
  right: var(--gap-12);
  bottom: 0;
  left: var(--gap-12);

  border-top: 1px solid var(--color-stroke-blocks);

  content: '';
}
</style>
