<template>
  <Drawer
    :modelValue="isOpen"
    :closeText="t('closeHint')"
    @update:modelValue="handleClose"
  >
    <template #title>
      <Button
        type="button"
        view="text"
        :iconLeft="IconChevron"
        @click="handleCancel"
      >
        {{ draft.name }}
      </Button>
    </template>

    <Text tag="h2" :class="$style.title">{{ t('title') }}</Text>

    <DraftForm
      formId="draft-edit"
      :initialValues="draft"
      @submit="handleSubmit"
    />

    <ApiErrorMessage :error="error" :class="$style.error" />

    <template #buttons>
      <Button type="submit" form="draft-edit" :loading="isLoading">
        {{ t('buttons.save') }}
      </Button>
      <Button type="button" view="link" @click="handleCancel">
        {{ t('buttons.cancel') }}
      </Button>
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import { Button, Drawer, IconChevron, Text } from '@smst/ui'
import type { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'vue-query'
import { useRoute, useRouter } from 'vue-router'

import { apiClient } from '@/api'
import type { DraftResult } from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'

import type { FormValues as DraftFormValues } from '../DraftForm.schema'
import DraftForm from '../DraftForm.vue'

const router = useRouter()
const route = useRoute()

const t = useComponentI18n('draft.edit')

const props = defineProps({
  id: Number,
  isOpen: Boolean,
})

const emit = defineEmits(['changeMode'])

const queryClient = useQueryClient()
const data = queryClient.getQueryData<AxiosResponse<DraftResult>>([
  'draft',
  props.id,
])
const draft = data?.data.draft

if (!draft) {
  throw new Error(`There is no data for editing a draft with id  ${props.id}`)
}

const {
  mutate: putDraft,
  isLoading,
  error,
} = useMutation(
  async (values: DraftFormValues) => {
    await apiClient.draft.draft_PUT(draft.draftId, values)
  },
  {
    onSuccess: () => {
      void queryClient.invalidateQueries('drafts')
      emit('changeMode', 'view')
    },
  }
)

const handleClose = () => {
  void router.push({ ...route, name: RouteNames.Drafts })
  emit('changeMode', 'view')
}

const handleCancel = () => {
  emit('changeMode', 'view')
}

const handleSubmit = (values: DraftFormValues) => {
  putDraft(values)
}
</script>

<style module>
.title {
  margin-bottom: var(--gap-24);
}

.error {
  margin-top: var(--gap-24);
}
</style>
