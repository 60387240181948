<template>
  <Text tag="h1" :class="$style.title">{{ t('title') }}</Text>

  <Grid>
    <Row>
      <template #default>
        <Card :title="t('card')">
          <form @submit.prevent="onSubmit">
            <FormControls :controls="formControls" />

            <Button
              fullWidth
              type="submit"
              :class="$style.submit"
              :loading="isLoading"
            >
              {{ t('submit') }}
            </Button>
          </form>
        </Card>
      </template>

      <template #right>
        <HintCard v-html="t('hint')" />
      </template>
    </Row>

    <Row v-if="isSuccess">
      <Card>
        <Text
          v-if="isTestSuccessful"
          color="success"
          weight="medium"
          :class="$style.result"
        >
          <IconCheck />
          {{ t('result.success') }}
        </Text>
        <Text v-else color="error" weight="medium" :class="$style.result">
          <IconError />
          {{ t('result.fail') }}
        </Text>
      </Card>
    </Row>
  </Grid>
</template>

<script lang="ts" setup>
import { Button, IconCheck, IconError, Text, toaster } from '@smst/ui'
import { StringParam, withDefault } from 'serialize-query-params'
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import { useMutation } from 'vue-query/esm'

import { apiClient } from '@/api'
import Card from '@/components/Card/Card.vue'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormControls from '@/components/FormControls/FormControls.vue'
import { Grid, Row } from '@/components/Grid'
import HintCard from '@/components/HintCard/HintCard.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useQueryParams } from '@/hooks/useQueryParams'
import { getErrorMessage } from '@/utils/errors'

import type { TemplatesTestForm } from './TemplatesTest.schema'
import { templatesTestSchema } from './TemplatesTest.schema'

const t = useComponentI18n('templatesTest')

const { query } = useQueryParams({
  template: withDefault(StringParam, ''),
  message: withDefault(StringParam, ''),
})

const {
  data,
  isLoading,
  isSuccess,
  mutate: handleTest,
} = useMutation(
  'templatesTest',
  (value: TemplatesTestForm) => {
    return apiClient.templates.test_POST(value)
  },
  {
    onError: (err) => {
      toaster.error(getErrorMessage(err))
    },
  }
)

const isTestSuccessful = computed(() => data.value?.data.success)

const initialValues = computed<TemplatesTestForm>(() => ({
  template: query.value.template,
  message: query.value.message,
}))

const { handleSubmit } = useForm({
  initialValues,
  validationSchema: templatesTestSchema,
})

const onSubmit = handleSubmit((values) => {
  const params: TemplatesTestForm = {
    message: String(values.message),
    template: String(values.template),
  }

  query.value = params
  handleTest(params)
})

if (query.value.message && query.value.template) {
  void onSubmit()
}

const formControls: ControlType[] = [
  {
    control: 'textarea',
    name: 'template',
    label: t('templateLabel'),
    transliteration: false,
  },
  {
    control: 'textarea',
    name: 'message',
    label: t('messageLabel'),
    transliteration: false,
  },
]
</script>

<style module>
.title {
  margin-bottom: var(--gap-40);
}

.submit {
  margin-top: var(--gap-20);
}

.result {
  display: flex;
  gap: var(--gap-8);
  align-items: center;
}
</style>
