<template>
  <div>
    <Dropdown
      :position="['down', 'right']"
      :label="t('dropdownLabel')"
      :items="dropdownItems"
      :disabled="disabled"
    />

    <Modal
      v-if="isOpenDelete"
      v-model="isOpenDelete"
      :title="tActions('delete.title')"
      :acceptButton="tActions('delete.confirm')"
      :cancelButton="tActions('delete.cancel')"
      @accept="handleDeleteAccept"
    />
  </div>
</template>

<script setup lang="ts">
import { Dropdown, IconDelete, IconSendMessage, Modal } from '@smst/ui'
import { ref } from 'vue'

import { useComponentI18n } from '@/hooks/useComponentI18n'

const t = useComponentI18n('groups')
const tActions = useComponentI18n('groups.multiplyActions')

defineProps({
  disabled: {
    type: Boolean,
    required: true,
  },
})
const emit = defineEmits(['deleteGroup', 'sendGroup'])

const isOpenDelete = ref(false)

const dropdownItems = [
  {
    icon: IconSendMessage,
    label: t('dropdownItems.send'),
    onClick: () => emit('sendGroup'),
  },

  {
    icon: IconDelete,
    label: t('dropdownItems.delete'),
    onClick: () => (isOpenDelete.value = true),
  },
]

const handleDeleteAccept = () => {
  emit('deleteGroup')

  isOpenDelete.value = false
}
</script>
