export type ValidationSchema<T> = {
  [Key in keyof T]:
    | string
    | ((value: T[Key], ctx: ValidationMetaInfo<T>) => true | string)
}

export type ValidationMetaInfo<T> = {
  form: T
}

export const passwordOptions = {
  // eslint-disable-next-line prefer-named-capture-group
  requiredSymbols: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([\w!@#$%^&*()+]+)$/,
  minLength: 8,
  maxLength: 25,
}

export const basicEntityNameLength = {
  min: 2,
  max: 255,
}

export const originatorOptions = {
  regExp: /^[A-Za-z!.&*?,"':_-\d\s]+$/,
  minLength: 2,
  maxLength: 15,
}

export const loginRegex = /^\w+$/

export const linkHttpRegex = /^(?:https?|viber?):\/\/\S+$/
export const linkViberRegex = /^viber?:\/\/\S+$/
export const linkLinkRegex = /^\S+\.\S+$/
