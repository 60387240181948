<template>
  <div :class="$style.container">
    <Status :status="status" />
    <Text :class="$style.text">
      {{ `${t('detailedStatus.totalPhones')}: ${totalPhones}` }}
    </Text>
    <Text v-if="percent" :class="$style.text">
      {{ `${t('detailedStatus.percent')}: ${percent}%` }}
    </Text>
    <Button
      v-if="sentPhones"
      type="button"
      view="link"
      @click="getFile({ batchId, type: 'sent' })"
      >{{ t('detailedStatus.success', sentPhones) }}</Button
    >
    <Button
      v-if="unsentPhones"
      type="button"
      view="link"
      @click="getFile({ batchId, type: 'unsent' })"
      >{{ t('detailedStatus.failed', unsentPhones) }}</Button
    >
  </div>
</template>

<script lang="ts" setup>
import { Button, Text } from '@smst/ui'
import { computed } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { downloadFile } from '@/utils/downloadFile'

import Status from '../../../Status/Status.vue'

const props = defineProps({
  batchId: {
    type: Number,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
  sentPhones: {
    type: Number,
    required: true,
  },
  unsentPhones: {
    type: Number,
    required: true,
  },
  totalPhones: {
    type: Number,
    required: true,
  },
})

const t = useComponentI18n('batches')

const percent = computed<number>(() => {
  const rawPercent = (props.sentPhones / props.totalPhones) * 100

  return parseFloat(rawPercent.toFixed(2))
})

const { mutate: getFile } = useMutation(
  (values: { batchId: number; type: 'sent' | 'unsent' }) =>
    apiClient.batches.numbers_GET_BY(
      values.batchId,
      { type: values.type },
      { format: 'blob' }
    ),
  {
    onSuccess: (response, vars) => {
      void downloadFile({
        response,
        fileName: `phones_${vars.batchId}_${vars.type}`,
        extension: 'zip',
      })
    },
  }
)
</script>

<style module>
.container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
