<template>
  <Text :class="$style.title" tag="h3" weight="semibold">
    {{ t('title') }}
  </Text>

  <Table
    v-if="data?.length"
    :withRowAction="true"
    :columns="tableColumns"
    :data="data"
    :rowKey="rowKey"
  >
    <template #column:email="{ item }">
      {{ item.email || '—' }}
    </template>

    <template #column:script="{ item }">
      {{ item.script || '—' }}
    </template>

    <template #rowAction="{ item }">
      <ButtonIcon :title="tTable('editButton')" @click="handleEditNumber(item)">
        <IconEdit />
      </ButtonIcon>
    </template>
  </Table>

  <NoResultsFound v-else :withAdvice="false" />
</template>

<script setup lang="ts">
import type { TableTypes } from '@smst/ui'
import { ButtonIcon, IconEdit, Table, Text } from '@smst/ui'
import type { PropType } from 'vue'
import { computed } from 'vue'

import type { MoNumberResponse } from '@/api/__generated__/api.schema'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

type TableColumns = Array<TableTypes.Column<MoNumberResponse>>

defineProps({
  data: {
    type: Array as PropType<MoNumberResponse[]>,
    required: true,
  },
})

const emit = defineEmits(['selectNumber'])

const t = useComponentI18n('settings.general.moNumbers')
const tTable = useComponentI18n('settings.general.moNumbers.table')

const rowKey: keyof MoNumberResponse = 'id'

const tableColumns = computed<TableColumns>(() => [
  {
    name: tTable('number'),
    prop: 'number',
  },
  {
    name: tTable('monthPrice'),
    prop: 'monthPrice',
  },
  {
    name: tTable('prefix'),
    prop: 'prefix',
  },
  {
    name: tTable('email'),
    prop: 'email',
  },
  {
    name: tTable('script'),
    prop: 'script',
    ellipsis: true,
  },
])

const handleEditNumber = (item: MoNumberResponse) => {
  emit('selectNumber', item)
}
</script>

<style module>
.title {
  margin-bottom: var(--gap-20);
}
</style>
