<template>
  <div>
    <FiltersPanel
      :items="selectedFilters"
      @open="handleDrawerOpen"
      @clear="handleFilterClear"
    />

    <Drawer
      v-model="isDrawerOpen"
      :title="$t('filter.drawer.title')"
      :closeText="$t('filter.drawer.close')"
    >
      <form :id="formId" @submit="onSubmit">
        <FormControls :class="$style.mainFields" :controls="controls" />

        <div>
          <Text tag="h3" weight="bold" size="xl" :class="$style.groupTitle">
            {{ t('groupByTitle') }}
          </Text>

          <Text
            v-if="groupError"
            tag="p"
            color="error"
            :class="$style.groupError"
          >
            {{ clean(groupError) }}
          </Text>

          <DndList v-model="groups">
            <template #default="{ element }">
              <Checkbox
                :modelValue="formValues.groupBy?.includes(element.value)"
                :label="element.label"
                name="groupBy"
                @input="handleChangeGroups(element.value)"
              />
            </template>
          </DndList>
        </div>
      </form>

      <template #buttons>
        <div :class="$style.buttonsWrapper">
          <slot name="extra-button" :formValues="formValues" />

          <Button type="submit" :form="formId">
            {{ $t('filter.drawer.submit') }}
          </Button>
        </div>
      </template>
    </Drawer>
  </div>
</template>

<script setup lang="ts">
import type { MultiselectTypes } from '@smst/ui'
import { Button, Checkbox, DndList, Drawer, Text } from '@smst/ui'
import { differenceInMonths, endOfToday, parseISO } from 'date-fns'
import { isDefined } from 'remeda'
import { useField, useForm } from 'vee-validate'
import type { ComputedRef, PropType } from 'vue'
import { computed, ref, watch } from 'vue'
import { useMutation, useQuery } from 'vue-query'

import { apiClient } from '@/api'
import { getFilterItemsDescription } from '@/components/Filters/Filters.utils'
import type { FilterDescription } from '@/components/FiltersPanel/FiltersPanel.utils'
import FiltersPanel from '@/components/FiltersPanel/FiltersPanel.vue'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormControls from '@/components/FormControls/FormControls.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useId } from '@/hooks/useId'
import { useProfile } from '@/hooks/useProfile'
import { getBatchesOptions } from '@/pages/Journal/MessagesLog/hooks/useGetFiltersState/useGetFiltersState.utils'
import { formatDateToRequest } from '@/utils/formatDateToRequest'

import type { StatisticFilters } from './Filters.schema'
import { statisticFiltersSchema } from './Filters.schema'
import type { GroupBy } from './Filters.types'
import {
  channelOptions,
  getOptionsBy,
  getSelectedGroupDescription,
  groupsInitialState,
  messageTypeOptions,
} from './Filters.utils'

const props = defineProps({
  modelValue: {
    type: Object as PropType<StatisticFilters>,
    required: true,
  },
})
const emit = defineEmits(['update:modelValue'])

const t = useComponentI18n('statistics.filters')
const { profile } = useProfile()
const formId = `statisticForm-${useId()}`

const isDrawerOpen = ref<boolean>(false)

const handleDrawerOpen = () => {
  isDrawerOpen.value = true
}

const filtersFormValues = ref(props.modelValue)

/**
 * Filter the groups options based on the user profile.
 * // убираем channel и trafficType из группировки, если не нужно
 * @returns {Array} The filtered groups options.
 */

const filterGroup = () => {
  let data = groupsInitialState
  // If profile has smartDelivery disable, filter out the channel group
  if (!profile.value?.smartDelivery) {
    data = data.filter((group) => group.value !== 'channel')
  }
  // If profile has showTrafficType disabled, filter out the trafficType group
  if (!profile.value?.showTrafficTypeIn?.stats) {
    data = data.filter((group) => group.value !== 'trafficType')
  }
  return data
}

const groups = ref(filterGroup())

const selectedFilters = ref<FilterDescription[]>([])

const clean = (value: string) => {
  return value.value
}

const { data: filterOptions } = useQuery(
  'messages-filters',
  apiClient.messages.filters_GET
)

const {
  values: formValues,
  handleSubmit,
  setFieldValue,
} = useForm({
  initialValues: filtersFormValues,
  validationSchema: statisticFiltersSchema,
})

const { errorMessage: groupError, handleChange: handleChangeGroups } = useField<
  GroupBy[]
>('groupBy', undefined, {
  type: 'checkbox',
})

const { data: filterStatistics } = useQuery(
  'filter_statistics',
  () => apiClient.statistics.filters_GET(),
  {
    keepPreviousData: true,
  }
)

// Сбрасываем форму в ящике при открытии
watch(
  () => isDrawerOpen.value,
  (newIsDrawerOpen) => {
    if (newIsDrawerOpen) {
      filtersFormValues.value = props.modelValue
    }
  }
)
/**
 * Следим за обновлением DnD в группах,
 * обновляем порядок выбранных значений в состоянии формы
 */
watch(
  () => groups.value,
  (updatedOrderGroups) => {
    const updatedOrderValue = updatedOrderGroups
      .map(({ value }) => value)
      .filter((value) => formValues.groupBy?.includes(value))

    formValues.groupBy = updatedOrderValue
  }
)

watch(
  () => [isDrawerOpen.value, formValues.date],
  ([newIsDrawerOpen, newDate]) => {
    if (!newIsDrawerOpen || !formValues.date) {
      return
    }
    const [from, to] = newDate as [Date, Date]

    // Запросить батчи можно только за период меньше 3-х месяцев
    if (differenceInMonths(to, from) < 3) {
      fetchBatches({
        from: formatDateToRequest(from, { withTime: true }),
        to: formatDateToRequest(to, { withTime: true }),
      })
    } else {
      setFieldValue('batchId', undefined)
      batchesOptions.value = []
    }
  }
)

const batchesOptions = ref<MultiselectTypes.Option[]>([])

const { mutate: fetchBatches } = useMutation(
  apiClient.messages.filtersBatches_GET,
  {
    onSuccess: (res) => {
      batchesOptions.value = getBatchesOptions(res?.data?.list)
    },
  }
)

const options = computed(() => ({
  messageTypes: messageTypeOptions,
  departments: getOptionsBy(filterOptions.value?.data.departments, {
    labelKey: 'departmentName',
    valueKey: 'departmentId',
  }),
  channels: channelOptions,
  batches: batchesOptions.value,
}))

const maxDate = computed(() => {
  const date = filterStatistics.value?.data?.data?.dateTo
  return date ? parseISO(date) : endOfToday()
})

const controls: ComputedRef<ControlType[]> = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const controlsDescriptor: Array<ControlType | undefined> = [
    {
      control: 'datepicker',
      maxDate: maxDate.value,
      label: t('date'),
      name: 'date',
      range: true,
    },
    {
      control: 'select',
      name: 'messageType',
      label: t('messageType'),
      withOptionAll: true,
      options: options.value.messageTypes,
    },
    {
      control: 'select',
      name: 'departmentId',
      label: t('departments'),
      withOptionAll: true,
      options: options.value.departments,
    },
    profile.value?.smartDelivery
      ? {
          control: 'select',
          name: 'channel',
          label: t('channel'),
          withOptionAll: true,
          options: options.value.channels,
        }
      : undefined,
    {
      control: 'multiselect',
      label: t('batch'),
      withOptionAll: true,
      name: 'batchId',
      options: options.value.batches,
    },
  ]

  return controlsDescriptor.filter(isDefined)
})

const handleFilterClear = () => {
  emit('update:modelValue', undefined)

  // Сбрасываем порядок DnD до дефолтного
  groups.value = groupsInitialState
  selectedFilters.value = []
}

// 1. Emit an update event with the form data
const onSubmit = handleSubmit((values) => {
  emit('update:modelValue', values)

  // 2. Close the drawer
  isDrawerOpen.value = false

  // 3. Update the selected filters description
  selectedFilters.value = [
    ...getFilterItemsDescription(formValues, controls.value),
    getSelectedGroupDescription(groups.value, formValues.groupBy as GroupBy[]),
  ]
})
</script>

<style module>
.mainFields {
  margin-bottom: var(--gap-68);
}

.groupTitle,
.groupError {
  margin-bottom: var(--gap-12);
}

.group ul {
  display: flex;
  flex-direction: column;
  row-gap: var(--gap-16);
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: var(--gap-16);
}
</style>
