<template>
  <div :class="$style.root">
    <Dropdown
      v-if="showTable"
      :position="['down', 'right']"
      :label="t('tableActionsLabel')"
      :items="tableActionsItems"
      :disabled="isTableActionsDisabled"
    />

    <template v-if="showParentsBatches">
      <div
        v-for="parentBatch in parentsBatches"
        :key="parentBatch.parentBatchId"
        :class="[
          $style.parentBatch,
          parentBatch.parentBatchId === selectedParentBatchId &&
            $style.isActive,
        ]"
        @click="handleParentBatchClick($event, parentBatch)"
      >
        <Text :class="$style.parentBatchName">
          {{ parentBatch.parentBatchName ?? t('parentsBatches.all') }}
        </Text>

        <Dropdown
          v-if="getParentBatchActionsItems(parentBatch).length > 0"
          :position="['down', 'left']"
          :label="$t('actions')"
          :iconTrigger="IconActionsHorizontal"
          :items="getParentBatchActionsItems(parentBatch)"
          :class="$style.parentBatchDropdown"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import {
  Dropdown,
  IconActionsHorizontal,
  IconCheck,
  IconDelete,
  IconPlay,
  IconStop,
  Text,
  toaster,
} from '@smst/ui'
import type { DropdownTypes, TableTypes } from '@smst/ui'
import type { PropType } from 'vue'
import { computed, useCssModule } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import { BatchStatusField } from '@/api/__generated__/api.schema'
import type {
  BatchResult,
  ParentBatchResult,
} from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'
import { getDateIntervalToRequest } from '@/utils/getDateIntervalToRequest'
import { hasSelectedElements } from '@/utils/hasSelectedElements'

const props = defineProps({
  showTable: Boolean,
  selectionModel: {
    type: Object as PropType<TableTypes.ModelSelection>,
    required: true,
  },
  date: {
    type: Array as PropType<Date[] | null>,
  },
  batches: {
    type: Array as PropType<BatchResult[]>,
    required: true,
  },
  parentsBatches: {
    type: Array as PropType<ParentBatchResult[]>,
    required: true,
  },
  selectedParentBatchId: {
    type: Number,
  },
})

const emit = defineEmits(['selectParentBatch'])

type BatchState = 'start' | 'pause' | 'cancel' | 'approve'

type BatchesStateChangePayload = {
  state: BatchState
  all: boolean
  include: number[]
  exclude: number[]
  dateFrom: string
  dateTo: string
}

type ParentBatchStateChangePayload = {
  parentBatchId: number
  state: BatchState
}

const t = useComponentI18n('batches')
const tActions = useComponentI18n('batches.actions')

const queryClient = useQueryClient()

const { mutate: changeBatchesState, isLoading: isChangeBatchesStateLoading } =
  useMutation(
    async (data: BatchesStateChangePayload) => {
      return await apiClient.batches.state_POST(data)
    },
    {
      onSuccess: (data) => {
        const count = data.data.list?.length ?? 0

        toaster.success(t('successStateChange', { count }))

        void queryClient.invalidateQueries('batches')
      },
      onError: (error) => {
        toaster.error(getErrorMessage(error))
      },
    }
  )

const isTableActionsDisabled = computed(
  () =>
    !hasSelectedElements(props.selectionModel) ||
    isChangeBatchesStateLoading.value
)
const dateInterval = computed(() => getDateIntervalToRequest(props.date))

const tableActionsItems = computed(() => {
  if (!dateInterval.value) {
    return []
  }

  const batchesStateChangeCommonPayload = {
    ...props.selectionModel,
    dateFrom: dateInterval.value.from,
    dateTo: dateInterval.value.to,
  }

  const actionItems: Record<BatchState, DropdownTypes.DropdownItem> = {
    approve: {
      icon: IconCheck,
      label: tActions('approve'),
      onClick: () =>
        changeBatchesState({
          state: 'approve',
          ...batchesStateChangeCommonPayload,
        }),
    },
    start: {
      icon: IconPlay,
      label: tActions('start'),
      onClick: () =>
        changeBatchesState({
          state: 'start',
          ...batchesStateChangeCommonPayload,
        }),
    },
    pause: {
      icon: IconStop,
      label: tActions('pause'),
      onClick: () =>
        changeBatchesState({
          state: 'pause',
          ...batchesStateChangeCommonPayload,
        }),
    },
    cancel: {
      icon: IconDelete,
      label: tActions('cancel'),
      onClick: () =>
        changeBatchesState({
          state: 'cancel',
          ...batchesStateChangeCommonPayload,
        }),
    },
  }

  // Если выбраны все элементы в таблице - мы не имеем возможность их проверить,
  // поэтому показываем все действия
  if (props.selectionModel.all) {
    return Object.values(actionItems)
  }

  const selectedBatches = props.batches.filter((batch) =>
    props.selectionModel.include.includes(batch.batchId)
  )

  if (selectedBatches.length === 0) {
    return []
  }

  const canApprove = selectedBatches.every(
    (batch) => batch.buttons?.includes('approve')
  )

  const canEdit = selectedBatches.every(
    (batch) => batch.buttons?.includes('edit')
  )

  const result = []

  if (canApprove) {
    result.push(actionItems.approve)
  }

  if (canEdit) {
    const actionStatusesMap: Partial<Record<BatchState, BatchStatusField[]>> = {
      start: [BatchStatusField.Paused],
      pause: [BatchStatusField.Processing],
      cancel: [
        BatchStatusField.New,
        BatchStatusField.Premoderate,
        BatchStatusField.Processing,
        BatchStatusField.Paused,
      ],
    }

    const allowedActions = Object.entries(actionStatusesMap)
      .map(([action, allowedStatuses]) =>
        selectedBatches.every((batch) => allowedStatuses.includes(batch.status))
          ? action
          : undefined
      )
      .filter((item) => Boolean(item)) as BatchState[]

    for (const action of allowedActions) {
      result.push(actionItems[action])
    }
  }

  return result
})

const { mutate: changeParentBatchState } = useMutation(
  async ({ parentBatchId, state }: ParentBatchStateChangePayload) => {
    if (!dateInterval.value) return

    return await apiClient.batches.parents_POST(parentBatchId, state, {
      dateFrom: dateInterval.value.from,
      dateTo: dateInterval.value.to,
    })
  },
  {
    onSuccess: (data) => {
      if (!data) return

      const count = data.data.list?.length ?? 0

      toaster.success(t('successStateChange', { count }))

      void queryClient.invalidateQueries('batches')
      void queryClient.invalidateQueries('parentsBatches')
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)

const getParentBatchActionsItems = (parentBatch: ParentBatchResult) => {
  const canStart = parentBatch.buttons?.includes('start')
  const canPause = parentBatch.buttons?.includes('pause')

  const result: DropdownTypes.DropdownItem[] = []

  if (canStart) {
    result.push({
      icon: IconPlay,
      label: tActions('start'),
      onClick: () =>
        changeParentBatchState({
          parentBatchId: parentBatch.parentBatchId,
          state: 'start',
        }),
    })
  }

  if (canPause) {
    result.push({
      icon: IconStop,
      label: tActions('pause'),
      onClick: () =>
        changeParentBatchState({
          parentBatchId: parentBatch.parentBatchId,
          state: 'pause',
        }),
    })
  }

  return result
}

const showParentsBatches = computed(() => props.parentsBatches.length > 0)

const classes = useCssModule()

const handleParentBatchClick = (event: MouseEvent, item: ParentBatchResult) => {
  const targetElement = event.target instanceof Element ? event.target : null

  if (!targetElement) {
    return
  }

  if (
    targetElement.classList.contains(classes.parentBatch) ||
    (targetElement.tagName === 'SPAN' &&
      targetElement.parentElement?.classList.contains(classes.parentBatch))
  ) {
    emit('selectParentBatch', item.parentBatchId)
  }
}
</script>

<style module>
.root {
  display: flex;
  align-items: center;

  column-gap: var(--gap-8);
}

.parentBatch {
  display: flex;
  align-items: center;
  padding: var(--gap-8) var(--gap-20);

  background-color: var(--color-bg-white);
  border-radius: 72px;
  cursor: pointer;

  &.isActive {
    background-color: var(--color-bg-heading);
  }
}

.parentBatchDropdown {
  margin-left: var(--gap-8);
}
</style>
