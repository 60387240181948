import { isDefined, isNumber } from 'remeda'

type Options = {
  delimiter: string
}

type Pair = string[]

export type Phone = number

export type Member = {
  phone: Phone
  name?: string
  birthday?: string
  gender?: string
}

const defaultOptions: Options = {
  delimiter: ';',
}

export const parseTextarea = (value: string, options?: Options): Pair[] => {
  const { delimiter } = options ?? defaultOptions

  const rows = value.trim().split('\n')
  const pairs = rows.map((v) => v.split(delimiter).map((i) => i.trim()))

  return pairs
}

const getGender = (gender: string) => {
  const gen = gender ? gender.toLowerCase() : ''

  if (gen === 'm' || gen === 'м') {
    return 'men'
  }
  if (gen === 'ж' || gen === 'w') {
    return 'women'
  }
  return
}

export const transformToMember = (pair: Pair): Member | null => {
  const [_phone = '', _name = '', _birthday = '', _gender] = pair

  const phone = _phone !== '' ? Number(_phone) : undefined
  const name = _name !== '' ? _name : undefined
  const gender = _gender !== '' ? getGender(_gender) : undefined
  const birthday = _birthday !== '' ? _birthday : undefined

  if (!isNumber(phone)) {
    return null
  }

  return { phone, name, birthday, gender }
}

export const getMembers = (value: string): Member[] => {
  const pairs = parseTextarea(value)
  const members = pairs.map((m) => transformToMember(m)).filter(isDefined)

  return members
}

export const getPhones = (value: string): Phone[] => {
  const members = getMembers(value)
  const phones = members.map((m) => m.phone)

  return phones
}
