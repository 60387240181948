import { formatDateToRequest } from '@/utils/formatDateToRequest'

export const getDateIntervalToRequest = (
  date: Date[] | null | undefined,
  tz?: string,
  withTime?: boolean
) => {
  if (date) {
    return {
      from: formatDateToRequest(date[0], { tz, withTime }),
      to: formatDateToRequest(date[1], { tz, withTime }),
    }
  }
}
