import { ProfileData } from '@/utils/profile/profile'

export const useProfile = () => {
  return {
    error: ProfileData.getError(),
    profile: ProfileData.getProfile(),
    profileType: ProfileData.getProfileType(),
    isFetching: ProfileData.getIsFetching(),
  }
}
