<template>
  <div :class="$style.main">
    <slot :invalid="invalid && touched" />
    <Text
      v-if="errorMessage && touched"
      color="error"
      size="s"
      weight="medium"
      :class="$style.error"
    >
      {{ error(errorMessage) }}
    </Text>
    <div v-if="descriptions" :class="$style.description">
      {{ descriptions }}
    </div>
  </div>
</template>

<script lang="ts">
import { Text } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ComputedRef, PropType } from 'vue'
import { computed, defineComponent } from 'vue'

export const formControlProps = {
  name: {
    type: String,
    required: true,
  },
  noTouched: Boolean as PropType<boolean>,
  label: String,
  metaData: Object,
  descriptions: String,
  disabled:
    // Без приведения типа проп выводится как обязательный
    Boolean as PropType<boolean>,
} as const

export default defineComponent({
  components: { Text },
  props: {
    ...formControlProps,
  },
  setup(props) {
    const { errorMessage } = useField(props.name)
    const invalid = computed(() => Boolean(errorMessage.value))

    const error = (err: string | ComputedRef): void => {
      return typeof err === 'string' ? err : err.value
    }
    const touched = computed(() => {
      if (props.noTouched || !props.metaData) return true
      return props.metaData?.touched
    })

    return {
      errorMessage,
      invalid,
      error,
      name: props.name,
      touched,
    }
  },
})
</script>

<style module>
.main {
  display: flex;
  flex-direction: column;
}

.error {
  margin-top: var(--gap-8);
}

.description {
  display: block;
  margin-top: var(--gap-8);
  margin-left: 15px;

  color: var(--color-text-grey);
  font-size: 12px;
}
</style>
