import { toaster } from '@smst/ui'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import { getErrorMessage } from '@/utils/errors'

import type { StatisticsFilterForm } from './components/Filters/Filters.types'
import type { StatisticsTableItem } from './Statistics.types'

export type UseStatisticTableItemsProps = {
  filters: StatisticsFilterForm | undefined
  groupingOrder: Array<StatisticsFilterForm['groupBy']>
}

export const useStatisticsTableItems = (
  props: ComputedRef<UseStatisticTableItemsProps>
) => {
  const { t } = useI18n()

  const fetchTableItems = (
    requestFilters: StatisticsFilterForm
  ): Promise<StatisticsTableItem[]> => {
    return apiClient.statistics
      .statistics_GET(requestFilters)
      .then(({ data: { data, groupBy } }) => {
        if (!data || !groupBy) {
          return []
        }

        const currentGroupingLevel = props.value.groupingOrder?.indexOf(
          groupBy as StatisticsFilterForm['groupBy']
        )
        const nextGroupBy =
          currentGroupingLevel >= 0
            ? props.value.groupingOrder[currentGroupingLevel + 1]
            : undefined

        return Object.entries(addZeroToNumber(data)).map(
          ([id, value]): StatisticsTableItem => {
            return {
              id: value.index ?? id,
              ...value,
              children: nextGroupBy
                ? () =>
                    fetchTableItems({
                      ...requestFilters,
                      groupBy: nextGroupBy,
                      [groupBy]: value.index ?? id, // Запрашиваем только детей текущего элемента
                    }).catch((error) => {
                      toaster.error(getErrorMessage(error))
                      console.error(error)
                      throw error
                    })
                : undefined,
            }
          }
        )
      })
  }

  const { data, ...rest } = useQuery(['statistics', props], () => {
    if (!props.value.filters) {
      return null
    }

    return fetchTableItems(props.value.filters)
  })

  const dataWithSummary = computed(() => {
    return data.value?.length
      ? [...data.value, getSummaryItem(data.value, t('statistics.summary'))]
      : data.value
  })

  return {
    data: dataWithSummary,
    ...rest,
  }
}

const percentKeyStatistic = [
  'sumCosts',
  'deliveredPercent',
  'readPercent',
  'clickedPercent',
]
// Добавляем нули к процентам = 0.00
const addZeroToNumber = (data: StatisticsTableItem) => {
  const newData: StatisticsTableItem[] = []
  if (!data) return []

  for (let i = 0; i < data.length; i++) {
    const newItem = {}
    Object.assign(newItem, data[i])

    for (let x = 0; x < percentKeyStatistic.length; x++) {
      const keys = percentKeyStatistic[x]
      newItem[keys] = roundPercentage(newItem[keys])
    }
    newData.push(newItem)
  }
  return newData
}

const getSummaryItem = (
  data: StatisticsTableItem[],
  name: string
): StatisticsTableItem => {
  const totals = data.reduce<
    Pick<
      StatisticsTableItem,
      'delivered' | 'read' | 'clicked' | 'total' | 'sumCosts'
    >
  >(
    (acc, item) => {
      ;(Object.keys(acc) as Array<keyof typeof acc>).forEach((key) => {
        acc[key] += Number(item[key])
      })

      return acc
    },
    {
      clicked: 0,
      delivered: 0,
      read: 0,
      total: 0,
      sumCosts: 0,
    }
  )

  return {
    name,
    id: 'summary',
    clicked: totals.clicked,
    clickedPercent: formatPercentage(totals.clicked, totals.total),
    delivered: totals.delivered,
    deliveredPercent: formatPercentage(totals.delivered, totals.total),
    read: totals.read,
    readPercent: formatPercentage(totals.read, totals.total),
    total: totals.total,
    sumCosts: roundPercentage(totals.sumCosts),
  }
}

// Округление до двух знаков после запятой после деления на total
const formatPercentage = (n: number, total: number) => {
  const num = Number(n) / Number(total)
  return ((num + Number.EPSILON) * 100).toFixed(2)
}
// Округление до двух знаков после запятой с округлением вверх
const roundPercentage = (n: number) => {
  return Number(n).toFixed(2)
}
