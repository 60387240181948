import type { TableTypes } from '@smst/ui'
import { parseISO } from 'date-fns'
import { isNumber, sortBy } from 'remeda'

import type {
  AccountShortResult,
  DepUserShortResult,
} from '@/api/__generated__/api.schema'
import { getTwoFactorAuthStatus } from '@/utils/getTwoFactorAuthStatus'

export type SortableAccountFields =
  | 'name'
  | 'fullLogin'
  | 'twoFactorAuth'
  | 'status'
  | 'addDate'
  | 'editDate'

export type SortableDepUserFields =
  | 'name'
  | 'fullLogin'
  | 'status'
  | 'addDate'
  | 'editDate'

export type SortableAccount = Pick<AccountShortResult, SortableAccountFields>
export type SortableDepUser = Pick<DepUserShortResult, SortableDepUserFields>

export const getSortedData = (
  data: AccountShortResult[] | DepUserShortResult[],
  prop: SortableAccountFields | SortableDepUserFields,
  direction: TableTypes.SortDirection
): AccountShortResult[] | DepUserShortResult[] => {
  // @ts-expect-error Тут сложно объяснить TS комбинацию data и prop
  return sortBy(data, [
    (item: AccountShortResult | DepUserShortResult) => {
      if (prop === 'addDate' || prop === 'editDate') {
        const date = parseISO(item[prop])

        return date.getTime()
      }

      if (isDepUserData(item)) {
        return item[prop as keyof DepUserShortResult]
      }

      if (prop === 'twoFactorAuth') {
        return isNumber(getTwoFactorAuthStatus(item[prop]))
      }

      return item[prop as keyof AccountShortResult]
    },
    direction,
  ])
}

export const isDepUserData = (
  data: AccountShortResult | DepUserShortResult
): data is DepUserShortResult => 'departmentId' in data

export const isDepUsersData = (
  items: Array<AccountShortResult | DepUserShortResult>
): items is DepUserShortResult[] => items.every(isDepUserData)
