import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import type { ControlType } from '@/components/FormControls/FormControls.types'
import { i18n } from '@/i18n'

const { t } = i18n.global

export const formControls: ComputedRef<ControlType[]> = computed(() => [
  {
    control: 'input',
    name: 'login',
    type: 'text',
    label: t('login.form.login'),
    placeholder: t('login.form.login'),
  },
  {
    control: 'input',
    name: 'password',
    type: 'password',
    label: t('login.form.password'),
    placeholder: t('login.form.password'),
  },
])
