<template>
  <DepartmentForm
    :departmentData="departmentData"
    :isLoading="isEditDepartmentLoading || isDepartmentDataFetching"
    :error="editError"
    @submit="onSubmit"
  />
</template>

<script lang="ts" setup>
import { toaster } from '@smst/ui'
import type { PropType } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import type { DepartmentResult } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'

import type { FormValues } from './DepartmentForm.utils'
import DepartmentForm from './DepartmentForm.vue'

const props = defineProps({
  departmentData: {
    type: Object as PropType<DepartmentResult>,
    required: true,
  },
  isDepartmentDataFetching: Boolean,
})

const t = useComponentI18n('editDepartment')

const queryClient = useQueryClient()

const {
  mutate: editDepartment,
  isLoading: isEditDepartmentLoading,
  error: editError,
} = useMutation(
  async (data: FormValues) => {
    await apiClient.departments.departments_PUT(props.departmentData.id, data)
  },
  {
    onSuccess: () => {
      toaster.success(t('successEdit'))

      void queryClient.invalidateQueries([
        'department',
        String(props.departmentData.id),
      ])
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)

const onSubmit = (values: FormValues) => {
  editDepartment(values)
}
</script>

<style module>
.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
