import { equals, pick } from 'remeda'
import type { QueryParamConfigMap } from 'serialize-query-params'
import { decodeQueryParams, encodeQueryParams } from 'serialize-query-params'
import type { DecodedValueMap } from 'serialize-query-params/lib/types'
import type { WritableComputedRef } from 'vue'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const useQueryParams = <T extends QueryParamConfigMap>(
  paramConfigMap: T
): {
  query: WritableComputedRef<DecodedValueMap<T>>
} => {
  const router = useRouter()
  const route = useRoute()
  const filteredQuery = ref()

  watch(
    () => route.query,
    (newRouterQuery) => {
      const newFilteredQuery = pick(newRouterQuery, Object.keys(paramConfigMap))
      if (!equals(newFilteredQuery, filteredQuery.value)) {
        filteredQuery.value = newFilteredQuery
      }
    },
    {
      immediate: true,
    }
  )

  const query = computed({
    get: () =>
      decodeQueryParams(
        paramConfigMap,
        filteredQuery.value
      ) as DecodedValueMap<T>,
    set: (newQuery) => {
      void router.push({
        query: {
          ...route.query,
          ...encodeQueryParams(paramConfigMap, newQuery),
        },
      })
    },
  })

  return {
    query,
  }
}
