import { differenceInMonths } from 'date-fns'
import { isDefined } from 'remeda'

import { i18n } from '@/i18n'
import yup from '@/utils/yup'

import type { FiltersState } from './useGetFiltersState.types'

const { t } = i18n.global

const getErrorText = (key: string) => t(`messagesLog.form.${key}`)
const MAX_ORIGINATOR = 15

export const validationSchema = yup.object({
  originator: yup
    .string()
    .max(
      MAX_ORIGINATOR,
      t('validation.string.max', { number: MAX_ORIGINATOR })
    ),
  phones: yup
    .array(yup.string())
    .ensure()
    .test({
      message: getErrorText('errorPhonesValidate'),
      test(phones) {
        if (!phones) {
          return false
        }

        return phones.every((phone) => /^[1-9]\d*$/.test(phone ?? ''))
      },
    }),
  date: yup
    .array(yup.date())
    .length(2)
    .test({
      message: getErrorText('endDateLargerStartDate'),
      test(dates) {
        if (!dates?.every(isDefined)) return false

        const [from, to] = dates

        return from < to
      },
    })
    .test({
      message: getErrorText('searchByDateWithoutPhones'),
      test(dates) {
        if (!dates?.every(isDefined)) return false

        const { phones } = this.parent
        const [from, to] = dates

        if (phones.length === 0) {
          const monthsBetween = differenceInMonths(to, from)

          return monthsBetween === 0
        }

        return true
      },
    })
    .test({
      message: getErrorText('isTooLongDateToRequest'),
      test(dates) {
        if (!dates?.every(isDefined)) return false

        const { phones } = this.parent

        const [from, to] = dates

        if (phones.length > 0) {
          const monthsBetween = differenceInMonths(to, from)

          return monthsBetween < 3
        }

        return true
      },
    }),
  /**
   * Не удалось создать схему, где TS выведет, что phones всегда есть как пустой массив.
   * .default и .ensure из yup никак не помогают в выводе типов, не смотря на то, что гарантируют
   * отсутствие undefined
   * Пример: https://github.com/jquense/yup#arrayensure-this
   */
}) as unknown as Required<FiltersState>
