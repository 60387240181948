<template>
  <template v-if="isVisible">
    {{ format(timer?.minutes) }}:{{ format(timer?.seconds) }}
  </template>
</template>

<script lang="ts" setup>
import { addMilliseconds, intervalToDuration } from 'date-fns'
import type { Duration } from 'date-fns'
import { onUnmounted, ref, watch } from 'vue'

const format = (time?: number) => {
  return String(time ?? '').padStart(2, '0')
}

const props = defineProps({
  duration: {
    type: Number,
    default: 300000,
  },
})

const emit = defineEmits(['end'])

const timer = ref<Duration>()
const isVisible = ref<boolean>(true)
const startDate = new Date()
const endDate = addMilliseconds(startDate, props.duration)

const intervalId = setInterval(() => {
  timer.value = intervalToDuration({ start: new Date(), end: endDate })
}, 1000)

watch(
  () => timer.value,
  (duration) => {
    if (!duration) return

    if (Object.values(duration).every((item) => item === 0)) {
      clearInterval(intervalId)
      isVisible.value = false

      emit('end')
    }
  }
)

onUnmounted(() => {
  clearInterval(intervalId)
})
</script>
