<template>
  <form :id="formId" @submit="onSubmit">
    <FormControls :controls="formControls" />
  </form>
</template>

<script lang="ts" setup>
import type { SelectTypes } from '@smst/ui'
import { useForm } from 'vee-validate'
import type { PropType } from 'vue'

import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormControls from '@/components/FormControls/FormControls.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getFormData } from '@/utils/getFormData'

import { createOriginatorsSchemaFile } from './CreateOriginatorsFile.schema'
import type { CreateOriginatorsFile } from './CreateOriginatorsFile.types'

const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  operatorOptions: {
    type: Array as PropType<SelectTypes.Option[]>,
    required: true,
  },
})

const emits = defineEmits(['submit'])

const t = useComponentI18n('originators.createOriginator')
const tForm = useComponentI18n('form')

const formControls: ControlType[] = [
  {
    control: 'fileInput',
    name: 'file',
    accept: '.xlsx, .csv',
    locale: {
      title: t('controls.file.title'),
      deleteFile: tForm('file.delete'),
      errors: {
        WrongFormat: tForm('file.errors.wrongFormat'),
        FileTooLarge: tForm('file.errors.fileTooLarge'),
      },
    },
    descriptions: [t('controls.file.csv'), t('controls.file.xlsx')],
  },
  {
    control: 'select',
    name: 'operatorId',
    label: t('controls.operatorId'),
    options: props.operatorOptions,
  },
]

const { handleSubmit } = useForm<CreateOriginatorsFile>({
  validationSchema: createOriginatorsSchemaFile,
})

const onSubmit = handleSubmit((value) => {
  emits('submit', getFormData(value))
})
</script>
