import type { MultiselectTypes, SelectTypes } from '@smst/ui'
import { isDefined } from 'remeda'

import { getFilterLabel } from '@/components/FiltersPanel/FiltersPanel.utils'
import type {
  FilterDescription,
  FilterState,
} from '@/components/FiltersPanel/FiltersPanel.utils'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import { i18n } from '@/i18n'
import { formatDateToDisplay } from '@/utils/formatDateToDisplay'

import type { FiltersFormValues } from './Filters.types'

const { t } = i18n.global

type Value = FiltersFormValues[keyof FiltersFormValues]

const isGroupOptions = (
  option: MultiselectTypes.Option | MultiselectTypes.Group
): option is MultiselectTypes.Group => 'options' in option

const getFilterState = (value: Value, item: ControlType): FilterState => {
  if (item.control === 'select') {
    const getSelectedOptions = () => {
      if (!Array.isArray(item.options)) return

      if (item.groups) {
        return Object.values(item.options as SelectTypes.Group[])
          .map((group) => group.options)
          .flat()
          .find((option) => option.value === value)
      }

      const options = item.options as SelectTypes.Option[]

      return options.find((o: SelectTypes.Option) => o.value === value)
    }

    const option = getSelectedOptions()

    if (option) {
      return { label: item.label, value: option.label }
    }
  }

  if (item.control === 'multiselect') {
    if (Array.isArray(item.options)) {
      const selectedValues = (value as MultiselectTypes.Value) ?? []

      /**
       * Приводим опции к одному виду, если тип опции Group, забираем только сами опции, без лейбла
       * тем самым получаем плоский массив опций, типа Option
       */
      const normalizedOptions: MultiselectTypes.Option[] = item.options
        .map((option: MultiselectTypes.Option | MultiselectTypes.Group) =>
          isGroupOptions(option) ? option.options : option
        )
        .flat()

      const labelsSelectedOptions = normalizedOptions
        .filter((option) => selectedValues.includes(option.value))
        .map(({ label }) => label)
        .join(', ')

      return { label: item.label, value: labelsSelectedOptions }
    }
  }

  if (item.control === 'tagInput') {
    if (!Array.isArray(value)) {
      throw new Error('value must be an Array')
    }

    const hasMoreThenOneValue = value.length > 1

    return {
      label: item.label,
      value: hasMoreThenOneValue
        ? `${value[0]}, ${t('filter.items.more')} ${value.length - 1}`
        : value[0],
    }
  }

  if (item.control === 'datetimepicker' || item.control === 'datepicker') {
    const withTime = item.control === 'datetimepicker'
    if (Array.isArray(value)) {
      return {
        value: `
          ${formatDateToDisplay(value[0], { withTime })}
          -
          ${formatDateToDisplay(value[1], { withTime })}`,
      }
    }

    return { value: formatDateToDisplay(value as Date, { withTime }) }
  }

  return { label: String(item.label), value: String(value) }
}

export const getFilterItemsDescription = (
  values: FiltersFormValues,
  controls: ControlType[]
) =>
  Object.entries(values)
    .filter((entry) => isDefined(entry[1]))
    .reduce<FilterDescription[]>((acc, [key, value]) => {
      const control = controls.find((c) => c.name === key)

      if (control) {
        const description = getFilterLabel(getFilterState(value, control))

        return acc.concat(description)
      }

      return acc
    }, [])
