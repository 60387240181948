<template>
  <FormControlBase v-slot="{ invalid }" :name="name">
    <div :class="$style.inner">
      <div :class="$style.time">
        <TimePicker
          :name="name"
          :invalid="invalid"
          :disabled="disabled"
          :label="t('time')"
          :placeholder="t('time')"
          :defaultUserTime="timeUser"
        />
      </div>
      <div :class="$style.date">
        <DatePicker
          :name="name"
          :invalid="invalid"
          :disabled="disabled"
          :label="t('date')"
          :placeholder="t('date')"
          :defaultUserDate="dateUser"
        />
      </div>
    </div>
  </FormControlBase>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate'
import { computed, onMounted, watch } from 'vue'

import DatePicker from '@/components/DatePicker.vue/DatePicker.vue'
import FormControlBase from '@/components/FormControlBase/FormControlBase.vue'
import TimePicker from '@/components/TimePicker/TimePicker.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { globalTime } from '@/utils/localTimeFromServer/globalLocalTime'

const props = defineProps({
  optionsStep: {
    type: Number,
    default: 15,
  },
  startSetDate: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  isSendImmediately: {
    type: Boolean,
    require: false,
  },
  disabled: Boolean,
  placeholder: String,
})

const { handleChange: handleDataTime } = useField<string>(props.name)

const t = useComponentI18n('dateTimeForm')

const timeUser = computed(() => {
  if (props.startSetDate) {
    const { hour, minutes } = globalTime.getTimeObjectFromStringDateTime(
      props.startSetDate
    )
    return { hours: hour, minutes }
  }
  return undefined
})
const dateUser = computed(() => {
  if (props.startSetDate) {
    return globalTime.getDateObjectFromStringDateTime(props.startSetDate)
  }
  return undefined
})
const { value: timePicker } = useField<string>(props.name + 'TimePicker')
const { value: HoursPicker } = useField<string>(props.name + 'HoursPicker')
const { value: MinutesPicker } = useField<string>(props.name + 'MinutesPicker')

const { value: datePicker } = useField<string>(props.name + 'DatePicker')
const { value: DayPicker } = useField<string>(props.name + 'DayPicker')
const { value: MonthPicker } = useField<string>(props.name + 'MonthPicker')
const { value: YearPicker } = useField<string>(props.name + 'YearPicker')
const { handleChange: handleServerDataTime } = useField<string>(
  props.name + 'ServerDateTime'
)

const timeDatePickerObject = computed(() => {
  return {
    hours: HoursPicker.value ?? dataTimeLocalNow.value.hours,
    minutes: MinutesPicker.value ?? dataTimeLocalNow.value.minutes,
    day: DayPicker.value ?? dataTimeLocalNow.value.day,
    month: MonthPicker.value ?? dataTimeLocalNow.value.month,
    year: YearPicker.value ?? dataTimeLocalNow.value.year,
  }
})

const dataTimeLocalNow = computed(() => globalTime.getLocalTimeDateObject())

const serverHandleTime = () => {
  const serverObjectDateTime = globalTime.formatObjectDateTimeToServerDateTime(
    timeDatePickerObject.value
  )
  const serverFormatedDateTime =
    globalTime.formatObjectDateTimeToStringDateTime(serverObjectDateTime)
  handleDataTime(serverFormatedDateTime)
  handleServerDataTime(serverObjectDateTime)
}
const setServerDate = () => {
  if (props.startSetDate) {
    handleDataTime(props.startSetDate)
  }
}

onMounted(() => {
  setServerDate()
  serverHandleTime()
})

watch(
  () => [timePicker.value, datePicker.value],
  () => {
    serverHandleTime()
  }
)
</script>

<style module>
.inner {
  display: flex;
}

.date {
  width: 60%;
}

.time {
  width: 40%;
  margin-right: 4px;
}
</style>
