import { computed } from 'vue'

import { i18n } from '@/i18n'
import yup from '@/utils/yup'

const { t } = i18n.global

const MAX_LENGTH = 1000

export type TemplatesTestForm = {
  template: string
  message: string
}

export const templatesTestSchema: yup.SchemaOf<TemplatesTestForm> = yup.object({
  template: yup
    .string()
    .max(
      MAX_LENGTH,
      computed(() => t('validation.string.max', { number: MAX_LENGTH }))
    )
    .min(
      1,
      computed(() => t('templatesTest.templateLabel'))
    ),

  message: yup
    .string()
    .max(
      MAX_LENGTH,
      computed(() => t('validation.string.max', { number: MAX_LENGTH }))
    )
    .min(
      1,
      computed(() => t('templatesTest.messageLabel'))
    ),
})
