<template>
  <Text tag="h1">
    {{ $t('resetPassword.title') }}
  </Text>
  <form :class="$style.form" @submit="onSubmit">
    <FormControls :controls="formControls" />

    <Button type="submit" :class="$style.submit" :loading="isLoading">
      {{ $t('resetPassword.form.submit') }}
    </Button>

    <ApiErrorMessage :error="error" :class="$style.submitError" />
  </form>
</template>

<script setup lang="ts">
import { Button, Text } from '@smst/ui'
import { useForm } from 'vee-validate'
import { useMutation } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormControls from '@/components/FormControls/FormControls.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'

import type { ResetPassword } from './ResetPassword.schema'
import { resetPasswordSchema } from './ResetPassword.schema'

const props = defineProps({
  code: {
    type: String,
    required: true,
  },
})

const t = useComponentI18n('resetPassword')

const router = useRouter()

const { handleSubmit } = useForm<ResetPassword>({
  validationSchema: resetPasswordSchema,
})

const formControls: ControlType[] = [
  {
    control: 'input',
    name: 'password',
    type: 'password',
    label: t('form.password.label'),
    placeholder: t('form.password.placeholder'),
  },
  {
    control: 'input',
    name: 'passwordRepeated',
    type: 'password',
    label: t('form.passwordRepeated.label'),
    placeholder: t('form.passwordRepeated.placeholder'),
  },
]

const {
  mutate: resetPassword,
  isLoading,
  error,
} = useMutation(
  async (values: ResetPassword) => {
    return await apiClient.restorePassword.resetPassword_POST(
      props.code,
      values
    )
  },

  {
    onSuccess: () => {
      void router.push({ name: RouteNames.Auth })
    },
  }
)

const onSubmit = handleSubmit((values) => {
  resetPassword(values)
})
</script>

<style module>
.form {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: var(--gap-32);
}

.submit {
  width: 100%;
  margin-top: var(--gap-32);
}

.submitError {
  margin-top: var(--gap-8);
}
</style>
