<template>
  <PageHeader :class="$style.header" />

  <SearchBar
    :class="$style.searchBar"
    :values="searchValues"
    :locale="{
      label: $t('groups.search.label'),
      button: $t('groups.search.submit'),
    }"
    @submit="handleSearchSubmit"
  />

  <template v-if="hasTableData">
    <MultiplyActions
      :class="$style.groupsDropdown"
      :disabled="!hasSelectedElements(selectedElements)"
      @sendGroup="handleSendGroups"
      @deleteGroup="handleDeleteGroups"
    />

    <LoaderWrapper :loading="isFetchingGroups">
      <GroupsTable
        v-model:modelSelection="selectedElements"
        v-model:modelSort="sortModel"
        :data="tableData"
        @sendGroup="handleSendGroup"
        @deleteGroup="handleDeleteGroup"
        @goToGroupPage="goToGroupPage"
      />
    </LoaderWrapper>

    <PaginationPanel v-model="paginationModel" :totalItems="totalItems" />
  </template>

  <div v-else :class="$style.container">
    <Loader v-if="isLoadingGroups" size="m" />

    <NoResultsFound v-if="showEmptyResultLabel" :withAdvice="false" />

    <ApiErrorMessage :error="groupsError" />
  </div>
  <Modal
    v-model="isOpenDelete"
    :title="t('delete.title', { name: groupDelete?.groupName })"
    :acceptButton="t('delete.acceptButton')"
    :cancelButton="t('delete.cancelButton')"
    @accept="handleModalDeleteGroup"
  />
</template>

<script setup lang="ts">
import type { TableTypes } from '@smst/ui'
import { Loader, LoaderWrapper, Modal, toaster } from '@smst/ui'
import { StringParam, withDefault } from 'serialize-query-params'
import { computed, ref } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import PaginationPanel from '@/components/PaginationPanel/PaginationPanel.vue'
import SearchBar from '@/components/SearchBar/SearchBar.vue'
import type { SearchValue } from '@/components/SearchBar/SearchBar.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { createSortQueryConfig, useTableRequest } from '@/hooks/useTableRequest'
import { selectedElementsToQueryString } from '@/pages/BatchCreate/BatchCreate.utils'
import { RouteNames } from '@/routeNames'
import { getErrorMessage } from '@/utils/errors'
import { hasSelectedElements } from '@/utils/hasSelectedElements'

import GroupsTable from './components/GroupsTable/GroupsTable.vue'
import MultiplyActions from './components/MultiplyActions/MultiplyActions.vue'
import PageHeader from './components/PageHeader/PageHeader.vue'
import { useGroupsTable } from './hooks/useGroupsTable'

type TableItem = (typeof tableData.value)[number]
type SelectedElements = TableTypes.ModelSelection<TableItem, 'groupId'>

const groupsQueryKey = 'groups'

const queryClient = useQueryClient()
const router = useRouter()

const isOpenDelete = ref<boolean>(false)
const groupDelete = ref<{ groupName: string; groupId: number } | undefined>()

const t = useComponentI18n('groups')

const emptySelectedState: SelectedElements = {
  all: false,
  include: [],
  exclude: [],
}

const sortConfig = createSortQueryConfig(['creationDate', 'groupName'])

const selectedElements = ref<SelectedElements>(emptySelectedState)
const searchValues = computed(() => ({ filter: request.value.filter ?? '' }))

const { request, paginationModel, filtersModel, resetPagination, sortModel } =
  useTableRequest({
    filtersConfig: {
      filter: withDefault(StringParam, undefined),
    },
    sortConfig,
  })

const handleSearchSubmit = (values: SearchValue) => {
  const searchValue = values.filter || undefined

  filtersModel.value = { filter: searchValue }
}

const {
  data: groups,
  error: groupsError,
  isLoading: isLoadingGroups,
  isFetching: isFetchingGroups,
} = useQuery(
  [groupsQueryKey, request],
  async () => {
    const response = await apiClient.groups.list_GET(request.value)

    return response.data
  },
  {
    keepPreviousData: true,
  }
)

const { mutate: deleteGroups } = useMutation(apiClient.groups.delete_POST, {
  onSuccess: () => {
    void queryClient.invalidateQueries(groupsQueryKey)
    selectedElements.value = emptySelectedState
    resetPagination()

    toaster.success(t('delete.success'))
  },
  onError: (error) => {
    toaster.error(getErrorMessage(error))
  },
})

const { tableData, totalItems, hasTableData, showEmptyResultLabel } =
  useGroupsTable(groups)

const goToGroupPage = (groupId: number) => {
  void router.push({
    name: RouteNames.GroupEdit,
    params: { groupId },
  })
}

const handleModalDeleteGroup = () => {
  if (groupDelete.value) {
    deleteGroups({
      selectedElements: {
        ...emptySelectedState,
        include: [groupDelete.value.groupId],
      },
    })
  }
  isOpenDelete.value = false
}

const handleDeleteGroup = (group: { groupId: number; groupName: string }) => {
  isOpenDelete.value = true
  groupDelete.value = group
}

const handleDeleteGroups = () => {
  deleteGroups({ selectedElements: selectedElements.value })
}

const handleSendGroups = () => {
  sendGroups(selectedElements.value)
}

const handleSendGroup = (groupId: number) => {
  const elements = {
    all: false,
    include: [groupId],
    exclude: [],
  }

  sendGroups(elements)
}

const sendGroups = (elements: SelectedElements) => {
  const queryString = selectedElementsToQueryString(elements)

  void router.push({
    name: RouteNames.BatchCreate,
    query: { selectedGroups: queryString },
  })
}
</script>

<style module>
.header {
  margin-bottom: var(--gap-24);
}

.searchBar {
  margin-bottom: var(--gap-24);
}

.container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.groupsDropdown {
  margin-bottom: var(--gap-16);
}
</style>
