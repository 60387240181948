// import { isDefined } from 'remeda'
import { toaster } from '@smst/ui'
import { computed, ref } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import { getErrorMessage } from '@/utils/errors'

const statusId = ref(0)
const dataStatusInfo = ref([])

const getStatusId = computed(() => {
  return statusId.value
})

export const totalItems = ref(0)
export const currentPage = ref(0)
export const totalPages = ref(0)

const setStatusPage = (page) => {
  currentPage.value = page
}
const resetData = () => {
  setStatusPage(0)
  dataStatusInfo.value = []
}

export const initStatusInfo = () => {
  const { mutate: getReportlog, isLoading } = useMutation(
    async () => {
      return await apiClient.messages.reportLog_GET_BY(statusId.value, {
        page: currentPage.value + 1,
      })
    },
    {
      onSuccess: ({ data }) => {
        dataStatusInfo.value.push(...data.list)
        currentPage.value = data?.pageInfo?.currentPage
        totalPages.value = data?.pageInfo?.totalPages
        totalItems.value = data?.pageInfo?.totalItems
      },
      onError: (error) => {
        toaster.error(getErrorMessage(error))
      },
    }
  )
  const setStatusId = (id: number) => {
    if (id !== getStatusId.value) resetData()
    statusId.value = id
    if (statusId.value) getReportlog()
  }
  return {
    isLoading,
    getReportlog,
    getStatusId,
    setStatusId,
    dataStatusInfo,
    currentPage,
    totalPages,
  }
}
