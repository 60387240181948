import { computed, nextTick, ref } from 'vue'

const LOCAL_AUTH_ERROR_CODE = 'authErrorCode'

const errorCode = ref(0)
const captchaToken = ref('')

// начало - устанавливаем error code
const setErrorCode = async (error: number) => {
  if (error === 109 || error === 138) {
    clearErrorCode()
    await nextTick()
    errorCode.value = error
    localStorage.setItem(LOCAL_AUTH_ERROR_CODE, JSON.stringify(error))
  }
}
const clearErrorCode = () => {
  errorCode.value = 0
  localStorage.setItem(LOCAL_AUTH_ERROR_CODE, JSON.stringify('0'))
}
const getErrorCode = () => {
  const error = localStorage.getItem(LOCAL_AUTH_ERROR_CODE)
  errorCode.value = error ? JSON.parse(error) : ''
}

const errorActive = computed(() => {
  return errorCode.value === 109 || errorCode.value === 138
})
const setCaptcha = (token: string) => {
  captchaToken.value = token
}

export {
  setErrorCode,
  clearErrorCode,
  getErrorCode,
  errorActive,
  setCaptcha,
  errorCode,
  captchaToken,
}
