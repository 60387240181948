import { watchEffect } from 'vue'

import { initValidation } from '@/utils/validator/inputValidation'

// eslint-disable-next-line @typescript-eslint/ban-types
export const startValidate = (schema, getSavePassword: Function) => {
  const {
    handlerValidate,
    info,
    valid,
    values,
    changeRules,
    validateRules,
    clearForm,
  } = initValidation(schema)

  watchEffect(() => {
    if (getSavePassword().value) {
      void validateRules('passwordConfirmations')
    }
  })
  return {
    handlerValidate,
    info,
    valid,
    values,
    changeRules,
    validateRules,
    clearForm,
  }
}
