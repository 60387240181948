import type { SelectTypes } from '@smst/ui'
import { toaster } from '@smst/ui'
import { computed } from 'vue'
import type { ComputedRef, Ref } from 'vue'
import { useMutation } from 'vue-query'
import { useRoute, useRouter } from 'vue-router'

import { apiClient } from '@/api'
import { Gender } from '@/api/__generated__/api.schema'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { i18n } from '@/i18n'
import { RouteNames } from '@/routeNames'
import { getErrorMessage } from '@/utils/errors'
import { formatDateToRequest } from '@/utils/formatDateToRequest'
import yup from '@/utils/yup'

export type FormValues = {
  phone: string
  gender?: Gender
  name?: string
  birthday?: Date
  comment?: string
}

export type Values = {
  phone: number
  gender?: Gender
  name?: string
  birthday?: string
  comment?: string
}

type Locale = {
  title: string
  submit: string
  cancel: string
  close: string
}

type UseMemberEditDrawer = () => {
  isOpen: ComputedRef<boolean>
  open: (phone: number) => void
  close: VoidFunction
}

type UseMemberEditProps = {
  groupId: Ref<number>
  phone: Ref<number>
  onSuccess: VoidFunction
}

type UseMemberEdit = (props: UseMemberEditProps) => {
  isLoading: Ref<boolean>
  locale: ComputedRef<Locale>
  editMember: (values: FormValues) => void
}

export const GROUP_MEMBER_KEY = 'group-member'

const genders = Object.values(Gender)

const { t: tGlobal } = i18n.global

const MIN_NUMBERS = 9
const MAX_NUMBERS = 15
const MAX_NAME = 100
const MAX_COMMENT = 1024

export const validationSchema: yup.SchemaOf<FormValues> = yup.object({
  phone: yup
    .string()
    .matches(/^\d+$/, {
      message: tGlobal('validation.number', {
        name: tGlobal('group.editMember.form.phone'),
      }),
      excludeEmptyString: true,
    })
    .min(MIN_NUMBERS, tGlobal('validation.string.min', { number: MIN_NUMBERS }))
    .max(MAX_NUMBERS, tGlobal('validation.string.max', { number: MAX_NUMBERS }))
    .requiredField('group.editMember.form.phone'),
  name: yup
    .string()
    .max(MAX_NAME, tGlobal('validation.string.max', { number: MAX_NAME })),
  birthday: yup.date(),
  gender: yup.mixed().oneOf<Gender>(
    genders,
    tGlobal('validation.required', {
      name: tGlobal('group.editMember.form.gender'),
    })
  ),
  comment: yup
    .string()
    .max(
      MAX_COMMENT,
      tGlobal('validation.string.max', { number: MAX_COMMENT })
    ),
})

export const getFormControls = (): ControlType[] => {
  const t = useComponentI18n('group.editMember.form')

  const options: SelectTypes.Option[] = genders.map((gender) => ({
    value: gender,
    label: tGlobal(`gender.${gender}`),
  }))

  return [
    {
      control: 'input',
      name: 'phone',
      type: 'text',
      label: t('phone'),
    },
    {
      control: 'input',
      name: 'name',
      type: 'text',
      label: t('name'),
    },
    {
      control: 'select',
      name: 'gender',
      label: t('gender'),
      options,
    },
    {
      control: 'datepicker',
      name: 'birthday',
      range: false,
      label: t('birthday'),
      isBirthday: true,
    },
    {
      control: 'input',
      name: 'comment',
      type: 'text',
      label: t('comment'),
    },
  ]
}

export const useMemberEditDrawer: UseMemberEditDrawer = () => {
  const router = useRouter()
  const route = useRoute()

  const isOpen = computed(() => {
    return route.name === RouteNames.GroupEditMember
  })

  return {
    isOpen,
    open: (phone: number) => {
      void router.push({
        ...route,
        name: RouteNames.GroupEditMember,
        params: { phone },
      })
    },
    close: () => {
      void router.push({
        ...route,
        name: RouteNames.GroupEdit,
        params: { groupId: route.params.groupId },
      })
    },
  }
}

export const useMemberEdit: UseMemberEdit = (props) => {
  const { phone, groupId, onSuccess } = props

  const t = useComponentI18n('group.editMember')

  const locale = computed<Locale>(() => ({
    title: t('title', { phone: phone?.value }),
    submit: t('save'),
    cancel: t('cancel'),
    close: t('close'),
  }))

  const { mutate: editMember, isLoading } = useMutation(
    (values: Values) =>
      apiClient.group.member_PUT(groupId.value, phone.value, values),
    {
      onSuccess: () => {
        onSuccess()
        toaster.success(t('success'))
      },
      onError: (e) => {
        toaster.error(getErrorMessage(e))
      },
    }
  )

  return {
    locale,
    isLoading,
    editMember: (values: FormValues) => {
      editMember({
        ...values,
        phone: Number(values.phone),
        birthday: values.birthday
          ? formatDateToRequest(values.birthday)
          : undefined,
      })
    },
  }
}
