import { computed } from 'vue'

import { i18n } from '@/i18n'

const { t } = i18n.global

const label = computed(() => t('accountForm.password.label'))
const placeholder = computed(() => t('accountForm.password.placeholder'))
const repeatLabel = computed(() => t('accountForm.passwordConfirmation.label'))
const repeatPlaceholder = computed(() =>
  t('accountForm.passwordConfirmation.placeholder')
)
const OldLabel = computed(() => t('accountForm.oldPassword.label'))
const OldPlaceholder = computed(() => t('accountForm.oldPassword.placeholder'))
const newOldlabel = computed(() =>
  t('accountForm.oldPassword.newOldplaceholder')
)
const newOldplaceholder = computed(() =>
  t('accountForm.oldPassword.newOldplaceholder')
)
const newRepeatOldlabel = computed(() =>
  t('accountForm.oldPassword.newRepeatOldplaceholder')
)
const newRepeatOldplaceholder = computed(() =>
  t('accountForm.oldPassword.newRepeatOldplaceholder')
)

export {
  label,
  placeholder,
  repeatLabel,
  repeatPlaceholder,
  OldLabel,
  OldPlaceholder,
  newOldlabel,
  newOldplaceholder,
  newRepeatOldlabel,
  newRepeatOldplaceholder,
}
