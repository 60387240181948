<template>
  <Card
    :title="t('title')"
    :description="
      t('description', {
        amount: minAmount,
        currency: currency,
      })
    "
  >
    <form :class="$style.form" @submit="onSubmit">
      <FormControls :controls="controls" />

      <Button type="submit" fullWidth :loading="isLoading">
        {{ t('submit') }}
      </Button>
    </form>
  </Card>
</template>

<script setup lang="ts">
import { Button, toaster } from '@smst/ui'
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import type { PropType } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import Card from '@/components/Card/Card.vue'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormControls from '@/components/FormControls/FormControls.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { getErrorMessage } from '@/utils/errors'

import type { IssueBillForm } from './IssueBill.utils'
import { getIssueBillFormSchema } from './IssueBill.utils'

const { profile } = useProfile()

const minAmount = computed(
  () => profile.value?.currencyInfo.minimumSchetAmount ?? 0
)

const currency = computed(() => profile.value?.currencyInfo.currency ?? '')

const t = useComponentI18n('issueBill')

const props = defineProps({
  onSuccess: {
    type: Function as PropType<() => void>,
  },
})

const controls: ControlType[] = [
  {
    control: 'input',
    name: 'amount',
    type: 'number',
    label: t('amount.label'),
    placeholder: t('amount.placeholder'),
  },
]

const { mutate, isLoading } = useMutation(apiClient.accounting.documents_POST, {
  onSuccess: (_data, { amount }) => {
    toaster.success(
      t('success', {
        amount,
        currency: currency.value,
      })
    )

    if (props.onSuccess) {
      props.onSuccess()
    }
  },
  onError: (error) => {
    toaster.error(getErrorMessage(error))
  },
})

const { handleSubmit } = useForm<IssueBillForm>({
  validationSchema: computed(() =>
    getIssueBillFormSchema(minAmount.value, currency.value)
  ),
  initialValues: computed(() => ({
    amount: String(minAmount.value),
  })),
})

const onSubmit = handleSubmit((values) =>
  mutate({
    amount: Number(values.amount),
  })
)
</script>

<style module>
.form {
  display: grid;
  grid-template-columns: 1fr 0.65fr;
  align-items: flex-start;

  column-gap: var(--gap-8);
}
</style>
