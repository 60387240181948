import { i18n } from '@/i18n'
import yup from '@/utils/yup'

export type Channel = 'sms' | 'viber' | 'vk' | 'whatsapp'

export type FormValues = {
  name: string
  channel: Channel
  text: string
}

const { t } = i18n.global

const MAX_NAME_LENGTH = 255

export const draftFormSchema: yup.SchemaOf<FormValues> = yup.object({
  name: yup
    .string()
    .requiredField('draft.form.name')
    .max(
      MAX_NAME_LENGTH,
      t('validation.string.max', { number: MAX_NAME_LENGTH })
    ),
  channel: yup
    .mixed()
    .oneOf<Channel>(['sms', 'viber', 'vk', 'whatsapp'])
    .required(t('validation.required', { name: t('draft.form.channel') })),
  text: yup.string().trim().requiredField('draft.form.text'),
})
