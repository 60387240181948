<template>
  <BatchEdit
    v-if="isEdit"
    :isOpen="isOpen"
    :isLoading="isLoading"
    :data="detailsData"
    :imagesData="imagesData"
    :error="error"
    @back="handleBack"
    @close="handleClose"
  />
  <BatchDetails
    v-else
    :isOpen="isOpen"
    :isLoading="isLoading"
    :data="detailsData"
    :imagesData="imagesData"
    :error="error"
    @edit="handleEdit"
    @close="handleClose"
  />
</template>

<script setup lang="ts">
import { isDefined } from 'remeda'
import { computed, ref, toRef } from 'vue'
import { useQuery } from 'vue-query'
import { useRoute, useRouter } from 'vue-router'

import { apiClient } from '@/api'
import type { ApiResult, BatchExtResult } from '@/api/__generated__/api.schema'
import { RouteNames } from '@/routeNames'

import type { ImageData } from '../../Batches.utils'
import BatchDetails from '../BatchDetails/BatchDetails.vue'
import BatchEdit from '../BatchEdit/BatchEdit.vue'

const props = defineProps({
  batchId: {
    type: String,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isArchive: {
    type: Boolean,
    default: false,
  },
})

const isOpen = computed(() => Boolean(props.batchId))

const imagesData = ref<ImageData[]>([])

const {
  isLoading,
  data: batchData,
  error,
} = useQuery(
  ['batchDetails', toRef(props, 'batchId')],
  () => apiClient.batches.batches_GET_BY(Number(props.batchId)),
  {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async ({ data: nBatchData }): Promise<void> => {
      if (nBatchData?.data) {
        const { channels } = nBatchData.data
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const channell = channels ? Object.entries(channels) : ''
        if (
          channell &&
          channels?.[channell[0][0]]?.fileUrl &&
          !channels[channell[0][0]]?.fileId
        )
          getImagesFromUrl(channell)
        else await getImegesFromFileId(nBatchData)
      }
    },
    enabled: computed(() => Boolean(props.batchId)),
  }
)

const getImagesFromUrl = (channesl: [BatchExtResult]) => {
  imagesData.value = channesl.map(([channel, template]) => {
    return { channel, file: template.fileUrl }
  })
}

const getImegesFromFileId = async (
  nBatchData: ApiResult & {
    data?: BatchExtResult | undefined
  }
) => {
  if (!nBatchData.data?.batchId || !nBatchData.data?.channels) {
    return
  }

  const { batchId, channels } = nBatchData.data

  const dataToRequest = Object.entries(channels)
    .filter((entry) => {
      isDefined(entry[1].fileId)
    })
    .map(([channel, template]) => ({
      channel,
      fileId: template.fileId as number,
    }))

  const results = await Promise.all(
    dataToRequest.map(async ({ channel, fileId }) => {
      const { data: fileData } = await apiClient.batches.files_GET_BY(
        batchId,
        fileId
      )

      return { channel, file: fileData.data }
    })
  )

  imagesData.value = results.filter((res) => isDefined(res.file)) as ImageData[]
}

const detailsData = computed(() => batchData.value?.data.data)

const router = useRouter()
const route = useRoute()

const handleEdit = () => {
  const name = props.isArchive
    ? RouteNames.BatchEditArchive
    : RouteNames.BatchEdit

  void router.push({ ...route, name })
}

const handleClose = () => {
  const name = props.isArchive ? RouteNames.BatchesArchive : RouteNames.Batches

  void router.push({ ...route, name })
}

const handleBack = () => {
  const name = props.isArchive
    ? RouteNames.BatchDetailsArchive
    : RouteNames.BatchDetails

  void router.push({ ...route, name })
}
</script>
