<template>
  <RouterTabs :items="items" />
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { RouteNames } from '@/routeNames'

import RouterTabs from './RouterTabs.vue'

const { t } = useI18n()

const items = [
  {
    value: RouteNames.Accounts,
    label: t('accountsAndDepartments.tabs.accounts'),
  },
  {
    value: RouteNames.Departments,
    label: t('accountsAndDepartments.tabs.departments'),
  },
]
</script>
