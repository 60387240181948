<template>
  <div :class="$style.root" @click.once="errorStart">
    {{ t('title') }}
    <Text weight="bold">
      {{ balanceInfo.balance ?? '—' }} {{ balanceInfo.currency }}
    </Text>
  </div>
</template>

<script setup lang="ts">
import { Text } from '@smst/ui'
import { computed } from 'vue'

import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'

const { profile } = useProfile()
const t = useComponentI18n('balanceInfo')

const balanceInfo = computed(() => {
  return {
    balance: profile.value?.balance,
    currency:
      profile.value?.accounting === 'money'
        ? profile.value?.currencyInfo.currency
        : t('creditCurrency'),
  }
})
const errorStart = () => {
  throw new Error('Sentry Error')
}
</script>

<style module>
.root {
  padding: var(--gap-8) var(--gap-20);

  background-color: var(--color-bg-white);
  border: 1px solid var(--color-stroke-blocks);
  border-radius: 20px;
}
</style>
