import { ref } from 'vue'
import type { Ref } from 'vue'

const sidebarStoreKey = 'sidebarCollapsed'
const getStore = localStorage.getItem(sidebarStoreKey) ?? 'false'

const isCollapsed = ref<boolean>(JSON.parse(getStore))

export const useSidebar = (): {
  isCollapsed: Ref<boolean>
  toggle: () => void
} => {
  return {
    isCollapsed,
    toggle: () => {
      isCollapsed.value = !isCollapsed.value
      localStorage.setItem(sidebarStoreKey, JSON.stringify(isCollapsed.value))
    },
  }
}
