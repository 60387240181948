import type { WritableComputedRef } from 'vue'

const LOCAL_STORAGE_LOCALE_KEY = 'locale'

export const setAppLocale = (locale: WritableComputedRef<string>) => {
  const storedLocale = localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY)

  // Устанавливаем локаль из Local Storage, если сохранена
  if (storedLocale) {
    locale.value = storedLocale
  } else {
    getBrowserLocale(locale)
  }
}

const getBrowserLocale = (locale: WritableComputedRef<string>): void => {
  const language = getBrowserLanguage()
  if (language === 'ru') {
    localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, language)
    locale.value = language
  } else {
    locale.value = 'en'
  }
}

const getBrowserLanguage = (): string => {
  return window.navigator ? window.navigator.language.slice(0, 2) : 'en'
}
