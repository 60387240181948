import { format, parse } from 'date-fns'
import { isDefined, isNil } from 'remeda'
import {
  decodeDelimitedArray,
  encodeDelimitedArray,
} from 'serialize-query-params'

export const dateFormatWithTime = 'yyyy-MM-dd HH:mm:ss'
export const dateFormatWithoutTime = 'yyyy-MM-dd'

const encodeDate = (date: Date): string => {
  return format(date, dateFormatWithoutTime)
}

const decodeDate = (
  string: string | null | undefined
): Date | null | undefined => {
  if (isNil(string)) {
    return string
  }

  const date = parse(string, dateFormatWithoutTime, new Date())

  if (isNaN(Number(date))) {
    return undefined
  }

  return date
}

export const RangeDateParam = {
  encode: (dateArray: Date[] | null | undefined): string | null | undefined => {
    if (!dateArray) {
      return dateArray
    }

    const stringArray = dateArray.map(encodeDate)
    const string = encodeDelimitedArray(stringArray, ',')

    return string
  },
  decode: (
    // eslint-disable-next-line @typescript-eslint/array-type
    string: string | (string | null)[] | null | undefined
  ): Date[] | null | undefined => {
    const stringArray = decodeDelimitedArray(string, ',')

    if (!stringArray) {
      return stringArray
    }

    const dateArray = stringArray.map(decodeDate)
    const filteredDateArray = dateArray.filter(isDefined)

    // Эта проверка необходима, чтобы уже на этом этапе отбросить некорректный range,
    // что избавит от большего количества проверок в дальнейшем
    if (filteredDateArray.length !== 2) {
      return undefined
    }

    return filteredDateArray
  },
}
