import type { SendFiltersForm } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'
import { checkboxToBoolean } from '@/utils/checkbox'
import {
  linkHttpRegex,
  linkLinkRegex,
  originatorOptions,
} from '@/utils/validation'
import yup from '@/utils/yup'

type CommonChannelData = { message?: string }

type ViberChannelData = {
  message?: string
  buttonName?: string
  buttonUrl?: string
  fileName?: string
}

type WhatsappChannelData = {
  message?: string
  fileName?: string
}

// eslint-disable-next-line no-shadow
export enum ExtraPhonesType {
  SelectedGroups = 'selectedGroups',
  SelectedPhones = 'selectedPhones',
}

export type RoutesType = SendFiltersForm['routes']
export type Route = RoutesType['string'][number]

// eslint-disable-next-line no-shadow
export enum Channel {
  Sms = 'sms',
  Viber = 'viber',
  Whatsapp = 'whatsapp',
  Ok = 'ok',
  Vk = 'vk',
  Email = 'email',
  Push = 'push',
}

export const channelsLimits = {
  sms: 10000,
  viber: 1000,
  whatsapp: 4096,
  ok: 2048,
  vk: 2048,
  email: 2048,
  push: 1024,
}

export const FILE_SIZE_KB = 1000 * 1024
export const FILE_EXT = '.jpg, .jpeg, .png, .gif, .bmp, .svg, .webp'

const { t } = i18n.global

const messageSection = 'batchCreate.form.message'

// path: channels.sms.message
const getChannel = (path: string) => {
  const channel = path.split('.')[1] as `${Channel}`

  return channel
}

const getChannelLimit = (path: string) => {
  const channel = getChannel(path)
  const limit = channelsLimits[channel]

  return limit
}

const getCommonChannelMessageMaxLengthValidationSchema = (
  routes: RoutesType | undefined
) =>
  yup.string().test(
    'channels.common.message.maxLength',
    ({ path }) => {
      const limit = getChannelLimit(path)

      return t('validation.string.max', { number: limit })
    },
    (value, context) => {
      if (!value) {
        return true
      }

      // @ts-expect-error Недоработка yup, не описано свойство from
      const routeGroupId = context.from[2].value.routeGroupId as string
      const route = routes[routeGroupId] ?? ''

      const path = context.path
      const channel = getChannel(path)

      if (!route.includes(channel)) {
        return true
      }

      const limit = getChannelLimit(path)

      if (!limit) {
        return true
      }

      return value.length <= limit
    }
  )

export const getCommonChannelValidationSchema = (
  routes: RoutesType | undefined
): yup.SchemaOf<CommonChannelData> =>
  yup.object({
    message: getCommonChannelMessageMaxLengthValidationSchema(routes).test(
      'channels.common.message.required',
      t('validation.required', {
        name: t(`${messageSection}.channels.common.textarea.label`),
      }),
      (value, context) => {
        // @ts-expect-error Недоработка yup, не описано свойство from
        const routeGroupId = context.from[2].value.routeGroupId as string
        const route = routes[routeGroupId] ?? ''

        const path = context.path
        const channel = getChannel(path)

        if (!route.includes(channel)) {
          return true
        }

        return Boolean(value)
      }
    ),
  })

export const getWhatsappChannelValidationSchema = (
  routes: RoutesType
): yup.SchemaOf<WhatsappChannelData> =>
  yup.object({
    message: getCommonChannelMessageMaxLengthValidationSchema(routes).test(
      'channels.smart.message.required',
      t('validation.required', {
        name: t(`${messageSection}.channels.common.textarea.label`),
      }),
      (value, context) => {
        // @ts-expect-error Недоработка yup, не описано свойство from
        const routeGroupId = context.from[2].value.routeGroupId as string
        const route = routes[routeGroupId] ?? ''

        const path = context.path
        const channel = getChannel(path)

        if (!route.includes(channel)) {
          return true
        }

        // @ts-expect-error Недоработка yup, не описано свойство from
        const fileName = context.from[0].value.fileName as string

        if (fileName) {
          return true
        }

        return Boolean(value)
      }
    ),
    fileName: yup.string(),
  })

/*
  message обезательно, если нет fileName
  buttonName обезательно, если есть buttonUrl, если есть fileName
  buttonUrl обезательно, если есть buttonName, если есть fileName
  fileName обезательно, если нет message - мы добавим только одно условие, для message
*/

export const getViberChannelValidationSchema = (
  routes: RoutesType
): yup.SchemaOf<ViberChannelData> =>
  getWhatsappChannelValidationSchema(routes).shape(
    {
      buttonName: yup.string().when(['buttonUrl', 'fileName'], {
        is: (buttonUrl: string, fileName: string) => buttonUrl || fileName,
        then: (schema) =>
          schema
            .max(30, t('validation.string.max', { number: 30 }))
            .requiredField(`${messageSection}.channels.viber.buttonName.label`),
      }),
      buttonUrl: yup.string().when(['buttonName', 'fileName'], {
        is: (buttonName: string, fileName: string) => buttonName || fileName,
        then: (schema) =>
          schema
            .max(1000, t('validation.string.max', { number: 1000 }))
            .matches(linkLinkRegex, t(`batchCreate.form.linkRegex`))
            .matches(linkHttpRegex, t(`batchCreate.form.linkHttp`))
            .requiredField(`${messageSection}.channels.viber.buttonUrl.label`),
      }),
    },
    [['buttonName', 'buttonUrl']]
  )

export const getValidationSchema = (routes: RoutesType) =>
  yup.object({
    batchName: yup
      .string()
      .test(
        'max',
        t('validation.string.max', { number: 255 }),
        (value) => !value || value.length <= 255
      ),
    originator: yup.string().when(['congratulate'], {
      is: (congratulate: boolean[]) => checkboxToBoolean(congratulate),
      then: (schema) => {
        return schema
          .min(
            originatorOptions.minLength,
            t('validation.string.min', { number: originatorOptions.minLength })
          )
          .max(
            originatorOptions.maxLength,
            t('validation.string.max', { number: originatorOptions.maxLength })
          )
          .matches(
            originatorOptions.regExp,
            t(`${messageSection}.originator.validation`)
          )
          .requiredField(`${messageSection}.originator.label`)
      },
    }),
    channels: yup.object().when(['congratulate'], {
      is: (congratulate: boolean[]) => {
        return checkboxToBoolean(congratulate)
      },
      then: (schemaChannel) => schemaChannel.optional(),
      otherwise: (schemaChannel) =>
        schemaChannel.shape({
          sms: getCommonChannelValidationSchema(routes),
          viber: getViberChannelValidationSchema(routes),
          whatsapp: getWhatsappChannelValidationSchema(routes),
          ok: getCommonChannelValidationSchema(routes),
          vk: getCommonChannelValidationSchema(routes),
          email: getCommonChannelValidationSchema(routes),
          push: getCommonChannelValidationSchema(routes),
        }),
    }),
  })
