import { unref } from 'vue'
import type { Composer } from 'vue-i18n'
import { useI18n } from 'vue-i18n'
import type { MaybeRef } from 'vue-query/lib/vue/types'

export const useComponentI18n = (
  component: MaybeRef<string>
): Composer['t'] => {
  const { t } = useI18n()
  return ((key, arg1, arg2) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    t(`${unref(component)}.${key}`, arg1 as any, arg2 as any)) as Composer['t']
}
