import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { i18n } from '@/i18n'

import slide1Image from './images/slide-1.png'
import slide2Image from './images/slide-2.png'
import slide3Image from './images/slide-3.png'
import slide4Image from './images/slide-4.png'
import slide5Image from './images/slide-5.png'
import slide6Image from './images/slide-6.png'
import slide7Image from './images/slide-7.png'

const { t } = i18n.global

type Slide = {
  key: string
  title: string
  image: {
    path: string
    width: number
    height: number
  }
}

export const slides: ComputedRef<Slide[]> = computed(() => [
  {
    key: 'slide-1',
    title: t('carousel.slide-1'),
    image: {
      path: slide1Image,
      width: 537,
      height: 372,
    },
  },
  {
    key: 'slide-2',
    title: t('carousel.slide-2'),
    image: {
      path: slide2Image,
      width: 538,
      height: 399,
    },
  },
  {
    key: 'slide-3',
    title: t('carousel.slide-3'),
    image: {
      path: slide3Image,
      width: 380,
      height: 237,
    },
  },
  {
    key: 'slide-4',
    title: t('carousel.slide-4'),
    image: {
      path: slide4Image,
      width: 537,
      height: 372,
    },
  },
  {
    key: 'slide-5',
    title: t('carousel.slide-5'),
    image: {
      path: slide5Image,
      width: 537,
      height: 372,
    },
  },
  {
    key: 'slide-6',
    title: t('carousel.slide-6'),
    image: {
      path: slide6Image,
      width: 537,
      height: 372,
    },
  },
  {
    key: 'slide-7',
    title: t('carousel.slide-7'),
    image: {
      path: slide7Image,
      width: 537,
      height: 372,
    },
  },
])
