import { toaster } from '@smst/ui'
import { subDays, subMonths } from 'date-fns'
import { nextTick, ref } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import { i18n } from '@/i18n'
import { getErrorMessage } from '@/utils/errors'

import { initAnalysis } from './TableAnalysis.utils'

const { t } = i18n.global
const tFields = (field: string) => t(`DeliveryAnalysis.${field}`)

export const isDrawerOpen = ref(false)

export const handleOpen = () => {
  isDrawerOpen.value = true
}
export const handleClose = () => {
  isDrawerOpen.value = false
}

const getSchemCreateAnalysis = (data) => {
  const schema = {
    startDate: data?.date[0] ?? '',
    endDate: data?.date[1] ?? '',
    failureInterval: data?.failureInterval,
    departmentId: data?.department,
    email: data.email,
  }
  return schema
}

export const initCreateAnalysis = () => {
  const queryClient = useQueryClient()
  const { showTable } = initAnalysis()
  const reloadTable = async () => {
    void queryClient.invalidateQueries({ queryKey: ['analysisTable'] })
    showTable.value = false
    await nextTick()
    showTable.value = true
  }
  const { mutate: createAnalysis } = useMutation(
    async (data) => {
      const schema = getSchemCreateAnalysis(data)
      return await apiClient.deliveryAnalysis.reportCreate_POST(schema)
    },

    {
      onSuccess: async () => {
        toaster.success(tFields('successMessage'))
        await reloadTable()
        handleClose()
      },
      onError: (error) => {
        if (error.code === 134) {
          toaster.error(tFields('error.134'))
        } else {
          toaster.error(getErrorMessage(error))
        }
      },
    }
  )

  return { createAnalysis }
}

const getDefaultDate = [subMonths(new Date(), 1), subDays(new Date(), 1)]

export const getInitialeValue = () => {
  return {
    failureInterval: 2,
    date: getDefaultDate,
  }
}
