import { toaster } from '@smst/ui'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import { downloadFile } from '@/utils/downloadFile'
import { getErrorMessage } from '@/utils/errors'

export const initDownloadErrorsPhones = () => {
  const { mutate: downloadDocument } = useMutation(
    async (nameOfTable: string) => {
      return await apiClient.files.subscribersDownload_GET(
        { fileName: nameOfTable },
        {}
      )
    },
    {
      onSuccess: (response) => {
        void downloadFile({
          response,
          fileName: `SMS-Traffic-errors-phones`,
          extension: 'csv',
        })
      },

      onError: (error = '') => {
        toaster.error(getErrorMessage(error))
      },
    }
  )
  return downloadDocument
}
