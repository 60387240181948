export function sortByObjectValue<Options>(
  sortArray: Options[],
  options: string
) {
  return sortArray?.sort((a, b) => {
    let optionA: unknown = a[options as keyof Options]
    let optionB: unknown = b[options as keyof Options]

    optionA = typeof optionA === 'string' ? optionA.toLowerCase() : optionA
    optionB = typeof optionB === 'string' ? optionB.toLowerCase() : optionB

    if (optionA && optionB && optionA < optionB) {
      return -1
    }
    if (optionA && optionB && optionA > optionB) {
      return 1
    }

    return 0
  })
}
