<template>
  <AccountForm
    v-if="isRolesSuccess"
    :accountData="accountData"
    :rolesList="rolesList"
    :isLoading="isEditAccountLoading || isAccountDataFetching"
    :error="editError"
    @submit="onSubmit"
  />

  <div v-else :class="$style.container">
    <Loader v-if="isRolesLoading" size="m" />

    <ApiErrorMessage :error="rolesError" />
  </div>
</template>

<script lang="ts" setup>
import { Loader, toaster } from '@smst/ui'
import { computed } from 'vue'
import type { PropType } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import type {
  AccountFullResult,
  DepUserFullResult,
} from '@/api/__generated__/api.schema'
import { dictionaryDataStaleTime } from '@/api/queryConfig'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { Role } from '@/hooks/usePermissions'
import type { EditFormValues } from '@/pages/CreateAccount/components/AccountForm/AccountForm.schema'
import AccountForm from '@/pages/CreateAccount/components/AccountForm/AccountForm.vue'
import { getErrorMessage } from '@/utils/errors'

const props = defineProps({
  departmentId: {
    type: String,
    required: false,
  },
  accountData: {
    type: Object as PropType<AccountFullResult | DepUserFullResult>,
    required: true,
  },
  isAccountDataFetching: Boolean,
})

const isDepUser = computed(() => Boolean(props.departmentId))

const {
  isLoading: isRolesLoading,
  isSuccess: isRolesSuccess,
  data: rolesData,
  error: rolesError,
} = useQuery('roles', apiClient.roles.list_GET, {
  staleTime: dictionaryDataStaleTime,
})

const rolesList = computed(() => {
  if (rolesData.value?.data.list) {
    const list = rolesData.value.data.list

    if (isDepUser.value) {
      // Для пользователя департамента нужно исключить роль "Создание и редактирование пользователей и их прав"
      return list.filter(({ id }) => id !== Role.CreateAndEditUser)
    }

    return list
  }

  return []
})

const t = useComponentI18n('editAccount')

const queryClient = useQueryClient()

const query = computed(() =>
  isDepUser.value
    ? {
        get: {
          key: ['depUser', props.departmentId, String(props.accountData.id)],
        },
        put: {
          fn: async (data: EditFormValues) => {
            await apiClient.departments.users_PUT(
              Number(props.departmentId),
              props.accountData.id,
              data
            )
          },
        },
      }
    : {
        get: {
          key: ['account', String(props.accountData.id)],
        },
        put: {
          fn: async (data: EditFormValues) => {
            await apiClient.accounts.accounts_PUT(props.accountData.id, data)
          },
        },
      }
)

const {
  mutate: editAccount,
  isLoading: isEditAccountLoading,
  error: editError,
} = useMutation(query.value.put.fn, {
  onSuccess: () => {
    toaster.success(t('successEdit'))

    void queryClient.invalidateQueries(query.value.get.key)
  },
  onError: (error) => {
    toaster.error(getErrorMessage(error))
  },
})

const onSubmit = (values: EditFormValues) => {
  editAccount(values)
}
</script>

<style module>
.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
