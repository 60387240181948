<template>
  <Text tag="h1" :class="$style.title">
    {{ t('title') }}
  </Text>

  <AccountsTabs :class="$style.tabs" />

  <LoaderWrapper v-if="showTable" :loading="showLoaderAboveTable">
    <Text tag="p" :class="$style.description" size="l" weight="semibold">
      {{ t('description.departments') }}
    </Text>

    <DepartmentsTable
      :data="tableData"
      @edit="handleEdit"
      @unblock="handleUnblock"
      @delete="openDeleteConfirmation"
    />

    <Modal
      v-model="isDeleteConfirmationOpen"
      :title="
        tModal('departments.title', { name: departmentShortDataToDelete?.name })
      "
      :acceptButton="tModal('acceptButton')"
      :cancelButton="tModal('cancelButton')"
      @accept="handleDelete"
    />
  </LoaderWrapper>

  <div v-else :class="$style.container">
    <Loader v-if="isDepartmentsListLoading" size="m" />

    <NoResultsFound v-if="showNoResultsFound" :withAdvice="false" />

    <ApiErrorMessage :error="departmentsListError" />
  </div>
</template>

<script lang="ts" setup>
import { Loader, LoaderWrapper, Modal, Text, toaster } from '@smst/ui'
import { computed, ref } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import AccountsTabs from '@/components/RouterTabs/AccountsTabs.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { RouteNames } from '@/routeNames'
import { getErrorMessage } from '@/utils/errors'

import DepartmentsTable from './DepartmentsTable.vue'

type DepartmentShortData = { id: number; name: string }
type DepartmentShortDataWithPayload = DepartmentShortData & {
  data: { status: 'active' }
}

const t = useComponentI18n('accountsAndDepartments')
const tToasts = useComponentI18n('accountsAndDepartments.toasts.departments')
const tModal = useComponentI18n('accountsAndDepartments.modal')

const isDeleteConfirmationOpen = ref(false)
const departmentShortDataToDelete = ref<DepartmentShortData | undefined>(
  undefined
)

const router = useRouter()
const { profile } = useProfile()
const departmentId = profile?.value?.departmentId

const departmentsListQueryKey = 'departmentsList'

const {
  isLoading: isDepartmentsListLoading,
  isFetching: isDepartmentsListFetching,
  isSuccess: isDepartmentsListSuccess,
  data: departmentsListData,
  error: departmentsListError,
} = useQuery(departmentsListQueryKey, apiClient.departments.list_GET)

// Пользователь департамента не должен видеть сам себя в списке департаментов:
const tableData = computed(() => {
  let data = departmentsListData.value?.data.list ?? []
  if (data.length > 0 && departmentId) {
    data = data.filter((item) => {
      return item.id !== departmentId
    })
  }
  return data
})

const showTable = computed(
  () => isDepartmentsListSuccess.value && tableData.value.length > 0
)
const showNoResultsFound = computed(
  () => isDepartmentsListSuccess.value && tableData.value.length === 0
)

const queryClient = useQueryClient()

const { mutate: deleteDepartment, isLoading: isDeleteDepartmentLoading } =
  useMutation(
    async ({ id }: DepartmentShortData) => {
      await apiClient.departments.departments_DELETE(id)
    },
    {
      onSuccess: (_, { name }) => {
        void queryClient.invalidateQueries(departmentsListQueryKey)
        toaster.success(tToasts('successDelete', { name }))
      },
      onError: (error) => {
        toaster.error(getErrorMessage(error))
      },
    }
  )

const openDeleteConfirmation = (data: DepartmentShortData) => {
  isDeleteConfirmationOpen.value = true
  departmentShortDataToDelete.value = data
}

const handleDelete = () => {
  if (departmentShortDataToDelete.value) {
    deleteDepartment(departmentShortDataToDelete.value)

    isDeleteConfirmationOpen.value = false
    departmentShortDataToDelete.value = undefined
  }
}

const { mutate: editDepartment, isLoading: isEditDepartmentLoading } =
  useMutation(
    async ({ id, data }: DepartmentShortDataWithPayload) => {
      await apiClient.departments.departments_PUT(id, data)
    },
    {
      onSuccess: (_, { name }) => {
        void queryClient.invalidateQueries(departmentsListQueryKey)
        toaster.success(tToasts('successUnblock', { name }))
      },
      onError: (error) => {
        toaster.error(getErrorMessage(error))
      },
    }
  )

const handleUnblock = ({ id, name }: DepartmentShortData) => {
  editDepartment({ id, name, data: { status: 'active' } })
}

const showLoaderAboveTable = computed(
  () =>
    isDepartmentsListFetching.value ||
    isDeleteDepartmentLoading.value ||
    isEditDepartmentLoading.value
)

const handleEdit = (id: number) => {
  void router.push({
    name: RouteNames.DepartmentSettings,
    params: { departmentId: id },
  })
}
</script>

<style module>
.title {
  margin-bottom: var(--gap-24);
}

.tabs {
  align-self: flex-start;
  margin-bottom: var(--gap-24);
}

.description {
  margin-bottom: var(--gap-24);
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
