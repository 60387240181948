<template>
  <div>
    <div
      v-if="active && doubleScroll"
      :ref="scrollTop"
      :class="$style.scrollWrap"
      @scroll="scrollTopHandle"
    >
      <div :class="$style.scroll" :style="{ width: scrollWidth }">
        {{ '\xA0' }}
      </div>
    </div>
    <div
      v-if="active"
      :ref="scrollTable"
      :class="$style.tableScrollbar"
      @scroll="scrollTableHandle"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'

const props = defineProps({
  doubleScroll: Boolean,
})

const element = ref()
const scrollBar = ref()
const active = ref(false)
const elWidth = ref(0)

const scrollTable = async (el) => {
  if (props.doubleScroll && el) {
    await nextTick()
    element.value = el
    elWidth.value = el.scrollWidth
  }
}
const scrollTableHandle = (event) => {
  if (props.doubleScroll)
    scrollBar.value.scrollLeft = event.srcElement.scrollLeft
}
const scrollTopHandle = (event) => {
  if (props.doubleScroll) element.value.scrollLeft = event.srcElement.scrollLeft
}
const scrollTop = (el) => {
  if (props.doubleScroll && el) scrollBar.value = el
}
const scrollWidth = computed(() => {
  return String(elWidth.value) + 'px'
})

onMounted(() => {
  active.value = true
})

onUnmounted(() => {
  active.value = false
})
</script>

<style module>
:root {
  --main-bg-color: #d7d7d7;
}

.tableScrollbar {
  width: 100%;
  min-height: calc(100vh - 330px);
  padding-bottom: var(--gap-8);
  overflow: scroll hidden;
  scrollbar-width: auto;
  scrollbar-color: var(--main-bg-color) transparent;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--main-bg-color);

    border-radius: var(--border-radius-s);
  }
}

.scrollWrap {
  position: sticky;
  top: 5px;
  z-index: 100;

  margin-bottom: var(--gap-8);
  overflow: scroll hidden;
  scrollbar-width: 10px;
  scrollbar-color: var(--main-bg-color) transparent;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--main-bg-color);

    border-radius: var(--border-radius-s);
  }
}

.scroll {
  height: 0;
}
</style>
