<template>
  <Link
    :to="{
      name: isDepUser ? RouteNames.DepartmentAccounts : RouteNames.Accounts,
    }"
    :class="$style.link"
    view="text"
    :iconLeft="IconChevron"
  >
    {{ t(`link.${isDepUser ? 'depUser' : 'account'}`) }}
  </Link>

  <template v-if="isSuccess">
    <Text tag="h1" :class="$style.title">
      {{ t('title') }}
      <br />
      {{ accountData?.name }}
    </Text>

    <RouterTabs :items="tabsItems" :class="$style.tabs" />

    <router-view
      :departmentId="departmentId"
      :accountData="accountData"
      :isAccountDataFetching="isFetching"
    />
  </template>

  <div v-else :class="$style.container">
    <Loader v-if="isLoading" size="m" />

    <ApiErrorMessage :error="error" />
  </div>
</template>

<script lang="ts" setup>
import type { TabsTypes } from '@smst/ui'
import { IconChevron, Loader, Text } from '@smst/ui'
import { useHead } from '@vueuse/head'
import { computed } from 'vue'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import type {
  AccountFullResult,
  DepUserFullResult,
} from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Link from '@/components/Link/Link.vue'
import RouterTabs from '@/components/RouterTabs/RouterTabs.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'
import type { UserQueryHelper } from '@/types/useQueryHelper'

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  departmentId: {
    type: String,
    required: false,
  },
})

const isDepUser = computed(() => Boolean(props.departmentId))

const routeNames = computed(() =>
  isDepUser.value
    ? {
        settings: RouteNames.DepartmentAccountSettings,
        privacy: RouteNames.DepartmentAccountPrivacy,
        security: RouteNames.DepartmentAccountSecurity,
        changePassword: RouteNames.DepartmentAccountChangePassword,
      }
    : {
        settings: RouteNames.AccountSettings,
        privacy: RouteNames.AccountPrivacy,
        security: RouteNames.AccountSecurity,
        changePassword: RouteNames.AccountChangePassword,
      }
)

const t = useComponentI18n('editAccount')

const tabsItems: TabsTypes.Item[] = [
  {
    value: routeNames.value.settings,
    label: t('tabs.settings'),
  },
  {
    value: routeNames.value.privacy,
    label: t('tabs.privacy'),
  },
  {
    value: routeNames.value.security,
    label: t('tabs.security'),
  },
  {
    value: routeNames.value.changePassword,
    label: t('tabs.changePassword'),
  },
]

const query = computed(() =>
  isDepUser.value
    ? {
        key: ['depUser', props.departmentId as string, props.accountId],
        fn: () =>
          apiClient.departments.users_GET_BY(
            Number(props.departmentId),
            Number(props.accountId)
          ),
      }
    : {
        key: ['account', props.accountId],
        fn: () => apiClient.accounts.accounts_GET_BY(Number(props.accountId)),
      }
)

const { isLoading, isSuccess, isFetching, data, error } = useQuery<
  UserQueryHelper<AccountFullResult | DepUserFullResult>
>(query.value.key, query.value.fn)

const accountData = computed(() => data.value?.data.data)

useHead({
  title: computed(() => `${t('title')} ${accountData.value?.name}`),
})
</script>

<style module>
.link {
  align-self: flex-start;
  margin-bottom: var(--gap-20);
}

.title {
  margin-bottom: var(--gap-24);
}

.tabs {
  align-self: flex-start;
  margin-bottom: var(--gap-24);
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
