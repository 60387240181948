import type { SelectTypes } from '@smst/ui'
import { toaster } from '@smst/ui'
import {
  createEnumParam,
  DateParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'serialize-query-params'
import { computed, ref } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import type { SelectedElements } from '@/api/__generated__/api.schema'
import { Gender } from '@/api/__generated__/api.schema'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { i18n } from '@/i18n'
import { getErrorMessage } from '@/utils/errors'

const { t } = i18n.global

const genderParam = createEnumParam([...Object.values(Gender)])

export const MEMBERS_QUERY_KEY = 'group-members'

export const filtersConfig = {
  phone: withDefault(NumberParam, undefined),
  memberName: withDefault(StringParam, undefined),
  gender: withDefault(genderParam, undefined),
  birthday: withDefault(DateParam, undefined),
}

const genders = Object.values(Gender)

const getGenderOptions = (): SelectTypes.Option[] =>
  genders.map((gender) => ({
    value: gender,
    label: t(`gender.${gender}`),
  }))

export const getFiltersControls = (): ControlType[] => {
  return [
    {
      control: 'input',
      name: 'phone',
      type: 'number',
      label: t('group.filters.phone.label'),
      placeholder: t('group.filters.phone.placeholder'),
    },
    {
      control: 'input',
      name: 'memberName',
      label: t('group.filters.memberName.label'),
      placeholder: t('group.filters.memberName.placeholder'),
    },
    {
      control: 'datepicker',
      name: 'birthday',
      range: false,
      label: t('group.filters.birthday.label'),
      isBirthday: true,
    },
    {
      control: 'select',
      name: 'gender',
      label: t('group.filters.gender.label'),
      options: getGenderOptions(),
    },
  ]
}

export const useMembersDelete = ({
  groupId,
  onSuccess,
}: {
  groupId: string
  onSuccess: VoidFunction
}) => {
  const isOpenModal = ref<boolean>(false)
  const valueDelete = ref<SelectedElements | number>()

  const handleDeleteMembers = (value: SelectedElements | number) => {
    valueDelete.value = value
    isOpenModal.value = true
  }

  const tModal = useComponentI18n('group.modalDelete')

  const queryClient = useQueryClient()

  const { mutate: deleteMembers, isLoading: isLoadingDeleteMembers } =
    useMutation(
      (values: SelectedElements) =>
        apiClient.group.membersDeleteList_POST(Number(groupId), {
          selectedElements: values,
        }),
      {
        onSuccess: () => {
          if (typeof valueDelete.value === 'number') {
            toaster.success(
              tModal('single.success', { phone: valueDelete.value })
            )
          } else {
            toaster.success(tModal('multiple.success'))
          }

          void queryClient.invalidateQueries([MEMBERS_QUERY_KEY])

          onSuccess()
        },
        onError: (error) => {
          toaster.error(getErrorMessage(error))
        },
        onSettled: () => {
          isOpenModal.value = false
        },
      }
    )

  const handleModalDelete = computed(() => {
    if (valueDelete.value === undefined) return

    if (typeof valueDelete.value === 'number') {
      return deleteMembers({
        all: false,
        include: [valueDelete.value],
        exclude: [],
      })
    }

    return deleteMembers(valueDelete.value)
  })

  const locale = computed(() => {
    if (valueDelete.value === undefined) {
      return {
        title: '',
        acceptButton: '',
        cancelButton: '',
      }
    }

    if (typeof valueDelete.value === 'number') {
      return {
        title: tModal('single.title', { phone: valueDelete.value }),
        acceptButton: tModal('submit'),
        cancelButton: tModal('cancel'),
      }
    }

    return {
      title: tModal('multiple.title'),
      acceptButton: tModal('submit'),
      cancelButton: tModal('cancel'),
    }
  })

  return {
    isOpenModal,
    modal: computed(() => {
      return {
        ...locale.value,
        handleDelete: () => handleModalDelete.value,
      }
    }),
    handleDeleteMembers,
    isLoadingDeleteMembers,
  }
}
