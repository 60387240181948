import yup from '@/utils/yup'

export type RestorePassword = {
  login: string
  captchaCode: string
}

export const restorePasswordSchema: yup.SchemaOf<RestorePassword> = yup.object({
  login: yup.string().requiredField('forgotPassword.form.login'),
  captchaCode: yup.string().requiredField('forgotPassword.form.captcha'),
})
