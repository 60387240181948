<template>
  <form @submit="onSubmit">
    <Grid>
      <Row>
        <Card :title="t('title')">
          <div :class="$style.charset">
            <Select
              v-model="charset"
              :options="charsetOptions"
              :label="t('file.charset.label')"
              :canDeselect="false"
              :searchable="false"
            />
          </div>
          <div :class="$style.content">
            <FormFileInput
              name="file"
              :locale="{
                title: t('file.title'),
                deleteFile: t('file.title'),
                errors: {
                  WrongFormat: t('file.errors.wrongFormat'),
                  FileTooLarge: t('file.errors.fileTooLarge'),
                },
              }"
              :descriptions="[
                t('file.acceptFiles', {
                  types: ACCEPT_FILES_DESC,
                  charsets: CHARSETS,
                }),
                t('file.additional'),
              ]"
              :accept="ACCEPT_FILES"
            />
            <Button
              type="submit"
              view="secondary"
              fullWidth
              :disabled="isDisabled"
              :loading="isLoading"
            >
              {{ t('submit') }}
            </Button>
          </div>
        </Card>
      </Row>
    </Grid>
  </form>
</template>

<script setup lang="ts">
import { Button, Select, toaster } from '@smst/ui'
import { useForm } from 'vee-validate'
import { computed, ref } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import Card from '@/components/Card/Card.vue'
import FormFileInput from '@/components/FormFileInput/FormFileInput.vue'
import { Grid, Row } from '@/components/Grid'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'

type Values = {
  file: File
  charset: 'windows-1251' | 'utf-8'
}
const charsetOptions = [
  { value: 'windows-1251', label: 'windows-1251' },
  { value: 'utf-8', label: 'UTF-8' },
]

const charset = ref<string>(charsetOptions[0].value)
const ACCEPT_FILES =
  '.txt, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"'
const CHARSETS = 'windows-1251'

const t = useComponentI18n('group.loadFromFileForm')

const ACCEPT_FILES_DESC = '.txt, .csv, xlsx'

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
})

const { handleSubmit, resetForm, meta } = useForm<Values>()

const isDisabled = computed(() => !meta.value.dirty)

const { mutate: loadFile, isLoading } = useMutation(
  (values: Values) =>
    apiClient.group.membersLoadFromFile_POST(Number(props.groupId), values),
  {
    onSuccess: () => {
      toaster.success(t('success'))
      resetForm()
    },
    onError: (e) => {
      toaster.error(getErrorMessage(e))
    },
  }
)

const onSubmit = handleSubmit((values) => {
  const formData = new FormData()
  formData.set('file', values.file)
  formData.set('charset', charset.value)

  loadFile(formData as unknown as Values)
})
</script>

<style module>
.charset {
  margin-bottom: var(--gap-16);
}

.content {
  display: flex;
  flex-direction: column;

  gap: var(--gap-20);
}
</style>
