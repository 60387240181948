<template>
  <LoaderWrapper :loading="generalSettingsIsLoading || isLoadingGroups">
    <GeneralForm
      :initialDataLoading="generalSettingsIsLoading"
      :sendSettingsIsLoading="sendSettingsIsLoading"
      :initialValues="generalFormValues"
      :blackListOptions="groupsOptions"
      @submit="handleSubmitGeneralForm"
    />

    <MoNumbersTable
      v-if="!generalSettingsIsLoading"
      :data="moNumbersTableData"
      @selectNumber="handleSelectMoNumber"
    />
  </LoaderWrapper>

  <Drawer v-model="isDrawerOpen" :closeText="tEdit('closeEditForm')">
    <template #title>
      <Text tag="h2"> {{ tEdit('title') }} </Text>
    </template>

    <EditMoNumber
      v-if="selectedElement"
      :formId="formId"
      :selectedElement="selectedElement"
      :departmentOptions="departmentOptions"
      :groupsOptions="groupsOptions"
      @submit="handleSubmitMoNumbersForm"
    />

    <template #buttons>
      <Button type="submit" :form="formId" :loading="changeMoNumberIsLoading">
        {{ tEdit('submitButton') }}
      </Button>
    </template>
  </Drawer>
</template>

<script lang="ts" setup>
import { Button, Drawer, LoaderWrapper, Text, toaster } from '@smst/ui'
import { computed, ref } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import type {
  MoNumberOptions,
  MoNumberResponse,
} from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useId } from '@/hooks/useId'
import { getErrorMessage } from '@/utils/errors'
import { ProfileData } from '@/utils/profile/profile'

import EditMoNumber from './EditMoNumber.vue'
import {
  formValuesToRequest,
  getDepartmentsOptions,
  getGroupOptions,
  settingsResponseToFormValues,
} from './General.utils'
import type { GeneralForm as GeneralFormValues } from './GeneralForm.schema'
import GeneralForm from './GeneralForm.vue'
import MoNumbersTable from './MoNumbersTable.vue'

const queryClient = useQueryClient()
const t = useComponentI18n('settings.general')
const tEdit = useComponentI18n('settings.general.moNumbers.editForm')

const formId = `moNumberForm-${useId()}`

const GENERAL_SETTINGS_KEY = 'general-settings'
const GROUPS_KEY = 'groups'

const { data: generalSettings, isLoading: generalSettingsIsLoading } = useQuery(
  GENERAL_SETTINGS_KEY,
  apiClient.settings.general_GET,
  {
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)

const { data: accountsListData } = useQuery(
  'accountsList',
  apiClient.accounts.list_GET
)
/**
 * Есть договоренность с бэкэндом, фиксированный размер, явно превышающий реальные кейсы
 */
const GROUPS_MAX_SIZE = 2000
const { data: groups, isLoading: isLoadingGroups } = useQuery(
  GROUPS_KEY,
  async () => {
    const { data } = await apiClient.groups.list_GET({
      page: 1,
      size: GROUPS_MAX_SIZE,
    })

    return data
  }
)

const { mutate: changeMoNumber, isLoading: changeMoNumberIsLoading } =
  useMutation(
    async ({ id, data }: { id: number; data: MoNumberOptions }) => {
      await apiClient.settings.generalMo_PUT(id, data)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(GENERAL_SETTINGS_KEY)

        toaster.success(t('successSaved'))
        handleCloseDrawer()
      },
      onError: (error) => {
        toaster.error(getErrorMessage(error))
      },
    }
  )

const { mutate: changeGeneralSettings, isLoading: sendSettingsIsLoading } =
  useMutation(apiClient.settings.general_PUT, {
    onSuccess: async () => {
      toaster.success(t('successSaved'))
      await ProfileData.reloadProfile()
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  })

const moNumbersTableData = computed(() => {
  return generalSettings.value?.data.data?.moNumbers ?? []
})

const isDrawerOpen = ref(false)
const handleOpenDrawer = () => {
  isDrawerOpen.value = true
}
const handleCloseDrawer = () => {
  isDrawerOpen.value = false
}

const selectedElement = ref<MoNumberResponse>()

const generalFormValues = computed(() =>
  settingsResponseToFormValues(generalSettings.value?.data.data)
)
const groupsOptions = computed(() => getGroupOptions(groups.value?.list))
const departmentOptions = computed(() =>
  getDepartmentsOptions(accountsListData.value?.data.list)
)

const handleSubmitGeneralForm = (values: GeneralFormValues) => {
  changeGeneralSettings(formValuesToRequest(values))
}

const handleSelectMoNumber = (numberInfo: MoNumberResponse) => {
  selectedElement.value = numberInfo
  handleOpenDrawer()
}

const handleSubmitMoNumbersForm = (values: MoNumberOptions) => {
  if (!selectedElement.value) {
    throw new Error('MO Number must be selected to edit the form')
  }

  changeMoNumber({ id: selectedElement.value?.id, data: values })
}
</script>
