<template>
  <div
    data-testid="date_picker"
    :class="[$style.timepicker, disabled && $style.disabled]"
  >
    <div :class="$style.input" @click="openSelect">
      <Text :class="$style.label">{{ label }}</Text>
      <Text data-testid="date_picker__text">{{ date }}</Text>
    </div>
    <div v-if="selectActive || disabled" :class="$style.selectInner">
      <FormSelectNormal
        :name="timeOfMYear"
        :label="t('year')"
        :placeholder="t('year')"
        :searchable="false"
        :canDeselect="false"
        :disabled="years.length > 0"
        :options="years ?? []"
        :class="$style.timeSelect"
        :locale="{
          noOptionsText: t('timezone.timezone.noOptionsText'),
          noResultsText: t('form.multiselect.noResultsText'),
        }"
      />
      <FormSelectNormal
        data-testid="date_picker__month"
        :name="timeOfMonth"
        :label="t('month')"
        :placeholder="t('month')"
        :searchable="false"
        :canDeselect="false"
        :disabled="disabled"
        :options="monthSelect ?? []"
        :class="$style.timeSelect"
        :locale="{
          noOptionsText: t('timezone.timezone.noOptionsText'),
          noResultsText: t('form.multiselect.noResultsText'),
        }"
      />
      <FormSelectNormal
        data-testid="date_picker__day"
        :name="timeOfDay"
        :label="t('day')"
        :placeholder="t('day')"
        :searchable="false"
        :canDeselect="false"
        :disabled="disabled"
        :options="daysSelect ?? []"
        :class="$style.timeSelect"
        :locale="{
          noOptionsText: t('timezone.timezone.noOptionsText'),
          noResultsText: t('form.multiselect.noResultsText'),
        }"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import { getDaysInMonth } from 'date-fns'
import { useField } from 'vee-validate'
import type { PropType } from 'vue'
import { computed, onMounted, ref, watch } from 'vue'

import FormSelectNormal from '@/components/FormSelectNormal/FormSelectNormal.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { globalTime } from '@/utils/localTimeFromServer/globalLocalTime'

const t = useComponentI18n('dateTimeForm')

type UserDate =
  | {
      year: string
      month: string
      day: string
    }
  | undefined
type LastTimeDate =
  | {
      hours: number
      minutes: number
      day: number
      year: number
      month: number
    }
  | undefined

const props = defineProps({
  name: { type: String, required: true },
  label: {
    type: String,
    required: true,
  },
  defaultUserDate: {
    type: Object as PropType<UserDate>,
  },
  placeholder: String,
  invalid: Boolean,
  disabled: Boolean,
  maxDay: Number,
})
onMounted(() => {
  datePickerReset()
})
watch(
  () => props.disabled,
  () => {
    datePickerReset()
  }
)

const datePickerReset = () => {
  handleMonths(initialValueMonth.value)
  handleDay(initialValueDay.value)
  handleYear(initialValueYear.value)
}

const localUserDateTime = computed(() => globalTime.getLocalTimeDateObject())
const lastTimeDate: LastTimeDate = globalTime.getLocalTimeDateInRangeDays(30)

const monthNow = localUserDateTime.value.month
const yearNow = localUserDateTime.value.year
const dayNow = localUserDateTime.value.day

const initialValueDay = computed(() => {
  if (props.defaultUserDate?.day) {
    const { day } = props.defaultUserDate
    return day
  }
  return dayNow
})

const initialValueMonth = computed(() => {
  if (props.defaultUserDate?.month) {
    const { month } = props.defaultUserDate
    return month
  }
  return monthNow
})

const initialValueYear = computed(() => {
  if (props.defaultUserDate?.year) {
    const { year } = props.defaultUserDate
    return year
  }
  return yearNow
})

const monthInit = initialValueMonth.value
const yearInit = initialValueYear.value
const dayInit = initialValueDay.value

const selectActive = ref(false)

const addZero = (item: number) => {
  if (item < 10) return `0${item}`
  return String(item)
}

const daysInMonth = computed(() => {
  return getDaysInMonth(new Date(Number(yearNow), Number(monthNow) - 1))
})

const daysSelect = computed(() => {
  if (month.value === monthNow && yearNow === year.value) {
    const ff = days.value.slice(Number(dayNow) - 1)
    handleDefaultDay('first')
    return ff
  } else if (Number(month.value) === lastTimeDate?.month) {
    handleDefaultDay('last')
    return days.value.slice(0, lastTimeDate.day)
  } else {
    return days.value
  }
})

const handleDefaultDay = (monthKeys: 'first' | 'last') => {
  const dayInitial = props.defaultUserDate?.day
  const monthInitial = props.defaultUserDate?.month
  if (monthKeys === 'first') handleDay(dayNow)
  if (monthKeys === 'last') handleDay('01')
  if (monthKeys === 'first' && dayInitial && monthInitial === month.value) {
    handleDay(dayInitial)
    handleMonths(monthInitial)
  }

  if (monthKeys === 'last' && dayInitial && monthInitial === month.value) {
    handleDay(dayInitial)
    handleMonths(monthInitial)
  }
}

const monthSelect = computed(() => {
  if (year.value === yearNow && monthNow === '12') {
    const monthSelected = months.value.slice(Number(monthNow) - 1)
    // handleMonths(monthNow)
    handleDefaultDay('first')
    return monthSelected
  } else if (
    year.value === yearNow ||
    Number(year.value) === lastTimeDate?.year
  ) {
    const firstMonth = monthNow === '12' ? '01' : monthNow
    const monthSelected = months.value.slice(
      Number(firstMonth) - 1,
      Number(lastTimeDate?.month)
    )
    // handleMonths(firstMonth)
    handleDefaultDay('last')
    return monthSelected
  } else {
    return months.value
  }
})

const days = computed(() => {
  return Array.from({ length: daysInMonth.value + 1 }, (v, k) => {
    if (k !== 0) return { value: addZero(k), label: addZero(k) }
  }).filter((item) => item)
})

const months = computed(() => {
  return Array.from({ length: 12 }, (v, k) => {
    return { value: addZero(k + 1), label: addZero(k + 1) }
  })
})

const years = computed(() => {
  if (lastTimeDate?.year !== Number(yearNow)) {
    return [
      {
        value: yearNow,
        label: yearNow,
      },
      { value: lastTimeDate?.year, label: lastTimeDate?.year },
    ]
  }
  return [
    {
      value: yearNow,
      label: yearNow,
    },
  ]
})

const openSelect = () => {
  selectActive.value = !selectActive.value
}

const timeOfDay = `${props.name}DayPicker`
const timeOfMonth = `${props.name}MonthPicker`
const timeOfMYear = `${props.name}YearPicker`
const datePicker = props.name

const initialValueDate = computed(() => {
  return `${yearInit}-${monthInit}-${dayInit}`
})

const { handleChange: handleTime, value: date } = useField<string>(
  datePicker + 'DatePicker',
  {},
  {
    initialValue: initialValueDate.value,
  }
)

const { value: day, handleChange: handleDay } = useField<string>(
  timeOfDay,
  {},
  {
    initialValue: initialValueDay.value,
  }
)
const { value: month, handleChange: handleMonths } = useField<string>(
  timeOfMonth,
  {},
  {
    initialValue: initialValueMonth.value,
  }
)
const { value: year, handleChange: handleYear } = useField<string>(
  timeOfMYear,
  {},
  {
    initialValue: initialValueYear.value,
  }
)
watch(
  () => [year.value, month.value, day.value],
  (newValue) => {
    if (newValue[0] === undefined) datePickerReset()
    const oursMinutes = newValue.join('-')
    handleTime(oursMinutes)
  }
)
</script>

<style module>
.timepicker {
  --z-select: 1;
  --z-input: 2;
  --timepicker-height: 54px;
  --gap-input: 14px;

  position: relative;
}

/* .timepicker :global(.multiselect-single-label-text) {
    display: none;
  } */

.select {
  /* z-index: var(--z-select);
      position: relative; */
  height: var(--timepicker-height);
}

.timeSelect {
  width: 100%;
  margin-right: 2px;
}

.timeSelect:last-child {
  margin-right: 0;
}

.input:hover {
  box-shadow: 1px 1px 4px #e3e2e2;
}

.disabled .input {
  color: rgb(var(--color-text-dark) 0.8);

  background-color: #eaeaea;
  cursor: inherit;
}

.disabled .input span {
  color: #808080;
}

.label {
  color: var(--color-text-grey);
  font-size: 12px;
}

.input {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 6px 12px 12px;

  /* padding-top: 0px;
    padding-bottom: 0px; */
  font-size: var(--font-size-m);
  font-family: var(--font-family);

  background-color: var(--color-bg-white);
  border: 1px solid var(--color-stroke-form);
  border-radius: var(--border-radius-s);
  cursor: pointer;

  transition:
    box-shadow var(--fast-transition),
    padding var(--fast-transition);
}

.selectInner {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
</style>
