<template>
  <div :class="$style.messageStatus">
    <Text
      :color="isSuccess ? 'success' : 'primary'"
      :weight="isSuccess ? 'bold' : 'semibold'"
    >
      {{ tStatus(mapStatus) }}
    </Text>
    <Tooltip v-if="error && error !== '0'" :title="tError(error)">
      <IconInfo :class="$style.iconInfo" />
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { IconInfo, Text, Tooltip } from '@smst/ui'
import type { PropType } from 'vue'
import { computed } from 'vue'

import { useComponentI18n } from '@/hooks/useComponentI18n'

import { MessageStatus } from './MessageStatus.types'

const props = defineProps({
  status: {
    type: String as PropType<MessageStatus>,
  },

  error: String,
})

const tStatus = useComponentI18n('messagesLog.messageStatus')
const tError = useComponentI18n('messagesLog.messageError')

const messageStatuses = [
  MessageStatus.DELIVERED,
  MessageStatus.EXPIRED,
  MessageStatus.DELETED,
  MessageStatus.UNDELIVERED,
  MessageStatus.BUFFERED,
  MessageStatus.UNKNOWN,
  MessageStatus.REJECTED,
  MessageStatus.READ,
  MessageStatus.TIMEOUT,
  MessageStatus.CLICKED,
  MessageStatus.NO_DATA,
  MessageStatus.WITH_ERROR,
  MessageStatus.CHECKED,
]

const successStatuses = [
  MessageStatus.DELIVERED,
  MessageStatus.READ,
  MessageStatus.CLICKED,
  MessageStatus.CHECKED,
]

const status = computed(() =>
  props.status
    ? (props.status.toLocaleLowerCase() as MessageStatus)
    : MessageStatus.NO_DATA
)
const isSuccess = computed(() => successStatuses.includes(status.value))
const mapStatus = computed(() =>
  messageStatuses.includes(status.value) ? status.value : 'unknown'
)
</script>

<style module>
.messageStatus {
  display: flex;
  align-items: center;

  column-gap: var(--gap-4);
}

.iconInfo {
  display: flex;
  margin: auto;
}
</style>
