export const getUserSchema = <T>(password: T, passwordConfirmations: T) => {
  return [
    {
      name: 'password',
      default: '',
      required: true,
      rules: [password],
    },
    {
      name: 'passwordConfirmations',
      default: '',
      required: true,
      rules: [passwordConfirmations],
    },
  ]
}
