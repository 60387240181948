export const statusDoc = [
  {
    statusId: -1,
    text: 'Сформирован, не загружен',
    color: 'blue',
  },
  {
    statusId: 0,
    text: 'Загружен в CЭД',
    color: 'blue',
  },
  {
    statusId: 2,
    text: 'Загружен в CЭД',
    color: 'blue',
  },
  {
    statusId: 3,
    text: 'Отправлен',
    color: 'blue',
  },
  {
    statusId: 4,
    text: 'Получен клиентом',
    color: 'blue',
  },
  {
    statusId: 6,
    text: 'Ошибка при отправке',
    color: 'red',
  },
  {
    statusId: 9,
    text: 'Получен клиентом',
    color: 'blue',
  },
  {
    statusId: 7,
    text: 'Подписан клиентом',
    color: 'green',
  },
  {
    statusId: 19,
    text: 'Отозван',
    color: 'red',
  },
  {
    statusId: 20,
    text: 'Удален',
    color: 'red',
  },
  {
    statusId: 22,
    text: 'Аннулирован',
    color: 'red',
  },
  {
    statusId: 23,
    text: 'Ожидает подписания клиентом',
    color: 'синий',
  },
  {
    statusId: 27,
    text: 'Ожидает аннулирования',
    color: 'red',
  },
  {
    statusId: 28,
    text: 'Ошибка запроса статуса',
    color: 'red',
  },
  {
    statusId: 29,
    text: 'Неизвестный статус',
    color: 'red',
  },
  {
    statusId: '',
    text: 'Не сформирован',
    color: 'blue',
  },
]
