import type { TableTypes } from '@smst/ui'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import type { Report } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

const { t } = i18n.global

const tFields = (field: string) => t(`DeliveryAnalysis.fields.${field}`)

export const columns: ComputedRef<Array<TableTypes.Column<Report>>> = computed(
  () => [
    {
      name: tFields('from'),
      prop: 'startDate',
    },
    {
      name: tFields('to'),
      prop: 'endDate',
    },
    {
      name: tFields('creationDate'),
      prop: 'creationDate',
    },
    {
      name: tFields('departments'),
      prop: 'department',
      wrap: true,
    },
    {
      name: tFields('failureInterval'),
      prop: 'failureInterval',
      wrap: true,
    },
    {
      name: tFields('deliveryStatus'),
      prop: 'status',
    },
  ]
)
