<template>
  <Text weight="semibold" :class="$style[`status-${status}`]">
    {{ t(`status.${status}`) }}
  </Text>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'

import { useComponentI18n } from '@/hooks/useComponentI18n'

defineProps({
  status: {
    type: String,
    required: true,
  },
})

const t = useComponentI18n('batches')
</script>

<style module>
.status-new,
.status-processing,
.status-canceled,
.status-finished,
.status-expired {
  color: var(--color-text-grey);
}

.status-paused,
.status-premoderate {
  color: var(--color-error);
}
</style>
