import type { TechDocument } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

import type { FileInfo, TechDocsTable } from './TechDocs.types'

const { t } = i18n.global

type GetTableDataOptions = {
  documents?: TechDocument[]
  locale: string
}

const getFileInfo = (
  files: TechDocument['files'],
  extension: 'pdf' | 'zip' | 'doc'
): FileInfo | undefined => {
  const file = files.find((item) => item.extension === extension)

  if (!file) return

  return {
    name: file.fileName,
    size: file.size,
  }
}

export const getTableData = ({
  documents,
  locale,
}: GetTableDataOptions): TechDocsTable[] => {
  if (!documents) return []

  const documentByLocale = documents
    .map((document) => ({
      ...document,
      files: document.files.filter((file) => file.language === locale),
    }))
    .filter((document) => document.files.length)

  return documentByLocale.map((document) => {
    return {
      documentType: document.documentType,
      pdf: getFileInfo(document.files, 'pdf'),
      doc: getFileInfo(document.files, 'doc'),
      zip: getFileInfo(document.files, 'zip'),
    }
  })
}

export const getFileSize = (size: number) => {
  const megabytes = Math.round(size / 1e6)

  // Если в мегабайтах не целое число то переводим в килобайты
  if (megabytes < 1) {
    return `${Math.round(size / 1000)} ${t('techDocs.size.kb')}`
  }

  return `${megabytes} ${t('techDocs.size.mb')}`
}
