<template>
  <header :class="$style.header">
    <Link
      view="text"
      :iconLeft="IconChevron"
      :to="{ name: RouteNames.Groups }"
      >{{ $t('group.goToGroups') }}</Link
    >
    <Text tag="h1">{{ t('title') }}</Text>
  </header>
  <!-- <br /><br /><br />
  groupRoutes ----{{ groupRoutes }} -->
  <GroupForm
    v-if="groupRoutes"
    :initialValues="initialValues"
    :isLoading="isLoading"
    :isFiltersSuccess="isFiltersSuccess"
    :groupRoutes="groupRoutes"
    @submit="handleSubmit"
  />
</template>

<script setup lang="ts">
import { IconChevron, Text, toaster } from '@smst/ui'
import { computed } from 'vue'
import { useMutation, useQuery } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import { dictionaryDataStaleTime } from '@/api/queryConfig'
import Link from '@/components/Link/Link.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'
import { getErrorMessage } from '@/utils/errors'

import type { FormValues, Values } from '../GroupForm.validation'
import GroupForm from '../GroupForm.vue'

const t = useComponentI18n('group.createGroup')

const initialValues: FormValues = {
  groupName: '',
  viewForAll: [],
  viewOptional: [],
  congratulate: [],
}

const router = useRouter()

const { isSuccess: isFiltersSuccess, data: filterGroup } = useQuery(
  'sendFilters',
  apiClient.send.filters_GET,
  {
    staleTime: dictionaryDataStaleTime,
  }
)

const groupRoutes = computed(() => {
  return filterGroup.value?.data?.data?.routes
})
const { mutate: createGroup, isLoading } = useMutation(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  (values: Values) => apiClient.group.create_POST(values),
  {
    onSuccess: (data) => {
      const groupId = data.data.group?.groupId ? data.data.group.groupId : null

      if (groupId) {
        toaster.success(t('success'))
        void router.push({
          name: RouteNames.GroupSettings,
          params: { groupId },
        })
      }
    },
    onError: (e) => {
      toaster.error(getErrorMessage(e))
    },
  }
)

const handleSubmit = (values: Values) => {
  createGroup(values)
}
</script>

<style module>
.header {
  display: flex;
  flex-direction: column;

  gap: var(--gap-24);
  align-items: flex-start;

  margin-bottom: var(--gap-24);
}
</style>
