import { computed, reactive } from 'vue'

import type { ProfileResult } from '@/api/__generated__/api.schema'

type ItemStore = Record<string, ProfileResult | undefined>

export class StoreData {
  store: ItemStore
  constructor() {
    this.store = reactive({})
  }
  getStoreData(key: string, defaultData?: ProfileResult | undefined) {
    if (!this.hasStoreData(key)) this.setStoreData(key, defaultData)
    return this.store[key]
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  setStoreModifiedData(key: string, modified: Function) {
    const data = this.getStoreData(key)
    if (this.hasStoreData(key)) {
      this.store[key] = modified(data)
    }
  }
  hasStoreData(key: string) {
    return this.store[key]
  }
  getAllStore() {
    return computed(() => Object.keys(this.store))
  }
  setStoreData(key: string, data: ProfileResult | undefined) {
    const refData = data
    this.store[key] = refData
  }
  deleteStoreData(key: string) {
    this.store[key] = undefined
  }
}
