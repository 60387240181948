import { i18n } from '@/i18n'
import yup from '@/utils/yup'

const { t } = i18n.global

export const byFinded = location.host.split('.').includes('by')

export type RegistrationForm = {
  companyName: string
  email: string
  personalData: string[]
  verifyCode: string
}

export const registrationFormPasswordSchema: yup.SchemaOf<RegistrationForm> =
  yup.object({
    companyName: yup.string().requiredField('registration.form.companyName'),
    personalData: yup.array().test(
      'personal data checked',
      t('validation.required', {
        name: t('registration.form.personalData.label'),
      }),
      (value) => {
        if (byFinded) return byFinded
        else return Boolean(value?.includes('personalData'))
      }
    ),
    email: yup
      .string()
      .requiredField('registration.form.email')
      .email(t('validation.email')),
    verifyCode: yup.string().requiredField('registration.form.captcha'),
  })
