export const getOldSchema = <T>(
  oldPassword: T,
  password: T,
  passwordConfirmations: T
) => {
  return [
    {
      name: 'oldPassword',
      default: '',
      required: true,
      rules: [oldPassword],
    },
    {
      name: 'password',
      default: '',
      required: true,
      rules: [password],
    },
    {
      name: 'passwordConfirmations',
      default: '',
      required: true,
      rules: [passwordConfirmations],
    },
  ]
}
