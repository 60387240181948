<template>
  <Drawer
    :modelValue="isOpen"
    :closeText="t('close')"
    :title="t('title')"
    @update:modelValue="emit('close')"
  >
    <form :id="formId" @submit="onSubmit">
      <FormTextarea name="testPhones" :label="t('testPhones')" />

      <Text color="secondary" :class="$style.hint" tag="p">
        {{ t('hint') }}
      </Text>
    </form>

    <div v-if="isSuccess" :class="$style.result">
      <Text tag="h4">{{ t('resultTitle') }}</Text>

      <div :class="$style.grid">
        <Text color="secondary">{{ t('totalPhones') }}:</Text>

        <Text>{{ totalPhones ?? '-' }}</Text>
      </div>
    </div>

    <div :class="$style.error">
      <ApiErrorMessage :error="error" />
    </div>

    <template #buttons>
      <Button
        :login="isLoading"
        type="submit"
        :form="formId"
        :disabled="isSubmitDisable"
      >
        {{ t('submit') }}
      </Button>

      <Button view="link" @click="emit('close')">
        {{ t('cancel') }}
      </Button>
    </template>
  </Drawer>
</template>

<script lang="ts" setup>
import { Button, Drawer, Text } from '@smst/ui'
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import type { PropType } from 'vue'
import { useMutation } from 'vue-query'
import { useRoute } from 'vue-router'

import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import FormTextarea from '@/components/FormTextarea/FormTextarea.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

import type { SendFormCommonType } from '../../BatchCreate.utils'
import { getQuery } from '../../BatchCreate.utils'

const formId = 'testBatch'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },

  values: {
    type: Object as PropType<SendFormCommonType>,
  },
})

const emit = defineEmits(['close'])
const t = useComponentI18n('batchCreate.testDrawer')
const route = useRoute()

const {
  handleSubmit,
  resetForm,
  values: testValue,
} = useForm<{ testPhones: string }>()

const isSubmitDisable = computed(() => testValue?.testPhones?.length === 0)

const {
  isLoading,
  error,
  mutate: testBatch,
  data,
  isSuccess,
} = useMutation(
  (mutationValues: SendFormCommonType) => {
    return getQuery(mutationValues, route)
  },
  {
    onSuccess: () => {
      resetForm()
    },
  }
)

const onSubmit = handleSubmit((valuesPhones: { testPhones: string }) => {
  void testBatch({
    ...props.values,
    test: true,
    testPhones: valuesPhones.testPhones.split(',').map((item) => Number(item)),
  } as SendFormCommonType)
})

const totalPhones = computed(() => data.value?.data.data?.totalPhones)
</script>

<style module>
.hint {
  margin-top: var(--gap-8);
}

.result,
.error {
  margin-top: var(--gap-24);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-8);
  margin-top: var(--gap-12);
}
</style>
