import type { TableTypes } from '@smst/ui'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import type { Report } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

const { t } = i18n.global

export const tFields = (field: string) => t(`ReportsOnRequest.${field}`)

export const columns: ComputedRef<Array<TableTypes.Column<Report>>> = computed(
  () => [
    {
      name: tFields('sentDate'),
      prop: 'sentDate',
    },
    {
      name: tFields('subject'),
      prop: 'subject',
    },
    {
      name: tFields('emails'),
      prop: 'emails',
    },
    {
      name: tFields('files'),
      prop: 'files',
    },
  ]
)
