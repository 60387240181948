import type {
  GeneralSettings,
  GeneralSettingsResult,
} from '@/api/__generated__/api.schema'
import { booleanToCheckbox, checkboxToBoolean } from '@/utils/checkbox'

import type { SmtpForm } from './Smtp.types'

export const getInitialValues = (
  generalSettings: GeneralSettingsResult
): SmtpForm => {
  const {
    smtp1,
    transliteration,
    smtpBadHeadersAlertPeriod,
    allowedPhones,
    allowedPhonesList,
  } = generalSettings

  return {
    smtp1: booleanToCheckbox(smtp1),
    transliteration: booleanToCheckbox(Boolean(transliteration)),
    smtpBadHeadersAlertPeriod: booleanToCheckbox(
      smtpBadHeadersAlertPeriod === 'immediately'
    ),
    allowedPhones,
    allowedPhonesList,
  }
}

export const formValuesToRequest = (values: SmtpForm): GeneralSettings => {
  return {
    ...values,
    smtp1: checkboxToBoolean(values.smtp1),
    transliteration: checkboxToBoolean(values.transliteration),
    smtpBadHeadersAlertPeriod: checkboxToBoolean(
      values?.smtpBadHeadersAlertPeriod ?? [false]
    )
      ? 'immediately'
      : 'never',
  }
}
