export const views = [
  'sms',
  'viber',
  'whatsapp',
  'vk',
  'email',
  'push',
] as const

export type View = (typeof views)[number]
