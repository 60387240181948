<template>
  <Link
    :to="{ name: RouteNames.Accounts }"
    :class="$style.link"
    view="text"
    :iconLeft="IconChevron"
  >
    {{ t('link') }}
  </Link>

  <Text tag="h1" :class="$style.title">{{ t('title') }}</Text>

  <AccountForm
    v-if="isRolesSuccess"
    :rolesList="rolesList"
    :isLoading="isCreateAccountLoading"
    :error="createError"
    @submit="onSubmit"
  />

  <div v-else :class="$style.container">
    <Loader v-if="isRolesLoading" size="m" />

    <ApiErrorMessage :error="rolesError" />
  </div>
</template>

<script lang="ts" setup>
import { IconChevron, Loader, Text, toaster } from '@smst/ui'
import { computed } from 'vue'
import { useMutation, useQuery } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import { dictionaryDataStaleTime } from '@/api/queryConfig'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Link from '@/components/Link/Link.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'

import type { CreateFormValues } from './components/AccountForm/AccountForm.schema'
import AccountForm from './components/AccountForm/AccountForm.vue'

const router = useRouter()

const t = useComponentI18n('createAccount')

const {
  isLoading: isRolesLoading,
  isSuccess: isRolesSuccess,
  data: rolesData,
  error: rolesError,
} = useQuery('roles', apiClient.roles.list_GET, {
  staleTime: dictionaryDataStaleTime,
})

const rolesList = computed(() => rolesData.value?.data.list ?? [])

const {
  isLoading: isCreateAccountLoading,
  error: createError,
  mutate: createAccount,
} = useMutation(apiClient.accounts.create_POST, {
  onSuccess: (data) => {
    toaster.success(t('successCreate'))

    void router.push({
      name: RouteNames.AccountSettings,
      params: { accountId: data.data.id },
    })
  },
})

const onSubmit = (values: CreateFormValues) => {
  createAccount(values)
}
</script>

<style module>
.link {
  align-self: flex-start;
  margin-bottom: var(--gap-20);
}

.title {
  margin-bottom: var(--gap-24);
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
