import type { AnyObject } from 'yup/lib/types'

import { i18n } from '@/i18n'
import { originatorOptions } from '@/utils/validation'
import yup from '@/utils/yup'

export type GeneralForm = {
  technicalEmail: string[]
  financialEmail: string[]
  administrativeEmail: string[]
  defaultOriginator?: string
  balanceAlertMinValue?: number | string
  balanceRemindDays?: number | string
  blackListId?: number
  rusByDefaultDisabled: [boolean] | boolean | undefined
  smtp2: [boolean] | boolean | undefined
  countryId?: number
  timezoneId?: number
}

export type GeneralFormSchema = yup.SchemaOf<GeneralForm>

const { t } = i18n.global

const isEmailSchema = yup.string().email()

function validateEmails(
  this: yup.TestContext<AnyObject>,
  emails: string[] = []
) {
  const invalidEmails = emails?.filter(
    (email) => !isEmailSchema.isValidSync(email)
  )

  return invalidEmails.length === 0
    ? true
    : this.createError({
        message: t(`settings.general.validation.email`, {
          emails: invalidEmails.join(', '),
        }),
      })
}

export const generalFormSchema: GeneralFormSchema = yup.object({
  technicalEmail: yup.array().test({
    test: validateEmails,
  }),
  financialEmail: yup.array().test({
    test: validateEmails,
  }),
  administrativeEmail: yup.array().test({
    test: validateEmails,
  }),
  defaultOriginator: yup
    .string()
    .test(
      'min',
      t('validation.string.min', { number: originatorOptions.minLength }),
      (defaultOriginator) =>
        !defaultOriginator ||
        defaultOriginator.length >= originatorOptions.minLength
    )
    .test(
      'max',
      t('validation.string.max', { number: originatorOptions.maxLength }),
      (defaultOriginator) =>
        !defaultOriginator ||
        defaultOriginator.length <= originatorOptions.maxLength
    )
    .test(
      'regExp',
      t('validation.originatorRegExp'),
      (defaultOriginator) =>
        !defaultOriginator ||
        Boolean(originatorOptions.regExp.exec(defaultOriginator))
    ),
  balanceAlertMinValue: yup
    .number()
    .transform((_, val) => Number(val))
    .min(0, t('validation.value.min', { number: 0 }))
    .max(99999, t('validation.value.max', { number: 99999 })),
  balanceRemindDays: yup
    .number()
    .transform((_, val) => Number(val))
    .max(365, t('validation.value.max', { number: 365 }))
    .min(0, t('validation.value.min', { number: 0 })),
  blackListId: yup.number(),
  rusByDefaultDisabled: yup.mixed<boolean | [boolean]>(),
  // Разрешить отправку по протоколам HTTP, SOAP, SMTP, Email
  smtp2: yup.mixed<boolean | [boolean]>(),
  countryId: yup.number().required(
    t('validation.required', {
      name: t('settings.general.timezone.country.label'),
    })
  ),
  timezoneId: yup.number().required(
    t('validation.required', {
      name: t('settings.general.timezone.timezone.label'),
    })
  ),
})
