<template>
  <Table :data="data" :columns="columns" :rowKey="rowKey">
    <template #column:startDate="{ item }">
      <TableDate :date="item.startDate" />
    </template>
    <template #column:isSmartDelivery="{ item }">
      <Text>
        {{
          item.isSmartDelivery
            ? t('messageType.smartDelivery')
            : t('messageType.sms')
        }}
      </Text>
    </template>
    <template #column:status="{ item }">
      <DetailedStatus
        :batchId="item.batchId"
        :status="item.status"
        :sentPhones="item.phonesReports.sent"
        :unsentPhones="item.phonesReports.unsent"
        :totalPhones="item.totalPhones"
      />
    </template>
    <template #column:batchesGroups="{ item }">
      <div :class="$style.batches">
        <Text
          v-for="batch in item.batchesGroups"
          :key="batch.groupId"
          :class="$style.batch"
        >
          <span :class="$style.batchName" :title="batch.groupName">
            {{ batch.groupName }}
          </span>
        </Text>
      </div>
    </template>
  </Table>
</template>

<script setup lang="ts">
import type { TableTypes } from '@smst/ui'
import { Table, Text } from '@smst/ui'
import type { PropType } from 'vue'

import type { BatchResult } from '@/api/__generated__/api.schema'
import TableDate from '@/components/TableDate/TableDate.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

import DetailedStatus from './components/DetailedStatus/DetailedStatus.vue'

defineProps({
  data: {
    type: Array as PropType<BatchResult[]>,
    required: true,
  },
})

const t = useComponentI18n('batches')
const tTable = useComponentI18n('batches.table.columns')

const rowKey = 'batchId'

const columns: Array<TableTypes.Column<BatchResult>> = [
  {
    name: tTable('sendDate'),
    prop: 'startDate',
  },
  {
    name: tTable('name'),
    prop: 'name',
    wrap: true,
  },
  {
    name: tTable('type'),
    prop: 'isSmartDelivery',
  },
  {
    name: tTable('message'),
    prop: 'message',
    wrap: true,
  },
  {
    name: tTable('status'),
    prop: 'status',
  },
  {
    name: tTable('batchesGroups'),
    prop: 'batchesGroups',
    width: '340px',
  },
  {
    name: tTable('ip'),
    prop: 'ip',
  },
]
</script>

<style module>
.batches {
  display: flex;
  flex-direction: column;

  gap: var(--gap-4);
  max-width: 340px;
}

.batch {
  display: block;
}

.batchName {
  display: block;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
