import { computed } from 'vue'

import {
  checkFrontByDomain,
  checkFrontKzDomain,
  checkFrontUzDomain,
  checkRitoricaDomain,
} from '@/utils/getDomain'

export const phones = computed(() => {
  if (checkRitoricaDomain()) {
    return {
      phone1: '491631234567',
      phone2: '447341234567',
    }
  }
  if (checkFrontUzDomain()) {
    return {
      phone1: '998881234567',
      phone2: '998771234567',
    }
  }
  if (checkFrontKzDomain()) {
    return {
      phone1: '77012345678',
      phone2: '77071234567',
    }
  }
  if (checkFrontByDomain()) {
    return {
      phone1: '375291234567',
      phone2: '375292345678',
    }
  }
  return {
    phone1: '79031234567',
    phone2: '79031234567',
  }
})
