import type {
  AccountShortResult,
  GeneralSettingsResult,
  GroupsResult,
  MoNumberOptions,
  MoNumberResponse,
} from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

import type { GeneralForm } from './GeneralForm.schema'

const { t } = i18n.global

const parseEmails = (emails = '') =>
  emails
    .split(',')
    .map((item) => item.trim())
    .filter((item) => item !== '')

const joinEmails = (arrEmails: string[]) => arrEmails.join(', ')

export const settingsResponseToFormValues = (
  settings?: GeneralSettingsResult
): GeneralForm => {
  return {
    technicalEmail: parseEmails(settings?.technicalEmail),
    financialEmail: parseEmails(settings?.financialEmail),
    administrativeEmail: parseEmails(settings?.administrativeEmail),
    defaultOriginator: settings?.defaultOriginator ?? '',
    balanceAlertMinValue:
      settings?.balanceAlertMinValue !== undefined
        ? String(settings?.balanceAlertMinValue)
        : '',
    balanceRemindDays:
      settings?.balanceRemindDays !== undefined
        ? String(settings?.balanceRemindDays)
        : '',
    rusByDefaultDisabled: settings?.rusByDefaultDisabled ?? false,
    blackListId: settings?.blackList?.blackListId ?? 0,
    smtp2: settings?.smtp2 ?? false,
    countryId: settings?.timezone?.country.countryId,
    timezoneId: settings?.timezone.id,
  }
}

const getCheckboxValue = (value: unknown) => {
  if (Array.isArray(value) && value.length > 0) {
    return value[0]
  }
  if (typeof value === 'boolean') {
    return value
  }

  return false
}

export const formValuesToRequest = (values: GeneralForm) => {
  return {
    ...values,
    technicalEmail: joinEmails(values.technicalEmail),
    financialEmail: joinEmails(values.financialEmail),
    administrativeEmail: joinEmails(values.administrativeEmail),
    balanceAlertMinValue: Number(values.balanceAlertMinValue),
    balanceRemindDays: Number(values.balanceRemindDays),
    rusByDefaultDisabled: getCheckboxValue(values.rusByDefaultDisabled),
    smtp2: getCheckboxValue(values.smtp2),
  }
}

export const getGroupOptions = (groups: GroupsResult['list']) => {
  if (!groups) {
    return []
  }

  const groupsOptions = groups.map(({ groupId, groupName }) => ({
    label: groupName,
    value: groupId,
  }))

  return [
    {
      label: t('settings.general.moNumbers.editForm.groupZeroValue'),
      value: 0,
    },
    ...groupsOptions,
  ]
}

export const getDepartmentsOptions = (departments?: AccountShortResult[]) => {
  if (!departments) {
    return []
  }

  const departmentsOptions = departments
    ?.filter(({ status }) => status === 'active')
    .map(({ id, fullLogin }) => ({
      label: fullLogin,
      value: id,
    }))

  return [
    {
      label: t('settings.general.moNumbers.editForm.departmentZeroValue'),
      value: 0,
    },
    ...departmentsOptions,
  ]
}

const getProtocolIncomingMessages = (
  selectedPhone: MoNumberResponse
): MoNumberOptions['type'] => {
  const { email, script, method } = selectedPhone

  // Св-во есть только для type http
  if (method) {
    return 'http'
  }

  if (script === 'smpp') {
    return 'smpp'
  }

  if (email) {
    return 'email'
  }
}

export const getInitialState = (
  selectedPhone: MoNumberResponse
): MoNumberOptions => {
  const { groupId, departmentId, email, script, method } = selectedPhone
  const protocolType = getProtocolIncomingMessages(selectedPhone) ?? 'email'

  return {
    groupId,
    departmentId,
    type: protocolType,
    email,
    http:
      protocolType === 'http' && method
        ? {
            method,
            script,
          }
        : undefined,
  }
}
