<template>
  <HintCard v-if="isVisible" :class="$style.shortenUrlHint">
    <Text weight="bold" color="success" tag="p">
      {{ t('title') }}
    </Text>

    <Text weight="bold" tag="p">
      {{ t('subtitle') }}
    </Text>

    <Text tag="p">
      {{ text }}
    </Text>
  </HintCard>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import { computed } from 'vue'

import HintCard from '@/components/HintCard/HintCard.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

const t = useComponentI18n('batchCreate.shortenUrl')

const props = defineProps({
  value: String,
})

const linkExample = ''
// eslint-disable-next-line prefer-named-capture-group, prefer-regex-literals
const httpRegExp = new RegExp('^(http|https)://', 'i')

const linkRegex =
  // eslint-disable-next-line prefer-named-capture-group
  /^(http|https):\/\/\/?.(www\.)?([a-zА-Яа-я\d][a-zА-Яа-я\d-]*\.?)*\.[a-zА-Яа-я\d-]{2,8}(\/([\w#!:.?+=&%@\-/])*)?/gi

const isVisible = computed(() => props?.value?.match(linkRegex))

const text = computed(() => props?.value?.replace(httpRegExp, linkExample))
</script>

<style module>
.shortenUrlHint {
  display: flex;
  flex-direction: column;
  row-gap: var(--gap-8);
}
</style>
