<template>
  <InputErrorWrap :errorMessage="error">
    <Input
      :modelValue="value"
      :type="type"
      :placeholder="placeholder"
      @input="inputHandel"
    />
  </InputErrorWrap>
</template>

<script setup lang="ts">
import { Input } from '@smst/ui'
import { computed, ref, watch, watchEffect } from 'vue'

import InputErrorWrap from './inputErrorWrap.vue'

const props = defineProps({
  label: String,
  placeholder: String,
  name: String,
  type: String,
  inputValidate: Function,
  meta: Function,
})
const error = ref('')
const timeOut = ref()
const info = computed(() => {
  return props.meta(props.name)
})

watch(
  () => info.value.value,
  () => {
    setError()
  }
)
watch(
  () => info.value.error,
  () => {
    setError()
  }
)

const clearError = () => {
  if (timeOut.value) {
    error.value = ''
    clearTimeout(timeOut.value)
  }
}
const setError = () => {
  clearError()
  if (info.value.error) {
    timeOut.value = setTimeout(() => {
      error.value = info.value.error
    }, 1000)
  } else {
    clearError()
  }
}

const value = ref('')

// хак для синхронизации inputs v-model value
watchEffect(() => {
  if (info.value.value) value.value = info.value.value
})

const inputHandel = (evt) => {
  if (props.inputValidate)
    return props.inputValidate(
      (evt.target as HTMLInputElement).value.trim(),
      props.name
    )
  return
}
</script>

<style module>
.invalid {
  border: red solid 1px;
}
</style>
