<template>
  <div :class="$style.root">
    <span v-if="accountsNames.length === 0">—</span>
    <span v-for="name in accountsNames" v-else :key="name">{{ name }}</span>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'

import type { DepUserShortResult } from '@/api/__generated__/api.schema'

const props = defineProps({
  accounts: {
    type: Array as PropType<DepUserShortResult[]>,
    required: true,
  },
})

const accountsNames = computed(() =>
  props.accounts.map((account) => account.fullLogin)
)
</script>

<style module>
.root {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
</style>
