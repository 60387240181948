import type { TableTypes } from '@smst/ui'

export const hasSelectedElements = (
  selectionState: TableTypes.ModelSelection
) => {
  return (
    selectionState.exclude.length > 0 ||
    selectionState.include.length > 0 ||
    selectionState.all
  )
}
