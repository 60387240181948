<template>
  <AccountSecurityForm
    v-if="isAccountSecuritySuccess"
    :securityData="securityData"
    :isLoading="isEditAccountLoading || isAccountSecurityFetching"
    :error="editError"
    @submit="onSubmit"
  />

  <div v-else :class="$style.container">
    <Loader v-if="isAccountSecurityLoading" size="m" />

    <ApiErrorMessage :error="accountSecurityError" />
  </div>
</template>

<script lang="ts" setup>
import { Loader, toaster } from '@smst/ui'
import { computed } from 'vue'
import type { PropType } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import type {
  AccountFullResult,
  AccountSecurityResult,
  DepUserFullResult,
} from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'

import type { FormValues } from './AccountSecurity.utils'
import AccountSecurityForm from './AccountSecurityForm.vue'

const props = defineProps({
  departmentId: {
    type: String,
    required: false,
  },
  accountData: {
    type: Object as PropType<AccountFullResult | DepUserFullResult>,
    required: true,
  },
})

const isDepUser = computed(() => Boolean(props.departmentId))

const query = computed(() =>
  isDepUser.value
    ? {
        get: {
          key: ['depUserSecurity', props.departmentId, props.accountData.id],
          fn: () =>
            apiClient.departments.usersSecurity_GET_BY(
              Number(props.departmentId),
              props.accountData.id
            ),
        },
        put: {
          fn: async (data: FormValues) =>
            await apiClient.departments.usersSecurity_PUT(
              Number(props.departmentId),
              props.accountData.id,
              data
            ),
        },
      }
    : {
        get: {
          key: ['accountSecurity', props.accountData.id],
          fn: () => apiClient.accounts.security_GET_BY(props.accountData.id),
        },
        put: {
          fn: async (data: FormValues) =>
            await apiClient.accounts.security_PUT(props.accountData.id, data),
        },
      }
)

const {
  isLoading: isAccountSecurityLoading,
  isSuccess: isAccountSecuritySuccess,
  isFetching: isAccountSecurityFetching,
  data: accountSecurityData,
  error: accountSecurityError,
} = useQuery(query.value.get.key, query.value.get.fn)

const securityData = computed(
  () => accountSecurityData.value?.data.data as AccountSecurityResult
)

const t = useComponentI18n('editAccount')

const queryClient = useQueryClient()

const {
  isLoading: isEditAccountLoading,
  error: editError,
  mutate: editAccount,
} = useMutation(query.value.put.fn, {
  onSuccess: () => {
    toaster.success(t('successEdit'))

    void queryClient.invalidateQueries(query.value.get.key)
  },
  onError: (error) => {
    toaster.error(getErrorMessage(error))
  },
})

const onSubmit = (values: FormValues) => {
  editAccount(values)
}
</script>

<style module>
.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
