import { add, addDays, format, fromUnixTime, getUnixTime, sub } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'

// import { useProfile } from '@/hooks/useProfile'

// eslint-disable-next-line import/no-cycle
import { ProfileData } from '../profile/profile'

export type DateType = Date

const region = computed(() => {
  const profile = ProfileData.getProfile()
  const profileTimeZone = computed(() => profile?.value?.timezone)
  const Offset = profileTimeZone?.value?.utcOffset
  const offSetOur = Offset?.slice(1, 3)
  const offSetMinute = Offset?.slice(4, 6)
  const Operand = Offset?.slice(0, 1)
  return {
    ourOffset: Number(offSetOur) ?? '',
    minuteOffset: Number(offSetMinute) ?? '',
    Operand: Operand ?? '',
  }
})
const timePattern = 'HH:mm'
const timeDatePattern = 'yyyy-MM-dd HH:mm:ss'
const datePattern = 'yyyy-MM-dd'

type TimeDate = {
  hours: string | undefined
  minutes: string | undefined
  day: string | undefined
  year: string | undefined
  month: string | undefined
}
type TimeDateNumber = {
  hours: number
  minutes: number
  day: number
  year: number
  month: number
}
class GlobalLocalTime {
  localTimeDate: Ref<Date | undefined>
  localPaternTimeDate: Ref<string | undefined>
  localPaternTime: Ref<string | undefined>
  localPaternDate: Ref<string | undefined>
  serverTimeDate: Ref<Date | undefined>
  timeStamp: Ref<number | undefined>
  localTimeDateObject: Ref<TimeDate>
  constructor() {
    this.localTimeDate = ref()
    this.localPaternTimeDate = ref()
    this.localPaternDate = ref()
    this.localPaternTime = ref()
    this.serverTimeDate = ref()
    this.localTimeDateObject = ref({
      hours: '',
      minutes: '',
      day: '',
      year: '',
      month: '',
    })
    this.timeStamp = ref()
  }
  serverTimeToUserTime = (date: DateType | number) => {
    /**
     * Moscow time to UTC -> UTC to local timezone
     */
    if (
      date instanceof Date &&
      typeof date !== 'number' &&
      profileTimeZoneName?.value
    ) {
      const utcDate = zonedTimeToUtc(date, profileTimeZoneName.value)
      return utcDate
    }
  }
  formatToObjectTimeDate(dateFormat: Date) {
    const hours = dateFormat.getHours()
    const minutes = dateFormat.getMinutes()
    const day = dateFormat.getDate()
    const year = dateFormat.getFullYear()
    const month = 1 + dateFormat.getMonth()
    return {
      hours,
      minutes,
      day,
      year,
      month,
    }
  }
  formatToObjectUtcTimeDate(dateFormat: Date) {
    const hours = dateFormat.getUTCHours()
    const minutes = dateFormat.getUTCMinutes()
    const day = dateFormat.getUTCDate()
    const year = dateFormat.getUTCFullYear()
    const month = 1 + dateFormat.getUTCMonth()
    return {
      hours,
      minutes,
      day,
      year,
      month,
    }
  }
  addUserOffsetFromGrinvich(date: number | Date) {
    const offset = {
      hours: region?.value?.ourOffset,
      minutes: region?.value?.minuteOffset,
    }
    return region.value.Operand === '+' ? add(date, offset) : sub(date, offset)
  }
  // toGrinvichOffsetFromMoscow(date: number | Date) {
  //   return sub(date, { hours: 3 })
  // }
  addZero = (item: number) => {
    if (Number(item) < 10) return `0${item}`
    return String(item)
  }
  formatToObjectFromTimeObject({
    hours,
    minutes,
    day,
    year,
    month,
  }: TimeDateNumber) {
    return {
      hours: this.addZero(hours),
      minutes: this.addZero(minutes),
      day: this.addZero(day),
      year: String(year),
      month: this.addZero(month),
    }
  }
  setGlobalTime() {
    const date = this.getTimeStampData()
    if (!date) return

    const dateFormat = this.addUserOffsetFromGrinvich(date)

    const formatedTimeDate = this.formatToObjectUtcTimeDate(dateFormat)
    const {
      hours: userUTCHours,
      minutes: userUTCMinutes,
      day: userUTCDate,
      year: userUTCFullYear,
      month: userUTCMonth,
    } = formatedTimeDate

    const localPaternTime = `${userUTCHours}:${this.addZero(userUTCMinutes)}:00`
    const localPaternDate = `${userUTCFullYear}-${userUTCMonth}-${userUTCDate}`
    const localPaternTimeDate = `${localPaternDate} ${localPaternTime}`

    this.localTimeDateObject.value =
      this.formatToObjectFromTimeObject(formatedTimeDate)

    this.localTimeDate.value = new Date(date)
    this.localPaternTime.value = localPaternTime
    this.localPaternDate.value = localPaternDate
    this.localPaternTimeDate.value = localPaternTimeDate

    this.serverTimeDate.value = date ? new Date(date) : undefined
    this.timeStamp.value = date
  }
  userTimeToServerTime(date: DateType | string) {
    /**
     * Бэкэнд работает только с Moscow timezone
     * Local time to UTC -> UTC to Moscow timezone
     *
     */
    if (!(date instanceof Date) && typeof date !== 'number') return undefined

    const serverTime = utcToZonedTime(date, 'Europe/Moscow')

    return serverTime
  }
  setTimeStamp(timeStamp: number) {
    this.timeStamp.value = timeStamp
  }
  getLocalTimeDateObject() {
    return this.localTimeDateObject.value
  }
  getDateObjectFromStringDateTime(dateTime: string) {
    const date = dateTime.slice(0, 10)
    const dateFormat = date.split('-')
    const year = dateFormat[0]
    const month = dateFormat[1]
    const day = dateFormat[2]
    return {
      year,
      month,
      day,
    }
  }
  getTimeObjectFromStringDateTime(dateTime: string) {
    const time = dateTime.slice(11, 19)
    const timeFormat = time.split(':')
    const hour = timeFormat[0]
    const minutes = timeFormat[1]
    return {
      hour,
      minutes,
    }
  }
  formatStringDateTimeToUnixTime(dateTime: string) {
    const { year, month, day } = this.getDateObjectFromStringDateTime(dateTime)
    const { hour, minutes } = this.getTimeObjectFromStringDateTime(dateTime)

    const unixTime = getUnixTime(
      new Date(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hour),
        Number(minutes) // сделать с минутами
      )
    )
    const fromUnix = fromUnixTime(unixTime)
    return fromUnix
  }
  serverStringDateTimeToUserStringDateTime(dateTime: string) {
    const timedate = this.formatStringDateTimeToUnixTime(dateTime)

    const objectDateTime = this.formatToObjectTimeDate(timedate)

    const userObjectDateTime =
      this.formatMoscowObjectDateTimeToGrinvichObjectDateTime(objectDateTime)

    const gg = this.formatObjectDateTimeToStringDateTime(userObjectDateTime)

    return gg
  }
  serverStringDateTimeToUserObjectDateTime(dateTime: string) {
    const timedate = this.formatStringDateTimeToUnixTime(dateTime)

    const objectDateTime = this.formatToObjectTimeDate(timedate)

    return this.formatMoscowObjectDateTimeToGrinvichObjectDateTime(
      objectDateTime
    )
  }
  userStringDateTimeToObjectServerDateTime(dateTime: string) {
    const timedate = this.formatStringDateTimeToUnixTime(dateTime)

    const objectDateTime = this.formatToObjectTimeDate(timedate)

    const userObjectDateTime =
      this.formatObjectDateTimeToServerDateTime(objectDateTime)

    return userObjectDateTime
  }
  getLocalTimeDate() {
    this.getServerTimeDate()
    return this.localTimeDate.value
  }
  getLocalTimeDateInRangeDays(range: number) {
    if (this.localTimeDate.value) {
      const lastDate = addDays(this.localTimeDate.value, range)
      const objectDateTime = this.formatToObjectTimeDate(lastDate)
      return objectDateTime
    }
  }
  getServerTimeDateRequest() {
    return this.localPaternTimeDate.value
  }
  formatObjectDateTimeToStringDateTime(dateTime: TimeDate | TimeDateNumber) {
    return `${dateTime.year}-${dateTime.month}-${dateTime.day} ${dateTime.hours}:${dateTime.minutes}:00`
  }
  formatMoscowObjectDateTimeToGrinvichObjectDateTime(dateTime: TimeDateNumber) {
    const hoursToGrinvich = dateTime.hours - 3

    const toUser =
      region.value.Operand === '+'
        ? Number(hoursToGrinvich) + region.value.ourOffset
        : Number(hoursToGrinvich) - region.value.ourOffset

    const minutesToGrinvich =
      region.value.Operand === '+'
        ? Number(dateTime.minutes) + region.value.minuteOffset
        : Number(dateTime.minutes) - region.value.minuteOffset

    const getUnix = getUnixTime(
      new Date(
        Number(dateTime.year),
        Number(dateTime.month) - 1,
        Number(dateTime.day),
        toUser,
        minutesToGrinvich // сделать с минутами
      )
    )
    const fromUnix = fromUnixTime(getUnix)
    const formatedTimeDate = this.formatToObjectTimeDate(fromUnix)

    const userFormatedTimeDate =
      this.formatToObjectFromTimeObject(formatedTimeDate)

    return userFormatedTimeDate
  }

  formatObjectDateTimeToServerDateTime(dateTime: TimeDate | TimeDateNumber) {
    const hoursToGrinvich =
      region.value.Operand === '+'
        ? Number(dateTime.hours) - region.value.ourOffset
        : Number(dateTime.hours) + region.value.ourOffset

    const hoursToMoscow = hoursToGrinvich + 3

    const minutesToGrinvich =
      region.value.Operand === '+'
        ? Number(dateTime.minutes) - region.value.minuteOffset
        : Number(dateTime.minutes) + region.value.minuteOffset

    const getUnix = getUnixTime(
      new Date(
        Number(dateTime.year),
        Number(dateTime.month) - 1,
        Number(dateTime.day),
        hoursToMoscow,
        minutesToGrinvich // сделать с минутами
      )
    )

    const fromUnix = fromUnixTime(getUnix)
    const formatedTimeDate = this.formatToObjectTimeDate(fromUnix)

    const serverFormatedTimeDate =
      this.formatToObjectFromTimeObject(formatedTimeDate)

    return serverFormatedTimeDate
  }
  getLocalTime() {
    return this.localPaternTime.value
  }
  getLocalDate() {
    return this.localTimeDate.value
  }
  getLocalPaternDate() {
    return this.localPaternTimeDate.value
  }
  getServerTimeDate() {
    return this.localPaternTimeDate.value
  }
  getServerTime() {
    const TimezoneResult = this.serverTimeDate.value
      ? format(this.serverTimeDate.value, timePattern)
      : undefined
    return TimezoneResult
  }
  getDateFormatLocalTime(date: Date) {
    if (!(date instanceof Date) && typeof date !== 'number') return undefined
    const dateTime = this.serverTimeToUserTime(date)
    return dateTime ? format(dateTime, timePattern) : undefined
  }
  getDateFormatLocalDateTime(date: Date) {
    if (!(date instanceof Date) && typeof date !== 'number') return undefined
    const dateTime = this.serverTimeToUserTime(date)
    return dateTime ? format(dateTime, timeDatePattern) : undefined
  }
  formatDateTolocalDateWithoutTime(date: Date) {
    if (!(date instanceof Date) && typeof date !== 'number') return undefined
    const dateTime = this.serverTimeToUserTime(date)
    return dateTime ? format(dateTime, datePattern) : undefined
  }
  formatDateToTime(date: Date) {
    return format(date, timePattern)
  }
  formatDateToDateWithoutTime(date: Date) {
    if (!(date instanceof Date) && typeof date !== 'number') return undefined
    return format(date, datePattern)
  }

  formatDateToServerDateTime(date: Date) {
    if (!(date instanceof Date) && typeof date !== 'number') return undefined
    return this.userTimeToServerTime(date)
  }
  getTimeStampData() {
    return this.timeStamp.value ?? undefined
  }
}

const globalTime = new GlobalLocalTime()

export { globalTime, GlobalLocalTime }
