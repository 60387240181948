import type { RouteParamValue } from 'vue-router'
import { createRouter, createWebHistory, RouterView } from 'vue-router'

import AuthLayout from '@/layouts/Auth/Auth.vue'
import MainLayout from '@/layouts/Main/Main.vue'
import AccountPage from '@/pages/Account/Account.vue'
import AccountPageChangePassword from '@/pages/Account/components/AccountChangePassword/AccountChangePassword.vue'
import AccountPagePrivacy from '@/pages/Account/components/AccountPrivacy/AccountPrivacy.vue'
import AccountPageSecurity from '@/pages/Account/components/AccountSecurity/AccountSecurity.vue'
import AccountPageSettings from '@/pages/Account/components/AccountSettings.vue'
import AccountingPage from '@/pages/Accounting/Accounting.vue'
import AccountingInfoPage from '@/pages/AccountingInfo/AccountingInfo.vue'
import AccountsPage from '@/pages/Accounts/Accounts.vue'
import AuthPage from '@/pages/Auth/Auth.vue'
import BatchCreatePage from '@/pages/BatchCreate/BatchCreate.vue'
import BatchesPage from '@/pages/Batches/Batches.vue'
import AccountsCreatePage from '@/pages/CreateAccount/CreateAccount.vue'
import DashboardPage from '@/pages/Dashboard/Dashboard.vue'
import DeliveryAnalysis from '@/pages/DeliveryAnalysis/DeliveryAnalysis.vue'
import DepartmentPageAccounts from '@/pages/Department/components/DepartmentAccounts.vue'
import DepartmentPageSettings from '@/pages/Department/components/DepartmentSettings/DepartmentSettings.vue'
import DepartmentPage from '@/pages/Department/Department.vue'
import DepartmentsPage from '@/pages/Departments/Departments.vue'
import DraftsPage from '@/pages/Drafts/Drafts.vue'
import EventLogsPage from '@/pages/EventLogs/EventLogs.vue'
import ForgotPasswordPage from '@/pages/ForgotPassword/ForgotPassword.vue'
import GroupCreatePage from '@/pages/Group/components/Create/GroupCreate.vue'
import GroupEditPage from '@/pages/Group/components/Edit/GroupEdit.vue'
import GroupSettingsPage from '@/pages/Group/components/Settings/GroupSettings.vue'
import GroupPage from '@/pages/Group/Group.vue'
import Groups from '@/pages/Groups/Groups.vue'
import JournalPage from '@/pages/Journal/Jouranl.vue'
import MessagesLogPage from '@/pages/Journal/MessagesLog/MessagesLog.vue'
import RequestedReportsPage from '@/pages/Journal/RequestedReports/RequestedReports.vue'
import LoginPrivateAreaPage from '@/pages/LoginPrivateArea/LoginPrivateArea.vue'
import OperatorByPhone from '@/pages/OperatorByPhone/OperatorByPhone.vue'
import OriginatorsPage from '@/pages/Originators/Originators.vue'
import PasswordConfirmationPage from '@/pages/PasswordConfirmation/PasswordConfirmation.vue'
import PhoneConfirmationPage from '@/pages/PhoneConfirmation/PhoneConfirmation.vue'
import RegistrationPage from '@/pages/Registration/Registration.vue'
import ResetPasswordPage from '@/pages/ResetPassword/ResetPassword.vue'
import SettingsPageGeneral from '@/pages/Settings/components/General/General.vue'
import SettingsPagePassword from '@/pages/Settings/components/Password/Password.vue'
import SettingsPageSecurity from '@/pages/Settings/components/Security/Security.vue'
import SettingsPageSmtp from '@/pages/Settings/components/Smtp/Smtp.vue'
import SettingsPage from '@/pages/Settings/Settings.vue'
import StatisticsPage from '@/pages/Statistics/Statistics.vue'
import TechDocsPage from '@/pages/TechDocs/TechDocs.vue'
import TemplatesCheckPage from '@/pages/TemplatesCheck/TemplatesCheck.vue'
import TemplatesListPage from '@/pages/TemplatesList/TemplatesList.vue'
import TemplatesTestPage from '@/pages/TemplatesTest/TemplatesTest.vue'
import ToOldFront from '@/pages/ToOldFront/ToOldFront.vue'
import TransactionsHistoryPage from '@/pages/TransactionsHistory/TransactionsHistory.vue'
import { RouteNames } from '@/routeNames'

import ReportsOnRequest from './pages/ReportsOnRequest/ReportsOnRequest.vue'
import { routerHandel } from './utils/routerHandle'

function normalizeParam(
  param: RouteParamValue | RouteParamValue[],
  defaultParam: string
): string {
  return param && typeof param === 'string' ? param : defaultParam
}

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/auth',
      component: AuthLayout,
      children: [
        {
          path: '',
          name: RouteNames.Auth,
          component: AuthPage,
        },
        {
          path: 'forgot-password',
          name: RouteNames.ForgotPassword,
          component: ForgotPasswordPage,
        },
        {
          path: 'reset-password',
          name: RouteNames.ResetPassword,
          component: ResetPasswordPage,
          props: (route) => ({
            code: route.query.code,
          }),
        },
        {
          path: 'login-private-area',
          name: RouteNames.LoginPrivateArea,
          component: LoginPrivateAreaPage,
        },
      ],
    },
    // {
    //   path: '/reset-password',
    //   name: RouteNames.ResetPassword,
    //   component: ResetPasswordPage,
    //   props: (route) => ({ code: route.query.code, login: route.query.login }),
    //   meta: {
    //     layout: 'AuthLayout',
    //   },
    // },
    {
      path: '/registration',
      component: AuthLayout,
      children: [
        {
          path: '',
          name: RouteNames.Registration,
          component: RegistrationPage,
        },
        {
          path: 'phone-confirmation/:confirmationCode',
          name: RouteNames.PhoneConfirmation,
          component: PhoneConfirmationPage,
          props: true,
        },
        {
          path: 'password-confirmation/:confirmationCode',
          name: RouteNames.PasswordConfirmation,
          component: PasswordConfirmationPage,
          props: true,
        },
      ],
    },
    {
      path: '/',
      component: MainLayout,
      children: [
        {
          path: '',
          name: RouteNames.Dashboard,
          component: DashboardPage,
        },
        {
          path: '/goto-old-front',
          name: RouteNames.ToOldFront,
          component: ToOldFront,
        },
        {
          path: '/password_expired',
          name: RouteNames.PasswordExpired,
          component: SettingsPagePassword,
          props: { expired: true },
        },
        {
          path: 'settings',
          component: SettingsPage,
          children: [
            {
              path: '',
              name: RouteNames.SettingsGeneral,
              component: SettingsPageGeneral,
            },
            {
              path: 'security',
              name: RouteNames.SettingsSecurity,
              component: SettingsPageSecurity,
            },
            {
              path: 'smtp',
              name: RouteNames.SettingsSmtp,
              component: SettingsPageSmtp,
            },
            {
              path: 'password',
              name: RouteNames.SettingsPassword,
              component: SettingsPagePassword,
            },
          ],
        },
        {
          path: 'templates',
          component: RouterView,
          children: [
            {
              path: ':channel?',
              name: RouteNames.TemplatesList,
              component: TemplatesListPage,
              props: (route) => {
                return { channel: normalizeParam(route.params.channel, 'sms') }
              },
            },
            {
              path: 'check',
              name: RouteNames.TemplatesCheck,
              component: TemplatesCheckPage,
            },
            {
              path: 'test',
              name: RouteNames.TemplatesTest,
              component: TemplatesTestPage,
            },
          ],
        },
        {
          path: 'groups',
          component: RouterView,
          children: [
            {
              path: '',
              name: RouteNames.Groups,
              component: Groups,
            },
            {
              path: 'create',
              name: RouteNames.GroupCreate,
              component: GroupCreatePage,
            },
            {
              path: ':groupId',
              name: RouteNames.Group,
              component: GroupPage,
              props: true,
              children: [
                {
                  path: '',
                  name: RouteNames.GroupEdit,
                  props: true,
                  component: GroupEditPage,
                },
                {
                  path: 'edit/:phone',
                  name: RouteNames.GroupEditMember,
                  props: true,
                  component: GroupEditPage,
                },
                {
                  path: 'settings',
                  name: RouteNames.GroupSettings,
                  props: true,
                  component: GroupSettingsPage,
                },
              ],
            },
          ],
        },
        {
          path: 'drafts',
          component: RouterView,
          children: [
            {
              path: ':channel?',
              component: RouterView,
              children: [
                {
                  path: '',
                  component: DraftsPage,
                  name: RouteNames.Drafts,
                  props: (route) => {
                    return {
                      channel: normalizeParam(route.params.channel, 'all'),
                    }
                  },
                },
                {
                  path: 'draft/:draftId',
                  component: DraftsPage,
                  name: RouteNames.Draft,
                  props: (route) => {
                    return {
                      channel: normalizeParam(route.params.channel, 'all'),
                      draftId: route.params.draftId,
                    }
                  },
                },
                {
                  path: 'create',
                  component: DraftsPage,
                  name: RouteNames.DraftCreate,
                  props: (route) => {
                    return {
                      channel: normalizeParam(route.params.channel, 'all'),
                    }
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'batches',
          component: RouterView,
          children: [
            {
              path: '',
              name: RouteNames.Batches,
              component: BatchesPage,
            },
            {
              path: 'archive',
              name: RouteNames.BatchesArchive,
              component: BatchesPage,
              props: { isArchive: true },
            },
            {
              path: ':batchId',
              name: RouteNames.BatchDetails,
              component: BatchesPage,
              props: true,
            },
            {
              path: ':batchId/edit',
              name: RouteNames.BatchEdit,
              component: BatchesPage,
              props: (route) => ({
                batchId: route.params.batchId,
                isEdit: true,
              }),
            },
            {
              path: 'archive/:batchId',
              name: RouteNames.BatchDetailsArchive,
              component: BatchesPage,
              props: (route) => ({
                batchId: route.params.batchId,
                isArchive: true,
              }),
            },
            {
              path: 'archive/:batchId/edit',
              name: RouteNames.BatchEditArchive,
              component: BatchesPage,
              props: (route) => ({
                batchId: route.params.batchId,
                isEdit: true,
                isArchive: true,
              }),
            },
          ],
        },
        {
          path: 'batch-create',
          name: RouteNames.BatchCreate,
          component: BatchCreatePage,
        },
        {
          path: 'operator-by-phone',
          name: RouteNames.OperatorByPhone,
          component: OperatorByPhone,
        },
        {
          path: 'messages-log',
          component: JournalPage,
          children: [
            {
              path: '',
              name: RouteNames.MessagesLog,
              component: MessagesLogPage,
            },
            {
              path: 'requested-reports',
              name: RouteNames.RequestedReports,
              component: RequestedReportsPage,
            },
          ],
        },
        {
          path: 'statistics',
          component: StatisticsPage,
          name: RouteNames.Statistics,
        },
        {
          path: 'delivery-analysis',
          component: DeliveryAnalysis,
          name: RouteNames.DeliveryAnalysis,
        },
        {
          path: 'reports-on-request',
          component: ReportsOnRequest,
          name: RouteNames.ReportsOnRequest,
        },
        {
          path: 'accounts',
          component: RouterView,
          children: [
            {
              path: '',
              name: RouteNames.Accounts,
              component: AccountsPage,
            },
            {
              path: 'create',
              name: RouteNames.AccountsCreate,
              component: AccountsCreatePage,
            },
            {
              path: ':accountId',
              component: AccountPage,
              props: true,
              children: [
                {
                  path: 'settings',
                  name: RouteNames.AccountSettings,
                  component: AccountPageSettings,
                },
                {
                  path: 'privacy',
                  name: RouteNames.AccountPrivacy,
                  component: AccountPagePrivacy,
                },
                {
                  path: 'security',
                  name: RouteNames.AccountSecurity,
                  component: AccountPageSecurity,
                },
                {
                  path: 'change-password',
                  name: RouteNames.AccountChangePassword,
                  component: AccountPageChangePassword,
                },
              ],
            },
          ],
        },
        {
          path: 'departments',
          component: RouterView,
          children: [
            {
              path: '',
              name: RouteNames.Departments,
              component: DepartmentsPage,
            },
            {
              path: ':departmentId',
              component: RouterView,
              props: true,
              children: [
                {
                  path: 'settings',
                  component: DepartmentPage,
                  children: [
                    {
                      path: '',
                      name: RouteNames.DepartmentSettings,
                      component: DepartmentPageSettings,
                    },
                  ],
                },
                {
                  path: 'accounts',
                  component: DepartmentPage,
                  children: [
                    {
                      path: '',
                      name: RouteNames.DepartmentAccounts,
                      component: DepartmentPageAccounts,
                    },
                  ],
                },
                {
                  path: 'accounts/:accountId',
                  component: AccountPage,
                  props: true,
                  children: [
                    {
                      path: 'settings',
                      name: RouteNames.DepartmentAccountSettings,
                      component: AccountPageSettings,
                    },
                    {
                      path: 'privacy',
                      name: RouteNames.DepartmentAccountPrivacy,
                      component: AccountPagePrivacy,
                    },
                    {
                      path: 'security',
                      name: RouteNames.DepartmentAccountSecurity,
                      component: AccountPageSecurity,
                    },
                    {
                      path: 'change-password',
                      name: RouteNames.DepartmentAccountChangePassword,
                      component: AccountPageChangePassword,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'event-logs',
          name: RouteNames.EventLogs,
          component: EventLogsPage,
        },
        {
          path: 'originators',
          name: RouteNames.Originators,
          component: OriginatorsPage,
        },
        {
          path: 'techdocs',
          name: RouteNames.TechDocs,
          component: TechDocsPage,
        },
        {
          path: 'accounting-info',
          name: RouteNames.AccountingInfo,
          component: AccountingInfoPage,
        },
        {
          path: 'accounting',
          name: RouteNames.Accounting,
          component: AccountingPage,
        },
        {
          path: 'transactions-history',
          name: RouteNames.TransactionsHistory,
          component: TransactionsHistoryPage,
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: RouteNames.NotFound,
      redirect: () => ({
        name: RouteNames.Dashboard,
      }),
    },
  ],
})

router.beforeEach((to, from) => {
  return routerHandel(to, from)
})
