import type { AxiosResponse } from 'axios'

type DownloadFileParams = {
  response: AxiosResponse<File>
  fileName: string
  extension?: string
}

export const downloadFile = ({
  response,
  fileName,
  extension,
}: DownloadFileParams) => {
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: response.headers['content-type'],
    })
  )
  const link = document.createElement('a')

  link.href = url
  link.setAttribute(
    'download',
    extension ? `${fileName}.${extension}` : fileName
  )

  document.body.appendChild(link)
  link.click()
  link.remove()
}
