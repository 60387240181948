<template>
  <FormControlBase
    v-slot="{ invalid }"
    :name="name"
    :class="fallthroughClass"
    :metaData="meta"
  >
    <!-- savedValue {{ savedValueArr }}
    value {{ value }} -->
    <div v-if="savedValueArr.length > 0" :class="$style.itemInner">
      <div
        v-for="(item, index) in savedValueArr"
        :key="index"
        :class="$style.itemWrap"
      >
        <div :class="$style.item">{{ item }}</div>
        <ButtonIcon
          :class="$style.closeButton"
          @click.prevent="toggleClearInput(index)"
        >
          <IconClose />
        </ButtonIcon>
      </div>
    </div>
    <div :class="$style.innerAdd">
      <Inputes
        :modelValue="savedValue"
        :type="type"
        :label="label"
        :placeholder="placeholder"
        :invalid="errorPhone"
        :disabled="disabled"
        v-bind="fallthroughAttrs"
        isIconClose
        :resetField="resetField"
        :shouldEmitEvent="true"
        @update:modelValue="handePhone"
        @keyup.enter.space.exact="handeEnter"
        @blur="handleBlur"
        @keydown.enter.space.prevent="keydown"
      />
      <div :class="$style.add" @click="handeEnter(savedValue, true)">
        {{ $t('filter.addButton') }}
      </div>
    </div>
    <div v-if="errorPhone" :class="$style.textError">{{ errorPhone }}</div>
  </FormControlBase>
</template>

<script lang="ts">
import {
  ButtonIcon,
  IconClose,
  Input as Inputes,
  Text as Texted,
} from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes, Ref } from 'vue'
import { defineComponent, ref } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formInputProps = {
  ...formControlProps,
  type: String,
  placeholder: String,
} as const

export type FormInputProps = ExtractPropTypes<typeof formInputProps>

const MAX_PHONE = 15
const MIN_PHONE = 7

export default defineComponent({
  components: { FormControlBase, Inputes, IconClose, ButtonIcon, Texted },
  inheritAttrs: false,
  props: formInputProps,
  setup(props, { attrs }) {
    const { value, handleBlur, handleChange, meta, resetField } =
      useField<string>(props.name, undefined, {
        // т.к. modelValue обязательный параметр и используется для вычисления withLabel
        initialValue: '',
      })

    const { handleChange: inputPhoneHandle } =
      useField<string>('inputPhoneSaved', undefined, {
        // т.к. modelValue обязательный параметр и используется для вычисления withLabel
        initialValue: '',
      })

    const errorPhone = ref()

    const keydown = () => {
      // console.log('keydown', evt);
    }

    const savedValue = ref()
    const savedValueArr: Ref<string[]> = ref([...value.value])
    const checkLetter = (letter: string) => {
      // console.log('letter', letter);
      const reg = /^\d+$/
      return reg.test(letter) || !letter
    }
    const checkLetters = (target: string) => {
      const data = target
        .split('')
        .map((letter: string) => (checkLetter(letter) ? letter : ''))

      const filtered = data.filter((item: string) => item)
      // console.log('filtered', filtered)
      return filtered.join('')
    }
    const saveLetter = (evt, letters: string) => {
      if (evt?.target) evt.target.value = letters
      savedValue.value = letters
    }
    const toggleClearInput = (index: number) => {
      // console.log('index', index)
      savedValueArr.value.splice(index, 1)
      handleChange(savedValueArr.value)
    }

    const handeEnter = (evt, isAdd = false) => {
      const target: string = isAdd ? evt : evt?.target?.value
      // console.log('evt', evt)
      if (target?.length > MAX_PHONE) {
        errorPhone.value = 'номер телефона должен быть не более 15 символов'
        return
      }
      if (target?.length < MIN_PHONE) {
        errorPhone.value = `номер телефона должен быть не менее ${MIN_PHONE} символов`
        return
      }
      if (target) {
        errorPhone.value = false
        savedValueArr.value.push(target)
        if (!isAdd) evt.preventDefault()
        resetField()
        saveLetter(evt, '')
        inputPhoneHandle(undefined)
        handleChange(savedValueArr.value)
      }
    }

    const timeOut = ref()

    const handePhone = (evt) => {
      const target: string = evt?.target?.value
      // console.log('target2', target)
      // console.log('target', target)
      clearTimeout(timeOut.value)
      if (target) {
        const letters = checkLetters(target)
        saveLetter(evt, letters)
        // console.log('letters', letters)
        // inputPhoneHandle(letters)
        if (letters.length < MIN_PHONE) {
          timeOut.value = setTimeout(() => {
            errorPhone.value = `номер телефона должен быть не менее ${MIN_PHONE} символов`
          }, 1000)
          inputPhoneHandle(letters)
        } else {
          inputPhoneHandle(letters)
          errorPhone.value = null
        }
      } else {
        saveLetter(evt, '')
        inputPhoneHandle(undefined)
        errorPhone.value = false
      }

      // if (firstLetter[0] !== '+' && firstLetter[0]) {
      //   saveLetter(evt, `${letters}`)
      // }
    }

    const { class: fallthroughClass, ...fallthroughAttrs } = attrs

    return {
      value,
      fallthroughClass,
      fallthroughAttrs,
      handleBlur,
      handleChange,
      meta,
      handePhone,
      savedValue,
      resetField,
      savedValueArr,
      handeEnter,
      keydown,
      toggleClearInput,
      errorPhone,
    }
  },
})
</script>

<style module>
.item {
  width: max-content;
  padding: 5px;

  border-radius: 4px;
}

.add {
  position: absolute;
  top: 50%;
  right: 43px;

  padding: 2px 5px;

  color: var(--color-text-white);
  font-size: 11px;

  background: var(--color-bg-button);
  border-radius: 5px;
  transform: translateY(-50%);
  cursor: pointer;
}

.innerAdd {
  position: relative;
}

.itemInner {
  position: relative;

  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.textError {
  color: var(--color-error);
  font-size: 12px;
}

.itemWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  margin-bottom: 4px;
  padding: var(--gap-8);

  color: var(--color-text-dark);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);

  background-color: var(--color-bg-white);
  border: 1px solid var(--color-stroke-blocks);
  border-radius: 20px;
}
</style>
