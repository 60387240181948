import { ref } from 'vue'

import type { LoginResult } from '@/api/__generated__/api.schema'

export type AuthTokens = Pick<LoginResult, 'accessToken' | 'refreshToken'>

const LOCAL_STORAGE_KEY = 'auth-tokens'

export const getStoredAuthTokens = (): AuthTokens => {
  const tokens = localStorage.getItem(LOCAL_STORAGE_KEY)

  return tokens && JSON.parse(tokens)
}

export const setAuthTokens = (tokens: AuthTokens) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(tokens))
  authTokens.value = tokens
}

export const removeAuthTokens = () => {
  authTokens.value = undefined
  localStorage.removeItem(LOCAL_STORAGE_KEY)
}

export const authTokens = ref<AuthTokens | undefined>(getStoredAuthTokens())
