import type { TableTypes } from '@smst/ui'

import { i18n } from '@/i18n'

import type { StatisticsTableItem } from './Statistics.types'

const { t } = i18n.global

export const staticColumns = (firstLevelOption: string, profile) => {
  const showSumCosts = profile.value?.accounting === 'money'
  const commonColumns: Array<TableTypes.Column<StatisticsTableItem>> = [
    {
      name: t(`statistics.groupBy.${firstLevelOption}`),
      prop: 'name',
      width: '30%',
    },
    {
      name: t('statistics.column.delivered'),
      prop: 'delivered',
    },
    {
      name: t('statistics.column.deliveredPercent'),
      prop: 'deliveredPercent',
    },
    {
      name: t('statistics.column.read'),
      prop: 'read',
    },
    {
      name: t('statistics.column.readPercent'),
      prop: 'readPercent',
    },
    {
      name: t('statistics.column.clicked'),
      prop: 'clicked',
    },
    {
      name: t('statistics.column.clickedPercent'),
      prop: 'clickedPercent',
    },
    {
      name: t('statistics.column.total'),
      prop: 'total',
    },
  ]

  return showSumCosts
    ? [
        ...commonColumns,
        {
          name: t('statistics.column.sumCosts', {
            currency: profile.value?.currencyInfo.currency,
          }),
          prop: 'sumCosts',
        },
      ]
    : commonColumns
}
