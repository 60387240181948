<template>
  <Drawer
    :closeText="locale.close"
    :modelValue="isOpen"
    @update:modelValue="emit('close')"
  >
    <template v-if="!isFetchingMember" #title>
      <Text tag="h2">{{ locale.title }}</Text>
    </template>

    <div :class="$style.root">
      <Loader v-if="isFetchingMember" size="m" :class="$style.loader" />
      <MemberEditForm
        v-else
        formId="member-edit"
        :initialValues="initialValues"
        @submit="editMember"
        @changeMeta="handleChangeMeta"
      />
    </div>

    <template v-if="!isFetchingMember" #buttons>
      <Button
        type="submit"
        form="member-edit"
        :disabled="isDisabled"
        :loading="isLoadingEditMember"
      >
        {{ locale.submit }}
      </Button>

      <Button view="link" @click="handleCancel">
        {{ locale.cancel }}
      </Button>
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import { Button, Drawer, Loader, Text, toaster } from '@smst/ui'
import { parseISO } from 'date-fns'
import { isDefined, isTruthy } from 'remeda'
import type { FormMeta } from 'vee-validate'
import type { ComputedRef } from 'vue'
import { computed, ref, toRef } from 'vue'
import { useQuery, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import { getErrorMessage } from '@/utils/errors'

import { MEMBERS_QUERY_KEY } from '../../GroupEdit.utils'

import type { FormValues } from './EditMember.utils'
import { GROUP_MEMBER_KEY, useMemberEdit } from './EditMember.utils'
import MemberEditForm from './MemberEditForm.vue'

const props = defineProps({
  isOpen: Boolean,
  phone: {
    type: Number,
    required: true,
  },
  groupId: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['close'])
const phone = toRef(props, 'phone')

const isDisabled = ref<boolean>(false)

const { data: dataMember, isFetching: isFetchingMember } = useQuery(
  [GROUP_MEMBER_KEY, phone],
  () => apiClient.group.member_GET_BY(props.groupId, phone.value),
  {
    enabled: computed(() => isTruthy(phone.value)),
    onError: (e) => {
      toaster.error(getErrorMessage(e))
      emit('close')
    },
  }
)

const queryClient = useQueryClient()

const initialValues = computed<FormValues | undefined>(() => {
  const data = dataMember.value?.data.groupMember

  if (!isDefined(data)) {
    return undefined
  }

  const values = {
    phone: data.phone.toString(),
    name: data.memberName ?? '',
    birthday: data.birthday ? parseISO(data.birthday) : undefined,
    gender: data.gender,
    comment: data.comment ?? '', // maybe null
  }

  return values
})

const handleCancel = () => {
  emit('close')
}

const {
  locale,
  editMember,
  isLoading: isLoadingEditMember,
} = useMemberEdit({
  groupId: toRef(props, 'groupId'),
  phone,
  onSuccess: () => {
    emit('close')
    void queryClient.invalidateQueries([MEMBERS_QUERY_KEY])
  },
})

const handleChangeMeta = (meta: ComputedRef<FormMeta<FormValues>>) => {
  isDisabled.value = !meta.value.dirty
}
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.loader,
.error {
  margin: auto;
}
</style>
