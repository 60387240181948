<template>
  <form @submit="onSubmit">
    <Grid>
      <Row>
        <Card :title="t('title')">
          <div :class="$style.content">
            <FormMultiselect
              name="groupIds"
              :options="getOptions"
              :placeholder="t('placeholder')"
            />
            <Button
              type="submit"
              view="secondary"
              fullWidth
              :disabled="isDisabled"
              :loading="isLoading"
            >
              {{ t('submit') }}
            </Button>
          </div>
        </Card>
      </Row>
    </Grid>
  </form>
</template>

<script setup lang="ts">
import { Button, toaster } from '@smst/ui'
import { isDefined } from 'remeda'
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import Card from '@/components/Card/Card.vue'
import FormMultiselect from '@/components/FormMultiselect/FormMultiselect.vue'
import { Grid, Row } from '@/components/Grid'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'

type Values = {
  groupIds: number[]
}

const t = useComponentI18n('group.deleteListForm')

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
})

const PAGINATION = { size: 20, page: 1 }

const getOptions = async (value: string) => {
  try {
    const { data } = await apiClient.groups.list_GET({
      ...PAGINATION,
      filter: value,
    })

    const options = data.list
      ? data.list
          .map((i) => ({ value: i.groupId, label: i.groupName }))
          .filter((i) => i.value !== Number(props.groupId))
      : []

    const hasMore = data.pageInfo
      ? data.pageInfo.totalItems > PAGINATION.size
      : false

    return {
      options,
      hasMore,
    }
  } catch (e) {
    toaster.error(getErrorMessage(e))

    return {
      options: [],
      hasMore: false,
    }
  }
}

const { handleSubmit, resetForm, meta } = useForm<Values>()

const isDisabled = computed(() => !meta.value.dirty)

const { mutate: deleteList, isLoading } = useMutation(
  (values: Values) =>
    apiClient.group.membersDeleteListFromList_POST(
      Number(props.groupId),
      values
    ),
  {
    onSuccess: (data) => {
      const count = data.data.affectedRows
      if (isDefined(count)) {
        toaster.success(t('success', { count }))
        resetForm()
      }
    },
    onError: (e) => {
      toaster.error(getErrorMessage(e))
    },
  }
)

const onSubmit = handleSubmit((values) => {
  deleteList(values)
})
</script>

<style module>
.content {
  display: flex;
  flex-direction: column;

  gap: var(--gap-20);
}
</style>
