<template>
  <div v-if="itemDataDrawer?.files.length">
    <div>{{ t('files') }}:</div>
    <br />
    <div v-for="(item, index) in itemDataDrawer?.files" :key="index">
      <div
        :class="$style.fileLink"
        @click="download(item.fileId, itemDataDrawer.id, item.name)"
      >
        <IconDownload :size="17" />
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconDownload } from '@smst/ui'

import { useComponentI18n } from '@/hooks/useComponentI18n'

import { initDownloadReport } from './utils/DownloadReport.utils'
import { itemDataDrawer } from './utils/TableReport.utils'

const getDownload = initDownloadReport()

const getExtFromFileName = (fileName: string) => {
  const name = fileName.split('.')
  const len = name.length - 1
  const ext = name[len]
  return ext
}

const download = (fileId: number, reportId: number, fileName: string): void => {
  getDownload({
    fileId: String(fileId),
    reportId,
    fileExt: getExtFromFileName(fileName),
  })
}

const t = useComponentI18n('ReportsOnRequest')
</script>

<style module>
.fileLink {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 6px;

  color: var(--color-bg-button-hover);
  font-weight: 500;

  cursor: pointer;
}

.fileLink:hover {
  color: var(--color-text-grey);
}
</style>
