<template>
  <div :class="$style.root">
    <SelectDraft
      v-if="draftFromFileOff"
      :isSameMessages="isSameMessages"
      :draft="draft"
      :channel="channel"
      :isPhoneSourceWithMessage="isPhoneSourceWithMessage"
      @update:draft="handleDraftUpdate"
    />
    <div :class="$style.wrap">
      <FormTextarea
        v-bind="pushLimit"
        :name="name"
        :label="label"
        :placeholder="placeholder"
        :transliteration="transliteration"
        :characterLimit="characterLimit"
        :badge="badge"
        :disabled="isPhoneSourceWithMessage || isDependentRoute"
      />

      <component
        :is="hint"
        key="hint"
        :class="$style.hint"
        :value="hintValue"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'
import type { Component, PropType } from 'vue'
import { computed, ref, watch, watchEffect } from 'vue'

import type { Draft } from '@/api/__generated__/api.schema'
import FormTextarea from '@/components/FormTextarea/FormTextarea.vue'
import type { Channel } from '@/pages/Drafts/Drafts.utils'

import { channelsLimits } from '../BatchForm/BatchForm.utils'
import SelectDraft from '../SelectDraft/SelectDraft.vue'

const props = defineProps({
  isSameMessages: Boolean,
  isDependentRoute: Boolean,
  commonDraft: Object as PropType<Draft>,
  hint: {
    type: Object as PropType<Component>,
  },
  hintValue: {
    type: String,
  },
  channel: {
    type: String as PropType<Channel>,
    required: true,
  },
  draftFromFileOff: { type: Boolean, default: true },
  name: {
    type: String,
    required: true,
  },
  isPhoneSourceWithMessage: Boolean,
  label: String,
  placeholder: String,
  transliteration: Boolean,
  characterLimit: Number,
  badge: String,
})

const emit = defineEmits(['commonDraftReset'])

const draft = ref<Draft | undefined>(props.commonDraft)

const handleDraftUpdate = (selectedDraft: Draft | undefined) => {
  draft.value = selectedDraft
}

const pushLimit = computed(() => {
  const channel = props.channel
  if (channel === 'all') return { maxlength: channelsLimits.push }
  return { maxlength: channelsLimits[channel] }
})

watchEffect(() => {
  draft.value = props.commonDraft
})

const { handleChange, value: formValue } = useField<string>(
  props.name,
  undefined,
  {
    initialValue: '',
  }
)

watchEffect(() => {
  if (draft.value) {
    console.log('draft.value', draft.value);
    handleChange(draft.value.text)
  }
})

watchEffect(() => {
  if (props.isPhoneSourceWithMessage) {
    draft.value = undefined
    handleChange('')
  }
})

// для сброса состояния при resetForm
watch(
  () => formValue.value,
  (newValue) => {
    if (!newValue) {
      draft.value = undefined
      emit('commonDraftReset')
    }
  }
)
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  gap: var(--gap-8);
}

.wrap {
  position: relative;
}

.hint {
  position: absolute;
  top: 0;
  right: 0;

  transform: translateX(calc(100% + var(--gap-12) + var(--gap-card)));
}
</style>
