import type { SelectTypes } from '@smst/ui'

import { i18n } from '@/i18n'

const { t } = i18n.global

// Депозит
// Возврат
// Конверсия внутри аккаунта
// Перевод с/на другой аккаунт
// Вычет
// Изменение тарифа
// Исправление ошибки начисления
// Возврат аванса
// Взаимозачет
// Абон. Плата
// Тестирование
// Окончание тестирования

enum TransactionType {
  Deposit = 'deposit',
  Refund = 'refund',
  Conversion = 'conversion',
  Transfer = 'transfer',
  Fine = 'fine',
  TariffChange = 'tariff change',
  Correction = 'correction',
  RefundAdvance = 'refund_advance',
  Netting = 'netting',
  AbonPlata = 'abon_plata',
  Testing = 'testing',
  TestingOver = 'testing over',
}

export const transactionTypes = Object.values(TransactionType)

export const transactionTypeOptions: SelectTypes.Option[] =
  transactionTypes.map((type) => ({
    value: type,
    label: t(`transactionsHistory.transactionTypes.${type}`),
  }))
