import type { AxiosResponse } from 'axios'

export const printFile = (response: AxiosResponse<File>) => {
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: response.headers['content-type'],
    })
  )

  const id = 'printFile'

  // Используем iframe повторно, не можем удалять до печати, иначе будет пустой лист
  const iframe =
    (document.getElementById(id) as HTMLIFrameElement) ??
    document.createElement('iframe')

  iframe.style.display = 'none'
  iframe.src = url
  iframe.id = id

  document.body.appendChild(iframe)

  setTimeout(function () {
    iframe.contentWindow?.print()
  }, 500)
}
