import type { AxiosRequestHeaders, AxiosResponse } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

// import { checkStageFront } from '@/utils/getDomain'
// eslint-disable-next-line import/no-cycle
import { globalTime } from '@/utils/localTimeFromServer/globalLocalTime'

import type { ApiResult } from './__generated__/api.schema'
import { Api, HttpClient } from './__generated__/api.schema'
import type { AuthTokens } from './authTokens'
import {
  getStoredAuthTokens,
  removeAuthTokens,
  setAuthTokens,
} from './authTokens'

const backendUrl = import.meta.env.DEV
  ? 'http://172.18.0.1:8080/dev'
  : 'https://api.smstraffic.ru/dev'

// const apiUrl = checkStageFront() ? 'http://10.254.13.152:8080' : backendUrl

const httpClient = new HttpClient({
  securityWorker: undefined,
  secure: undefined,
  format: undefined,
  baseURL: backendUrl,
})

export class ApiError extends Error {
  code: ApiResult['code']
  constructor(data: ApiResult) {
    super(data.description)
    this.code = data.code
  }
}

// Обработка ошибок, приходящих в 200-ответах
httpClient.instance.interceptors.response.use(
  (response: AxiosResponse<ApiResult>) => {
    if (response.data.code) {
      throw new ApiError(response.data)
    }

    return response
  }
)
// Обработка ошибок, приходящих в 200-ответах
httpClient.instance.interceptors.response.use(
  (response: AxiosResponse<ApiResult>) => {
    if (response?.data?.timestamp) {
      // console.log('response.data', response?.data?.timestamp)
      globalTime.setTimeStamp(response?.data?.timestamp)
    }

    return response
  }
)
// Заголовок авторизации
httpClient.instance.interceptors.request.use((request) => {
  const tokens = getStoredAuthTokens()
  // Избавиться от skipAuth, когда поправим проставление флага secure в https://jira.csssr.io/browse/SMST-110
  const skipAuth =
    /^\/(?:auth\/|restore-password\/|registration\/|token\/renew$)/.test(
      request.url ?? ''
    )

  if (request.secure && tokens && !skipAuth) {
    request.headers = {
      ...request.headers,
      ...getAuthHeaders(tokens),
    }
  }

  return request
})

// Обновление токена
createAuthRefreshInterceptor(
  httpClient.instance,
  async () => {
    try {
      const tokens = getStoredAuthTokens()
      if (!tokens) return Promise.reject(new Error('token is not'))

      const { data } = await apiClient.token.renew_POST(tokens, {
        skipAuthRefresh: true,
      })

      setAuthTokens({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
    } catch (error) {
      removeAuthTokens()
      return Promise.reject(error)
    }

    return Promise.resolve()
  },
  {
    pauseInstanceWhileRefreshing: true, // default: false
  }
)

export const getAuthHeaders = (tokens: AuthTokens): AxiosRequestHeaders => ({
  Authorization: `Bearer ${tokens.accessToken}`,
})

export const apiClient = new Api(httpClient)
