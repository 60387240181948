<template>
  <FormControlBase
    v-slot="{ invalid }"
    :name="name"
    :class="fallthroughClass"
    :metaData="meta"
  >
    <Input
      :modelValue="value"
      :type="type"
      :label="label"
      :placeholder="placeholder"
      :invalid="invalid"
      :disabled="disabled"
      v-bind="fallthroughAttrs"
      @input="
        (evt) => handleChange((evt.target as HTMLInputElement).value.trim())
      "
      @blur="handleBlur"
    />
  </FormControlBase>
</template>

<script lang="ts">
import { Input } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes } from 'vue'
import { defineComponent } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formInputProps = {
  ...formControlProps,
  type: String,
  placeholder: String,
} as const

export type FormInputProps = ExtractPropTypes<typeof formInputProps>

export default defineComponent({
  components: { FormControlBase, Input },
  inheritAttrs: false,
  props: formInputProps,
  setup(props, { attrs }) {
    const { value, handleBlur, handleChange, meta } = useField<string>(
      props.name,
      undefined,
      {
        // т.к. modelValue обязательный параметр и используется для вычисления withLabel
        initialValue: '',
      }
    )

    const { class: fallthroughClass, ...fallthroughAttrs } = attrs

    return {
      value,
      fallthroughClass,
      fallthroughAttrs,
      handleBlur,
      handleChange,
      meta,
    }
  },
})
</script>
