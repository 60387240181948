import { i18n } from '@/i18n'
import yup from '@/utils/yup'

const { t } = i18n.global

export type TwoFactorType = string | number | null
export type TwoFactor = {
  enabled: boolean
  phone: number | null
}

export const twoFactorAuthPhone = yup
  .number()
  .typeError(t('validation.phoneNumber'))
  .min(100000, t('validation.phoneNumber'))
  .moreThan(100000, t('validation.phoneNumber'))
  .nullable()
  .transform((_, val) => (val !== '' ? Number(val) : null))

export const handleTwoFactor = (twoFactorAuth: TwoFactor | undefined) => {
  return twoFactorAuth?.phone && twoFactorAuth?.enabled
    ? String(twoFactorAuth?.phone)
    : ''
}
