/* eslint-disable unicorn/better-regex */
export enum PassStrengthPolitics {
  Default = 'default',
  Weak = 'weak',
  Complex = 'complex',
}
export const passStrengthPolitics = Object.values(PassStrengthPolitics)

export const passwordOptions = {
  [PassStrengthPolitics.Default]:
    /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*()_+]{8,25}$/,
  [PassStrengthPolitics.Weak]: /^[\w!@#$%^&*()+]{8,25}$/,
  [PassStrengthPolitics.Complex]:
    /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,25}$/,
  minLength: 8,
  maxLength: 25,
}

export const getRegexPolitic = (politic: string | undefined) => {
  return politic ? passwordOptions[politic] : ''
}
