<template>
  <Filters
    :isDrawerOpen="isDrawerOpen"
    :controls="formControls"
    :activeFilters="activeFilters"
    :class="$style.filters"
    @update:isDrawerOpen="toggleDrawer"
    @submit.prevent="handleChangeFilters"
    @clear="handleClearFiltersModel"
  >
    <template #extra-button>
      <ReportGeneration :filtersValues="values" :reportFields="reportFields" />
    </template>
  </Filters>

  <div v-if="showEmptyState" :class="$style.container">
    <Loader v-if="isLoadingMessagesLog" size="m" />

    <ApiErrorMessage v-else-if="errorMessagesLog" :error="errorMessagesLog" />

    <NoResultsFound
      v-else
      :withAdvice="false"
      :variant="isEmptyFilters ? MessageType.noData : MessageType.noResult"
    />
  </div>

  <template v-else>
    <LoaderWrapper :loading="isFetchingMessagesLog">
      <MessagesLogTable
        v-model:modelSort="sortModel"
        :data="tableData"
        :columns="tableColumns"
      />
    </LoaderWrapper>
    <PaginationPanel
      v-model="paginationModel"
      :totalItems="totalItems"
      totalHide
    >
      <div :class="$style.inner">
        <FilterFields
          :modelValue="tableFields"
          :getDefaultValue="getReportFields"
          @submit="handleUpdateFilterFields"
        />
        <div :class="$style.result">
          <div v-if="paginationTotalCostShow">
            {{
              t('spent', {
                totalCosts: totalCosts.toFixed(2),
                currency: currency,
              })
            }}
          </div>
          <div>
            {{
              t('totalMessages', {
                count: totalItems,
              })
            }}
          </div>
        </div>
      </div>
    </PaginationPanel>
  </template>
</template>

<script setup lang="ts">
import { Loader, LoaderWrapper } from '@smst/ui'
import {
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  ObjectParam,
  StringParam,
  withDefault,
} from 'serialize-query-params'
import type { Ref } from 'vue'
import { computed } from 'vue'

import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import type { FieldFilterItem } from '@/components/FilterFields/FilterFields.types'
import FilterFields from '@/components/FilterFields/FilterFields.vue'
import { MessageType } from '@/components/NoResultsFound/NoResultsFound.types'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import PaginationPanel from '@/components/PaginationPanel/PaginationPanel.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { createSortQueryConfig, useTableRequest } from '@/hooks/useTableRequest'
import { formatDateToRequest } from '@/utils/formatDateToRequest'
import { hasValues } from '@/utils/object'

import Filters from './components/Filters/Filters.vue'
import MessagesLogTable from './components/MessagesLogTable/MessagesLogTable.vue'
import ReportGeneration from './components/ReportGeneration/ReportGeneration.vue'
import { useGetFiltersState } from './hooks/useGetFiltersState/useGetFiltersState'
import type { FiltersModel } from './hooks/useGetFiltersState/useGetFiltersState.types'
import { useGetMessagesLogTable } from './hooks/useGetMessagesLogTable'
import {
  getCheckedFields,
  getFieldsStateFromQuery,
  getReportFields,
  getTableColumns,
  transformFieldsStateToQueryParam,
} from './MessagesLog.utils'

const t = useComponentI18n('messagesLog')

const { profile } = useProfile()
const { request, paginationModel, filtersModel, sortModel } = useTableRequest({
  sortConfig: createSortQueryConfig(['submissionDate']),
  filtersConfig: {
    phones: withDefault(DelimitedArrayParam, undefined),
    from: withDefault(StringParam, undefined),
    to: withDefault(StringParam, undefined),
    originator: withDefault(StringParam, undefined),
    type: withDefault(StringParam, undefined),
    status: withDefault(StringParam, undefined),
    channel: withDefault(StringParam, undefined),
    departmentIds: withDefault(DelimitedNumericArrayParam, undefined),
    batchIds: withDefault(DelimitedArrayParam, undefined),
    fields: withDefault(ObjectParam, undefined),
  },
})

const currency = computed(() => {
  return profile.value?.currencyInfo.currency
})

const normalizeRequestQuery = computed(() => {
  const { fields, from, to, ...rest } = request.value

  return {
    ...rest,
    from: from
      ? formatDateToRequest(from, {
          tz: profile.value?.timezone.name,
          withTime: true,
        })
      : undefined,
    to: to
      ? formatDateToRequest(to, {
          tz: profile.value?.timezone.name,
          withTime: true,
        })
      : undefined,
    fields: getCheckedFields(fields),
  }
})

const isEmptyFilters = computed(() => !hasValues(filtersModel.value))

const {
  isDrawerOpen,
  toggleDrawer,
  values,
  inputPhoneSaved,
  setFieldValue,
  formControls,
  activeFilters,
  handleChangeFiltersModel,
  handleClearFiltersModel,
} = useGetFiltersState(filtersModel as unknown as Ref<FiltersModel>)

const {
  showEmptyState,
  tableData,
  totalItems,
  totalCosts,
  errorMessagesLog,
  isLoadingMessagesLog,
  isFetchingMessagesLog,
} = useGetMessagesLogTable(normalizeRequestQuery, isEmptyFilters)

const fieldsModel = computed(() =>
  getFieldsStateFromQuery(filtersModel.value.fields)
)

const handleChangeFilters = async (evt: Event) => {
  // console.log('setFieldValue', setFieldValue)
  // console.log('inputPhoneSaved', inputPhoneSaved.value)
  if (inputPhoneSaved.value && Number(inputPhoneSaved?.value?.length > 7)) {
    setFieldValue('phones', [...values?.phones, Number(inputPhoneSaved.value)])
    await handleChangeFiltersModel(evt)
    setFieldValue('inputPhoneSaved', undefined)
    return
  }
  if (inputPhoneSaved.value === undefined) {
    await handleChangeFiltersModel(evt)
  }
}

const paginationTotalCostShow = computed(() => {
  return profile.value?.accounting === 'money'
})
const tableFields = computed(() => {
  let fields = getReportFields({
    selectedFields: fieldsModel.value,
    type: 'table',
  })

  if (!profile.value?.smartDelivery) {
    fields = fields.filter((field) => field.value !== 'channel')
  }

  if (!profile.value?.showTrafficTypeIn?.messages) {
    fields = fields.filter((field) => field.value !== 'trafficType')
  }

  return fields
})

const reportFields = computed(() => {
  let fields = getReportFields({
    selectedFields: fieldsModel.value,
    type: 'file',
  })

  if (!profile.value?.smartDelivery) {
    fields = fields.filter((field) => field.value !== 'channel')
  }

  if (!profile.value?.showTrafficTypeIn?.messages) {
    fields = fields.filter((field) => field.value !== 'trafficType')
  }

  return fields
})

const handleUpdateFilterFields = (filters: FieldFilterItem[]) => {
  filtersModel.value = {
    ...filtersModel.value,
    fields: transformFieldsStateToQueryParam(filters),
  }
}

const tableColumns = computed(() =>
  getTableColumns({
    selectedFields: getCheckedFields(filtersModel.value.fields),
    sortableFields: ['submissionDate'],
  })
)
</script>

<style module>
.container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.filters {
  margin-top: var(--gap-12);
  margin-bottom: var(--gap-12);
}

.inner {
  display: flex;
  width: 480px;
}

.result {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding-right: 12px;

  color: var(--color-text-grey);
  font-weight: 400;
}

.panel {
  width: 100%;
}
</style>
