import type { SelectTypes } from '@smst/ui'

import type { Operator } from '@/api/__generated__/api.schema'
import { OriginatorStatus } from '@/api/__generated__/api.schema'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import { i18n } from '@/i18n'
import { getOperatorName } from '@/utils/getOperatorName'

import { OriginatorStatuses } from './OriginatorStatuses'

const { t } = i18n.global

export const originatorsStatuses = Object.values(OriginatorStatus)

const getStatusOptions = (): SelectTypes.Option[] => {
  return OriginatorStatuses()
}

const getOperatorOptions = (operators?: Operator[]) => {
  if (!operators) return []

  return operators.map((operator) => ({
    value: operator.operatorId,
    label: getOperatorName(operator),
  }))
}

type GetFilterControlOptions = {
  operators?: Operator[]
}

export const getFilterControl = ({
  operators,
}: GetFilterControlOptions): ControlType[] => [
  {
    control: 'input',
    name: 'originator',
    label: t('originators.filters.originator'),
  },
  {
    control: 'select',
    name: 'operatorId',
    label: t('originators.filters.operatorId'),
    options: getOperatorOptions(operators),
    withOptionAll: true,
  },
  {
    control: 'select',
    name: 'status',
    label: t('originators.filters.status'),
    options: getStatusOptions(),
    withOptionAll: true,
  },
]
