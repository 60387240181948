import { i18n } from '@/i18n'

const SINGLE_SMS_LENGTH = 160
const SINGLE_SMS_CYRILLIC_LENGTH = 70

const SMS_LENGTH = 153
const SMS_CYRILLIC_LENGTH = 67

export const calculateSms = (string: string, isCyrillic: boolean) => {
  let smsLength = SINGLE_SMS_LENGTH

  if (isCyrillic) {
    if (string.length > SINGLE_SMS_CYRILLIC_LENGTH) {
      smsLength = SMS_CYRILLIC_LENGTH
    } else {
      smsLength = SINGLE_SMS_CYRILLIC_LENGTH
    }
  } else {
    if (string.length > SINGLE_SMS_LENGTH) {
      smsLength = SMS_LENGTH
    }
  }

  const sms = Math.ceil(string.length / smsLength)

  return {
    sms,
    characterLimit: smsLength * sms,
  }
}

const { t } = i18n.global

export const getSmsFormData = (string: string, isCyrillic: boolean) => {
  const { sms, characterLimit } = calculateSms(string, isCyrillic)

  return {
    characterLimit,
    badge: sms > 1 ? t('form.textarea.sms', { count: sms }) : undefined,
  }
}
