import { i18n } from '@/i18n'
import { passwordOptions } from '@/utils/validation'
import yup from '@/utils/yup'

const { t } = i18n.global

export type ConfirmationPassword = {
  password: string
  passwordRepeated: string
}

export const confirmationPasswordSchema: yup.SchemaOf<ConfirmationPassword> =
  yup.object({
    password: yup
      .string()
      .matches(passwordOptions.requiredSymbols, t('validation.password'))
      .min(
        passwordOptions.minLength,
        t('validation.string.min', { number: passwordOptions.minLength })
      )
      .max(
        passwordOptions.maxLength,
        t('validation.string.max', { number: passwordOptions.maxLength })
      )
      .requiredField('accountForm.password.label'),
    passwordRepeated: yup
      .string()
      .oneOf([yup.ref('password'), null], t('validation.passwordConfirmation'))
      .requiredField('accountForm.passwordConfirmation.label'),
  })
