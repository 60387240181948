<template>
  <form :id="formId" @submit="onSubmit">
    <FormControls :controls="formControls" />
  </form>
</template>

<script lang="ts" setup>
import type { SelectTypes } from '@smst/ui'
import { useForm } from 'vee-validate'
import type { PropType } from 'vue'

import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormControls from '@/components/FormControls/FormControls.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

import { createOriginatorsFormSchema } from './CreateOriginatorsForm.schema'
import type { CreateOriginatorsForm } from './CreateOriginatorsForm.types'

const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  operatorOptions: {
    type: Array as PropType<SelectTypes.Option[]>,
    required: true,
  },
})

const emits = defineEmits(['submit'])

const t = useComponentI18n('originators.createOriginator')

const formControls: ControlType[] = [
  {
    control: 'input',
    name: 'originator',
    label: t('controls.originator'),
  },
  {
    control: 'input',
    name: 'companyName',
    label: t('controls.companyName'),
  },
  {
    control: 'input',
    name: 'inn',
    label: t('controls.inn'),
  },
  {
    control: 'select',
    name: 'operatorId',
    label: t('controls.operatorId'),
    placeholder: t('controls.operatorPlaceholder'),
    options: props.operatorOptions,
  },
]

const { handleSubmit } = useForm<CreateOriginatorsForm>({
  validationSchema: createOriginatorsFormSchema,
})

const onSubmit = handleSubmit((value) => {
  emits('submit', value)
})
</script>
