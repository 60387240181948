<template>
  <form :id="formId" @submit="handleSubmit">
    <FormControls :controls="formControls" />
  </form>
</template>

<script setup lang="ts">
import { isTruthy } from 'remeda'
import { useField, useForm } from 'vee-validate'
import { computed } from 'vue'
import type { PropType } from 'vue'

import type { Draft } from '@/api/__generated__/api.schema'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormControls from '@/components/FormControls/FormControls.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { getSmsFormData } from '@/utils/sms'

import type { Channel, FormValues } from './DraftForm.schema'
import { draftFormSchema } from './DraftForm.schema'

const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  initialValues: Object as PropType<Draft>,
})

const emit = defineEmits(['submit'])

const t = useComponentI18n('draft.form')
const tChannel = useComponentI18n('channels')

const { profile } = useProfile()

const initialFormValues = computed<FormValues | undefined>(() => {
  if (props.initialValues) {
    const channel = !profile.value?.smartDelivery
      ? 'sms'
      : props.initialValues.channel

    return {
      ...props.initialValues,
      channel,
    }
  }

  // Доработать после https://jira.csssr.io/browse/SMST-270
  if (!profile.value?.smartDelivery) {
    return {
      name: '',
      channel: 'sms',
      text: '',
    }
  }

  return undefined
})

const { handleSubmit: handleSubmitForm } = useForm<FormValues>({
  initialValues: initialFormValues.value,
  validationSchema: draftFormSchema,
})

const { value: textValue } = useField<string>('text', undefined, {
  initialValue: '',
})
const { value: channelValue } = useField<Channel | undefined>('channel')

const isCyrillic = computed(() => {
  const hasCyrillic = /[а-яА-Я]+/.exec(textValue.value)
  return isTruthy(hasCyrillic)
})

const isSmsChannel = computed(() => channelValue.value === 'sms')

const textAdditional = computed(() =>
  getSmsFormData(textValue.value, isCyrillic.value)
)

const formControls = computed<ControlType[]>(() => {
  const fields: ControlType[] = [
    {
      control: 'input',
      name: 'name',
      type: 'text',
      label: t('name'),
    },
    {
      control: 'select',
      name: 'channel',
      placeholder: t('channel'),
      label: t('channel'),
      options: [
        { label: tChannel('sms'), value: 'sms' },
        { label: tChannel('viber'), value: 'viber' },
        { label: tChannel('whatsapp'), value: 'whatsapp' },
        { label: tChannel('vk'), value: 'vk' },
      ],
    },
    {
      control: 'textarea',
      name: 'text',
      characterLimit: isSmsChannel.value
        ? textAdditional.value.characterLimit
        : undefined,
      badge: isSmsChannel.value ? textAdditional.value.badge : undefined,
      transliteration: true,
      label: t('text'),
      trim: true,
    },
  ]

  // Доработать после https://jira.csssr.io/browse/SMST-270
  if (!profile.value?.smartDelivery) {
    fields.splice(1, 1)
  }

  return fields
})

const handleSubmit = handleSubmitForm((values) => {
  emit('submit', values)
})
</script>
