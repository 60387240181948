import { unref } from 'vue'
import type { MaybeRef } from 'vue-query/lib/vue/types'

import { PhonesTypeFile } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

const { t } = i18n.global
const accept = {
  full: '.txt, .csv',
  one: '.txt, .csv',
}

export const useFileInputProps = (type: MaybeRef<PhonesTypeFile>) => {
  if (unref(type) === PhonesTypeFile.LineSeparatedFile) {
    return createData(PhonesTypeFile.LineSeparatedFile, accept.full)
  } else if (unref(type) === PhonesTypeFile.LineSeparatedWithMessagesFile) {
    return createData(PhonesTypeFile.LineSeparatedWithMessagesFile, accept.one)
  } else if (unref(type) === PhonesTypeFile.LineSeparatedWithParamsFile) {
    return createData(PhonesTypeFile.LineSeparatedWithParamsFile, accept.full)
  }
  return createData('lineSeparatedFile', accept.one)
}

const createData = (type: string, fileType: string) => {
  return {
    locale: {
      title: t('form.file.title'),
      deleteFile: t('form.file.delete'),
      errors: {
        WrongFormat: t('form.file.wrongFormat'),
        FileTooLarge: t('form.file.errors.fileTooLarge'),
      },
    },
    maxSize: undefined,
    accept: fileType,
    descriptions: [
      t('form.file.accept', { types: fileType }),
      t(`batchCreate.form.phonesSource.blocks.${type}.file.description`),
    ],
  }
}
