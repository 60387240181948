import { ref } from 'vue'

import { apiClient, getAuthHeaders } from '@/api'
import { getStoredAuthTokens, removeAuthTokens } from '@/api/authTokens'

export const isDrawerOpen = ref(false)
export const isOpenLogoutConfirmModal = ref(false)

export const onLogout = async () => {
  // Если все не закрыть то останется "overflow: hidden" и не пропадет при повторной авторизации
  isDrawerOpen.value = false
  isOpenLogoutConfirmModal.value = false

  // Чтобы не задерживать пользователя, сразу выкидываем его на авторизацию, а только потом отзываем токены
  const tokensToRevoke = getStoredAuthTokens()

  if (tokensToRevoke) {
    void (await apiClient.token.revoke_POST(tokensToRevoke, {
      // Передаём заголовки явно, т.к. токены на этот момент уже удалены и автоматически в запрос не подставятся
      headers: getAuthHeaders(tokensToRevoke),
    }))
  }

  removeAuthTokens()
}
