<template>
  <FormControlBase
    v-slot="{ invalid }"
    :name="name"
    :class="fallthroughClass"
    :metaData="meta"
  >
    <Input
      :modelValue="savedValue"
      :type="type"
      :label="label"
      :placeholder="placeholder"
      :invalid="invalid"
      :disabled="disabled"
      v-bind="fallthroughAttrs"
      isIconClose
      :resetField="resetField"
      :shouldEmitEvent="true"
      @update:modelValue="handePhone"
      @blur="handleBlur"
    />
  </FormControlBase>
</template>

<script lang="ts">
import { Input } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes } from 'vue'
import { defineComponent, ref } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formInputProps = {
  ...formControlProps,
  type: String,
  placeholder: String,
} as const

export type FormInputProps = ExtractPropTypes<typeof formInputProps>

export default defineComponent({
  components: { FormControlBase, Input },
  inheritAttrs: false,
  props: formInputProps,
  setup(props, { attrs }) {
    const { value, handleBlur, handleChange, meta, resetField } =
      useField<string>(props.name, undefined, {
        // т.к. modelValue обязательный параметр и используется для вычисления withLabel
        initialValue: '',
      })

    const savedValue = ref('')
    const checkLetter = (letter: string) => {
      const reg = /^\d+$/
      return (
        reg.test(letter) ||
        letter === '+' ||
        letter === ' ' ||
        letter === '-' ||
        letter === '(' ||
        letter === ')' ||
        !letter
      )
    }
    const checkLetters = (target: string) => {
      const data = target
        .split('')
        .map((letter: string) => (checkLetter(letter) ? letter : ''))

      const filtered = data.filter((item: string) => item)
      return filtered.join('')
    }
    const saveLetter = (evt, letters: string) => {
      evt.target.value = letters
      savedValue.value = letters
      handleChange(savedValue.value)
    }

    const handePhone = (evt) => {
      const target = evt.target.value
      const letters = checkLetters(target)
      const firstLetter = letters.split('')
      saveLetter(evt, letters)
      if (firstLetter[0] !== '+' && firstLetter[0]) {
        saveLetter(evt, `+${letters}`)
      }
    }

    const { class: fallthroughClass, ...fallthroughAttrs } = attrs

    return {
      value,
      fallthroughClass,
      fallthroughAttrs,
      handleBlur,
      handleChange,
      meta,
      handePhone,
      savedValue,
      resetField,
    }
  },
})
</script>
