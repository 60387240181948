<template>
  <Card>
    <div :class="$style.preliminaryEvaluation">
      <IconAnalysis v-if="mode === 'init'" />
      <IconDeferred v-if="mode === 'loading'" />
      <IconCheck v-if="mode === 'success'" />

      <div :class="$style.content">
        <Text tag="p" weight="semibold">
          <template v-if="isSuccess">
            {{ estimatedCost }} {{ currencyCredits }}
          </template>

          <template v-else>
            {{ t(`${mode}.title`) }}
          </template>
        </Text>

        <Text tag="p" color="secondary" size="s">
          {{ t(`${mode}.subTitle`) }}
        </Text>
      </div>

      <Button
        view="link"
        :class="$style.button"
        :disabled="disabled"
        @click="handleEvaluation"
      >
        {{ t(`${mode}.button`) }}
      </Button>
    </div>
  </Card>
</template>

<script lang="ts" setup>
import {
  Button,
  IconAnalysis,
  IconCheck,
  IconDeferred,
  Text,
  toaster,
} from '@smst/ui'
import { computed, ref } from 'vue'
import { useMutation } from 'vue-query'
import { useRoute } from 'vue-router'

import type { SimpleSendSmartForm } from '@/api/__generated__/api.schema'
import Card from '@/components/Card/Card.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { getErrorMessage } from '@/utils/errors'

import { getQuery } from '../../BatchCreate.utils'
import type { SendFormCommonType } from '../../BatchCreate.utils'

const { profile } = useProfile()
const route = useRoute()

const t = useComponentI18n('batchCreate.preliminaryEvaluation')

const accounting = computed(() => profile.value?.accounting)

const currency = computed(() => profile.value?.currencyInfo.currency)

const currencyCredits = computed(() => {
  if (accounting.value === 'credits')
    return t('credits', Number(estimatedCost.value))
  return currency
})

const props = defineProps({
  disabled: Boolean,

  values: {
    type: Object,
  },
})

const {
  isLoading,
  isSuccess,
  mutate: evaluationBatch,
  reset,
} = useMutation(
  (values: SendFormCommonType) => {
    return getQuery(values, route)
  },
  {
    onSuccess: ({ data: { data } }) => {
      if (data && 'estimatedCost' in data) {
        estimatedCost.value = data.estimatedCost
      }
    },

    onError: (err) => {
      toaster.error(getErrorMessage(err))
    },
  }
)

const estimatedCost = ref()

const handleEvaluation = computed(() =>
  isLoading.value
    ? reset.value
    : () => {
        evaluationBatch({
          ...props.values,
          calculateCost: true,
        } as SimpleSendSmartForm)
      }
)

type Mode = 'init' | 'loading' | 'success'

const getMode = (): Mode => {
  if (isSuccess.value) return 'success'
  if (isLoading.value) return 'loading'

  return 'init'
}

const mode = computed(() => getMode())
</script>

<style module>
.preliminaryEvaluation {
  display: flex;
  align-items: center;
}

.content {
  margin-left: var(--gap-16);
}

.button {
  margin-left: auto;
}
</style>
