<template>
  <div :class="$style.root">
    <component
      :is="variant.icon"
      :size="iconSizes[variant.iconSize]"
      :class="$style.icon"
    />

    <Text size="2xl" color="secondary" weight="semibold" :class="$style.title">
      <span v-html="variant.title" />
    </Text>

    <Text
      v-if="withAdvice && variant?.note"
      color="secondary"
      weight="medium"
      :class="$style.note"
    >
      <span v-html="variant.note" />
    </Text>
  </div>
</template>

<script lang="ts" setup>
import { IconFind, IconNoData, Text } from '@smst/ui'
import type { DefineComponent, PropType } from 'vue'
import { computed } from 'vue'

import { useComponentI18n } from '@/hooks/useComponentI18n'

import type { MessageType } from './NoResultsFound.types'

type IconSize = 'm' | 'l'

type Variant = {
  icon: DefineComponent
  iconSize: IconSize
  title: string
  note?: string
}

const props = defineProps({
  variant: {
    type: String as PropType<MessageType>,
    default: 'noResult',
  },
  customVariant: Object as PropType<Variant>,
  withAdvice: {
    type: Boolean,
    default: true,
  },
})

const t = useComponentI18n('noResult')

const iconSizes: Record<IconSize, number> = {
  m: 28,
  l: 64,
}

const variants: Record<MessageType, Variant> = {
  noResult: {
    icon: IconFind,
    iconSize: 'm',
    title: t('noResultsFound.title'),
    note: t('noResultsFound.note'),
  },
  noData: {
    icon: IconNoData,
    iconSize: 'l',
    title: t('noData.title'),
  },
}

const variant = computed(() => props.customVariant ?? variants[props.variant])
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: var(--gap-12);
}

.icon {
  color: var(--color-text-grey);
}

.note,
.title {
  text-align: center;
}
</style>
