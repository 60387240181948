<template>
  <div v-if="showButton">
    <Button
      :class="$style.root"
      view="link"
      fullWidth
      @click="handleDrawerOpen"
    >
      {{ $t('extra.goToOldFront') }}
    </Button>
  </div>
</template>

<script setup lang="ts">
import { Button } from '@smst/ui'
import { useRouter } from 'vue-router'

import {
  checkFrontUzDomain,
  checkRitoricaDomain,
  checkStMediaDomain,
} from '@/utils/getDomain'

const showButton = !(
  checkRitoricaDomain() ||
  checkStMediaDomain() ||
  checkFrontUzDomain()
)

const router = useRouter()

const handleDrawerOpen = () => {
  void router.push('goto-old-front')
}
</script>

<style module>
.root {
  padding: var(--gap-8) var(--gap-20);

  color: rgb(44 44 44);

  background-color: var(--color-bg-white);
  border: 1px solid var(--color-stroke-blocks);
  border-radius: 20px;
}
</style>
