import type { SelectTypes } from '@smst/ui'
import { equals, isNil } from 'remeda'

import { getFilterLabel } from '@/components/FiltersPanel/FiltersPanel.utils'
import { i18n } from '@/i18n'
import { initLocale } from '@/utils/reactiveLocale'

import type { StatisticFilters } from './Filters.schema'
import type {
  ChannelOption,
  GroupItem,
  MessageTypeOption,
} from './Filters.types'

export const getOptionsBy = <T>(
  data: T[] | undefined,
  {
    labelKey,
    valueKey,
  }: {
    labelKey: keyof T
    valueKey: keyof T
  }
): SelectTypes.Option[] => {
  if (!data) {
    return []
  }

  const options = data.map((item) => {
    return {
      label: String(item[labelKey]),
      value: item[valueKey] as unknown as string | number,
    }
  })

  return options
}

const { t } = i18n.global

export const messageTypeOptions: MessageTypeOption[] = [
  {
    label: t('messages.mt'),
    value: 'MT',
  },
  {
    label: t('messages.mo'),
    value: 'MO',
  },
]

const locale = initLocale()

export const channelOptions: ChannelOption[] = [
  {
    value: 'sms',
    label: t('channels.sms'),
  },
  {
    value: 'viber',
    label: t('channels.viber'),
  },
  {
    value: 'whatsapp',
    label: t('channels.whatsapp'),
  },
  {
    value: 'vk',
    label: t('channels.vk'),
  },
  {
    value: 'ok',
    label: t('channels.ok'),
  },
  {
    value: 'push',
    label: t('channels.push'),
  },
]

export const groupsInitialState: GroupItem[] = [
  {
    id: 'date',
    label: locale('statistics.groupBy.date'),
    value: 'date',
  },
  {
    id: 'originator',
    label: locale('statistics.groupBy.originator'),
    value: 'originator',
  },
  {
    id: 'channel',
    label: locale('statistics.groupBy.channel'),
    value: 'channel',
  },
  {
    id: 'messageType',
    label: locale('statistics.groupBy.messageType'),
    value: 'messageType',
  },
  {
    id: 'month',
    label: locale('statistics.groupBy.month'),
    value: 'month',
  },
  {
    id: 'operatorId',
    label: locale('statistics.groupBy.operatorId'),
    value: 'operatorId',
  },
  {
    id: 'departmentId',
    label: locale('statistics.groupBy.departmentId'),
    value: 'departmentId',
  },
  {
    id: 'batchId',
    label: locale('statistics.groupBy.batchId'),
    value: 'batchId',
  },
  {
    id: 'trafficType',
    label: locale('statistics.groupBy.trafficType'),
    value: 'trafficType',
  },
]

export const isEmptyFilters = (
  filtersState: StatisticFilters,
  defaultFilters?: StatisticFilters
) => {
  if (equals(filtersState, defaultFilters)) {
    return true
  }

  return Object.values(filtersState).every((filter) => {
    if (Array.isArray(filter)) {
      const isEmpty = filter.length === 0

      return isEmpty
    }

    return isNil(filter)
  })
}

export const getSelectedGroupDescription = (
  groups: GroupItem[],
  selectedGroups: string[]
) => {
  const selectedGroupLabels = groups
    .filter(({ value }) => selectedGroups.includes(value))
    .map(({ label }) => label)
    .join(', ')

  return getFilterLabel({
    label: t('statistics.filters.groupByTitle'),
    value: selectedGroupLabels,
  })
}
