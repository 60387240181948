import type { MultiselectTypes } from '@smst/ui'
import { isDefined, last } from 'remeda'
import { computed } from 'vue'

import { useId } from '@/hooks/useId'
import { i18n } from '@/i18n'

const { t } = i18n.global

export const OPTION_ALL = computed(() => {
  return {
    label: t('form.defaultSelectOptionLabel'),
    value: `all-${useId()}`,
  }
})

export const hasSelectedOptions = (options: MultiselectTypes.Value) => {
  return isDefined(options) && options.length > 1
}

export const lastChoiceIsAllOption = (options: MultiselectTypes.Value = []) =>
  last(options) === OPTION_ALL.value.value
