<template>
  <div :class="$style.dashboardGrid">
    <slot />
  </div>
</template>

<style module>
.dashboardGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--gap-32) var(--gap-16);
  margin-top: var(--gap-32);
}
</style>
