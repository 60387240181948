<template>
  <div :class="$style[style]">
    <FormSelectNormal
      name="countryId"
      :label="t('timezone.country.label')"
      :placeholder="t('timezone.country.placeholder')"
      :searchable="true"
      :options="countriesOptionsFiltered ?? []"
      :disabled="isCountriesLoading"
      :locale="{
        noOptionsText: $t('form.multiselect.noOptionsText'),
        noResultsText: $t('form.multiselect.noResultsText'),
      }"
    />
    <FormSelectNormal
      name="timezoneId"
      :label="t('timezone.timezone.label')"
      :placeholder="t('timezone.timezone.placeholder')"
      :searchable="true"
      :disabled="isTimezonesLoading"
      :options="cityOptionsFiltered ?? []"
      :locale="{
        noOptionsText: t('timezone.timezone.noOptionsText'),
        noResultsText: $t('form.multiselect.noResultsText'),
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, ref, watch } from 'vue'
import type { PropType } from 'vue'

import FormSelectNormal from '@/components/FormSelectNormal/FormSelectNormal.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import {
  cityOptionsFiltered,
  countriesOptionsFiltered,
  isCountriesLoading,
  isTimezonesLoading,
  useFetchCountries,
  useFetchTimezones,
} from '@/hooks/useFetchTimezones'

const t = useComponentI18n('settings.general')

const countryId = ref()

const props = defineProps({
  countryIds: {
    type: Number as PropType<number | undefined>,
    required: true,
  },
  meta: {
    type: Object,
    required: true,
  },
  style: {
    type: String,
  },
})

onMounted(async () => {
  countryId.value = props.countryIds
  await useFetchCountries()
  await nextTick()
  await useFetchTimezones(props.countryIds)
})

watch(
  [
    () => props.countryIds,
    () => props.meta.initialValues?.timezoneId,
    () => props.meta,
    () => isCountriesLoading.value,
  ],
  async (
    [newCountryId, newTimeZone, newMeta, newLoadCountry],
    [oldCountryId, oldTimeZone, oldMeta, oldLoadCountry]
  ) => {
    // загрузка городов начальное состояние после загрузки страны
    if (
      (!newLoadCountry && oldLoadCountry) ||
      (!oldMeta.dirty && newCountryId && oldCountryId && !newMeta.touched)
    ) {
      countryId.value = newCountryId
      await useFetchTimezones(newCountryId)
    }
    // загрузка нового списка городов после смены страны
    if (oldCountryId !== newCountryId && newMeta.touched && newMeta.dirty) {
      countryId.value = newCountryId
      await useFetchTimezones(newCountryId)
    }
    // загрузка списка городов если они не загрузились
    if (oldCountryId !== newCountryId && oldTimeZone !== newTimeZone) {
      countryId.value = newCountryId
      await useFetchTimezones(newCountryId)
    }
  }
)
</script>

<style module>
.columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  column-gap: var(--gap-20);
}

.vertical {
  display: grid;
  row-gap: var(--gap-16);
}
</style>
