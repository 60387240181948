<template>
  <div v-if="state === 'form'" :class="$style.root">
    <Text tag="h1">
      {{ t('forgotTitle') }}
    </Text>

    <Text tag="p" weight="medium" :class="$style.description">
      {{ t('forgotDescription') }}
    </Text>

    <form :class="$style.form" @submit="onSubmit">
      <FormControls :controls="formControls" />

      <FormRecaptcha
        v-if="errorActive"
        name="captchaCode"
        :class="$style.captcha"
      />

      <Button type="submit" :class="$style.submit" :loading="isLoading">
        {{ t('form.submit') }}
      </Button>

      <ApiErrorMessage :error="error" :class="$style.submitError" />
    </form>
    <Text :class="$style.captcha">
      <span>{{ $t('forgotPassword.goBackToLogin') }}</span>
      {{ ' ' }}
      <Link inline :to="{ name: RouteNames.Auth }">
        {{ $t('forgotPassword.goToLogin') }}
      </Link>
    </Text>
  </div>

  <div v-else :class="$style.root">
    <Text tag="h1">
      {{ t('restoreTitle') }}
    </Text>

    <Text tag="p" weight="medium" :class="$style.description">
      {{
        t('restoreDescription', {
          login,
        })
      }}
    </Text>
  </div>
</template>

<script setup lang="ts">
import { Button, Text } from '@smst/ui'
import { useForm } from 'vee-validate'
import { nextTick, ref } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormControls from '@/components/FormControls/FormControls.vue'
import FormRecaptcha from '@/components/FormRecaptcha/FormRecaptcha.vue'
import Link from '@/components/Link/Link.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'

import type { RestorePassword } from './ForgotPassword.schema'
import { restorePasswordSchema } from './ForgotPassword.schema'

type State = 'form' | 'success'

const t = useComponentI18n('forgotPassword')

const formControls: ControlType[] = [
  {
    control: 'input',
    name: 'login',
    type: 'text',
    label: t('form.login'),
  },
]

const { handleSubmit } = useForm<RestorePassword>({
  validationSchema: restorePasswordSchema,
})

const login = ref()
const state = ref<State>('form')
const errorActive = ref(true)

const reloadCaptcha = async () => {
  errorActive.value = false
  await nextTick()
  errorActive.value = true
}

const {
  mutate: restorePassword,
  isLoading,
  error,
} = useMutation(apiClient.restorePassword.restore_POST, {
  onSuccess: (_, values) => {
    login.value = values.login
    state.value = 'success'
  },
  onError: (err) => {
    if (err) {
      void reloadCaptcha()
    }
  },
})

const onSubmit = handleSubmit((values) => restorePassword(values))
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  text-align: center;
}

.description {
  margin-top: var(--gap-16);

  white-space: pre-wrap;
}

.form {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: var(--gap-32);
}

.submit {
  width: 100%;
  margin-top: var(--gap-32);
}

.submitError {
  margin-top: var(--gap-8);
}

.captcha {
  margin-top: var(--gap-16);
}
</style>
