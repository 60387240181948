<template>
  <div v-if="list.length > 0" :class="$style.root">
    <Text>{{ title }}</Text>
    <ol :class="$style.list">
      <li v-for="member in list" :key="member" :class="$style.item">
        {{ member }}
      </li>
    </ol>
  </div>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import type { PropType } from 'vue'
import { computed } from 'vue'

import type { SelectedElements } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'

const props = defineProps({
  selectedElements: {
    type: Object as PropType<SelectedElements>,
    required: true,
  },
})

const t = useComponentI18n('group.listMembers')

const title = computed(() => {
  return props.selectedElements.all ? t('exclude') : t('phones')
})

const list = computed(() => {
  if (props.selectedElements.all) {
    return props.selectedElements.exclude
  } else {
    return props.selectedElements.include
  }
})
</script>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;

  gap: var(--gap-16);
}

.list {
  margin: 0;
  padding: 0;

  list-style: none;

  border: 1px solid var(--color-stroke-form);
  border-radius: var(--border-radius-s);

  counter-reset: num;
}

.item {
  position: relative;

  padding-top: var(--gap-16);
  padding-bottom: var(--gap-16);

  padding-left: calc(var(--gap-12) + var(--gap-32));

  &::before {
    position: absolute;
    left: calc(var(--gap-12));

    color: var(--color-text-grey);

    content: counter(num);
    counter-increment: num;
  }

  &:nth-child(odd) {
    background-color: var(--color-bg-table-line);
  }
}
</style>
