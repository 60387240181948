<template>
  <GroupForm
    v-if="routes"
    :initialValues="initialValues"
    :isLoading="isLoadingChangeGroup"
    :isFiltersSuccess="isFiltersSuccess"
    :groupRoutes="routes"
    :routeInitialId="routeInitialId"
    @submit="changeGroup"
  />
</template>

<script lang="ts" setup>
import { toaster } from '@smst/ui'
import type { Ref } from 'vue'
import { computed, ref, toRef } from 'vue'
import { useMutation, useQuery } from 'vue-query'

import { apiClient } from '@/api'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'
import { globalTime } from '@/utils/localTimeFromServer/globalLocalTime'

import { GROUP_KEY } from '../../Group.utils'
import type { Values } from '../GroupForm.validation'
import GroupForm from '../GroupForm.vue'

const t = useComponentI18n('group.settings')

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
})

const {
  data,
  refetch,
  isSuccess: isFiltersSuccess,
} = useQuery(
  [GROUP_KEY, toRef(props, 'groupId')],
  () => apiClient.group.group_GET_BY(Number(props.groupId)),
  { refetchOnMount: false }
)

const routes = computed(() => {
  if (data.value?.data.group === undefined) {
    return undefined
  }
  return data.value?.data.group?.routes
})

const routeInitialId: Ref<string | undefined> = ref(undefined)

const getRouteId = (congratulateData: { channels: object }) => {
  const routeInitial = Object.keys(congratulateData?.channels)
    .toSorted()
    .join()
  if (!routes.value) return '0'
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  for (const [key, value] of Object.entries(routes?.value)) {
    const routesValue = value ? value.toSorted().join() : ''
    if (routeInitial === routesValue) routeInitialId.value = key
  }
}

const getLocalUserTime = (time: string) => {
  const dataTime = `01-01-2024 ${time}:00`
  const { hours, minutes } =
    globalTime.serverStringDateTimeToUserObjectDateTime(dataTime)
  return `${hours}:${minutes}`
}

const initialValues = computed(() => {
  if (data.value?.data.group === undefined) {
    return undefined
  }

  const {
    group: {
      congratulate: _congratulate,
      congratulateData,
      viewForAll,
      viewOptional,
      groupName,
    },
  } = data.value.data

  const congratulate = _congratulate ? [_congratulate] : []
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  _congratulate && getRouteId(congratulateData)

  return {
    groupName,
    viewForAll: viewForAll ? [viewForAll] : [],
    viewOptional: viewOptional ? [viewOptional] : [],
    congratulate,
    channels: _congratulate ? congratulateData?.channels : {},
    startTime: _congratulate
      ? getLocalUserTime(congratulateData?.startTime)
      : '',
    transliterate: _congratulate ? congratulateData?.transliterate : false,
    originator: _congratulate ? congratulateData?.originator : '',
  }
})

const { mutate: changeGroup, isLoading: isLoadingChangeGroup } = useMutation(
  (values: Values) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return apiClient.group.group_PUT(Number(props.groupId), values)
  },
  {
    onSuccess: () => {
      void refetch.value()
      toaster.success(t('success'))
    },
    onError: (e) => {
      toaster.error(getErrorMessage(e))
    },
  }
)
</script>

<style module>
.submit {
  margin-top: var(--gap-32);
}
</style>
