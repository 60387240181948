<template>
  <Button
    :iconLeft="IconOptions"
    view="link"
    fullWidth
    @click="handleDrawerOpen"
  >
    {{ $t('filter.fields.title') }}
  </Button>

  <Drawer
    v-model="isDrawerOpen"
    :title="$t('filter.fields.title')"
    :closeText="$t('filter.drawer.close')"
  >
    <DndList
      v-model="listModelValue"
      :class="$style.dndList"
      :locale="{
        dragHandlerTitle: $t('filter.fields.dragHandlerTitle'),
      }"
    >
      <template #default="{ element }">
        <Checkbox
          :modelValue="element.checked"
          :class="$style.item"
          type="checkbox"
          :label="element.label"
          :value="element.value"
          @update:modelValue="
            (checked) => handleChangeSelectField(checked, element.value)
          "
        />
      </template>
    </DndList>

    <template #buttons>
      <Button type="button" @click="handleChange">
        {{ $t('filter.drawer.apply') }}
      </Button>

      <Button type="button" view="link" @click="handleReset">
        {{ $t('filter.drawer.reset') }}
      </Button>
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import { Button, Checkbox, DndList, Drawer, IconOptions } from '@smst/ui'
import { ref, watch } from 'vue'
import type { PropType } from 'vue'

import type { FieldFilterItem } from './FilterFields.types'

const props = defineProps({
  modelValue: {
    type: Array as PropType<FieldFilterItem[]>,
    required: true,
  },

  getDefaultValue: {
    type: Function as PropType<() => FieldFilterItem[]>,
    required: true,
  },
})

const emit = defineEmits(['submit'])

const isDrawerOpen = ref<boolean>(false)
const listModelValue = ref<FieldFilterItem[]>(props.modelValue)

const handleDrawerOpen = () => {
  isDrawerOpen.value = true
}

const handleReset = () => {
  listModelValue.value = props.getDefaultValue()
}

// Сбрасываем состояние в ящике при открытии
watch(
  () => isDrawerOpen.value,
  (newIsDrawerOpen) => {
    if (newIsDrawerOpen) {
      listModelValue.value = props.modelValue
    }
  }
)

const handleChange = () => {
  emit('submit', listModelValue.value)

  isDrawerOpen.value = false
}

const handleChangeSelectField = (checked: boolean, value: string) => {
  listModelValue.value = listModelValue.value.map((item) => {
    if (item.value !== value) {
      return item
    }

    return {
      ...item,
      checked,
    }
  })
}
</script>

<style module>
.dndList {
  display: grid;
  margin-top: var(--gap-24);
  row-gap: var(--gap-20);
}
</style>
