<template>
  <Button view="secondary" :iconLeft="IconDownload" @click="handleOpen">
    {{ t('openButton') }}
  </Button>

  <Drawer v-model="isDrawerOpen" :closeText="t('close')">
    <template #title>
      <Button view="text" :iconLeft="IconChevron" @click="handleClose">
        {{ t('back') }}
      </Button>
    </template>

    <Text size="2xl" weight="semibold" tag="h3">
      {{ t('title') }}
    </Text>

    <Text tag="p" :class="$style.subtitle">
      {{ t('subtitle') }}
    </Text>

    <Text tag="p" :class="$style.description">
      {{ t('description') }}
    </Text>

    <form :id="formId" :class="$style.form" @submit="onSubmit">
      <Text tag="p" size="s" :class="$style.explanation">
        {{ t('explanation') }}
      </Text>
      <FormControls :controls="formControls" />
      <Text tag="h4" size="xl" weight="semibold" :class="$style.fieldsTitle">
        {{ t('fieldsTitle') }}
      </Text>
      <!-- error checkbox message -->
      <Text
        v-if="checkboxError.active"
        tag="h5"
        size="s"
        weight="semibold"
        :class="$style.errorText"
      >
        {{ checkboxError.message }}
      </Text>
      <!-- error checkbox message end -->
      <DndList
        v-model="fields"
        :class="[$style.dndList, { [$style.checkError]: checkboxError.active }]"
        :locale="{
          dragHandlerTitle: t('dragHandlerTitle'),
        }"
      >
        <template #default="{ element }">
          <Checkbox
            :modelValue="element.checked"
            type="checkbox"
            :label="element.label"
            :value="element.value"
            @update:modelValue="
              (checked) => handleChangeSelectField(checked, element.value)
            "
          />
        </template>
      </DndList>
    </form>

    <template #buttons>
      <Button type="submit" :form="formId" :loading="isLoading">
        {{ t('submit') }}
      </Button>

      <ApiErrorMessage :error="error" :class="$style.error" />

      <Button view="link" @click="handleClose">
        {{ t('cancel') }}
      </Button>
    </template>

    <Modal
      v-if="isSuccessModalOpen"
      v-model="isSuccessModalOpen"
      :title="t('success.title')"
      :description="t('success.description')"
      :cancelButton="t('success.cancel')"
      :acceptButton="t('success.accept')"
      @accept="handleRouteRequestedReports"
      @update:modelValue="handleClose"
    />
  </Drawer>
</template>

<script setup lang="ts">
import {
  Button,
  Checkbox,
  DndList,
  Drawer,
  IconChevron,
  IconDownload,
  Modal,
  Text,
} from '@smst/ui'
import { useForm } from 'vee-validate'
import { onMounted, reactive, ref, watch } from 'vue'
import type { PropType } from 'vue'
import { useMutation } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import type { FieldFilterItem } from '@/components/FilterFields/FilterFields.types'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormControls from '@/components/FormControls/FormControls.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import type { FieldName } from '@/pages/Journal/MessagesLog/MessagesLog.types'
import { RouteNames } from '@/routeNames'

import type { FiltersState } from '../../hooks/useGetFiltersState/useGetFiltersState.types'
import { filterStateToFiltersModel } from '../../hooks/useGetFiltersState/useGetFiltersState.utils'
import { getSelectedFields } from '../../MessagesLog.utils'

import { reportGenerationSchema } from './ReportGeneration.schema'
import type { ReportGenerationForm } from './ReportGeneration.types'

const props = defineProps({
  reportFields: {
    type: Array as PropType<FieldFilterItem[]>,
    required: true,
  },
  filtersValues: {
    type: Object as PropType<FiltersState>,
    required: true,
  },
})

const { handleSubmit, values, setFieldValue } = useForm<ReportGenerationForm>({
  validationSchema: reportGenerationSchema,
  initialValues: {
    email: '',
    fileEncoding: 'UTF-8',
  },
})

const formId = 'reportGenerationForm'

const router = useRouter()
const t = useComponentI18n('messagesLog.reportGeneration')

onMounted(() => {
  const getFromStore = localStorage.getItem('fileEncoding')
  if (getFromStore) setFieldValue('fileEncoding', getFromStore)
})
const formControls: ControlType[] = [
  {
    control: 'input',
    name: 'email',
    type: 'email',
    label: t('form.email'),
  },
  {
    control: 'select',
    name: 'fileEncoding',
    options: [
      { label: 'CP-1251', value: 'CP-1251' },
      { label: 'UTF-8', value: 'UTF-8' },
    ],
    label: t('form.fileEncoding'),
    placeholder: t('form.fileEncoding'),
  },
]
const checkboxFields = ref<FieldName[]>([])
const checkboxError = reactive({
  message: t('checkbox'),
  active: false,
})

const isDrawerOpen = ref(false)
const isSuccessModalOpen = ref(false)

const fields = ref(props.reportFields)

// Сбрасываем состояние в ящике при открытии
watch(
  () => isDrawerOpen.value,
  (newIsDrawerOpen) => {
    if (newIsDrawerOpen) {
      fields.value = props.reportFields
    }
  }
)

watch(
  () => values.fileEncoding,
  (newVal) => {
    if (newVal) {
      localStorage.setItem('fileEncoding', newVal)
    }
  }
)

const handleOpen = () => {
  isDrawerOpen.value = true
  checkboxError.active = false
}

const handleClose = () => {
  isDrawerOpen.value = false
  checkboxError.active = false
}

const {
  mutate: createReport,
  isLoading,
  error,
} = useMutation(apiClient.messages.reportCreate_POST, {
  onSuccess: () => {
    isSuccessModalOpen.value = true
  },
})

const handleRouteRequestedReports = () => {
  handleClose()
  void router
    .push({
      name: RouteNames.RequestedReports,
    })
    .then(() => {
      // После перехода на страницу удаляем стили на html оставшиеся после незакрытых Drawer
      document.documentElement.setAttribute('style', '')
    })
}

const handleChangeSelectField = (checked: boolean, value: string) => {
  fields.value = fields.value.map((item) => {
    if (item.value !== value) {
      return item
    }

    return {
      ...item,
      checked,
    }
  })

  checkboxFields.value = getSelectedFields(fields.value)

  if (checkboxFields.value.length === 0) checkboxError.active = true
  else checkboxError.active = false
}

const onSubmit = handleSubmit(({ email, fileEncoding }) => {
  if (checkboxError.active) return
  const { batchIds, ...rest } = filterStateToFiltersModel(props.filtersValues)

  createReport({
    ...rest,
    batchIds: batchIds?.map(Number),
    fields: getSelectedFields(fields.value),
    email,
    format: 'csv',
    fileEncoding,
  })
})
</script>

<style module>
.subtitle,
.form {
  margin-top: var(--gap-24);
}

.description,
.explanation {
  margin-bottom: var(--gap-8);
}

.explanation {
  color: var(--color-text-grey);
}

.fieldsTitle {
  margin-top: var(--gap-40);
}

.dndList {
  display: grid;
  margin-top: var(--gap-24);
  row-gap: var(--gap-20);
}

.error {
  margin-top: var(--gap-8);
}

.errorText {
  position: absolute;

  color: var(--color-error);
}

.checkError {
  background-color: var(--color-error-light);
  outline: solid 6px var(--color-error-light);
}
</style>
