<template>
  <FormControlBase
    v-slot="{ invalid }"
    :descriptions="descriptions"
    :name="name"
    noTouched
  >
    <div :class="$style.dateTimeRange">
      <DateTime
        :modelValue="dateFrom"
        :class="$style.topRow"
        :invalid="invalid"
        :timeLabel="labels.from.time"
        :dateLabel="labels.from.date"
        :maxDate="maxDate"
        :minDate="minDateFrom"
        :disabled="disabled"
        @update:modelValue="
          (value) => handleChangeDate(value, range ? 'from' : undefined)
        "
      />

      <template v-if="range && dateTo">
        <DateTime
          :modelValue="dateTo"
          :class="$style.bottomRow"
          :invalid="invalid"
          :timeLabel="labels?.to?.time"
          :dateLabel="labels?.to?.date"
          :maxDate="maxDate"
          :defaultValue="maxDate"
          :disabled="disabled"
          @update:modelValue="(value) => handleChangeDate(value, 'to')"
        />
      </template>
    </div>
  </FormControlBase>
</template>

<script lang="ts">
import { useField } from 'vee-validate'
import type { ExtractPropTypes, PropType } from 'vue'
import { computed, defineComponent } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

import DateTime from './DateTime.vue'
import type { DateTimeLabel, FieldValue } from './FormDateTimepicker.types'
import { getTimeFromDate } from './FormDateTimepicker.utils'

const formFormDateTimepickerProps = {
  ...formControlProps,
  labels: {
    type: Object as PropType<DateTimeLabel>,
    required: true,
  },
  maxDate: Date,
  minDateFrom: Date,
  range: Boolean,
  descriptions: String,
} as const

export type FormDateTimepickerProps = ExtractPropTypes<
  typeof formFormDateTimepickerProps
>

export default defineComponent({
  components: {
    FormControlBase,
    DateTime,
  },
  props: formFormDateTimepickerProps,
  setup(props) {
    const { value: formValue, handleChange } = useField<FieldValue>(props.name)

    const dateFrom = computed(() => {
      if (Array.isArray(formValue.value)) {
        return formValue.value[0]
      }

      return formValue.value
    })

    const dateTo = computed(() => {
      if (Array.isArray(formValue.value)) {
        return formValue.value[1]
      }

      return undefined
    })

    const handleChangeDate = (
      fieldValue: Date | [Date, Date],
      period?: 'from' | 'to'
    ) => {
      if (period) {
        const rangeDates =
          period === 'from'
            ? [fieldValue, dateTo.value]
            : [dateFrom.value, fieldValue]

        handleChange(rangeDates)
      } else {
        handleChange(fieldValue)
      }
    }
    return {
      dateFrom,
      dateTo,

      getTimeFromDate,
      handleChangeDate,
    }
  },
})
</script>

<style module>
.dateTimeRange {
  --z-container: 3;
  --z-top-row: calc(var(--z-container) + 1);
  --z-bottom-row: calc(var(--z-top-row) - 1);

  position: relative;
  z-index: var(--z-container);

  display: grid;

  gap: var(--gap-16);
}

.topRow {
  position: relative;
  z-index: var(--z-top-row);
}

.bottomRow {
  position: relative;
  z-index: var(--z-bottom-row);
}
</style>
