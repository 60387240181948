import type { PropType } from 'vue'

import type { ControlType } from '@/components/FormControls/FormControls.types'
import type { ValidationSchema } from '@/utils/validation'

export type FiltersFormValues = Record<string, unknown>

export const filtersProps = {
  modelValue: {
    type: Object as PropType<FiltersFormValues>,
    required: true,
  },
  defaultValues: Object as PropType<FiltersFormValues>,
  controls: {
    type: Array as PropType<ControlType[]>,
    required: true,
  },
  validationSchema: {
    type: Object as PropType<ValidationSchema<FiltersFormValues>>,
    default: () => ({}),
  },
} as const
