import type {
  GetOptions,
  Option,
} from '@/../../ui/lib/src/components/Select/Select.types'

export const findSelectedField = (
  options: GetOptions | Option[],
  value: number | string
) => {
  if (Array.isArray(options))
    return options.find((element) => {
      return element.value === value
    })
  return options
}
