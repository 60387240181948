import { computed, watchEffect } from 'vue'

import { i18n } from '@/i18n'
import { passStrengthPolitics } from '@/utils/passwordValidation'

import { strengthSelectedValue } from '../SelectPassStrength/PassStrengthPolitics.utils'

import { initPasswordRules } from './InitPasswordRules'
import { schema } from './PasswordForm.schema'
import { startValidate } from './Validation.utils'

const { t } = i18n.global

const { passwordRule, getSavePassword } = initPasswordRules()

const StrengthOptions = passStrengthPolitics.map((type) => ({
  value: type,
  label: t(`accountForm.PassStrength.${type}.label`),
}))

const { handlerValidate, info, valid, values, changeRules } = startValidate(
  schema,
  getSavePassword
)

const handleRules = () => {
  changeRules('password', [passwordRule])
}

watchEffect(() => {
  if (strengthSelectedValue.value) {
    void handleRules()
  }
})

const data = computed(() => {
  return { password: values.value.password }
})

// инициализируем файл
export const initPasswordUtils = () => {
  return {
    StrengthOptions,
    passStrengthPolitics,
    passwordsValidate: handlerValidate,
    info,
    passwordValid: valid,
    passwordValues: data,
    handleRules,
  }
}
