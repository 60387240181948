type FilterLabel = string
type FilterValue = string | number

export type FilterState = {
  label?: FilterLabel
  value?: FilterValue
}

export type FilterDescription =
  | `${FilterLabel}: ${FilterValue}`
  | `${FilterValue}`

export const getFilterLabel = ({
  label,
  value,
}: FilterState): FilterDescription =>
  label ? `${label}: ${value}` : String(value)
