<template>
  <template v-if="isSuccesGroup">
    <header :class="$style.header">
      <Link
        view="text"
        :iconLeft="IconChevron"
        :to="{ name: RouteNames.Groups }"
        >{{ t('goToGroups') }}</Link
      >
      <Text tag="h1">{{ group?.groupName }}</Text>

      <RouterTabs :items="tabs" />
    </header>
    <router-view />
  </template>
  <div v-else :class="$style.container">
    <Loader v-if="isLoadingGroup" size="m" />
    <ApiErrorMessage :error="errorGroup" />
  </div>
</template>

<script setup lang="ts">
import { IconChevron, Loader, Text } from '@smst/ui'
import { useHead } from '@vueuse/head'
import { computed, toRef } from 'vue'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Link from '@/components/Link/Link.vue'
import RouterTabs from '@/components/RouterTabs/RouterTabs.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'

import { GROUP_KEY } from './Group.utils'

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
  phone: String,
})

const t = useComponentI18n('group')

const {
  data: groupData,
  isLoading: isLoadingGroup,
  error: errorGroup,
  isSuccess: isSuccesGroup,
} = useQuery([GROUP_KEY, toRef(props, 'groupId')], () =>
  apiClient.group.group_GET_BY(Number(props.groupId))
)

const group = computed(() => groupData.value?.data.group)

const tabs = [
  { label: t('tabs.edit'), value: RouteNames.GroupEdit },
  { label: t('tabs.settings'), value: RouteNames.GroupSettings },
]

useHead({
  title: computed(() => group.value?.groupName ?? t('goToGroups')),
})
</script>

<style module>
.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: column;

  gap: var(--gap-24);
  align-items: flex-start;
}
</style>
