import { toaster } from '@smst/ui'
import { ref } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient, ApiError } from '@/api'
import { downloadFile } from '@/utils/downloadFile'
import { getErrorMessage } from '@/utils/errors'

export const downloadReportInit = () => {
  const downloadReportStatus = ref(false)
  const { mutate: downloadReport } = useMutation(
    async (reportId: number) => {
      downloadReportStatus.value = true
      return await apiClient.messages.report_GET_BY(reportId, {
        format: 'blob',
      })
    },

    {
      onSuccess: async (data) => {
        downloadReportStatus.value = false
        if (data.data.size < 55) {
          const text = await new Response(data.data).text()
          const error = JSON.parse(text)
          if (error.code) {
            const err = new ApiError(error)
            toaster.error(getErrorMessage(err))
          }
        } else {
          void downloadFile({
            response: data,
            fileName: `reports-${new Date().toLocaleDateString()}`,
            extension: 'zip',
          })
        }
      },
      onError: (err) => {
        toaster.error(getErrorMessage(err))
      },
    }
  )
  return {
    downloadReport,
    downloadReportStatus,
  }
}
