<template>
  <form @submit="onSubmit">
    <Grid>
      <Row>
        <Card :title="t('title')">
          <div :class="$style.content">
            <FormTextarea
              name="members"
              :description="t('tip')"
              :placeholder="t('placeholder', { phone: phones.phone1 })"
            />
            <Button
              type="submit"
              view="secondary"
              fullWidth
              :disabled="isDisabled"
              :loading="isLoading"
            >
              {{ t('submit') }}
            </Button>
          </div>
        </Card>
        <template #right>
          <HintCard v-html="t('hint', { phone: phones.phone1 })" />
        </template>
      </Row>
    </Grid>
  </form>
</template>

<script setup lang="ts">
import { Button, toaster } from '@smst/ui'
import { isDefined } from 'remeda'
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import Card from '@/components/Card/Card.vue'
import FormTextarea from '@/components/FormTextarea/FormTextarea.vue'
import { Grid, Row } from '@/components/Grid'
import HintCard from '@/components/HintCard/HintCard.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'
import type { Member } from '@/utils/members'
import { getMembers } from '@/utils/members'
import { phones } from '@/utils/phones'

type FormValues = {
  members: string
}

type Values = {
  members: Member[]
}

const t = useComponentI18n('group.membersForm')

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
})

const { handleSubmit, resetForm, meta } = useForm<FormValues>()

const isDisabled = computed(() => !meta.value.dirty)

const { mutate: addMembers, isLoading } = useMutation(
  (values: Values) =>
    apiClient.group.membersAddList_POST(Number(props.groupId), values),
  {
    onSuccess: (data) => {
      const count = data.data.affectedRows
      if (isDefined(count)) {
        toaster.success(t('success', { count }))
        resetForm()
      }
    },
    onError: (e) => {
      toaster.error(getErrorMessage(e))
    },
  }
)

const onSubmit = handleSubmit((values) => {
  const members = getMembers(values.members)
  addMembers({ members })
})
</script>

<style module>
.content {
  display: flex;
  flex-direction: column;

  gap: var(--gap-20);
}
</style>
