<template>
  <div :class="$style.root">
    <header :class="$style.header">
      <Text tag="h1">{{ t('title') }}</Text>
      <Button
        type="button"
        view="link"
        :iconLeft="IconPlus"
        @click="router.push({ ...route, name: RouteNames.DraftCreate })"
      >
        {{ t('create') }}
      </Button>
    </header>

    <!-- Доработать после https://jira.csssr.io/browse/SMST-270  -->
    <Tabs v-if="profile?.smartDelivery" :items="tabs">
      <template #default="{ item, tabClass, tabActiveClass }">
        <router-link
          :to="{ params: { channel: item.value } }"
          :class="[tabClass, item.value === channel && tabActiveClass]"
        >
          {{ item.label }}
        </router-link>
      </template>
    </Tabs>

    <SearchBar
      :values="searchValues"
      :locale="{
        label: $t('drafts.search.label'),
        button: $t('drafts.search.submit'),
      }"
      @submit="handleSearchSubmit"
    />

    <Draft :id="draftId ? Number(draftId) : undefined" />
    <DraftCreate :isOpen="isOpenCreate" />

    <template v-if="showTable">
      <LoaderWrapper
        :loading="isFetchingDrafts || isLoadingDelete"
        :class="$style.main"
      >
        <Button
          type="button"
          view="action"
          :disabled="!hasSelectedElements(selectionModel)"
          :class="$style.delete"
          :iconLeft="IconDelete"
          @click="isOpenModalDelete = true"
        >
          {{ t('delete') }}
        </Button>
        <Table
          v-model:modelSelection="selectionModel"
          v-model:modelSort="sortModel"
          :class="$style.table"
          :columns="tableColumns"
          :data="tableData"
          :rowKey="tableRowKey"
          @rowClick="handleRowClick"
        >
          <template #column:channel="{ item }">
            {{ channelTranslation[item.channel as Channel] }}
          </template>
          <template #column:addDate="{ item }">
            <TableDate :date="item.addDate" />
          </template>
        </Table>
      </LoaderWrapper>

      <PaginationPanel
        v-model="paginationModel"
        :totalItems="totalTableItems"
      />
    </template>

    <div v-else :class="$style.container">
      <Loader v-if="isLoadingDrafts" size="m" />

      <ApiErrorMessage v-else-if="errorDrafts" :error="errorDrafts" />

      <LoaderWrapper v-else-if="!hasTableItems" :loading="isFetchingDrafts">
        <NoResultsFound />
      </LoaderWrapper>
    </div>
  </div>

  <Modal
    v-model="isOpenModalDelete"
    :title="localeDelete.title"
    :acceptButton="localeDelete.acceptButton"
    :cancelButton="localeDelete.cancelButton"
    @accept="handleDelete"
  />
</template>

<script setup lang="ts">
import type { TableTypes } from '@smst/ui'
import {
  Button,
  IconDelete,
  IconPlus,
  Loader,
  LoaderWrapper,
  Modal,
  Table,
  Tabs,
  Text,
  toaster,
} from '@smst/ui'
import { StringParam, withDefault } from 'serialize-query-params'
import type { PropType } from 'vue'
import { computed, toRef, watch } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'
import { useRoute, useRouter } from 'vue-router'

import { apiClient } from '@/api'
import type {
  Draft as DraftType,
  SelectedElements,
} from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import PaginationPanel from '@/components/PaginationPanel/PaginationPanel.vue'
import SearchBar from '@/components/SearchBar/SearchBar.vue'
import type { SearchValue } from '@/components/SearchBar/SearchBar.vue'
import TableDate from '@/components/TableDate/TableDate.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { useTableSelection } from '@/hooks/useTable'
import { createSortQueryConfig, useTableRequest } from '@/hooks/useTableRequest'
import { RouteNames } from '@/routeNames'
import { getErrorMessage } from '@/utils/errors'
import { hasSelectedElements } from '@/utils/hasSelectedElements'

import Draft from './Draft/Draft.vue'
import DraftCreate from './DraftCreate.vue'
import type { Channel } from './Drafts.utils'
import { channelTranslation, tabs, useModalDelete } from './Drafts.utils'

type TableItem = DraftType
type TableColumns = Array<TableTypes.Column<TableItem>>

const props = defineProps({
  channel: {
    type: String as PropType<Channel>,
    required: true,
  },
  draftId: String,
})

const router = useRouter()
const route = useRoute()

const queryClient = useQueryClient()

const t = useComponentI18n('drafts')

const isOpenCreate = computed(() => {
  return route.name === RouteNames.DraftCreate
})

const { profile } = useProfile()

const filtersConfig = {
  filter: withDefault(StringParam, undefined),
}
const sortConfig = createSortQueryConfig(['addDate'])

const { selectionModel, resetSelection } = useTableSelection<number>()

const { isOpen: isOpenModalDelete, locale: localeDelete } =
  useModalDelete(selectionModel)

const handleDelete = () => {
  isOpenModalDelete.value = false
  void deleteDrafts(selectionModel.value)
}

const { sortModel, paginationModel, filtersModel, request, resetPagination } =
  useTableRequest({
    sortConfig,
    filtersConfig,
  })

watch(
  () => props.channel,
  () => {
    resetSelection()
  }
)

const {
  data: draftsData,
  isSuccess: isSuccessDrafts,
  isFetching: isFetchingDrafts,
  isLoading: isLoadingDrafts,
  error: errorDrafts,
} = useQuery(
  ['drafts', toRef(props, 'channel'), request],
  () => apiClient.drafts.list_GET_BY(props.channel, request.value),
  {
    keepPreviousData: true,
  }
)

const { mutate: deleteDrafts, isLoading: isLoadingDelete } = useMutation(
  async (values: SelectedElements) =>
    apiClient.drafts.delete_POST(props.channel, {
      selectedElements: values,
    }),
  {
    onSuccess: () => {
      void queryClient.invalidateQueries('drafts')
      resetSelection()
      resetPagination()
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)

const searchValues = computed(() => ({
  filter: filtersModel.value.filter ?? '',
}))

const handleRowClick = (item: DraftType) => {
  void router.push({
    ...route,
    name: RouteNames.Draft,
    params: { draftId: item.draftId, channel: props.channel },
  })
}

const handleSearchSubmit = (values: SearchValue) => {
  const searchValue = values.filter || undefined

  filtersModel.value = { filter: searchValue }
}

const tableData = computed(() => draftsData.value?.data.list ?? [])
const totalTableItems = computed(
  () => draftsData.value?.data.pageInfo?.totalItems ?? 0
)

const hasTableItems = computed(() => totalTableItems.value > 0)
const showTable = computed(() => hasTableItems.value && isSuccessDrafts.value)

const tableColumns = computed<TableColumns>(() => {
  const columns: TableColumns = [
    {
      name: t('table.name'),
      prop: 'name',
      wrap: true,
    },
    {
      name: t('table.text'),
      prop: 'text',
      wrap: true,
    },
    {
      name: t('table.addDate'),
      prop: 'addDate',
      sortable: true,
    },
  ]

  if (props.channel === 'all' && profile.value?.smartDelivery) {
    columns.splice(1, 0, { name: t('table.channel'), prop: 'channel' })
  }

  return columns
})

const tableRowKey: keyof TableItem = 'draftId'
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--gap-24);
  align-items: flex-start;
}

.header {
  display: flex;
  gap: var(--gap-32);
  align-items: baseline;
}

.table,
.main {
  width: 100%;
}

.delete {
  margin-bottom: var(--gap-16);

  color: var(--color-error);
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  align-self: center;
  justify-content: center;
}
</style>
