<template>
  <Text
    v-if="status"
    weight="medium"
    :class="$style.status"
    :color="statusColor"
  >
    {{ rateProgress }} {{ t(`status.${status}`) }}
  </Text>
</template>

<script lang="ts" setup>
import type { TextTypes } from '@smst/ui'
import { Text } from '@smst/ui'
import { computed } from 'vue'
import type { PropType } from 'vue'

import { TaskStatus } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'

const t = useComponentI18n('tasksSidebar')

const props = defineProps({
  progress: Number,
  status: String as PropType<TaskStatus>,
})

const rateProgress = computed(() =>
  props.progress && props.progress < 100 ? `${props.progress}%` : undefined
)

const statusColor = computed<TextTypes.Color>(() => {
  if (!props.status) return 'primary'

  if ([TaskStatus.Error, TaskStatus.Canceled].includes(props.status)) {
    return 'error'
  }

  if (props.status === TaskStatus.Finished) {
    return 'success'
  }

  if ([TaskStatus.Paused, TaskStatus.New].includes(props.status)) {
    return 'secondary'
  }

  if (props.status === TaskStatus.Processed) {
    return 'button'
  }

  return 'primary'
})
</script>

<style module>
.status {
  display: inline-flex;
  width: max-content;
  height: max-content;
  padding: var(--gap-4) var(--gap-12);

  border: 1px solid;
  border-radius: 20px;
}
</style>
