import { differenceInCalendarMonths, differenceInDays } from 'date-fns'
import { isDefined } from 'remeda'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { i18n } from '@/i18n'
import yup from '@/utils/yup'

type ReportGenerationForm = {
  email: string
}

const { t } = i18n.global

const getFilterErrorText = (key: string) => t(`statistics.filtersError.${key}`)

export const AnalysisGenerationSchema: ComputedRef<
  yup.SchemaOf<ReportGenerationForm>
> = computed(() =>
  yup.object({
    email: yup
      .string()
      .requiredField('messagesLog.reportGeneration.form.email')
      .email(t('validation.email')),
    department: yup.number(),
    failureInterval: yup.number(),
    date: yup
      .array(yup.date().required())
      .length(2)
      .test({
        message: getFilterErrorText('rangeMoreThanTwoMonth'),
        test(dates) {
          if (!dates?.every(isDefined)) return false

          const [from, to] = dates

          const daysBetween = differenceInCalendarMonths(to, from)

          return daysBetween <= 1
        },
      })
      .test({
        message: getFilterErrorText('endDateLargerStartDate'),
        test(dates) {
          if (!dates?.every(isDefined)) return false

          const [from, to] = dates

          const daysBetween = differenceInDays(to, from)

          return daysBetween >= 0
        },
      }),
  })
)
