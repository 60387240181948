<template>
  <Text tag="h1">
    {{ t('title') }}
  </Text>

  <Filters
    v-model="filtersModel"
    :controls="controls"
    :class="$style.filter"
    :defaultValues="defaultFilterValues"
  />

  <template v-if="showTable">
    <LoaderWrapper :loading="isFetching" :class="$style.table">
      <Table
        v-model:modelSort="sortModel"
        :data="tableData"
        :columns="columns"
        rowKey="originatorId"
        :onRowClick="handleRowClick"
      >
        <template #column:transactionDate="{ item }">
          <TableDate :date="item.transactionDate" />
        </template>

        <template #column:transactionType="{ item }">
          {{ t(`transactionTypes.${item.transactionType}`) }}
        </template>

        <template #column:deltaRur="{ item }">
          {{ item.deltaRur }} {{ item.currency }}
        </template>
      </Table>
    </LoaderWrapper>

    <PaginationPanel v-model="paginationModel" :totalItems="totalTableItems" />

    <Drawer
      v-model="isDrawerOpen"
      :closeText="t('closeText')"
      :title="
        t('transactionsInfo.title', {
          date: formatDate(transactionInfo?.transactionDate),
        })
      "
    >
      <TransactionsInfo
        v-if="transactionInfo"
        :transactionInfo="transactionInfo"
      />

      <template #buttons>
        <Button type="button" @click="isDrawerOpen = false">
          {{ t('closeButton') }}
        </Button>
      </template>
    </Drawer>
  </template>

  <div v-else :class="$style.container">
    <Loader v-if="isLoading" size="m" />

    <ApiErrorMessage :error="error" />

    <NoResultsFound
      v-if="showNoData || showNoResults"
      :variant="showNoData ? MessageType.noData : MessageType.noResult"
    />
  </div>
</template>

<script lang="ts" setup>
import { Button, Drawer, Loader, LoaderWrapper, Table, Text } from '@smst/ui'
import type { TableTypes } from '@smst/ui'
import { parseISO, subDays } from 'date-fns'
import { isDefined, omit } from 'remeda'
import { createEnumParam } from 'serialize-query-params'
import type { ComputedRef } from 'vue'
import { computed, ref } from 'vue'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import type { ClientTransaction } from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Filters from '@/components/Filters/Filters.vue'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import { MessageType } from '@/components/NoResultsFound/NoResultsFound.types'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import PaginationPanel from '@/components/PaginationPanel/PaginationPanel.vue'
import TableDate from '@/components/TableDate/TableDate.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { createSortQueryConfig, useTableRequest } from '@/hooks/useTableRequest'
import { RangeDateParam } from '@/utils/date'
import { getDateIntervalToRequest } from '@/utils/getDateIntervalToRequest'
import { hasValues, omitBy } from '@/utils/object'

import TransactionsInfo from './components/TransactionsInfo/TransactionsInfo.vue'
import {
  transactionTypeOptions,
  transactionTypes,
} from './TransactionsHistory.utils'

const t = useComponentI18n('transactionsHistory')

const sortConfig = createSortQueryConfig([
  'transactionDate',
  'transactionType',
  'deltaRur',
  'deltaUsd',
  'deltaCredits',
])

const filtersConfig = {
  date: RangeDateParam,
  transactionType: createEnumParam(transactionTypes),
}

const defaultFilterValues = {
  date: [subDays(new Date(), 60), new Date()],
}

const { request, paginationModel, sortModel, filtersModel } = useTableRequest({
  filtersConfig,
  sortConfig,
})

const isFiltersNotEmpty = computed(() => hasValues(filtersModel.value))

const { data, isSuccess, isLoading, isFetching, error } = useQuery(
  ['transactionsHistory', request],
  () =>
    apiClient.accounting.transactions_GET({
      ...omitBy(omit(request.value, ['date']), isDefined),
      ...getDateIntervalToRequest(request.value.date),
    }),
  {
    keepPreviousData: true,
    enabled: computed(() => isFiltersNotEmpty.value),
  }
)

const tableData = computed(() => data.value?.data.list ?? [])
const showTable = computed(
  () => isSuccess.value && tableData.value.length > 0 && isFiltersNotEmpty.value
)
const totalTableItems = computed(
  () => data.value?.data.pageInfo?.totalItems ?? 0
)
const showNoData = computed(() => !isFiltersNotEmpty.value && !error.value)
const showNoResults = computed(
  () => isSuccess.value && tableData.value.length === 0
)

const columns: ComputedRef<Array<TableTypes.Column<ClientTransaction>>> =
  computed(() => [
    {
      name: t('columns.transactionDate'),
      prop: 'transactionDate',
      sortable: true,
    },
    {
      name: t('columns.transactionType'),
      prop: 'transactionType',
      sortable: true,
    },
    {
      name: t('columns.deltaRur'),
      prop: 'deltaRur',
      sortable: true,
    },
    {
      name: t('columns.deltaUsd'),
      prop: 'deltaUsd',
      sortable: true,
    },
    {
      name: t('columns.deltaCredits'),
      prop: 'deltaCredits',
      sortable: true,
    },
    {
      name: t('columns.notes'),
      prop: 'notes',
      ellipsis: true,
      width: '35%',
    },
  ])

const formatDate = (date?: string) =>
  date ? parseISO(date).toLocaleDateString() : ''

const isDrawerOpen = ref<boolean>(false)
const transactionInfo = ref<ClientTransaction>()

const handleRowClick = (transaction: ClientTransaction) => {
  transactionInfo.value = transaction

  isDrawerOpen.value = true
}

const controls: ControlType[] = [
  {
    control: 'datepicker',
    name: 'date',
    range: true,
    label: t('filters.date'),
  },
  {
    control: 'select',
    name: 'transactionType',
    label: t('filters.transactionType'),
    options: transactionTypeOptions,
    withOptionAll: true,
  },
]
</script>

<style module>
.table {
  margin-top: var(--gap-24);
}

.container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.filter {
  margin-top: var(--gap-40);
}
</style>
