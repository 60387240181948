<template>
  <header :class="$style.header">
    <Text tag="h1">
      {{ t('title') }}
    </Text>

    <Link
      :to="{ name: RouteNames.AccountsCreate }"
      view="link"
      :iconLeft="IconPlus"
    >
      {{ t('createAccountLink') }}
    </Link>
  </header>

  <AccountsTabs v-if="showTabs" :class="$style.tabs" />

  <LoaderWrapper v-if="showTable" :loading="showLoaderAboveTable">
    <Text tag="p" :class="$style.description" size="l" weight="semibold">
      {{ t('description.accounts') }}
    </Text>

    <AccountsTable
      :data="tableData"
      @openEventLog="handleOpenEventLog"
      @edit="handleEdit"
      @unblock="handleUnblock"
      @delete="openDeleteConfirmation"
    />
  </LoaderWrapper>

  <div v-else :class="$style.container">
    <Loader v-if="isAccountsListLoading || isDepartmentsListLoading" size="m" />

    <NoResultsFound
      v-if="showNoResultsFound"
      :customVariant="{
        icon: IconAddUser,
        iconSize: 'l',
        title: t('noAccounts'),
      }"
    />

    <ApiErrorMessage :error="accountsListError" />
  </div>

  <Modal
    v-model="isDeleteConfirmationOpen"
    :title="tModal('accounts.title', { name: accountShortDataToDelete?.name })"
    :acceptButton="tModal('acceptButton')"
    :cancelButton="tModal('cancelButton')"
    @accept="handleDelete"
  />
</template>

<script lang="ts" setup>
import {
  IconAddUser,
  IconPlus,
  Loader,
  LoaderWrapper,
  Modal,
  Text,
  toaster,
} from '@smst/ui'
import { computed, ref } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import AccountsTable from '@/components/AccountsTable/AccountsTable.vue'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Link from '@/components/Link/Link.vue'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import AccountsTabs from '@/components/RouterTabs/AccountsTabs.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { RouteNames } from '@/routeNames'
import { getErrorMessage } from '@/utils/errors'

import { getEventLogDate } from './Accounts.utils'

type AccountShortData = { id: number; name: string }
type AccountShortDataWithPayload = AccountShortData & {
  data: { status: 'active' }
}

const t = useComponentI18n('accountsAndDepartments')
const tToasts = useComponentI18n('accountsAndDepartments.toasts.accounts')
const tModal = useComponentI18n('accountsAndDepartments.modal')

const isDeleteConfirmationOpen = ref(false)
const accountShortDataToDelete = ref<AccountShortData | undefined>(undefined)

const router = useRouter()

const accountsListQueryKey = 'accountsList'

const { profile } = useProfile()
const departmentId = profile?.value?.departmentId

const {
  isLoading: isAccountsListLoading,
  isFetching: isAccountsListFetching,
  isSuccess: isAccountsListSuccess,
  data: accountsListData,
  error: accountsListError,
} = useQuery(accountsListQueryKey, apiClient.accounts.list_GET)

// Субучетка не должна видеть сама скбя в списке:
const tableData = computed(() => {
  let data = accountsListData.value?.data.list ?? []
  if (data.length > 0 && departmentId) {
    data = data.filter((item) => {
      return item.id !== departmentId
    })
  }
  return data
})

const {
  isLoading: isDepartmentsListLoading,
  isSuccess: isDepartmentsListSuccess,
  data: departmentsListData,
} = useQuery('departmentsList', apiClient.departments.list_GET)

const departmentsData = computed(
  () => departmentsListData.value?.data.list ?? []
)

const showTabs = computed(
  () => isDepartmentsListSuccess.value && departmentsData.value.length > 0
)

const showTable = computed(
  () =>
    isAccountsListSuccess.value &&
    tableData.value.length > 0 &&
    !isDepartmentsListLoading.value
)

const showNoResultsFound = computed(
  () =>
    isAccountsListSuccess.value &&
    tableData.value.length === 0 &&
    !isDepartmentsListLoading.value
)

const queryClient = useQueryClient()

const { mutate: deleteAccount, isLoading: isDeleteAccountLoading } =
  useMutation(
    async ({ id }: AccountShortData) => {
      await apiClient.accounts.accounts_DELETE(id)
    },
    {
      onSuccess: (_, { name }) => {
        void queryClient.invalidateQueries(accountsListQueryKey)
        toaster.success(tToasts('successDelete', { name }))
      },
      onError: (error) => {
        toaster.error(getErrorMessage(error))
      },
    }
  )

const openDeleteConfirmation = (data: AccountShortData) => {
  isDeleteConfirmationOpen.value = true
  accountShortDataToDelete.value = data
}

const handleDelete = () => {
  if (accountShortDataToDelete.value) {
    deleteAccount(accountShortDataToDelete.value)

    isDeleteConfirmationOpen.value = false
    accountShortDataToDelete.value = undefined
  }
}

const { mutate: editAccount, isLoading: isEditAccountLoading } = useMutation(
  async ({ id, data }: AccountShortDataWithPayload) => {
    await apiClient.accounts.accounts_PUT(id, data)
  },
  {
    onSuccess: (_, { name }) => {
      void queryClient.invalidateQueries(accountsListQueryKey)
      toaster.success(tToasts('successUnblock', { name }))
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)

const handleUnblock = ({ id, name }: AccountShortData) => {
  editAccount({ id, name, data: { status: 'active' } })
}

const showLoaderAboveTable = computed(
  () =>
    isAccountsListFetching.value ||
    isDeleteAccountLoading.value ||
    isEditAccountLoading.value
)

const handleEdit = (id: number) => {
  void router.push({
    name: RouteNames.AccountSettings,
    params: { accountId: id },
  })
}

const handleOpenEventLog = (id: number) => {
  void router.push({
    name: RouteNames.EventLogs,
    query: { user: id, date: getEventLogDate() },
  })
}
</script>

<style module>
.header {
  display: flex;
  align-items: baseline;
  margin-bottom: var(--gap-24);

  column-gap: var(--gap-32);
}

.tabs {
  align-self: flex-start;
  margin-bottom: var(--gap-24);
}

.description {
  margin-bottom: var(--gap-24);
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
