import './style.css'
import './vars.css'
import '@smst/ui/lib/style.css'

import { createHead } from '@vueuse/head'
import { createApp } from 'vue'
import type { VueQueryPluginOptions } from 'vue-query'
import { VueQueryPlugin } from 'vue-query'
import VueAnalytics from 'vue3-analytics'
import VueYandexMetrika from 'vue3-yandex-metrika'

import App from './App.vue'
import { i18n } from './i18n'
import { router } from './router'
// import { SentryInit } from './utils/sentry/sentry.config'

const app = createApp(App)
const head = createHead()

// SentryInit(app)

app.use(i18n)
app.use(router)
app.use(head)

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  },
}

app.use(VueAnalytics, {
  id: 'UA-81508828-2',
  router,
  checkDuplicatedScript: true,
})
if (!import.meta.env.DEV) {
  app.use(VueYandexMetrika, {
    id: 38746440,
    router,
    env: 'production',
    // other options
  })
}

app.use(VueQueryPlugin, vueQueryPluginOptions)

app.mount('#app')
