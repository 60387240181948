import { computed } from 'vue'

import { i18n } from '@/i18n'

const { t } = i18n.global

/**
 * Creates a computed function that returns the translation for the given key.
 * If no translation is found, it returns the key itself.
 * @param {string} mainKey - The key prefix. Usually the name of the component.
 * @returns {function} - The computed function.
 */

export const initLocale = (mainKey = '') => {
  let dot = '.'
  if (!mainKey) dot = ''
  return (key: string, options = {}) => {
    return computed(() => {
      const fullKey = `${mainKey}${dot}${key}`
      const value = t(fullKey, options)
      if (value === fullKey) {
        console.warn(`No translation found for key: ${fullKey}`)
        return key
      }
      return value
    })
  }
}
