<template>
  <UILink
    v-if="isExternal && typeof to === 'string'"
    v-bind="uiProps"
    target="_blank"
    rel="noopener noreferrer"
    :href="to"
  >
    <slot />
  </UILink>
  <router-link v-else v-slot="router" :to="to" custom>
    <UILink v-bind="uiProps" :href="router.href" @click="router.navigate">
      <slot />
    </UILink>
  </router-link>
</template>

<script setup lang="ts">
import { linkProps, Link as UILink } from '@smst/ui'
import { omit } from 'remeda'
import { computed, useAttrs } from 'vue'
import type { PropType } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

const attrs = useAttrs()

const props = defineProps({
  ...omit(linkProps, ['href']),
  to: {
    type: [Object, String] as PropType<RouteLocationRaw | string>,
    required: true,
  },
})

const uiProps = computed(() => {
  return { ...omit(props, ['to']), ...attrs }
})

const testExternal = /^(?<external>mailto:|https?:|tel:)/

const isExternal = computed(
  () => typeof props.to === 'string' && testExternal.test(props.to)
)
</script>
