<template>
  <Text :color="isSuccess ? 'success' : 'primary'" weight="bold">
    {{ t(status) }}
  </Text>
</template>

<script setup lang="ts">
import { Text } from '@smst/ui'
import type { PropType } from 'vue'

import { OriginatorStatus } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'

const t = useComponentI18n('originators.status')

const props = defineProps({
  status: {
    type: String as PropType<OriginatorStatus>,
    required: true,
  },
})

const isSuccess = props.status === OriginatorStatus.Approved
</script>
