<template>
  <div
    data-testid="time_picker"
    :class="[$style.timepicker, disabled && $style.disabled]"
  >
    <div :class="$style.input" @click="openSelect">
      <Text :class="$style.label">{{ label }} </Text>
      <Text data-testid="time_picker__text">{{ time }}</Text>
    </div>
    <div v-show="selectActive" :class="$style.selectInner">
      <FormSelectNormal
        data-testid="time_picker__hours"
        :name="timeOfOurs"
        :label="t('hours')"
        :placeholder="t('hours')"
        :searchable="false"
        :canDeselect="false"
        :disabled="disabled"
        :options="ours ?? []"
        :class="$style.timeSelect"
        :locale="{
          noOptionsText: $t('timezone.timezone.noOptionsText'),
          noResultsText: $t('form.multiselect.noResultsText'),
        }"
      />
      <FormSelectNormal
        data-testid="time_picker__minutes"
        :name="timeOfMinutes"
        :label="t('minutes')"
        :placeholder="t('minutes')"
        :searchable="false"
        :canDeselect="false"
        :disabled="disabled"
        :options="minutes ?? []"
        :class="$style.timeSelect"
        :locale="{
          noOptionsText: $t('timezone.timezone.noOptionsText'),
          noResultsText: $t('form.multiselect.noResultsText'),
        }"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import { useField } from 'vee-validate'
import type { PropType } from 'vue'
import { computed, onMounted, ref, watch } from 'vue'

import FormSelectNormal from '@/components/FormSelectNormal/FormSelectNormal.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { globalTime } from '@/utils/localTimeFromServer/globalLocalTime'
import { minutes, ours } from '@/utils/timeData.ts'

const selectActive = ref(false)

const t = useComponentI18n('dateTimeForm')

const openSelect = () => {
  selectActive.value = !selectActive.value
}

const localUserDateTime = computed(() => globalTime.getLocalTimeDateObject())

type UserDate =
  | {
      hours: string
      minutes: string
    }
  | undefined

const props = defineProps({
  name: { type: String, required: true },
  label: {
    type: String,
    required: true,
  },
  placeholder: String,
  defaultUserTime: {
    type: Object as PropType<UserDate>,
  },
  invalid: Boolean,
  disabled: Boolean,
})

onMounted(() => {
  timePickerReset()
})
watch(
  () => props.disabled,
  () => {
    timePickerReset()
  }
)

const timePickerReset = () => {
  handleHours(initialHours.value)
  handleMinutes(initialMinutes.value)
}
const timeOfOurs = `${props.name}HoursPicker`
const timeOfMinutes = `${props.name}MinutesPicker`
// const initialValueTime = `${localUserDateTime.value.hours}:${localUserDateTime.value.minutes}:00`

const initialTime = computed(() => {
  return `${initialHours.value}:${initialMinutes.value}:00`
})
const initialHours = computed(() => {
  if (props.defaultUserTime?.hours) {
    return props.defaultUserTime.hours
  }
  return localUserDateTime.value.hours
})

const initialMinutes = computed(() => {
  if (props.defaultUserTime?.minutes) {
    return props.defaultUserTime.minutes
  }
  return localUserDateTime.value.minutes
})

const { handleChange: handleTime, value: time } = useField<string>(
  props.name + 'TimePicker',
  {},
  {
    initialValue: initialTime.value,
  }
)

const { value: Hours, handleChange: handleHours } = useField<string>(
  timeOfOurs,
  {},
  {
    initialValue: initialHours.value,
  }
)
const { value: Minutes, handleChange: handleMinutes } = useField<string>(
  timeOfMinutes,
  {},
  {
    initialValue: initialMinutes.value,
  }
)
watch(
  () => [Hours.value, Minutes.value],
  (newValue, old) => {
    if (newValue[0] === undefined) timePickerReset()
    const hours = newValue[0] ?? old[0]
    const minute = newValue[1] ?? old[1]
    const hoursMinutes = `${hours}:${minute}:00`
    handleTime(hoursMinutes)
  }
)
</script>

<style module>
.timepicker {
  /* --z-select: 1;
  --z-input: 2;
  --timepicker-height: 54px;
  --gap-input: 14px; */

  position: relative;
}

.input:hover {
  box-shadow: 1px 1px 4px #e3e2e2;
}

.disabled .input {
  color: rgb(var(--color-text-dark) 0.8);

  background-color: #eaeaea;
  cursor: inherit;
}

.disabled .input span {
  color: #808080;
}

.label {
  color: var(--color-text-grey);
  font-size: 12px;
}

.select {
  /* z-index: var(--z-select);
    position: relative; */
  height: var(--timepicker-height);
}

.timeSelect:first-child {
  margin-right: 2px;
}

.timeSelect {
  width: 100%;
  margin-right: 2px;
}

.input {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 6px 12px 12px;

  /* padding-top: 0px;
  padding-bottom: 0px; */
  font-size: var(--font-size-m);
  font-family: var(--font-family);

  background-color: var(--color-bg-white);
  border: 1px solid var(--color-stroke-form);
  border-radius: var(--border-radius-s);
  cursor: pointer;

  transition:
    box-shadow var(--fast-transition),
    padding var(--fast-transition);
}

.selectInner {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
</style>
