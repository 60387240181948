<template>
  <div :class="[$style.root, { [$style.isCollapsed]: isCollapsed }]">
    <div :class="[$style.panel]">
      <ButtonIcon view="link" :title="resizeButtonText" @click="toggle">
        <IconResize />
      </ButtonIcon>
      <div :class="$style.additional">
        <slot></slot>
      </div>
      <Pagination
        v-model="pagination"
        :class="$style.pagination"
        :pageSizes="pageSizes"
        :locale="locale"
        :totalItems="totalItems"
        selectOpenDirection="top"
        :totalHide="totalHide"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PaginationTypes } from '@smst/ui'
import { ButtonIcon, IconResize, Pagination } from '@smst/ui'
import { computed } from 'vue'
import type { PropType } from 'vue'

import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useSidebar } from '@/layouts/Main/components/Sidebar/Sidebar.utils'
import { defaultPageSizes } from '@/utils/pagination'

const { toggle, isCollapsed } = useSidebar()
const t = useComponentI18n('pagination')

const props = defineProps({
  modelValue: {
    type: Object as PropType<PaginationTypes.Model>,
    required: true,
  },
  pageSizes: {
    type: Array as PropType<number[]>,
    default: () => defaultPageSizes,
  },
  totalItems: {
    type: Number,
    required: true,
  },
  totalHide: {
    required: false,
    type: Boolean as PropType<boolean>,
  },
})

const emit = defineEmits(['update:modelValue'])

const locale = computed(() => ({
  nextButton: t('nextButton'),
  prevButton: t('prevButton'),
  jump: t('jump'),
  pageSize: t('pageSize'),
  totalItems: t('totalItems', props.totalItems),
}))

const resizeButtonText = computed(() =>
  isCollapsed.value ? t('expand') : t('collapse')
)

const pagination = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  },
})
</script>

<style module>
.root {
  position: relative;

  margin-top: var(--gap-68);

  &.isCollapsed .panel {
    left: var(--width-sidebar-collapsed);

    width: calc(100% - var(--width-sidebar-collapsed));
  }
}

.panel {
  position: fixed;
  bottom: 0;
  left: var(--width-sidebar);
  z-index: 10;

  display: flex;
  align-items: center;

  width: calc(100% - var(--width-sidebar));

  padding: var(--gap-16) var(--gap-20);

  background-color: var(--color-bg-white);

  border-top: 1px solid var(--color-text-menu-grey);
}

.pagination {
  margin-left: auto;
}

.additional {
  margin-left: var(--gap-20);
}
</style>
