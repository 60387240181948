<template>
  <div>
    <HeaderButton :title="t('openMenu')" @click="isDrawerOpen = true">
      <IconUser />
    </HeaderButton>

    <Drawer v-model="isDrawerOpen" userMenu :closeText="t('closeMenu')">
      <div :class="$style.wrap">
        <ProfileCard :class="$style.profileCard" :name="profile?.fullLogin" />

        <ProfileMenu :items="menuItems" />
      </div>
      <ProfileContacts />

      <Modal
        v-if="isOpenLogoutConfirmModal"
        v-model="isOpenLogoutConfirmModal"
        :title="t('logout.confirmTitle')"
        :acceptButton="t('logout.accept')"
        :cancelButton="t('logout.cancel')"
        @accept="onLogout"
      />
    </Drawer>
  </div>
</template>

<script setup lang="ts">
import {
  Drawer,
  IconAccounts,
  IconApi,
  IconEventLog,
  IconExit,
  IconSenderList,
  IconUser,
  Modal,
} from '@smst/ui'
import { isDefined } from 'remeda'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useId } from '@/hooks/useId'
import { usePermissions } from '@/hooks/usePermissions'
import { useProfile } from '@/hooks/useProfile'
import { RouteNames } from '@/routeNames'
import { checkRitoricaDomain, checkStMediaDomain } from '@/utils/getDomain'

import HeaderButton from '../HeaderButton/HeaderButton.vue'

import ProfileCard from './components/ProfileCard/ProfileCard.vue'
import ProfileContacts from './components/ProfileContacts/ProfileContacts.vue'
import type { MenuItem } from './components/ProfileMenu/ProfileMenu.types'
import ProfileMenu from './components/ProfileMenu/ProfileMenu.vue'
import {
  isDrawerOpen,
  isOpenLogoutConfirmModal,
  onLogout,
} from './ProfileSIdebar.utils'

const { profile, profileType } = useProfile()
const permissions = usePermissions()
const router = useRouter()

const t = useComponentI18n('profileMenu')

const showDepartments = computed(() => {
  return (
    permissions.value.accountsAndDepartments.canView &&
    profile.value?.hasDepartments &&
    !profile.value.selfRegistered
  )
})

const menuItems = computed<MenuItem[]>(() => {
  return [
    profile.value?.showOriginators
      ? {
          id: useId(),
          type: 'link',
          to: { name: RouteNames.Originators },
          icon: IconSenderList,
          title: t('senderNames'),
        }
      : undefined,
    showDepartments.value
      ? {
          id: useId(),
          type: 'link',
          to: { name: RouteNames.Accounts },
          icon: IconAccounts,
          title: t('accounts'),
        }
      : undefined,
    checkStMediaDomain() || checkRitoricaDomain()
      ? undefined
      : {
          id: useId(),
          type: 'link',
          to: { name: RouteNames.TechDocs },
          icon: IconApi,
          title: t('api'),
        },
    permissions.value.eventsLog.canView || profileType === 'selfRegistered'
      ? {
          id: useId(),
          type: 'link',
          to: { name: RouteNames.EventLogs },
          icon: IconEventLog,
          title: t('eventsLog'),
        }
      : undefined,
    {
      id: useId(),
      type: 'button',
      onClick: () => {
        isOpenLogoutConfirmModal.value = true
      },
      icon: IconExit,
      title: t('logout.buttonTitle'),
    },
  ].filter(isDefined) as MenuItem[]
})

router.afterEach(() => {
  if (isDrawerOpen.value) {
    isDrawerOpen.value = false
  }
})
</script>

<style module>
.profileCard {
  margin-bottom: var(--gap-32);
}

.wrap {
  padding: var(--gap-20);
  padding-bottom: var(--gap-40);
}
</style>
