<template>
  <IssueBill :onSuccess="handleSuccess" />
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'

import IssueBill from '@/components/IssueBill/IssueBill.vue'
import { RouteNames } from '@/routeNames'

const router = useRouter()

const handleSuccess = () => {
  void router.push({
    name: RouteNames.Accounting,
  })
}
</script>
