<template>
  <div v-if="responseData">
    <div :class="$style.rowItem">
      <div>{{ errorTable('added') }} : {{ responseData.successRows }}</div>
      <div>{{ errorTable('errors') }} : {{ responseData.errorRows }}</div>
      <div>{{ errorTable('allRows') }} : {{ responseData.rowsProcessed }}</div>
      <div>{{ errorTable('size') }} : {{ responseData.sizeKb }}</div>
    </div>
    <div v-if="responseData.errorRows" :class="$style.headerTable">
      <span>{{ errorTable('tableErrorHead') }}</span>
    </div>
    <div v-if="responseData.errorRows" :class="$style.table">
      <Table :data="responseData.errors" :columns="errorColumns" rowKey="id">
        <template #column:rowNumber="{ item }">
          {{ item.rowNumber }}
        </template>
        <template #column:row="{ item }">
          {{ item.row }}
        </template>
        <template #column:errorNumber="{ item }">
          {{ errorCodes(item.errorNumber) }}
        </template>
      </Table>
    </div>
    <span
      v-if="responseData?.fileNameErrors && responseData?.fileNameErrors.length"
      :class="$style.errorDownload"
      @click="downloadErrors"
      >{{ errorTable('downloadAllErrors', responseData.errorRows) }}</span
    >
  </div>
</template>

<script lang="ts" setup>
import { Table } from '@smst/ui'
import { computed, type PropType } from 'vue'

import type { FileSubscribersUploadResult } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'

import { initDownloadErrorsPhones } from './downloadErrorsPhones.utils'

const errorCodes = useComponentI18n(
  'batchCreate.form.phonesSource.errorTable.errorCodes'
)
const errorTable = useComponentI18n('batchCreate.form.phonesSource.errorTable')

const props = defineProps({
  responseData: {
    type: Object as PropType<FileSubscribersUploadResult> | undefined,
    required: true,
  },
})
const downloadErrorsPhones = initDownloadErrorsPhones()

const downloadErrors = () => {
  if (!props?.responseData?.fileNameErrors) return
  downloadErrorsPhones(props?.responseData?.fileNameErrors)
}

const errorColumns = computed(() => {
  return [
    { name: errorTable('rowNumber'), prop: 'rowNumber' },
    { name: errorTable('row'), prop: 'row' },
    { name: errorTable('errorNumber'), prop: 'errorNumber' },
  ]
})
</script>

<style module>
.rowItem {
  width: 200px;
  margin-top: 12px;
  padding: var(--gap-20);

  font-size: var(--font-size-m);

  line-height: var(--line-height-m);
  text-align: left;

  background: var(--color-stroke-blocks);
  border: 1px solid var(--color-stroke-blocks);
  border-radius: var(--border-radius-m);
}

.headerTable {
  display: flex;
  justify-content: center;

  margin-top: 12px;
  margin-bottom: 4px;

  font-weight: 600;
  text-align: center;
}

.errorDownload {
  display: flex;
  justify-content: center;

  margin-top: 16px;
  margin-bottom: 16px;

  font-weight: 600;
  text-align: center;

  cursor: pointer;
}

.table {
  display: block;
  max-height: 300px;
  overflow: hidden scroll;
}

.loader {
  display: flex;
  gap: var(--gap-8);
  margin-top: var(--gap-16);
}

.error {
  display: inline-flex;
  margin-top: var(--gap-8);
}
</style>
