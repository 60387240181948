<template>
  <form @submit="onSubmit">
    <Grid>
      <Row>
        <Card :title="t('title')">
          <div :class="$style.content">
            <FormTextarea
              name="phones"
              :description="t('tip')"
              :placeholder="t('placeholder')"
            />
            <Button
              type="submit"
              view="secondary"
              fullWidth
              :disabled="isDisabled"
              :loading="isLoading"
            >
              {{ t('submit') }}
            </Button>
          </div>
        </Card>
      </Row>
    </Grid>
  </form>
</template>

<script setup lang="ts">
import { Button, toaster } from '@smst/ui'
import { isDefined } from 'remeda'
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import type { SelectedElements } from '@/api/__generated__/api.schema'
import Card from '@/components/Card/Card.vue'
import FormTextarea from '@/components/FormTextarea/FormTextarea.vue'
import { Grid, Row } from '@/components/Grid'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'
import { getPhones } from '@/utils/members'

type FormValues = {
  phones: string
}

type Values = {
  selectedElements: SelectedElements
}

const t = useComponentI18n('group.deleteMembersForm')

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
})

const { handleSubmit, resetForm, meta } = useForm<FormValues>()

const isDisabled = computed(() => !meta.value.dirty)

const { mutate: deleteMembers, isLoading } = useMutation(
  (values: Values) =>
    apiClient.group.membersDeleteList_POST(Number(props.groupId), values),
  {
    onSuccess: (data) => {
      const count = data.data.affectedRows
      if (isDefined(count)) {
        toaster.success(t('success', { count }))
        resetForm()
      }
    },
    onError: (e) => {
      toaster.error(getErrorMessage(e))
    },
  }
)

const onSubmit = handleSubmit((values) => {
  const phones = getPhones(values.phones)
  deleteMembers({
    selectedElements: { all: false, exclude: [], include: phones },
  })
})
</script>

<style module>
.content {
  display: flex;
  flex-direction: column;

  gap: var(--gap-20);
}
</style>
