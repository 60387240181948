import type { MoNumberOptions } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'
import { initLocale } from '@/utils/reactiveLocale'
import { basicEntityNameLength } from '@/utils/validation'
import yup from '@/utils/yup'

const { t } = i18n.global
const formKey = 'settings.general.moNumbers.editForm'

const locale = initLocale()

export const editMoNumberSchema: yup.SchemaOf<MoNumberOptions> = yup.object({
  groupId: yup.number().required(),
  departmentId: yup.number().required(),
  type: yup.mixed<MoNumberOptions['type']>().required(),
  email: yup.string().when('type', {
    is: 'email',
    then: yup
      .string()
      .email(locale('validation.email'))
      .required(locale('validation.required', { name: t(`${formKey}.email`) })),
  }),
  http: yup.mixed<MoNumberOptions['http']>().when('type', {
    is: 'http',
    then: yup.object({
      method: yup
        .string()
        .required(
          t('validation.required', { name: t(`${formKey}.httpMethod.label`) })
        ),
      script: yup
        .string()
        .min(10, t('validation.string.min', { number: 10 }))
        .max(
          basicEntityNameLength.max,
          t('validation.string.max', { number: basicEntityNameLength.max })
        )
        .required(
          t('validation.required', { name: t(`${formKey}.scriptUrl`) })
        ),
    }),
  }),
})
