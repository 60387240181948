import { i18n } from '@/i18n'
import yup from '@/utils/yup'

const { t } = i18n.global

const MAX_AMOUNT = 999999999

export type IssueBillForm = {
  amount: string
}

export const getIssueBillFormSchema = (
  amount: number,
  currency: string
): yup.SchemaOf<IssueBillForm> =>
  yup.object({
    amount: yup
      .string()
      .matches(/^\d+$/, {
        message: t('validation.number', {
          name: t('issueBill.amount.label'),
        }),
        excludeEmptyString: true,
      })
      .test(
        'min amount',
        t('issueBill.validate.amount', {
          amount,
          currency,
        }),
        (val) => Number(val) >= amount
      )
      .test(
        'max amount',
        t('validation.value.max', { number: MAX_AMOUNT }),
        (val) => Number(val) <= MAX_AMOUNT
      )
      .required(
        t('validation.required', {
          name: t('issueBill.amount.label'),
        })
      ),
  })
