import { createI18n } from 'vue-i18n'

import { messages } from './locales'

enum PluralResult {
  NO = 0,
  ONE = 1,
  TWO = 2,
  MANY = 3,
}

export const pluralizeRu = (choice: number): PluralResult => {
  if (choice === 0) {
    return PluralResult.NO
  }

  const isTeen = choice % 100 > 10 && choice % 100 < 20
  const endsWithOne = choice % 10 === 1

  if (isTeen) {
    return PluralResult.MANY
  }

  if (endsWithOne) {
    return PluralResult.ONE
  }

  if (choice % 10 >= 2 && choice % 10 <= 4) {
    return PluralResult.TWO
  }

  return PluralResult.MANY
}

export const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'ru',
  messages,
  pluralRules: {
    ru: pluralizeRu,
  },
})
