import { i18n } from '@/i18n'

const { t } = i18n.global

export const OriginatorStatuses = () => {
  return [
    {
      value: 'New',
      label: t(`originators.status.New`),
    },
    {
      value: 'WaitConfirm',
      label: t(`originators.status.WaitConfirm`),
    },
    {
      value: 'Approved',
      label: t(`originators.status.Approved`),
    },
    {
      value: 'WaitDeleted',
      label: t(`originators.status.WaitDeleted`),
    },
    {
      value: 'ToDelete',
      label: t(`originators.status.ToDelete`),
    },
    {
      value: 'Deleted',
      label: t(`originators.status.Deleted`),
    },
    {
      value: 'Failed',
      label: t(`originators.status.Failed`),
    },
  ]
}
