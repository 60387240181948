<template>
  <AccountPrivacyForm
    v-if="showForm && privacyData"
    :departmentId="departmentId"
    :accountData="accountData"
    :privacyData="privacyData"
    :accountsList="accountsList"
    :departmentsList="departmentsList"
    :isLoading="isEditAccountLoading || isAccountPrivacyFetching"
    :error="editError"
    @submit="onSubmit"
  />

  <div v-else :class="$style.container">
    <Loader v-if="showLoader" size="m" />

    <ApiErrorMessage
      :error="accountPrivacyError || accountsListError || departmentsListError"
    />
  </div>
</template>

<script lang="ts" setup>
import { Loader, toaster } from '@smst/ui'
import { computed } from 'vue'
import type { PropType } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import type {
  AccountFullResult,
  DepUserFullResult,
} from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'

import type { FormValues } from './AccountPrivacy.utils'
import AccountPrivacyForm from './AccountPrivacyForm.vue'

const props = defineProps({
  departmentId: {
    type: String,
    required: false,
  },
  accountData: {
    type: Object as PropType<AccountFullResult | DepUserFullResult>,
    required: true,
  },
})

const isDepUser = computed(() => Boolean(props.departmentId))

const query = computed(() =>
  isDepUser.value
    ? {
        get: {
          key: ['depUserPrivacy', props.departmentId, props.accountData.id],
          fn: () =>
            apiClient.departments.usersPrivacy_GET_BY(
              Number(props.departmentId),
              props.accountData.id
            ),
        },
        put: {
          fn: async (data: FormValues) =>
            await apiClient.departments.usersPrivacy_PUT(
              Number(props.departmentId),
              props.accountData.id,
              data
            ),
        },
      }
    : {
        get: {
          key: ['accountPrivacy', props.accountData.id],
          fn: () => apiClient.accounts.privacy_GET_BY(props.accountData.id),
        },
        put: {
          fn: async (data: FormValues) =>
            await apiClient.accounts.privacy_PUT(props.accountData.id, data),
        },
      }
)

const {
  isLoading: isAccountPrivacyLoading,
  isSuccess: isAccountPrivacySuccess,
  isFetching: isAccountPrivacyFetching,
  data: accountPrivacyData,
  error: accountPrivacyError,
} = useQuery(query.value.get.key, query.value.get.fn)

const privacyData = computed(() => accountPrivacyData.value?.data.data)

const {
  isLoading: isAccountsListLoading,
  isSuccess: isAccountsListSuccess,
  data: accountsListData,
  error: accountsListError,
} = useQuery('accountsList', apiClient.accounts.list_GET)

const accountsList = computed(() => accountsListData.value?.data.list ?? [])

const {
  isLoading: isDepartmentsListLoading,
  isSuccess: isDepartmentsListSuccess,
  data: departmentsListData,
  error: departmentsListError,
} = useQuery('departmentsList', apiClient.departments.list_GET)

const departmentsList = computed(
  () => departmentsListData.value?.data.list ?? []
)

const showForm = computed(
  () =>
    isAccountPrivacySuccess.value &&
    isAccountsListSuccess.value &&
    isDepartmentsListSuccess.value
)

const showLoader = computed(
  () =>
    isAccountPrivacyLoading.value ||
    isAccountsListLoading.value ||
    isDepartmentsListLoading.value
)

const t = useComponentI18n('editAccount')

const queryClient = useQueryClient()

const {
  isLoading: isEditAccountLoading,
  error: editError,
  mutate: editAccount,
} = useMutation(query.value.put.fn, {
  onSuccess: () => {
    toaster.success(t('successEdit'))

    void queryClient.invalidateQueries(query.value.get.key)
  },
  onError: (error) => {
    toaster.error(getErrorMessage(error))
  },
})

const onSubmit = (values: FormValues) => {
  editAccount(values)
}
</script>

<style module>
.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
