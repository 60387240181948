import { toaster } from '@smst/ui'
import { ref } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import { downloadFile } from '@/utils/downloadFile'
import { getErrorMessage } from '@/utils/errors'

import { idReport } from './TableReport.utils'

const ext = ref('pdf')

export const initDownloadReport = () => {
  const { mutate: downloadDocument } = useMutation(
    async (data: { reportId: number; fileId: number; fileExt: string }) => {
      ext.value = data.fileExt
      return await apiClient.reportsOnRequest.reportsOnRequest_GET_BY(
        data.reportId,
        data.fileId,
        {
          format: 'blob',
        })
    },
    {
      onSuccess: (response) => {
        void downloadFile({
          response,
          fileName: `Report-on-Request-sms-traffic-${idReport.value}`,
          extension: ext.value,
        })
      },

      onError: (error = '') => {
        toaster.error(getErrorMessage(error))
      },
    }
  )
  return downloadDocument
}
