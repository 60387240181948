import {
  compareAsc,
  endOfMonth,
  format,
  isAfter,
  isEqual,
  parseISO,
  startOfMonth,
  subMonths,
} from 'date-fns'
import type { Locale } from 'date-fns'
import { enUS, ru } from 'date-fns/locale'
import { computed } from 'vue'

import type { ClientDocument } from '@/api/__generated__/api.schema'
import { DocumentStatus } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

const localization: Record<string, Locale> = {
  ru,
  en: enUS,
}

const { locale } = i18n.global

const dayFormat = 'd MMMM'
const monthFormat = 'LLLL'

const dateFormat = (date: Date, formatDate: string = dayFormat) =>
  format(date, formatDate, { locale: localization[locale.value] })

const toDate = new Date()
const subMonthsDate = subMonths(toDate, 1)

export const fromDayThisMonth = computed(() => dateFormat(startOfMonth(toDate)))
export const toDayThisMonth = computed(() => dateFormat(toDate))

export const fromDayLastMonth = computed(() =>
  dateFormat(startOfMonth(subMonthsDate))
)
export const toDayLastMonth = computed(() =>
  dateFormat(endOfMonth(subMonthsDate))
)

export type DocumentSum = {
  month: string
  sum: number
  currency: string
  status: DocumentStatus
}

// Нужно взять последний месяц, в котором есть ненулевые документы.
// Если в последний месяц есть неоплаченные, то показываем сумму всех неоплаченных.
// Если за последний месяц все оплачены - показываем сумму всех оплаченных за этот месяц.

export const getDocumentSummary = (
  lastUnpaidDocuments?: ClientDocument[]
): DocumentSum | undefined => {
  if (!lastUnpaidDocuments || lastUnpaidDocuments.length === 0) return

  // Определяем все ненулевые документы и сортируем по дате
  const documentsNotNull = lastUnpaidDocuments
    .filter((document) => Number(document.sumSNds) > 0)
    .sort((a, b) => compareAsc(parseISO(b.enterDate), parseISO(a.enterDate)))

  if (documentsNotNull.length === 0) return

  // Определяем начало месяца с ненулевым документом
  const dateEndWithoutNullDocuments = startOfMonth(
    parseISO(documentsNotNull[0].enterDate)
  )

  // Определяем все ненулевые документы за последний месяц
  const documentsNotNullOnMonth = documentsNotNull.filter((document) => {
    const date = parseISO(document.enterDate)

    return (
      isAfter(date, dateEndWithoutNullDocuments) ||
      isEqual(date, dateEndWithoutNullDocuments)
    )
  })

  // Определяем валюту, одинаковая для всех документов
  const currency = documentsNotNullOnMonth[0].currency

  // Если в последний месяц есть неоплаченные, показываем сумму всех неоплаченных
  const isNotPaid = documentsNotNullOnMonth.some(
    (document) => document.documentStatus === DocumentStatus.NotPaid
  )

  if (isNotPaid) {
    // Определяем все не оплаченные документы
    const documentNotPaid = documentsNotNullOnMonth.filter(
      (document) => document.documentStatus === DocumentStatus.NotPaid
    )
    // Вычисляем сумму не оплаченных документов
    const sumNotPaid = documentNotPaid.reduce((acc, doc) => {
      return acc + Number(doc.sumSNds)
    }, 0)

    return {
      sum: sumNotPaid,
      currency,
      month: dateFormat(dateEndWithoutNullDocuments, monthFormat),
      status: DocumentStatus.NotPaid,
    }
  }

  // Иначе считаем что все счета оплачены и показываем сумму всех оплаченных за этот месяц
  const sumPaid = documentsNotNullOnMonth.reduce((acc, doc) => {
    return acc + Number(doc.sumSNds)
  }, 0)

  return {
    sum: sumPaid,
    currency,
    month: dateFormat(dateEndWithoutNullDocuments, monthFormat),
    status: DocumentStatus.Paid,
  }
}
