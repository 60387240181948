<template>
  <div v-if="hasSlot()" :style="getStyleObject()">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { PropType, StyleValue } from 'vue'
import { useSlots } from 'vue'

const props = defineProps({
  column: Object as PropType<{
    start: number
    end: number
  }>,
  fullWidth: Boolean,
})

const getStyleObject = (): StyleValue | undefined => {
  if (props.fullWidth) {
    return {
      gridColumnStart: 1,
      gridColumnEnd: 5,
    }
  }

  return {
    gridColumnStart: props.column?.start,
    gridColumnEnd: props.column?.end,
  }
}

const slots = useSlots()

const hasSlot = () => slots.default
</script>
