<template>
  <FormControlBase
    v-slot="{ invalid }"
    :descriptions="descriptions"
    :name="name"
    noTouched
  >
    <Datepicker
      :locale="locale"
      :modelValue="value"
      :range="range"
      :label="label"
      :placeholder="placeholder"
      :invalid="invalid"
      :disabled="disabled"
      :minDate="dateMin"
      :maxDate="maxDate"
      :minRange="minRange"
      :monthChangeOnScroll="false"
      @update:modelValue="handleChange"
      @blur="handleBlur"
    />
  </FormControlBase>
</template>

<script lang="ts">
import { Datepicker } from '@smst/ui'
import type { DatepickerTypes } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes } from 'vue'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formDatepickerProps = {
  ...formControlProps,
  range: Boolean,
  label: {
    type: String,
    required: true,
  },
  placeholder: String,
  isBirthday: Boolean,
  minDate: Date,
  maxDate: Date,
  minRange: Number,
  maxRange: Number,
  descriptions: String,
} as const

export type FormDatepickerProps = ExtractPropTypes<
  Omit<typeof formDatepickerProps, 'isBirthday'>
> & {
  // Хак, чтобы prop был опциональным
  readonly isBirthday?: boolean
}

export default defineComponent({
  components: { FormControlBase, Datepicker },
  props: formDatepickerProps,
  setup(props) {
    const { value, handleChange, handleBlur } = useField<DatepickerTypes.Value>(
      props.name
    )

    const { locale } = useI18n()

    // Дата основания компании устанавливается как минимальная возможная дата
    const companyFoundationDate = new Date(2000, 0)

    const getMinDate = () => {
      if (props.isBirthday) return undefined

      if (props.minDate) {
        return props.minDate
      }

      return companyFoundationDate
    }

    return {
      value,
      handleChange,
      handleBlur,
      locale,
      dateMin: getMinDate(),
    }
  },
})
</script>
