import { i18n } from '@/i18n'
import yup from '@/utils/yup'

const { t } = i18n.global

const MIN_NUMBERS = 9
const MAX_NUMBERS = 19

export const operatorByPhoneSchema: yup.SchemaOf<{ phoneNumber: string }> =
  yup.object({
    phoneNumber: yup
      .string()
      .min(MIN_NUMBERS, t('validation.string.min', { number: MIN_NUMBERS }))
      .max(MAX_NUMBERS, t('validation.string.max', { number: MAX_NUMBERS }))
      .requiredField('operatorByPhone.form.inputPhoneError'),
  })
