import type { Ref } from 'vue'
import { computed } from 'vue'

import type { GroupsResult } from '@/api/__generated__/api.schema'

export const useGroupsTable = (data: Ref<GroupsResult | undefined>) => {
  const tableData = computed(() => data.value?.list ?? [])
  const totalItems = computed(() => data.value?.pageInfo?.totalItems ?? 0)
  const hasTableData = computed(() => tableData.value.length > 0)
  const showEmptyResultLabel = computed(
    () => Boolean(data.value) && !hasTableData.value
  )

  return {
    tableData,
    totalItems,
    hasTableData,
    showEmptyResultLabel,
  }
}
