<template>
  <form :class="$style.root" @submit="onSubmit">
    <div :class="$style.inputWrapper">
      <FormInput name="filter" :label="locale?.label" />
    </div>
    <Button type="submit">
      {{ locale?.button }}
    </Button>
  </form>
</template>

<script setup lang="ts">
import { Button } from '@smst/ui'
import { useForm } from 'vee-validate'
import type { PropType } from 'vue'

import FormInput from '@/components/FormInput/FormInput.vue'

export type SearchValue = { filter: string }

const props = defineProps({
  locale: {
    type: Object as PropType<{
      label: string
      button: string
    }>,
    required: true,
  },

  values: {
    type: Object as PropType<SearchValue>,
    required: true,
  },
})

const { handleSubmit } = useForm<SearchValue>({
  initialValues: props.values,
})

const emit = defineEmits(['submit'])

const onSubmit = handleSubmit((v) => {
  emit('submit', v)
})
</script>

<style module>
.root {
  display: flex;
  gap: var(--gap-12);

  width: 100%;

  padding: var(--gap-20);

  background-color: var(--color-bg-white);

  border: 1px solid var(--color-stroke-blocks);
  border-radius: var(--border-radius-m);
}

.inputWrapper {
  flex-grow: 1;
}
</style>
