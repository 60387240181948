<template>
  <Filters
    v-model="filtersModel"
    :class="$style.filters"
    :controls="filtersContorls"
  />
  <template v-if="showTable">
    <LoaderWrapper :class="$style.main" :loading="isLoadingTable">
      <Dropdown
        :label="$t('actions')"
        :items="tableActions"
        :disabled="!hasSelectedElements(selectionModel)"
      />
      <Table
        v-model:modelSelection="selectionModel"
        v-model:modelSort="sortModel"
        :columns="tableColumns"
        :data="tableData"
        :rowKey="tableRowKey"
      >
        <template #column:memberName="{ item }">
          {{ item.memberName || '—' }}
        </template>
        <template #column:gender="{ item }">
          {{ $t(`gender.${item.gender}`) }}
        </template>
        <template #column:comment="{ item }">
          {{ item.comment || '—' }}
        </template>
        <template #column:birthday="{ item }">
          {{ item.birthday || '—' }}
        </template>
        <template #column:addDate="{ item }">
          <TableDate :date="item.addDate" />
        </template>
        <template #rowAction="{ item }">
          <Dropdown
            :items="getRowActions(item)"
            :iconTrigger="IconActions"
            :label="$t('actions')"
          />
        </template>
      </Table>
    </LoaderWrapper>
    <PaginationPanel v-model="paginationModel" :totalItems="totalTableItems" />
  </template>
  <div v-else :class="$style.container">
    <Loader v-if="isLoadingGroupMembers" size="m" />

    <ApiErrorMessage v-else-if="errorGroupMembers" :error="errorGroupMembers" />

    <LoaderWrapper v-else-if="!hasTableItems" :loading="isFetchingGroupMembers">
      <NoResultsFound />
    </LoaderWrapper>
  </div>
  <TransferDrawer
    :isOpen="isOpen"
    :selectedElements="selectionModel"
    :isLoading="isLoadingTransfer"
    :showList="showList"
    :locale="locale"
    @selectGroup="handleSelectGroup"
    @submit="handleTransferSubmit"
    @close="handleCloseDrawer"
  />
  <MemberEditDrawer
    :isOpen="isOpenMemberEditDrawer"
    :phone="Number(phone!)"
    :groupId="Number(groupId)"
    @close="closeMemberEditDrawer"
  />
  <Modal
    v-model="isOpenModalDeleteMembers"
    :title="modalDelete.title"
    :acceptButton="modalDelete.acceptButton"
    :cancelButton="modalDelete.cancelButton"
    @accept="modalDelete.handleDelete"
  />
</template>

<script setup lang="ts">
import {
  Dropdown,
  IconActions,
  IconCopy,
  IconDelete,
  IconEdit,
  IconMove,
  IconSendMessage,
  Loader,
  LoaderWrapper,
  Modal,
  Table,
} from '@smst/ui'
import type { TableTypes } from '@smst/ui'
import { computed, ref, toRef } from 'vue'
import { useQuery } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import type {
  GroupMember,
  SelectedElements,
} from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Filters from '@/components/Filters/Filters.vue'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import PaginationPanel from '@/components/PaginationPanel/PaginationPanel.vue'
import TableDate from '@/components/TableDate/TableDate.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useTableSelection } from '@/hooks/useTable'
import { createSortQueryConfig, useTableRequest } from '@/hooks/useTableRequest'
import { selectedElementsToQueryString } from '@/pages/BatchCreate/BatchCreate.utils'
import { RouteNames } from '@/routeNames'
import { formatDateToRequest } from '@/utils/formatDateToRequest'
import { hasSelectedElements } from '@/utils/hasSelectedElements'

import { useMemberEditDrawer } from './components/EditMember/EditMember.utils'
import MemberEditDrawer from './components/EditMember/MemberEditDrawer.vue'
import { useTransferGroup } from './components/Transfer/Transfer.utils'
import TransferDrawer from './components/Transfer/TransferDrawer.vue'
import {
  filtersConfig,
  getFiltersControls,
  MEMBERS_QUERY_KEY,
  useMembersDelete,
} from './GroupEdit.utils'

type TableColumns = Array<TableTypes.Column<GroupMember>>

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
  phone: String,
})

const transferType = ref<'copy' | 'move'>('copy')
const isOpen = ref<boolean>(false)

const t = useComponentI18n('group')

const filtersContorls = getFiltersControls()

const { selectionModel, resetSelection } = useTableSelection<number>()

const { request, paginationModel, resetPagination, sortModel, filtersModel } =
  useTableRequest({
    filtersConfig,
    sortConfig: createSortQueryConfig(['phone', 'memberName', 'addDate']),
  })

const { data: groupInfo } = useQuery(['group', toRef(props, 'groupId')])

const {
  data: groupMembersData,
  error: errorGroupMembers,
  refetch: refetchGroupMembers,
  isFetching: isFetchingGroupMembers,
  isLoading: isLoadingGroupMembers,
  isSuccess: isSuccessGroupMembers,
} = useQuery(
  [MEMBERS_QUERY_KEY, toRef(props, 'groupId'), request],
  () =>
    apiClient.group.members_GET_BY(Number(props.groupId), {
      ...request.value,
      birthday: request.value.birthday
        ? formatDateToRequest(request.value.birthday)
        : undefined,
    }),
  {
    keepPreviousData: true,
  }
)

const tableData = computed(() => groupMembersData.value?.data.list ?? [])
const totalTableItems = computed(
  () => groupMembersData.value?.data.pageInfo?.totalItems ?? 0
)
const hasTableItems = computed(() => tableData.value.length > 0)
const showTable = computed(
  () => hasTableItems.value && isSuccessGroupMembers.value
)

const {
  isOpenModal: isOpenModalDeleteMembers,
  modal: modalDelete,
  handleDeleteMembers,
  isLoadingDeleteMembers,
} = useMembersDelete({
  groupId: props.groupId,
  onSuccess: () => {
    resetPagination()
  },
})

const isLoadingTable = computed(
  () => isFetchingGroupMembers.value || isLoadingDeleteMembers.value
)

const congratulation = computed(() => groupInfo.value.data.group.congratulate)

const {
  locale,
  showList,
  handleSelectGroup,
  handleTransferSubmit,
  handleReset,
  isLoading: isLoadingTransfer,
} = useTransferGroup({
  sourceGroup: Number(props.groupId),
  selectedElements: selectionModel,
  type: transferType,
  onSuccess: () => {
    resetSelection()
    isOpen.value = false

    if (transferType.value === 'move') {
      void refetchGroupMembers.value()
    }
  },
})

const {
  isOpen: isOpenMemberEditDrawer,
  open: openMemberEditDrawer,
  close: closeMemberEditDrawer,
} = useMemberEditDrawer()

const handleCloseDrawer = () => {
  isOpen.value = false
  handleReset()
}

const tableActions = [
  {
    label: t('actions.send'),
    icon: IconSendMessage,
    onClick: () => {
      handleSendMembers(selectionModel.value)
    },
  },
  {
    label: t('actions.move'),
    icon: IconMove,
    selectText: t('actions.selectList'),
    onClick: () => {
      isOpen.value = true
      transferType.value = 'move'
    },
  },
  {
    label: t('actions.copy'),
    icon: IconCopy,
    selectText: t('actions.selectList'),
    onClick: () => {
      isOpen.value = true
      transferType.value = 'copy'
    },
  },
  {
    label: t('actions.delete'),
    icon: IconDelete,
    onClick: () => {
      handleDeleteMembers(selectionModel.value)
    },
  },
]

const getRowActions = (item: GroupMember) => {
  return [
    {
      label: t('rowActions.send'),
      icon: IconSendMessage,
      onClick: () => handleSendMember(item.phone),
    },
    {
      label: t('rowActions.edit'),
      icon: IconEdit,
      onClick: () => {
        openMemberEditDrawer(item.phone)
      },
    },
    {
      label: t('rowActions.delete'),
      icon: IconDelete,
      onClick: () => {
        handleDeleteMembers(item.phone)
      },
    },
  ]
}

const tableColumns = computed<TableColumns>(() => {
  const columns: TableColumns = [
    { name: t('table.phone'), prop: 'phone', sortable: true },
    {
      name: t('table.memberName'),
      prop: 'memberName',
      sortable: true,
      wrap: true,
    },
    { name: t('table.gender'), prop: 'gender' },
    { name: t('table.comment'), prop: 'comment', wrap: true },
    { name: t('table.addDate'), prop: 'addDate', sortable: true },
  ]
  if (congratulation.value)
    columns.splice(3, 0, { name: t('table.birthday'), prop: 'birthday' })
  return columns
})

const tableRowKey: keyof GroupMember = 'phone'

const router = useRouter()

const handleSendMembers = (selectedElements: SelectedElements) => {
  // Если пользователь выбрал опцию "все" без исключений, значит выбрал группу
  if (selectedElements.all && selectedElements.exclude.length === 0) {
    const elements = {
      all: false,
      include: [Number(props.groupId)],
      exclude: [],
    }

    sendGroups(elements)
  } else {
    sendMembers(selectedElements)
  }
}

const handleSendMember = (phone: number) => {
  const elements = {
    all: false,
    include: [phone],
    exclude: [],
  }

  sendMembers(elements)
}

const sendMembers = (selectedElements: SelectedElements) => {
  const queryString = selectedElementsToQueryString(selectedElements)

  void router.push({
    name: RouteNames.BatchCreate,
    query: { group: props.groupId, selectedPhones: queryString },
  })
}

const sendGroups = (selectedElements: SelectedElements) => {
  const queryString = selectedElementsToQueryString(selectedElements)

  void router.push({
    name: RouteNames.BatchCreate,
    query: { selectedGroups: queryString },
  })
}
</script>

<style module>
.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.main {
  display: flex;
  flex-direction: column;

  gap: var(--gap-24);
  align-items: flex-start;
  margin-top: var(--gap-16);
}

.filters {
  margin-top: var(--gap-32);
}
</style>
