<template>
  <div>
    <FormSelect
      name="passStrengthPolitics"
      :label="label"
      :placeholder="placeholder"
      :searchable="true"
      :options="StrengthOptions ?? []"
      :canDeselect="false"
    />
    <Text weight="semibold" :class="$style.shortDescription">
      {{ t(`PassStrength.${StrengthPolitic}.shortDescription`) }}
    </Text>
  </div>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import { watchEffect } from 'vue'

import FormSelect from '@/components/FormSelect/FormSelect.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

import { initPassStrengthUtils } from './PassStrengthPolitics.utils'

const {
  placeholder,
  label,
  setStrengthSelectedValue,
  StrengthOptions,
  useField,
} = initPassStrengthUtils()

const t = useComponentI18n('accountForm')

const { value: StrengthPolitic } = useField()

watchEffect(() => {
  if (StrengthPolitic.value) setStrengthSelectedValue(StrengthPolitic.value)
})
</script>

<style module>
.shortDescription {
  display: block;
  margin-top: 12px;

  font-size: 13px;
}
</style>
