import type { TableTypes } from '@smst/ui'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import type { AnalysisReportData } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

const { t } = i18n.global

const tFields = (field: string) => t(`DeliveryAnalysis.fields.${field}`)

export const columns: ComputedRef<
  Array<TableTypes.Column<AnalysisReportData>>
> = computed(() => [
  {
    name: tFields('phone'),
    prop: 'phone',
  },
  {
    name: tFields('failureDays'),
    prop: 'failureDays',
  },
  {
    name: tFields('failureMessages'),
    prop: 'failureMessages',
  },
])
