<template>
  <form @submit="onSubmit">
    <!-- meta {{ meta }} <br /><br /><br />
    values {{ values }} groupSelected {{ groupSelected }} <br /><br /><br />
    errors {{ errors }}

    groupRoutes {{ groupRoutes }} -->

    <Grid>
      <Row>
        <Card :title="t('groupName')">
          <FormInput
            name="groupName"
            :placeholder="t('groupName')"
            :maxLength="basicEntityNameLength.max"
          />
        </Card>
      </Row>
      <Row>
        <Card :title="t('additional')">
          <div :class="$style.additional">
            <FormSwitch
              name="congratulate"
              fullWidth
              :value="true"
              :label="tCongratulate('switch')"
            />

            <FormSwitch
              :disabled="!viewForAllActive"
              name="viewForAll"
              fullWidth
              :value="true"
              :label="t('viewForAll')"
            />

            <FormSwitch
              name="viewOptional"
              fullWidth
              :value="true"
              :label="t('viewOptional')"
            />
          </div>
        </Card>
      </Row>
      <Row v-if="hasCongratulate">
        <Congratulate
          v-if="isFiltersSuccess"
          :routes="groupRoutes"
          :startTime="startTime"
        />
      </Row>
    </Grid>
    <Button
      type="submit"
      :loading="isLoading"
      :disabled="isSubmitDisabled"
      :class="$style.submit"
    >
      {{ t('save') }}
    </Button>
  </form>
</template>

<script lang="ts" setup>
import { Button } from '@smst/ui'
import { useFieldValue, useForm } from 'vee-validate'
import type { PropType } from 'vue'
import { computed, onMounted, ref, watch } from 'vue'

import type { GroupResult } from '@/api/__generated__/api.schema'
// import { dictionaryDataStaleTime } from '@/api/queryConfig'
import Card from '@/components/Card/Card.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import FormSwitch from '@/components/FormSwitch/FormSwitch.vue'
import { Grid, Row } from '@/components/Grid'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { basicEntityNameLength } from '@/utils/validation'

import Congratulate from './Congratulate/Congratulate.vue'
import type { FormValues, Values } from './GroupForm.validation'
import { validationSchema } from './GroupForm.validation'

const { profile } = useProfile()

const props = defineProps({
  initialValues: Object as PropType<FormValues>,
  groupRoutes: Object,
  isLoading: Boolean,
  isFiltersSuccess: Boolean,
  groupId: String,
  routeInitialId: String,
})

const defaultRoute = { sms: ['sms'] }

const smartDelivery: boolean | undefined = profile?.value?.smartDelivery
const groupRoutes = computed(() =>
  smartDelivery ? props.groupRoutes : defaultRoute
)

const startTime = computed(() => {
  return props.initialValues?.startTime
})

const initValue = computed(() => {
  const origin = props.initialValues?.originator
  const startTimer = props.initialValues?.startTime

  if (!startTimer && props.initialValues)
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties, vue/no-mutating-props
    props.initialValues.startTime = '10:00:00'
  if (!origin && props.initialValues)
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties, vue/no-mutating-props
    props.initialValues.originator = profile.value?.defaultOriginator
  return props.initialValues
})

const emit = defineEmits(['submit'])

const t = useComponentI18n('group.groupForm')
const tCongratulate = useComponentI18n('group.groupForm.congratulate')

const { handleSubmit, meta, setFieldValue, values } = useForm<FormValues>({
  initialValues: initValue.value,
  validationSchema: validationSchema(groupRoutes.value),
})

const viewForAllActive = ref(true)

const congratulateValue = useFieldValue<boolean[]>('congratulate')

const hasCongratulate = computed<boolean>(() => {
  return congratulateValue.value[0] === true
})

const groupSelected = ref([])

watch(
  () => values.routeGroupId,
  (routeGroupId) => {
    groupSelected.value = routeGroupId ? groupRoutes?.value[routeGroupId] : []
    const group = groupSelected?.value.length ? groupSelected.value[0] : ''
    if ((group === 'sms' && hasCongratulate.value) || !hasCongratulate.value) {
      viewForAllActive.value = true
    } else {
      viewForAllActive.value = false
      setFieldValue('viewForAll', [false])
    }
  }
)

onMounted(() => {
  if (props.routeInitialId) setFieldValue('routeGroupId', props.routeInitialId)
})
const isSubmitDisabled = computed(() => !meta.value.dirty)

const getTransliterate = (transliterate: boolean | boolean[]): boolean => {
  if (typeof transliterate === 'boolean') return transliterate
  if (typeof transliterate === 'object' && transliterate.length > 0)
    return transliterate[0]
  return false
}
// const fileUrlRemove = (itemChannel) => {
//   // if (itemChannel?.fileUrl) {
//   //   delete itemChannel.fileName
//   // }
//   // if (!itemChannel?.fileUrl && !itemChannel?.fileName) itemChannel.fileName = ''
//   return itemChannel
// }
const getChannelsFromRouteId = () => {
  const routes = {}
  const channels = values?.channels
  groupSelected.value.forEach((item) => {
    const itemChannel = channels ? channels[item] : undefined
    // const itemSave = fileUrlRemove(itemChannel)
    if (itemChannel) {
      routes[item] = itemChannel
    }
  })
  return routes
}

const dataForSend = (data: GroupResult) => {
  const dataSend: Values = {
    groupName: data.groupName,
    viewForAll: data.viewForAll[0] ?? false,
    viewOptional: data.viewOptional[0] ?? false,
    congratulate: hasCongratulate.value
      ? {
          startTime: data?.congratulateStartTimeServerTime
            ? data?.congratulateStartTimeServerTime.slice(0, 5)
            : '',
          originator: data?.originator,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          transliterate: getTransliterate(data?.transliterate),
          routeGroupId: data?.routeGroupId,
          channels: getChannelsFromRouteId(),
        }
      : undefined,
  }
  return dataSend
}

const onSubmit = handleSubmit((v) => {
  const data = dataForSend(v)
  emit('submit', data)
})
</script>

<style module>
.additional {
  display: flex;
  flex-direction: column;

  gap: var(--gap-12);
}

.congratulate {
  display: flex;
  flex-direction: column;

  gap: var(--gap-16);
}

.submit {
  margin-top: var(--gap-32);
}

.hint {
  display: inline-flex;

  gap: var(--gap-8);
  align-items: center;
}
</style>
