<template>
  <FormControlBase
    v-slot="{ invalid }"
    :descriptions="descriptions"
    :name="name"
    :class="fallthroughClass"
  >
    <Select
      :modelValue="fieldValue"
      :label="label"
      :options="options"
      :placeholder="placeholder || label"
      :locale="locale"
      :invalid="invalid"
      :disabled="disabled"
      :groups="groups"
      v-bind="{ ...fallthroughAttrs, ...withOptionAllProps }"
      @update:modelValue="handleChangeField"
      @blur="handleBlur"
    />
  </FormControlBase>
</template>

<script lang="ts">
import { Select } from '@smst/ui'
import type { SelectTypes } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes, PropType } from 'vue'
import { computed, defineComponent, onMounted } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'
import { findSelectedField } from '@/components/FormSelect/FormSelect.utils'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useId } from '@/hooks/useId'

const formSelectProps = {
  ...formControlProps,
  options: {
    type: [Array, Function] as PropType<
      SelectTypes.Option[] | SelectTypes.Group[] | SelectTypes.GetOptions
    >,
    required: true,
  },
  locale: Object as PropType<SelectTypes.Locale>,
  placeholder: String,
  defaultId: String,
  groups: Boolean as PropType<boolean>,
  withOptionAll: Boolean as PropType<boolean>,
  descriptions: String,
  defaultWithId: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  canDeselect: {
    type: Boolean,
    default: true,
  },
} as const

export type FormSelectProps = Omit<
  ExtractPropTypes<typeof formSelectProps>,
  'canDeselect' | 'defaultWithId'
> & {
  // Хак, чтобы prop был опциональным, но если его не добавили, то он true по дефолту
  readonly canDeselect?: boolean
  readonly defaultWithId?: boolean
}

export default defineComponent({
  components: { FormControlBase, Select },
  inheritAttrs: false,
  props: formSelectProps,
  setup(props, { attrs }) {
    const {
      value: formValue,
      handleChange: setFormFieldValue,
      handleBlur,
    } = useField<string>(props.name)

    const t = useComponentI18n('form')

    const DEFAULT_OPTION = {
      label: t('defaultSelectOptionLabel'),
      value: `all-${useId()}`,
    }

    const withOptionAllProps = computed(() => {
      if (props.withOptionAll) {
        return {
          withOptionAll: true,
          canDeselect: false,
        }
      }

      return {
        withOptionAll: false,
        canDeselect: props.canDeselect,
      }
    })

    const { class: fallthroughClass, ...fallthroughAttrs } = attrs

    const options = computed(() => {
      /**
       * Если используется функция для получения опций, то возвращаем ее
       */
      if (typeof props.options === 'function') {
        return props.options
      }
      if (props.withOptionAll && props.groups) {
        return [{ options: [DEFAULT_OPTION] }, ...props.options]
      }

      if (props.withOptionAll) {
        return [DEFAULT_OPTION, ...props.options]
      }

      return props.options
    })
    const defaultOptions = props.defaultWithId
      ? DEFAULT_OPTION.value
      : undefined

    const fieldValue = computed(() => {
      if (typeof props.options === 'function') {
        return
      }
      if (
        !props.withOptionAll &&
        !props.groups &&
        !findSelectedField(options.value, formValue.value)
      )
        return 0
      return props.withOptionAll && formValue.value === undefined
        ? DEFAULT_OPTION.value
        : formValue.value
    })

    onMounted(() => {
      if (props.defaultId) setFormFieldValue(props.defaultId)
    })

    const handleChangeField = (targetValue: SelectTypes.Value) => {
      setFormFieldValue(
        targetValue === DEFAULT_OPTION.value ? defaultOptions : targetValue
      )
    }

    return {
      withOptionAllProps,
      fallthroughClass,
      fallthroughAttrs,

      fieldValue,
      options,
      handleChangeField,
      handleBlur,
    }
  },
})
</script>
