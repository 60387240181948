<template>
  <Text tag="h1">
    {{ t('title') }}
  </Text>

  <form :class="$style.form" @submit="onSubmit">
    <FormControls :controls="formControls" />

    <Button type="submit" :class="$style.submit" :loading="isLoading" fullWidth>
      {{ t('button') }}
    </Button>

    <ApiErrorMessage :error="error" :class="$style.submitError" />
  </form>
</template>

<script lang="ts" setup>
import { Button, Text } from '@smst/ui'
import { useForm } from 'vee-validate'
import { useMutation } from 'vue-query'
import { useRouter } from 'vue-router'

import { apiClient } from '@/api'
import { setAuthTokens } from '@/api/authTokens'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormControls from '@/components/FormControls/FormControls.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'

import type { ConfirmationPassword } from './PasswordConfirmation.schema'
import { confirmationPasswordSchema } from './PasswordConfirmation.schema'

const t = useComponentI18n('passwordConfirmation')
const router = useRouter()

const props = defineProps({
  confirmationCode: {
    type: String,
    required: true,
  },
})

const formControls: ControlType[] = [
  {
    control: 'input',
    name: 'password',
    type: 'password',
    label: t('password'),
  },
  {
    control: 'input',
    name: 'passwordRepeated',
    type: 'password',
    label: t('passwordRepeated'),
  },
]

const { handleSubmit } = useForm<ConfirmationPassword>({
  validationSchema: confirmationPasswordSchema,
})

const {
  mutate: confirmationPassword,
  isLoading,
  error,
} = useMutation(
  (values: ConfirmationPassword) => {
    return apiClient.registration.changePassword_POST(
      props.confirmationCode,
      values
    )
  },

  {
    onSuccess: ({ data }) => {
      setAuthTokens({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })

      void router.push({ name: RouteNames.Dashboard })
    },
  }
)

const onSubmit = handleSubmit((values) => confirmationPassword(values))
</script>

<style module>
.form {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: var(--gap-32);
}

.submit {
  margin-top: var(--gap-32);
}

.submitError {
  margin-top: var(--gap-16);
}
</style>
