<template>
  <Table
    v-model:modelSelection="selectedElements"
    v-model:modelSort="sort"
    :columns="tableColumns"
    :data="data"
    :rowKey="rowKey"
    @rowClick="handleRowClick"
  >
    <template #column:congratulate="{ item }">
      <Text>
        {{
          t(
            item.congratulate
              ? 'congratulateVariants.yes'
              : 'congratulateVariants.no'
          )
        }}
      </Text>
    </template>

    <template #column:creationDate="{ item }">
      <TableDate :date="item.creationDate" />
    </template>

    <template #rowAction="{ item }">
      <Dropdown
        :label="$t('actions')"
        :iconTrigger="IconActions"
        :items="getDropdownItems(item)"
      />
    </template>
  </Table>
</template>

<script setup lang="ts">
import type { TableTypes } from '@smst/ui'
import {
  Dropdown,
  IconActions,
  IconDelete,
  IconList,
  IconSendMessage,
  Table,
  Text,
} from '@smst/ui'
import type { PropType } from 'vue'
import { computed } from 'vue'

import type { Group } from '@/api/__generated__/api.schema'
import TableDate from '@/components/TableDate/TableDate.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

type TableColumns = Array<TableTypes.Column<Group>>

const props = defineProps({
  modelSelection: {
    type: Object as PropType<TableTypes.ModelSelection>,
    required: true,
  },
  modelSort: {
    type: Object as PropType<TableTypes.ModelSort>,
    required: true,
  },
  data: {
    type: Array as PropType<Group[]>,
    required: true,
  },
})
const emit = defineEmits([
  'deleteGroup',
  'goToGroupPage',
  'sendGroup',
  'update:modelSort',
  'update:modelSelection',
])

const t = useComponentI18n('groups.table')
const tLabels = useComponentI18n('groups.dropdownItems')

const selectedElements = computed({
  get: () => props.modelSelection,
  set: (value) => {
    emit('update:modelSelection', value)
  },
})

const sort = computed({
  get: () => props.modelSort,
  set: (value) => {
    emit('update:modelSort', value)
  },
})

const rowKey: keyof Group = 'groupId'
const tableColumns = computed<TableColumns>(() => [
  {
    name: t('groupName'),
    prop: 'groupName',
    wrap: true,
    sortable: true,
  },
  {
    name: t('totalPhones'),
    prop: 'totalPhones',
  },
  {
    name: t('congratulate'),
    prop: 'congratulate',
  },
  {
    name: t('creationDate'),
    prop: 'creationDate',
    sortable: true,
  },
])

const getDropdownItems = ({ groupId, groupName }: Group) => [
  {
    icon: IconSendMessage,
    label: tLabels('send'),
    onClick: () => emit('sendGroup', groupId),
  },
  {
    icon: IconList,
    label: tLabels('openGroup'),
    onClick: () => emit('goToGroupPage', groupId),
  },
  {
    icon: IconDelete,
    label: tLabels('delete'),
    onClick: () => emit('deleteGroup', { groupId, groupName }),
  },
]

const handleRowClick = ({ groupId }: Group) => {
  emit('goToGroupPage', groupId)
}
</script>
