import { twoFactorAuthPhone } from '@/components/TwoFactorInput/TwoFactorInput.utils'
import { i18n } from '@/i18n'
import { basicEntityNameLength, loginRegex } from '@/utils/validation'
import yup from '@/utils/yup'

import type { FormValues } from './DepartmentForm.utils'

const { t } = i18n.global

const keyBase = 'editDepartment.sections.settings.form'
const nameLabel = keyBase + '.name.label'
const loginLabel = keyBase + '.login.label'

export const validationSchema: yup.SchemaOf<FormValues> = yup.object({
  name: yup
    .string()
    .min(
      basicEntityNameLength.min,
      t('validation.string.min', { number: basicEntityNameLength.min })
    )
    .max(
      basicEntityNameLength.max,
      t('validation.string.max', { number: basicEntityNameLength.max })
    )
    .requiredField(nameLabel),
  login: yup
    .string()
    .matches(loginRegex, t('validation.login'))
    .max(60, t('validation.string.max', { number: 60 }))
    .requiredField(loginLabel),
  twoFactorAuthPhone,
})
