import { mapValues } from 'remeda'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'

import type { ProfileResult } from '@/api/__generated__/api.schema'
import type { AccountType } from '@/utils/getAccountType'
import { getAccountType } from '@/utils/getAccountType'

import { useProfile } from './useProfile'

type PositiveRule = { includes: number }
type NegativeRule = { excludes: number }
type Rule = PositiveRule | NegativeRule
type Value = boolean

export type PermissionsRules = Permissions<Rule>
export type PermissionsValues = Permissions<Value>

export type Permissions<T> = {
  batches: {
    canView: T
  }
  templates: {
    canView: T
  }
  reports: {
    canView: T
  }
  accounting: {
    canView: T
  }
  accountsAndDepartments: {
    canView: T
  }
  eventsLog: {
    canView: T
  }
}

export enum Role {
  CreateAndEditUser = 1,
  SendSMS = 2,
  ViewPosts = 3,
  ViewEventLog = 4,
  Bookkeeping = 5,
  DontShowTextOfMessagesInJournal = 7, // Обработка этой роли будет на бэкенде
  DontShowDigitsInJournal = 8, // Обработка этой роли будет на бэкенде
  CreateBatchesOnly = 9, // Обработка этой роли будет на бэкенде
}

export const permissionsRules: PermissionsRules = {
  batches: {
    canView: {
      includes: Role.SendSMS,
    },
  },
  templates: {
    canView: {
      includes: Role.ViewPosts,
    },
  },
  reports: {
    canView: {
      includes: Role.ViewPosts,
    },
  },
  accounting: {
    canView: {
      includes: Role.Bookkeeping,
    },
  },
  accountsAndDepartments: {
    canView: {
      includes: Role.CreateAndEditUser,
    },
  },
  eventsLog: {
    canView: {
      includes: Role.ViewEventLog,
    },
  },
}

type ValueOf<T> = T[keyof T]

const isPositiveRule = (rule: Rule): rule is PositiveRule => {
  return (rule as PositiveRule).includes !== undefined
}

export const getPermissions = (
  permissions: PermissionsRules,
  accountType: AccountType | undefined,
  roles: Roles | undefined
) => {
  const secondRound = (value: Rule) => {
    if (accountType === undefined) {
      return false
    }

    // Для основной учетной записи нет ограничений

    if (accountType === 'user') {
      return true
    }

    // Метод возвращает roles: undefined только для accountType === 'user'
    if (roles === undefined) {
      return false
    }

    if (isPositiveRule(value)) {
      return roles.includes(value.includes)
    }

    return !roles.includes(value.excludes)
  }

  const firstRound = (value: ValueOf<PermissionsRules>) => {
    return mapValues(value, secondRound)
  }
  return mapValues(permissions, firstRound) as PermissionsValues
}

type Roles = ProfileResult['roles']

const getRoles = (profile: ProfileResult | undefined): Roles | undefined => {
  if (!profile) {
    return undefined
  }

  return profile.roles
}

export const usePermissions = (): Ref<PermissionsValues> => {
  const { profile } = useProfile()
  const permissions = ref()

  if (profile?.value) {
    const roles = computed(() => getRoles(profile?.value))

    const accountType = computed(() => getAccountType(profile?.value))

    permissions.value = getPermissions(
      permissionsRules,
      accountType.value,
      roles.value
    )
  }

  return permissions
}
