import { i18n } from '@/i18n'
import yup from '@/utils/yup'

import type { ReportGenerationForm } from './ReportGeneration.types'

const { t } = i18n.global

export const reportGenerationSchema: yup.SchemaOf<ReportGenerationForm> =
  yup.object({
    email: yup
      .string()
      .requiredField('messagesLog.reportGeneration.form.email')
      .email(t('validation.email')),
  })
