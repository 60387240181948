import type { Ref } from 'vue'
import { computed, ref } from 'vue'

import type { Draft, SelectedElements } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

export type Channel = Draft['channel'] | 'all'

type UseModalDelete = (selectedElements: Ref<SelectedElements>) => {
  isOpen: Ref<boolean>
  locale: Ref<{
    title: string
    acceptButton: string
    cancelButton: string
  }>
}

type Tab = { value: Channel; label: string }

const { t } = i18n.global

export const channelTranslation = computed<Record<Channel, string>>(() => ({
  all: t('drafts.tabs.all'),
  sms: t('channels.sms'),
  viber: t('channels.viber'),
  whatsapp: t('channels.whatsapp'),
  vk: t('channels.vk'),
}))

export const tabs = computed<Tab[]>(() => [
  { value: 'all', label: channelTranslation.value.all },
  { value: 'sms', label: channelTranslation.value.sms },
  { value: 'viber', label: channelTranslation.value.viber },
  { value: 'whatsapp', label: channelTranslation.value.whatsapp },
  { value: 'vk', label: channelTranslation.value.vk },
])

const getOperationType = (selectedElements: SelectedElements) => {
  if (selectedElements.include.length > 0) {
    return 'include'
  }
  if (selectedElements.exclude.length > 0) {
    return 'exclude'
  }

  return 'all'
}

export const useModalDelete: UseModalDelete = (selectedElements) => {
  const isOpen = ref<boolean>(false)
  const operationType = computed(() => {
    return getOperationType(selectedElements.value)
  })

  const title = computed(() => {
    return {
      include: t('draft.deleteMultiple.include', {
        count: selectedElements.value.include.length,
      }),
      exclude: t('draft.deleteMultiple.exclude', {
        count: selectedElements.value.exclude.length,
      }),
      all: t('draft.deleteMultiple.all'),
    }
  })

  return {
    isOpen,
    locale: computed(() => ({
      title: title.value[operationType.value],
      acceptButton: t('draft.deleteMultiple.buttons.delete'),
      cancelButton: t('draft.deleteMultiple.buttons.cancel'),
    })),
  }
}
