<template>
  <div
    v-if="status === 'completed' && !downloadReportStatus"
    :class="$style.statusInner"
  >
    <Button view="link" @click="downloadReport(id ?? 0)">
      {{ t(status) }}
    </Button>
    <div :class="$style.status">
      <div :class="$style.infoIcon" @click="getInfo(id ?? 0)">
        <IconInfo />
      </div>
      <IconInfoStatus :id="id" :index="index" />
    </div>
  </div>
  <Loader v-else-if="downloadReportStatus" size="m" :class="$style.center" />
  <Text v-else>
    {{ t(status) }}
  </Text>
</template>

<script setup lang="ts">
import { Button, IconInfo, Loader, Text } from '@smst/ui'
import type { PropType } from 'vue'

import type { StatusReport } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'

import { downloadReportInit } from './downloadReport'
import { initStatusInfo } from './StatusInfo.utils'
import IconInfoStatus from './StatusInfo.vue'

const { downloadReport, downloadReportStatus } = downloadReportInit()

const t = useComponentI18n('requestedReports.reportStatus')

const { setStatusId } = initStatusInfo()
defineProps({
  status: {
    type: String as PropType<StatusReport>,
    required: true,
  },
  index: Number,
  id: Number,
})
const getInfo = (id: number) => {
  setStatusId(id)
}

defineEmits(['click'])
</script>

<style module>
.center {
  margin-left: 10px;
}

.status {
  position: relative;
}

.statusInner {
  display: flex;
  align-items: center;
  height: 100%;
}

.infoIcon {
  display: flex;
  align-items: center;
  margin-left: 6px;

  cursor: pointer;

  transition: cubic-bezier(0.39, 0.575, 0.565, 1) 0.3 all;
}

.infoIcon:hover {
  opacity: 0.6;
}
</style>
