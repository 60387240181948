<template>
  <AccountChangePasswordForm
    :politics="accountData.passStrengthPolitics"
    :isLoading="isEditAccountLoading"
    @submit="onSubmit"
  />
</template>

<script lang="ts" setup>
import { toaster } from '@smst/ui'
import type { PropType } from 'vue'
import { computed } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import type {
  AccountFullResult,
  DepUserFullResult,
} from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'

import type { FormValues } from './AccountChangePassword.utils'
import AccountChangePasswordForm from './AccountChangePasswordForm.vue'

const props = defineProps({
  departmentId: {
    type: String,
    required: false,
  },
  accountData: {
    type: Object as PropType<AccountFullResult | DepUserFullResult>,
    required: true,
  },
})

const isDepUser = computed(() => Boolean(props.departmentId))

const t = useComponentI18n('editAccount')

const query = computed(() =>
  isDepUser.value
    ? {
        put: {
          fn: async (password: string) =>
            await apiClient.departments.usersChangePassword_POST(
              Number(props.departmentId),
              props.accountData.id,
              {
                password,
              }
            ),
        },
      }
    : {
        put: {
          fn: async (password: string) =>
            await apiClient.accounts.changePassword_POST(props.accountData.id, {
              password,
            }),
        },
      }
)

const { isLoading: isEditAccountLoading, mutate: editAccount } = useMutation(
  query.value.put.fn,
  {
    onSuccess: () => {
      toaster.success(t('successEdit'))
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)
const onSubmit = (values: FormValues) => {
  editAccount(values.password)
}
</script>
