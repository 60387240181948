<template>
  <Text tag="p" :class="$style.title">
    {{ $t('confirmation.notCode') }}
  </Text>

  <div :class="$style.generate">
    <Button
      view="link"
      :disabled="isCodeValid"
      :loading="isLoading"
      @click="handleGenerate"
    >
      {{ $t('confirmation.again') }}
    </Button>

    <template v-if="isCodeValid">
      <Text color="secondary">
        {{ $t('confirmation.time') }}
      </Text>

      <Counter @end="isCodeValid = false" />
    </template>
  </div>

  <ApiErrorMessage :error="error" />
</template>

<script setup lang="ts">
import { Button, Text } from '@smst/ui'
import { ref } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Counter from '@/components/Counter/Counter.vue'

const isCodeValid = ref<boolean>(true)

const props = defineProps({
  confirmationCode: {
    type: String,
    required: true,
  },
})

const {
  mutate: generate,
  isLoading,
  error,
} = useMutation(
  (confirmationCode: string) =>
    apiClient.registration.resetCode_POST(confirmationCode),
  {
    onSuccess: ({ data }) => {
      isCodeValid.value = true
      // New Phone Confirmation Code
      data.phoneConfirmationCode &&
        console.info(`New Code: `, data.phoneConfirmationCode)
    },
  }
)

const handleGenerate = () => {
  generate(props.confirmationCode)
}
</script>

<style module>
.title {
  margin-top: var(--gap-52);
}

.generate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--gap-8);

  column-gap: var(--gap-4);
}
</style>
