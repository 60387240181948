export enum MessageStatus {
  DELIVERED = 'delivered',
  EXPIRED = 'expired',
  DELETED = 'deleted',
  UNDELIVERED = 'undelivered',
  BUFFERED = 'buffered',
  UNKNOWN = 'unknown',
  REJECTED = 'rejected',
  READ = 'read',
  TIMEOUT = 'timeout',
  CLICKED = 'clicked',
  NO_DATA = 'noData',
  WITH_ERROR = 'with_error',
  CHECKED = 'checked',
}
