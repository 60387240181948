/*
  Если мы используем одиночный Checkbox или Switch
  и устанавливаем проп :value="true", то тогда vee-validate устанавливает:
    - Активное состояние как [true]
    - Неактивное состояние как []
*/

export const booleanToCheckbox = (value: boolean) => {
  return value ? [true] : []
}

export const checkboxToBoolean = (value: boolean[]) => {
  if (typeof value === `boolean`) return value
  if (value === undefined || value[0] === undefined) return false
  return Boolean(value[0])
}
