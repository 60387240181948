// eslint-disable-next-line no-shadow
export enum RouteNames {
  Auth = 'Auth',
  LoginPrivateArea = 'LoginPrivateArea',
  Registration = 'Registration',
  PhoneConfirmation = 'PhoneConfirmation',
  ForgotPassword = 'ForgotPassword',
  ResetPassword = 'ResetPassword',
  Dashboard = 'Dashboard',
  Groups = 'Groups',
  Group = 'Group',
  Drafts = 'Drafts',
  Draft = 'Draft',
  DeliveryAnalysis = 'DeliveryAnalysis',
  GroupSettings = 'GroupSettings',
  GroupCreate = 'GroupCreate',
  GroupEdit = 'GroupEdit',
  GroupEditMember = 'GroupEditMember',
  OperatorByPhone = 'OperatorByPhone',
  DraftCreate = 'DraftCreate',
  SettingsGeneral = 'SettingsGeneral',
  SettingsSecurity = 'SettingsSecurity',
  SettingsSmtp = 'SettingsSmtp',
  SettingsPassword = 'SettingsPassword',
  NotFound = 'NotFound',
  TemplatesCheck = 'TemplatesCheck',
  TemplatesTest = 'TemplatesTest',
  TemplatesList = 'TemplatesList',
  Accounts = 'Accounts',
  AccountsCreate = 'AccountsCreate',
  AccountSettings = 'AccountSettings',
  AccountPrivacy = 'AccountPrivacy',
  AccountSecurity = 'AccountSecurity',
  AccountChangePassword = 'AccountChangePassword',
  Departments = 'Departments',
  DepartmentSettings = 'DepartmentSettings',
  DepartmentAccounts = 'DepartmentAccounts',
  DepartmentAccountSettings = 'DepartmentAccountSettings',
  DepartmentAccountPrivacy = 'DepartmentAccountPrivacy',
  DepartmentAccountSecurity = 'DepartmentAccountSecurity',
  DepartmentAccountChangePassword = 'DepartmentAccountChangePassword',
  Statistics = 'Statistics',
  MessagesLog = 'MessagesLog',
  RequestedReports = 'RequestedReports',
  Batches = 'Batches',
  BatchesArchive = 'BatchesArchive',
  BatchDetails = 'BatchDetails',
  BatchDetailsArchive = 'BatchDetailsArchive',
  BatchEdit = 'BatchEdit',
  BatchEditArchive = 'BatchEditArchive',
  BatchCreate = 'BatchCreate',
  EventLogs = 'EventLogs',
  Originators = 'Originators',
  TechDocs = 'TechDocs',
  AccountingInfo = 'AccountingInfo',
  Accounting = 'Accounting',
  TransactionsHistory = 'TransactionsHistory',
  PasswordConfirmation = 'PasswordConfirmation',
  PasswordExpired = 'PasswordExpired',
  ToOldFront = 'ToOldFront',
  ReportsOnRequest = 'ReportsOnRequest',
}
