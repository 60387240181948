<template>
  <div :class="$style.root">
    <div :class="$style.block">
      <template v-if="startDateDescription">
        <Text>
          {{ startDateDescription.date }}
        </Text>

        <Text v-if="!withoutTime" color="secondary">
          {{ startDateDescription.time }}
        </Text>
      </template>

      <template v-else>-</template>
    </div>

    <template v-if="endDateDescription">
      <Text>—</Text>

      <div :class="$style.block">
        <Text>
          {{ endDateDescription.date }}
        </Text>

        <Text v-if="!withoutTime" color="secondary">
          {{ endDateDescription.time }}
        </Text>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import type { PropType } from 'vue'
import { computed } from 'vue'

import { useProfile } from '@/hooks/useProfile'
import { serverTimeToUserTime } from '@/utils/timezone'

type InputDate = string | number | [string, string]

type DateDescription = {
  date: string
  time: string
}

const props = defineProps({
  date: {
    type: [String, Array, Number] as PropType<InputDate>,
  },

  withoutTime: Boolean,
})

const { profile } = useProfile()
const startDate = computed<string | number | undefined>(() =>
  Array.isArray(props.date) ? props.date[0] : props.date
)

const endDate = computed<string | undefined>(() =>
  Array.isArray(props.date) ? props.date[1] : undefined
)

const getDateDescription = (
  date?: string | number
): DateDescription | undefined => {
  if (!date) return

  const dateObj = serverTimeToUserTime(date, profile.value?.timezone.name)

  return {
    date: dateObj.toLocaleDateString(),
    time: dateObj.toLocaleTimeString(),
  }
}

const startDateDescription = computed<DateDescription | undefined>(() =>
  getDateDescription(startDate.value)
)

const endDateDescription = computed<DateDescription | undefined>(() =>
  endDate.value ? getDateDescription(endDate.value) : undefined
)
</script>

<style module>
.root {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  column-gap: var(--gap-8);
}

.block {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
</style>
