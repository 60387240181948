<template>
  <Text tag="h1">
    {{ t('title') }}
  </Text>

  <Filters
    v-model="filtersModel"
    :controls="controls"
    :class="$style.filters"
    :defaultValues="defaultFilterValues"
  >
    <template #extra-button>
      <Button
        view="secondary"
        :iconLeft="IconDownload"
        :loading="isEventLogsDownloadLoading"
        @click="handleDownloadClick"
      >
        {{ t('download') }}
      </Button>

      <ApiErrorMessage :error="eventLogsDownloadError" />
    </template>
  </Filters>

  <template v-if="showTable">
    <LoaderWrapper :loading="isFetching" :class="$style.table">
      <Table
        v-model:modelSort="sortModel"
        :data="tableData"
        :columns="columns"
        rowKey="eventId"
      >
        <template #column:recordDate="{ item }">
          <TableDate :date="item.recordDate" />
        </template>
      </Table>
    </LoaderWrapper>

    <PaginationPanel v-model="paginationModel" :totalItems="totalTableItems" />
  </template>

  <div v-else :class="$style.container">
    <Loader v-if="isLoading" size="m" />

    <NoResultsFound v-if="showNoResultsFound" />

    <ApiErrorMessage :error="error" />
  </div>
</template>

<script setup lang="ts">
import {
  Button,
  IconDownload,
  Loader,
  LoaderWrapper,
  Table,
  Text,
} from '@smst/ui'
import type { TableTypes } from '@smst/ui'
import { subDays } from 'date-fns'
import { isDefined } from 'remeda'
import { NumberParam } from 'serialize-query-params'
import { computed } from 'vue'
import { useMutation, useQuery } from 'vue-query'

import { apiClient } from '@/api'
import type { EventLog } from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Filters from '@/components/Filters/Filters.vue'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import PaginationPanel from '@/components/PaginationPanel/PaginationPanel.vue'
import TableDate from '@/components/TableDate/TableDate.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { createSortQueryConfig, useTableRequest } from '@/hooks/useTableRequest'
import { RangeDateParam } from '@/utils/date'
import { downloadFile } from '@/utils/downloadFile'
import { omitBy } from '@/utils/object'

import type { EventLogsQueryParams } from './EventLogs.types'
import { getQueryParams, getUsersOptions } from './EventLogs.utils'

const t = useComponentI18n('eventLogs')

const sortConfig = createSortQueryConfig(['ip', 'recordDate'])

const filtersConfig = {
  date: RangeDateParam,
  user: NumberParam,
}

const defaultFilterValues = {
  date: [subDays(new Date(), 1), new Date()],
}

const { request, paginationModel, sortModel, filtersModel } = useTableRequest({
  filtersConfig,
  sortConfig,
})

const { data: departmentsListData } = useQuery(
  'departmentsList',
  apiClient.departments.list_GET
)

const { data: accountsListData } = useQuery(
  'accountsList',
  apiClient.accounts.list_GET
)

const queryParams = computed(() =>
  getQueryParams({
    departments: departmentsListData.value?.data.list,
    user: filtersModel.value.user,
    date: filtersModel.value.date,
  })
)

const { data, isSuccess, isLoading, isFetching, error } = useQuery(
  ['eventLogs', request, filtersModel, queryParams],
  () =>
    apiClient.eventLogs.eventLogs_GET({
      ...omitBy(request.value, isDefined),
      ...queryParams.value,
    }),
  {
    keepPreviousData: true,
  }
)

const tableData = computed(() => data.value?.data.list ?? [])
const showTable = computed(() => isSuccess.value && tableData.value.length > 0)
const totalTableItems = computed(
  () => data.value?.data.pageInfo?.totalItems ?? 0
)

const showNoResultsFound = computed(
  () => isSuccess.value && tableData.value.length === 0 && !isLoading.value
)

const columns: Array<TableTypes.Column<EventLog>> = [
  {
    name: t('column.login'),
    prop: 'login',
  },
  {
    name: t('column.eventDesc'),
    prop: 'eventDesc',
    wrap: true,
  },
  {
    name: t('column.ip'),
    prop: 'ip',
    sortable: true,
  },
  {
    name: t('column.recordDate'),
    prop: 'recordDate',
    sortable: true,
  },
]

const getControls = (): ControlType[] => [
  {
    control: 'datepicker',
    name: 'date',
    range: true,
    label: t('filters.date'),
  },
  {
    control: 'select',
    name: 'user',
    label: t('filters.user'),
    options: getUsersOptions({
      departmentsListData: departmentsListData.value?.data.list,
      accountsListData: accountsListData.value?.data.list,
    }),
    groups: true,
    withOptionAll: true,
  },
]

const controls = computed(() => getControls())

const {
  mutate: downloadEventLogs,
  isLoading: isEventLogsDownloadLoading,
  error: eventLogsDownloadError,
} = useMutation(
  async (values: EventLogsQueryParams) => {
    return await apiClient.eventLogs.file_GET(values, {
      format: 'blob',
    })
  },

  {
    onSuccess: (eventLogsResponse) => {
      void downloadFile({
        response: eventLogsResponse,
        fileName: `event-logs-${new Date().toLocaleDateString()}`,
        extension: 'xlsx',
      })
    },
  }
)

const handleDownloadClick = () => {
  downloadEventLogs(queryParams.value)
}
</script>

<style module>
.filters {
  margin-top: var(--gap-24);
}

.table {
  margin-top: var(--gap-12);
}

.container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
