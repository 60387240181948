import type { PaginationTypes, TableTypes } from '@smst/ui'
import { pick } from 'remeda'
import type { QueryParamConfigMap } from 'serialize-query-params'
import {
  createEnumParam,
  NumberParam,
  withDefault,
} from 'serialize-query-params'
import type { DecodedValueMap } from 'serialize-query-params/lib/types'
import { computed } from 'vue'
import type { Ref } from 'vue'

import { Direction } from '@/api/__generated__/api.schema'
import { useQueryParams } from '@/hooks/useQueryParams'
import { defaultPageSize } from '@/utils/pagination'

const pageQueryConfig: QueryParamConfigMap = {
  page: withDefault(NumberParam, 1),
  size: withDefault(NumberParam, defaultPageSize),
}

export const createSortQueryConfig = <T extends string>(fields: T[]) => {
  return {
    field: withDefault(createEnumParam(fields), undefined),
    direction: withDefault(
      createEnumParam([Direction.Asc, Direction.Desc]),
      undefined
    ),
  }
}

export const useTableRequest = <
  SortConfig extends QueryParamConfigMap,
  FiltersConfig extends QueryParamConfigMap,
  T = DecodedValueMap<FiltersConfig> & DecodedValueMap<SortConfig>,
>(props: {
  sortConfig: SortConfig
  filtersConfig: FiltersConfig
}) => {
  const { sortConfig, filtersConfig } = props

  const { query } = useQueryParams({
    ...sortConfig,
    ...filtersConfig,
    ...pageQueryConfig,
  })

  const sortModel = computed<TableTypes.ModelSort>({
    get: () => ({
      field: query.value.field,
      direction: query.value.direction,
    }),
    set: (newSort) => {
      if (newSort?.field || newSort?.direction) {
        query.value = {
          ...query.value,
          field: newSort.field,
          direction: newSort.direction,
          page: 1,
        }
      } else {
        query.value = {
          ...query.value,
          field: undefined,
          direction: undefined,
          page: 1,
        }
      }
    },
  })

  const paginationModel = computed<PaginationTypes.Model>({
    get: () => ({
      page: query.value.page,
      size: query.value.size,
    }),
    set: (newPagination) => {
      query.value = {
        ...query.value,
        ...newPagination,
      }
    },
  })

  const filtersModel = computed<DecodedValueMap<FiltersConfig>>({
    get: () =>
      pick(
        query.value,
        Object.keys(filtersConfig)
      ) as DecodedValueMap<FiltersConfig>,
    set: (newFilters) => {
      query.value = {
        ...query.value,
        ...newFilters,
        page: 1,
      }
    },
  })

  return {
    sortModel,
    paginationModel,
    filtersModel,
    resetPagination: () => {
      paginationModel.value = { page: 1, size: paginationModel.value.size }
    },
    request: query as unknown as Ref<T & PaginationTypes.Model>,
  }
}
