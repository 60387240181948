import type { TableTypes } from '@smst/ui'

import type { apiClient } from '@/api'
import type { SessionResult } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'

const { t } = i18n.global

/* eslint-disable camelcase */
export type SessionSelectedElements = Parameters<
  typeof apiClient.settings.sessionsDelete_POST
>[0]

export const getOperationType = (selectedElements: SessionSelectedElements) => {
  if (selectedElements.include.length > 0) {
    return 'include'
  }
  if (selectedElements.exclude.length > 0) {
    return 'exclude'
  }

  if (selectedElements.all) {
    return 'all'
  }

  return null
}

export const getSelectedSession = (
  sessions: SessionResult[],
  selectedModel: SessionSelectedElements
) => {
  const selectionType = getOperationType(selectedModel)
  if (selectionType === 'all' || selectionType === null) {
    return
  }

  const selectedSessions = sessions.filter(({ accessTokenHash }) =>
    selectedModel[selectionType].includes(accessTokenHash)
  )

  return selectedSessions.map(({ createdAt, ip }) => ({
    createdAt,
    ip,
  }))
}

export const normalizeUnixTimestamp = (unixTimestamp: number) =>
  unixTimestamp * 1000

type TableColumns = Array<TableTypes.Column<SessionResult>>

export const rowKey: keyof SessionResult = 'accessTokenHash'

const tTable = (key: string) => t(`settings.security.table.${key}`)

export const tableColumns: TableColumns = [
  {
    name: tTable('createdAt'),
    prop: 'createdAt',
  },
  {
    name: tTable('ip'),
    prop: 'ip',
  },
  {
    name: tTable('login'),
    prop: 'login',
  },
]

export const SESSIONS_KEY = 'sessions'
