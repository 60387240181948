<template>
  <div :class="$style.main">
    <component
      v-bind="control"
      :is="controlComponents[control.control]"
      v-for="control in controls"
      :key="control.name"
    />
  </div>
</template>

<script lang="ts">
import type { Component, PropType } from 'vue'
import { defineComponent } from 'vue'

import FormCheckbox from '@/components/FormCheckbox/FormCheckbox.vue'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import FormDatepicker from '@/components/FormDatepicker/FormDatepicker.vue'
import FormDateTimepicker from '@/components/FormDateTimepicker/FormDateTimepicker.vue'
import FormFileInput from '@/components/FormFileInput/FormFileInput.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import FormMultiselect from '@/components/FormMultiselect/FormMultiselect.vue'
import FormRadio from '@/components/FormRadio/FormRadio.vue'
import FormSelect from '@/components/FormSelect/FormSelect.vue'
import FormSwitch from '@/components/FormSwitch/FormSwitch.vue'
import FormTagInput from '@/components/FormTagInput/FormTagInput.vue'
import FormTextarea from '@/components/FormTextarea/FormTextarea.vue'
import PhonesInput from '@/components/PhonesInput/FormInputPhone.vue'

const controlComponents: Record<ControlType['control'], Component> = {
  input: FormInput,
  textarea: FormTextarea,
  checkbox: FormCheckbox,
  switch: FormSwitch,
  radio: FormRadio,
  select: FormSelect,
  multiselect: FormMultiselect,
  tagInput: FormTagInput,
  datepicker: FormDatepicker,
  datetimepicker: FormDateTimepicker,
  fileInput: FormFileInput,
  phonesInput: PhonesInput,
}

export default defineComponent({
  props: {
    controls: Array as PropType<ControlType[]>,
  },
  setup() {
    return {
      controlComponents,
    }
  },
})
</script>

<style module>
.main {
  display: grid;
  gap: var(--gap-16);
}
</style>
