import type { SelectTypes } from '@smst/ui'
import { toaster } from '@smst/ui'
import { isDefined } from 'remeda'
import type { Ref } from 'vue'
import { computed, ref, unref } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import type { SelectedElements } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getErrorMessage } from '@/utils/errors'
import yup from '@/utils/yup'

export type TransferFormValues = {
  group: number
}

export type Locale = {
  title: string
  informationTitle: string
  confirmTitle: string
  submit: string
  close: string
}

export type TransferValues = {
  groupIds: number[]
  selectedElements: SelectedElements
}

export const transferValidationSchema = yup.object({
  group: yup.number().required(),
})

type TransferType = 'copy' | 'move'

type UseTransferGroupProps = {
  type: Ref<TransferType>
  selectedElements: Ref<SelectedElements>
  sourceGroup: number
  onSuccess?: VoidFunction
}

const getOperationType = (selectedElements: SelectedElements) => {
  if (selectedElements.include.length > 0) {
    return 'include'
  }
  if (selectedElements.exclude.length > 0) {
    return 'exclude'
  }

  return 'all'
}

export const useTransferGroup = (props: UseTransferGroupProps) => {
  const { sourceGroup, selectedElements, onSuccess } = props

  const groupName = ref<string | undefined>()
  const showList = ref<boolean>(false)

  const transferQuery = computed(() => {
    return props.type.value === 'copy'
      ? apiClient.group.membersCopyMembers_POST
      : apiClient.group.membersMoveMembers_POST
  })

  const groupType = computed(() => {
    return `group.${unref(props.type)}`
  })

  const t = useComponentI18n(groupType)

  const operationType = computed(() => getOperationType(selectedElements.value))

  const titles = computed(() => {
    return {
      confirm: {
        all: t('confirmAll', {
          list: groupName.value,
        }),
        exclude: t('confirmExclude', {
          list: groupName.value,
          count: selectedElements.value.exclude.length,
        }),
        include: t('confirmInclude', {
          count: selectedElements.value.include.length,
          list: groupName.value,
        }),
      },
      information: {
        all: t('informationAll', {
          list: groupName.value,
        }),
        exclude: t('informationExclude', {
          list: groupName.value,
          count: selectedElements.value.exclude.length,
        }),
        include: t('informationInclude', {
          count: selectedElements.value.include.length,
          list: groupName.value,
        }),
      },
    }
  })

  const handleReset = () => {
    groupName.value = undefined
    showList.value = false
  }

  const { mutate: transfer, isLoading: isLoading } = useMutation(
    (values: TransferValues) => transferQuery.value(sourceGroup, values),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        handleReset()
        toaster.success(t('success'))
      },
      onError: (e) => {
        handleReset()
        toaster.error(getErrorMessage(e))
      },
    }
  )

  const locale = computed<Locale>(() => ({
    informationTitle: titles.value.information[operationType.value],
    confirmTitle: titles.value.confirm[operationType.value],
    title: t('title'),
    submit: t('submit'),
    close: t('close'),
  }))

  const handleSelectGroup = (group: SelectTypes.Option) => {
    if (isDefined(group)) {
      groupName.value = group.label
      showList.value = true
    } else {
      handleReset()
    }
  }

  return {
    groupName,
    showList,
    locale,
    isLoading,
    handleSelectGroup,
    handleReset,
    handleTransferSubmit: (values: TransferFormValues) => {
      transfer({
        groupIds: [values.group],
        selectedElements: selectedElements.value,
      })
    },
  }
}
