<template>
  <Text tag="h1" :class="$style.title">{{ $t('templatesList.title') }}</Text>

  <Tabs
    v-if="profile?.smartDelivery"
    :items="allowedChannels"
    :class="$style.tabs"
  >
    <template #default="{ item, tabClass, tabActiveClass }">
      <router-link
        :to="{ params: { channel: item.value } }"
        :class="[tabClass, item.value === channel && tabActiveClass]"
      >
        {{ item.label }}
      </router-link>
    </template>
  </Tabs>

  <Filters
    v-if="showFilters"
    v-model="filtersModel"
    :controls="filtersControls"
    :class="$style.filters"
  >
    <template #extra-button="{ formValues }">
      <Button
        view="secondary"
        :iconLeft="IconDownload"
        :loading="isTemplatesDownloadLoading"
        @click="handleDownloadClick(formValues)"
      >
        {{ $t('templatesList.downloadButton') }}
      </Button>

      <ApiErrorMessage :error="templatesDownloadError" />
    </template>
  </Filters>

  <template v-if="showTable">
    <LoaderWrapper :loading="isListFetching">
      <TemplatesTable :data="tableItems" />
    </LoaderWrapper>

    <PaginationPanel v-model="paginationModel" :totalItems="totalTableItems" />
  </template>

  <div v-else :class="$style.secondaryContent">
    <Loader v-if="showLoader" size="m" />

    <LoaderWrapper v-if="showNoResultsFound" :loading="isListFetching">
      <NoResultsFound />
    </LoaderWrapper>

    <ApiErrorMessage v-if="showError" :error="filtersError || listError" />
  </div>
</template>

<script lang="ts" setup>
import {
  Button,
  IconDownload,
  Loader,
  LoaderWrapper,
  Tabs,
  Text,
  toaster,
} from '@smst/ui'
import { isDefined } from 'remeda'
import type { PropType } from 'vue'
import { computed, toRef, watch } from 'vue'
import { useMutation, useQuery } from 'vue-query'
import { useRoute, useRouter } from 'vue-router'

import { apiClient } from '@/api'
import { dictionaryDataStaleTime } from '@/api/queryConfig'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import type { FiltersFormValues } from '@/components/Filters/Filters.types'
import Filters from '@/components/Filters/Filters.vue'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import PaginationPanel from '@/components/PaginationPanel/PaginationPanel.vue'
import TemplatesTable from '@/components/TemplatesTable/TemplatesTable.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { useTableRequest } from '@/hooks/useTableRequest'
import { downloadFile } from '@/utils/downloadFile'
import { getErrorMessage } from '@/utils/errors'
import { omitBy } from '@/utils/object'

import type { Channel } from './TemplatesList.utils'
import {
  filtersQueryConfig,
  getFiltersControls,
  getTabsItems,
} from './TemplatesList.utils'

const t = useComponentI18n('templates')

const router = useRouter()
const route = useRoute()

const props = defineProps({
  channel: {
    type: String as PropType<Channel>,
    required: true,
  },
})

const { profile } = useProfile()

const { request, filtersModel, paginationModel } = useTableRequest({
  filtersConfig: filtersQueryConfig,
  sortConfig: {},
})
const {
  isLoading: isFiltersLoading,
  isSuccess: isFiltersSuccess,
  data: filtersData,
  error: filtersError,
} = useQuery(['templatesFilters'], () => apiClient.templates.filters_GET(), {
  staleTime: dictionaryDataStaleTime,
})

const templatesFilters = () => {
  if (!filtersData?.value?.data) return undefined
  const dataFilter = filtersData.value.data
  if (routeTabs('vk')) {
    return dataFilter?.vkServices[0].serviceId
  }
  if (routeTabs('whatsapp')) {
    return dataFilter.whatsappServices[0].serviceId
  }
  if (routeTabs('viber')) {
    return dataFilter?.viberServices[0].serviceId
  }
  return undefined
}
const routeTabs = (channelList: string) => {
  return route.params.channel === channelList
}
const channelsAllow = () => {
  return routeTabs('vk') || routeTabs('whatsapp') || routeTabs('viber')
}
const enabled = computed(() => {
  if (channelsAllow()) return !!route.query.serviceId
  return true
})

const goToServiceId = () => {
  if (templatesFilters())
    void router.push(
      `/templates/${route.params.channel}?serviceId=${templatesFilters()}`
    )
}

watch(
  () => isFiltersSuccess.value,
  () => {
    if (!enabled.value) goToServiceId()
  }
)
watch(
  () => route.params.channel,
  () => {
    if (!enabled.value && isFiltersSuccess.value) goToServiceId()
  }
)
const {
  isLoading: isListLoading,
  isSuccess: isListSuccess,
  isFetching: isListFetching,
  data: listData,
  error: listError,
} = useQuery(
  ['templatesList', toRef(props, 'channel'), request],
  () => {
    return apiClient.templates.list_GET_BY(
      props.channel,
      omitBy(request.value, isDefined)
    )
  },
  {
    keepPreviousData: true,
    enabled,
  }
)

const totalTableItems = computed(
  () => listData.value?.data.pageInfo?.totalItems ?? 0
)
const tableItems = computed(() => listData.value?.data.list ?? [])

const filtersControls = computed(() => {
  return getFiltersControls(props.channel, filtersData.value?.data)
})
const allowedChannels = computed(() => {
  return isListSuccess
    ? getTabsItems(filtersData?.value?.data?.allowedChannels)
    : ''
})

const showLoader = computed(() => isFiltersLoading.value || isListLoading.value)
const showError = computed(
  () => !showLoader.value && (filtersError.value || listError.value)
)
const showFilters = computed(
  () => isFiltersSuccess.value && isListSuccess.value
)
const showTable = computed(
  () => isFiltersSuccess.value && isListSuccess.value && totalTableItems.value
)
const showNoResultsFound = computed(
  () => isFiltersSuccess.value && isListSuccess.value && !totalTableItems.value
)

const {
  mutate: downloadTemplates,
  isLoading: isTemplatesDownloadLoading,
  error: templatesDownloadError,
} = useMutation(
  async (values: FiltersFormValues) => {
    return await apiClient.templates.file_GET_BY(props.channel, values, {
      format: 'blob',
    })
  },

  {
    onSuccess: (data) => {
      if (data?.status !== 200) {
        toaster.error(getErrorMessage(data.status))
        return
      }
      void downloadFile({
        response: data,
        fileName: `list-of-templates-${new Date().toLocaleDateString()}`,
        extension: 'xls',
      })
      toaster.success(t('message_downloaded'))
    },
    onError(error) {
      toaster.error(getErrorMessage(error))
    },
  }
)

const handleDownloadClick = (values: FiltersFormValues) => {
  downloadTemplates(values)
}
</script>

<style module>
.title {
  margin-bottom: var(--gap-24);
}

.tabs {
  align-self: flex-start;
  margin-bottom: var(--gap-32);
}

.filters {
  margin-bottom: var(--gap-24);
}

.secondaryContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
