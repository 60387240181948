<template>
  <Button type="button" view="link" :iconLeft="IconPlus" @click="handleOpen">
    {{ t('title') }}
  </Button>

  <Drawer v-model="isOpen" :title="t('title')" :closeText="t('close')">
    <Select
      v-model="additionMethod"
      :options="additionMethodOptions"
      :label="t('additionMethod.label')"
      :class="$style.additionMethod"
      :canDeselect="false"
    />

    <CreateOriginatorsForm
      v-if="additionMethod === 'form'"
      :operatorOptions="operatorOptions"
      :formId="formId"
      @submit="createOriginator"
    />

    <CreateOriginatorsFile
      v-if="additionMethod === 'file'"
      :operatorOptions="operatorOptions"
      :formId="formId"
      @submit="createOriginators"
    />

    <template #buttons>
      <Button
        type="submit"
        :form="formId"
        :loading="isLoading || isLoadingCreateOriginators"
      >
        {{ t('submit') }}
      </Button>
    </template>
  </Drawer>
</template>

<script lang="ts" setup>
import { Button, Drawer, IconPlus, Select, toaster } from '@smst/ui'
import type { SelectTypes } from '@smst/ui'
import { computed, ref } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'

import { apiClient } from '@/api'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useId } from '@/hooks/useId'
import { getErrorMessage } from '@/utils/errors'

import CreateOriginatorsFile from '../CreateOriginatorsFile/CreateOriginatorsFile.vue'
import CreateOriginatorsForm from '../CreateOriginatorsForm/CreateOriginatorsForm.vue'

import { getOperatorOptions } from './CreateOriginators.utils'

const t = useComponentI18n('originators.createOriginator')
const queryClient = useQueryClient()

const formId = `addOriginatorsFormId-${useId()}`

type AdditionMethod = 'form' | 'file'
const additionMethod = ref<AdditionMethod>('form')

const isOpen = ref(false)

const handleOpen = () => {
  isOpen.value = true
}

const handleSuccess = () => {
  toaster.success(t('success'))
  isOpen.value = false
  void queryClient.invalidateQueries('originators')
}

const { mutate: createOriginator, isLoading } = useMutation(
  apiClient.originator.create_POST,
  {
    onSuccess: () => {
      handleSuccess()
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)

const { mutate: createOriginators, isLoading: isLoadingCreateOriginators } =
  useMutation(apiClient.originators.originators_POST, {
    onSuccess: () => {
      handleSuccess()
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  })

const additionMethodOptions: SelectTypes.Option[] = [
  {
    value: 'form',
    label: t('additionMethod.options.form'),
  },
  {
    value: 'file',
    label: t('additionMethod.options.file'),
  },
]

const { data: operatorsData } = useQuery(
  'originatorsFilters',
  apiClient.originators.filters_GET
)

const operatorOptions = computed(() => {
  return getOperatorOptions(operatorsData.value?.data.operators)
})
</script>

<style module>
.additionMethod {
  margin-top: var(--gap-52);
  margin-bottom: var(--gap-16);
}
</style>
