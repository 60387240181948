<template>
  <FormControlBase
    v-if="!disabled"
    v-slot="{ invalid }"
    :name="name"
    :class="fallthroughClass"
  >
    <Selected
      :modelValue="formValue"
      :label="label"
      :options="options"
      :placeholder="placeholder || label"
      :locale="locale"
      :canDeselect="canDeselect"
      :invalid="invalid"
      :disabled="disabled"
      :size="size"
      v-bind="{ ...fallthroughAttrs }"
      @update:modelValue="handleChangeField"
      @blur="handleBlur"
    />
  </FormControlBase>
</template>

<script lang="ts">
import { Select as Selected } from '@smst/ui'
import type { SelectTypes } from '@smst/ui'
import { useField } from 'vee-validate'
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formSelectProps = {
  ...formControlProps,
  options: {
    type: [Array, Function, Object] as PropType<
      SelectTypes.Option[] | SelectTypes.Group[]
    >,
    required: true,
  },
  disabled: Boolean,
  canDeselect: { type: Boolean, default: true },
  size: String,
  locale: Object as PropType<SelectTypes.Locale>,
  placeholder: String,
} as const

export default defineComponent({
  components: { FormControlBase, Selected },
  inheritAttrs: false,
  props: formSelectProps,
  setup(props, { attrs }) {
    const {
      value: formValue,
      handleChange: setFormFieldValue,
      handleBlur,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    } = useField<string>(props.name)

    const { class: fallthroughClass, ...fallthroughAttrs } = attrs

    const options = computed(() => {
      return props.options
    })

    const handleChangeField = (targetValue: SelectTypes.Value) => {
      setFormFieldValue(targetValue)
    }

    return {
      fallthroughClass,
      fallthroughAttrs,
      formValue,
      options,
      handleChangeField,
      handleBlur,
    }
  },
})
</script>
