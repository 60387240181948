import { pick, uniq } from 'remeda'

import { PassStrengthPolitics } from '@/api/__generated__/api.schema'
import type {
  AccountFullResult,
  DepUserFullResult,
  RoleResult,
} from '@/api/__generated__/api.schema'
import { handleTwoFactor } from '@/components/TwoFactorInput/TwoFactorInput.utils'
import { Role } from '@/hooks/usePermissions'
import { i18n } from '@/i18n'
import type yup from '@/utils/yup'

import type { CreateFormValues, EditFormValues } from './AccountForm.schema'
import {
  editAccountSchema,
  editDepartmentAccountSchema,
} from './AccountForm.schema'

const { t, te } = i18n.global

const createAccountInitialValues: CreateFormValues = {
  name: '',
  login: '',
  defaultOriginator: '',
  twoFactorAuthPhone: '',
  roles: [],
  countryId: undefined,
  timezoneId: undefined,
  passStrengthPolitics: PassStrengthPolitics.Default,
}

export const isDepartmentUser = (
  accountData: AccountFullResult | DepUserFullResult
): accountData is DepUserFullResult => 'departmentId' in accountData

export const getInitialValues = (
  accountData: AccountFullResult | DepUserFullResult | undefined
): EditFormValues | CreateFormValues => {
  if (!accountData) {
    return createAccountInitialValues
  }

  const commonFields = pick(accountData, [
    'name',
    'login',
    'defaultOriginator',
    'roles',
    'passStrengthPolitics',
  ])

  if (isDepartmentUser(accountData)) {
    return {
      ...commonFields,
    }
  }

  const { timezone } = accountData

  return {
    ...commonFields,
    twoFactorAuthPhone: handleTwoFactor(accountData.twoFactorAuth),
    countryId: timezone?.country.countryId,
    timezoneId: timezone?.id,
  }
}

export const getValidationSchema = (
  accountData: AccountFullResult | DepUserFullResult | undefined
): yup.SchemaOf<EditFormValues | CreateFormValues> => {
  if (accountData && isDepartmentUser(accountData)) {
    return editDepartmentAccountSchema
  }
  return editAccountSchema
}

export const activationRulesForRoles = [
  {
    observableRoleId: Role.SendSMS,
    dependentRolesIds: [Role.CreateBatchesOnly],
  },
  {
    observableRoleId: Role.ViewPosts,
    dependentRolesIds: [
      Role.DontShowTextOfMessagesInJournal,
      Role.DontShowDigitsInJournal,
    ],
  },
]

const getActivationRuleByDependentRole = (roleId: number) =>
  activationRulesForRoles.find(({ dependentRolesIds }) =>
    dependentRolesIds.includes(roleId)
  )

const getActivationRuleByObservableRole = (roleId: number) =>
  activationRulesForRoles.find(
    ({ observableRoleId }) => observableRoleId === roleId
  )

export const isRoleDisabled = (roleId: number, selectedRolesIds?: number[]) => {
  if (!selectedRolesIds) {
    return false
  }

  const activationRule = getActivationRuleByDependentRole(roleId)

  if (!activationRule) {
    return false
  }

  const { observableRoleId } = activationRule

  const isObservableRoleSelected = selectedRolesIds.includes(observableRoleId)

  if (isObservableRoleSelected) {
    return false
  }

  return true
}

export const getRolesToDeselect = (deselectedRoles: number[]) => {
  const result = deselectedRoles.reduce<number[]>((acc, deselectedRole) => {
    const activationRule = getActivationRuleByObservableRole(deselectedRole)

    if (activationRule) {
      return [...acc, ...activationRule.dependentRolesIds]
    }

    return acc
  }, [])

  return uniq(result)
}

export const getRoleHint = (roleId: number) => {
  const key = `rolesHints.${roleId}`

  if (te(key)) {
    return t(key)
  }

  return ''
}

export const showFormSwitch = (id: number) => {
  if (id === 9 || id === 7 || id === 8) return false
  return true
}
export const showCheckBox = (id: number) => {
  if (id === 9 || id === 7 || id === 8) return true
  return false
}

export const rolesSort = (roles: RoleResult[]): RoleResult[] => {
  const data = [
    roles[0], // Создание и редактирование пользователей и их прав 1
    roles[1], // Отправка СМС 2
    roles[7], // Только создание рассылок [] 9
    roles[2], // Просмотр сообщений 3 ??
    roles[5], // Не показывать текст сообщений в журнале [] 7
    roles[6], // Не показывать цифры в тексте сообщений в журнале [] 8
    roles[3], // Просмотр журнала событий 4
    roles[4], // Бухгалтерия 5
  ]

  return data.filter((item) => item)
}
