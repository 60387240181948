<template>
  <div></div>
</template>

<script setup lang="ts">
import { checkRitoricaDomain, checkStMediaDomain } from '@/utils/getDomain'

if (checkStMediaDomain()) {
  const favicon = document.querySelector('link[rel="icon"]')
  favicon?.setAttribute('href', '/favicon-st.png')
  const bodyStyles = document.body.style
  bodyStyles.setProperty('--color-bg-menu', '#878786')
  bodyStyles.setProperty('--color-bg-button', '#00923a')
  bodyStyles.setProperty('--color-bg-button-hover', '#98c21f')
  bodyStyles.setProperty('--color-bg-heading', '#b2b2b2')
  bodyStyles.setProperty('--color-bg-menu-active', '#00923a')
  bodyStyles.setProperty('--color-title-card', '#002962')
}
if (checkRitoricaDomain()) {
  const favicon = document.querySelector('link[rel="icon"]')
  favicon?.setAttribute('href', '/favicon-ritorica.png')
}
</script>

<style>
#app {
  display: contents;
}
</style>
