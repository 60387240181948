import type { AxiosResponse } from 'axios'
import axios from 'axios'
import { computed, ref } from 'vue'

import { getStoredAuthTokens } from '@/api/authTokens'
import { onLogout } from '@/layouts/Main/components/ProfileSidebar/ProfileSIdebar.utils'
import {
  checkFrontByDomain,
  checkFrontKzDomain,
  checkFrontUzDomain,
} from '@/utils/getDomain'

//lk.smstraffic.ru
const URL = (url: string) => `https://${url}/login/back-from-new-lk`

export const reason = ref('')
export const errorMessage = ref('')
export const handleChange = (evt) => {
  reason.value = evt.target.value
}

const getUrl = computed(() => {
  if (checkFrontUzDomain()) return URL('lk.smstraffic.uz')
  if (checkFrontByDomain()) return URL('lk.smstraffic.by')
  if (checkFrontKzDomain()) return URL('lk.smstraffic.kz')
  return URL('lk.smstraffic.ru')
})
const getData = () => {
  const token = getStoredAuthTokens()
  let data = {}
  if (token)
    data = {
      accessToken: token.accessToken,
      refreshToken: token.refreshToken,
      reason: reason.value,
    }
  return data
}

export const postTokenToOldFront = () => {
  void axios
    .post(getUrl.value, getData(), {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Allow-Origin': '*',
      },
    })
    .then(async (res: AxiosResponse) => {
      if (res.data?.hash) {
        await onLogout()
        window.location.href = `${getUrl.value}?hash=${res.data?.hash}`
      }
    })
    .catch((err) => {
      errorMessage.value = err.message
    })
}
