// import { PhonesType, PhonesTypeFile } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'
import { checkboxToBoolean } from '@/utils/checkbox'
import { basicEntityNameLength } from '@/utils/validation'
import type { RoutesType } from '@/utils/validation/batchGroupValid/batchGroupFormValidation'
import {
  getCommonChannelValidationSchema,
  getViberChannelValidationSchema,
  getWhatsappChannelValidationSchema,
} from '@/utils/validation/batchGroupValid/batchGroupFormValidation'
import yup from '@/utils/yup'

type CongratulateSettingsForm = {
  routeGroupId: string
  originator: string
  startTime: string
  transliterate: boolean[]
  channels: string
}

type CongratulateSettings = {
  originator: string
  startTime: string
  transliterate: boolean
  routeGroupId: string
  channels: string
}

export type FormValues = {
  groupName: string
  viewForAll: boolean[]
  viewOptional: boolean[]
  congratulate: boolean[]
  startTime?: string
  originator?: string
  channels?: CongratulateSettingsForm
}

export type Values = {
  groupName: string
  viewForAll: boolean
  viewOptional: boolean
  congratulate?: CongratulateSettings
}

const { t } = i18n.global

export const MAX_ORIGINATOR_NAME_LENGTH = 15

export const MIN_ORIGINATOR_LENGTH = 2

// const phonesSource = 'batchCreate.form.phonesSource'
// const messageSection = 'batchCreate.form.message'

const originatorSchema = yup.string().when(['congratulate'], {
  is: (congratulate: boolean[]) => checkboxToBoolean(congratulate),
  then: (schema) => {
    return schema
      .requiredField('group.groupForm.congratulate.originator.label')
      .min(
        MIN_ORIGINATOR_LENGTH,
        t('validation.string.min', { number: MIN_ORIGINATOR_LENGTH })
      )
      .max(
        MAX_ORIGINATOR_NAME_LENGTH,
        t('validation.string.max', { number: MAX_ORIGINATOR_NAME_LENGTH })
      )
      .matches(
        /^[\w\s-]+$/,
        t('group.groupForm.congratulate.originator.validation')
      )
  },
})

const congratulateSchema = (routes: RoutesType | undefined) =>
  yup.object({
    originator: originatorSchema,
    startTime: yup.string().nullable(),
    transliterate: yup.array().nullable(),
    routeGroupId: yup.string().nullable(),
    channels: yup
      .object()
      .when(['congratulate'], {
        is: (congratulate: boolean[]) => {
          return checkboxToBoolean(congratulate)
        },
        then: (schemaChannel) =>
          schemaChannel.shape({
            sms: getCommonChannelValidationSchema(routes),
            viber: getViberChannelValidationSchema(routes),
            whatsapp: getWhatsappChannelValidationSchema(routes),
            ok: getCommonChannelValidationSchema(routes),
            vk: getCommonChannelValidationSchema(routes),
            email: getCommonChannelValidationSchema(routes),
            push: getCommonChannelValidationSchema(routes),
          }),
        otherwise: (schemaChannel) => schemaChannel.optional(),
      })
      .nullable(),
  })

export const validationSchema = (routes: RoutesType | undefined) => {
  return yup
    .object({
      groupName: yup
        .string()
        .requiredField('group.groupForm.groupName')
        .min(
          basicEntityNameLength.min,
          t('validation.string.min', { number: basicEntityNameLength.min })
        )
        .max(
          basicEntityNameLength.max,
          t('validation.string.max', { number: basicEntityNameLength.max })
        ),
      viewForAll: yup.array(),
      viewOptional: yup.array(),
      congratulate: yup.array(),
    })
    .concat(congratulateSchema(routes))
}
