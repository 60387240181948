import type { SelectTypes } from '@smst/ui'
import { isDefined } from 'remeda'

import type {
  AccountShortResult,
  DepartmentResult,
} from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'
import { formatDateToRequest } from '@/utils/formatDateToRequest'

import type { EventLogsQueryParams } from './EventLogs.types'

const { t } = i18n.global

type GetUsersOptionsParams = {
  departmentsListData?: DepartmentResult[]
  accountsListData?: AccountShortResult[]
}

export const getUsersOptions = ({
  departmentsListData,
  accountsListData,
}: GetUsersOptionsParams): SelectTypes.Group[] => {
  const mainAccount: SelectTypes.Group = {
    options: [
      {
        value: 0,
        label: t('eventLogs.filters.mainAccount'),
      },
    ],
  }

  const getAccountsGroup = (): SelectTypes.Group | undefined => {
    if (!accountsListData) return

    return {
      label: t('eventLogs.filters.users'),
      options: accountsListData.map((account) => ({
        value: account.id,
        label: account.fullLogin,
      })),
    }
  }

  const getDepartmentsGroup = (): SelectTypes.Group | undefined => {
    if (!departmentsListData) return

    return {
      label: t('eventLogs.filters.departmentUsers'),
      options: departmentsListData
        .map((department) => department.users)
        .flat()
        .map((user) => ({
          value: user.id,
          label: user.fullLogin,
        })),
    }
  }

  return [mainAccount, getAccountsGroup(), getDepartmentsGroup()].filter(
    isDefined
  )
}

const getDepartmentsByUserId = (
  departmentsListData?: DepartmentResult[]
): Record<number, number> => {
  if (!departmentsListData) return []

  return departmentsListData
    .map((department) => department.users)
    .flat()
    .reduce<Record<number, number>>((acc, item) => {
      acc[item.id] = item.departmentId

      return acc
    }, {})
}

type GetQueryParams = {
  departments?: DepartmentResult[]
  user?: number | null | undefined
  date?: Date[] | null | undefined
}

export const getQueryParams = ({
  departments,
  user,
  date,
}: GetQueryParams): EventLogsQueryParams => {
  const getDepartmentsQuery = () => {
    if (!departments || !isDefined(user)) return {}

    const departmentsByUserId = getDepartmentsByUserId(departments)

    // Если это учетная запись департамента
    if (departmentsByUserId[user]) {
      return {
        departmentId: departmentsByUserId[user],
        departmentUserId: user,
      }
    }
    // Если выбрана основная учетная запись
    if (user === 0) {
      return {
        departmentId: 0,
      }
    }

    // Если это суб.учетная запись
    return {
      departmentId: user,
      departmentUserId: 0,
    }
  }

  const getDateQuery = () => {
    if (!date) return {}

    return {
      from: formatDateToRequest(date[0]),
      to: formatDateToRequest(date[1]),
    }
  }

  return {
    ...getDepartmentsQuery(),
    ...getDateQuery(),
  }
}
