<template>
  <Table
    v-model:modelSort="sortModel"
    :data="sortedData"
    :columns="columns"
    :rowKey="rowKey"
  >
    <template #column:status="{ item }">
      <Text weight="semibold" :class="$style[`status-${item.status}`]">
        {{ t(`status.${item.status}`) }}
      </Text>
    </template>
    <template #column:addDate="{ item }">
      <TableDate :date="item.addDate" />
    </template>
    <template #column:twoFactorAuth="{ item }">
      {{ getTwoFactorAuthStatus(item.twoFactorAuth) }}
    </template>
    <template #column:users="{ item }">
      <ExpandableBlock :accounts="item.users" />
    </template>
    <template #rowAction="{ item }">
      <Dropdown
        :label="$t('actions')"
        :iconTrigger="IconActions"
        :items="getDropdownItems(item)"
      />
    </template>
  </Table>
</template>

<script setup lang="ts">
import type { DropdownTypes, TableTypes } from '@smst/ui'
import {
  Dropdown,
  IconActions,
  IconDelete,
  IconEdit,
  IconUnblock,
  Table,
  Text,
} from '@smst/ui'
import type { PropType } from 'vue'
import { computed, ref } from 'vue'

import type { DepartmentResult } from '@/api/__generated__/api.schema'
import TableDate from '@/components/TableDate/TableDate.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getTwoFactorAuthStatus } from '@/utils/getTwoFactorAuthStatus'

import ExpandableBlock from './components/ExpandableBlock.vue'
import { getSortedData } from './DepartmentsTable.utils'
import type { SortableDepartment } from './DepartmentsTable.utils'

const props = defineProps({
  data: {
    type: Array as PropType<DepartmentResult[]>,
    required: true,
  },
})

const t = useComponentI18n('departmentsTable')
const tTable = useComponentI18n('departmentsTable.columns')
const tActions = useComponentI18n('departmentsTable.actions')

const rowKey: keyof DepartmentResult = 'id'

const columns: Array<TableTypes.Column<DepartmentResult>> = [
  {
    name: tTable('name'),
    prop: 'name',
    sortable: true,
    wrap: true,
  },
  {
    name: tTable('status'),
    prop: 'status',
    sortable: true,
  },
  {
    name: tTable('addDate'),
    prop: 'addDate',
    sortable: true,
  },
  {
    name: tTable('twoFactorAuth'),
    prop: 'twoFactorAuth',
    sortable: true,
  },
  {
    name: tTable('accounts'),
    prop: 'users',
    sortable: true,
  },
]

const sortModel = ref<TableTypes.ModelSort<SortableDepartment>>({})

const sortedData = computed(() =>
  sortModel.value.field && sortModel.value.direction
    ? getSortedData(
        props.data,
        sortModel.value.field,
        sortModel.value.direction
      )
    : props.data
)

const emit = defineEmits(['edit', 'unblock', 'delete'])

const getDropdownItems = (item: DepartmentResult) => {
  const { id, name, status } = item

  const items: DropdownTypes.DropdownItem[] = [
    {
      icon: IconEdit,
      label: tActions('edit'),
      onClick: () => emit('edit', id),
    },
  ]

  if (status === 'deleted' || status === 'blocked') {
    items.push({
      icon: IconUnblock,
      label: tActions('unblock'),
      onClick: () => emit('unblock', { id, name }),
    })
  }

  if (status === 'active' || status === 'blocked') {
    items.push({
      icon: IconDelete,
      label: tActions('delete'),
      onClick: () => emit('delete', { id, name }),
    })
  }

  return items
}
</script>

<style module>
.status-active {
  color: var(--color-success);
}

.status-deleted {
  color: var(--color-error);
}

.status-blocked {
  color: var(--color-text-grey);
}
</style>
