import type { Login } from '@/api/__generated__/api.schema'
import yup from '@/utils/yup'

export const authFormSchema: yup.SchemaOf<Login> = yup
  .object({
    login: yup.string().requiredField('login.form.login'),
    password: yup.string().requiredField('login.form.password'),
    captchaCode: yup.string().notRequired().default(''),
  })
  .defined()
