<template>
  <FormControlBase v-slot="{ invalid }" :name="name">
    <div :class="$style.inner">
      <div :class="$style.time">
        <TimePicker
          :name="name"
          :invalid="invalid"
          :label="t('time')"
          :placeholder="t('time')"
          :defaultUserTime="timeUser"
        />
      </div>
    </div>
  </FormControlBase>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate'
import { computed, onMounted, watch } from 'vue'

import FormControlBase from '@/components/FormControlBase/FormControlBase.vue'
import TimePicker from '@/components/TimePicker/TimePicker.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { globalTime } from '@/utils/localTimeFromServer/globalLocalTime'

const props = defineProps({
  startSetDate: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
})

const t = useComponentI18n('dateTimeForm')

const timeUser = computed(() => {
  if (props.startSetDate) {
    const { hour, minutes } = globalTime.getTimeObjectFromStringDateTime(
      props.startSetDate
    )
    return { hours: hour, minutes }
  }
  return undefined
})
const { handleChange: handleServerDataTime } = useField<string>(
  props.name + 'ServerTime'
)
const { value: timePicker } = useField<string>(props.name + 'TimePicker')
const { value: HoursPicker } = useField<string>(props.name + 'HoursPicker')
const { value: MinutesPicker } = useField<string>(props.name + 'MinutesPicker')

const timeDatePickerObject = computed(() => {
  return {
    hours: HoursPicker.value ?? dataTimeLocalNow.value.hours,
    minutes: MinutesPicker.value ?? dataTimeLocalNow.value.minutes,
    day: dataTimeLocalNow.value.day,
    month: dataTimeLocalNow.value.month,
    year: dataTimeLocalNow.value.year,
  }
})

const dataTimeLocalNow = computed(() => globalTime.getLocalTimeDateObject())

const serverHandleTime = () => {
  const serverObjectDateTime = globalTime.formatObjectDateTimeToServerDateTime(
    timeDatePickerObject.value
  )
  const timeServerString = `${serverObjectDateTime.hours}:${serverObjectDateTime.minutes}:00`
  handleServerDataTime(timeServerString)
}

onMounted(() => {
  serverHandleTime()
})

watch(
  () => [timePicker.value],
  () => {
    serverHandleTime()
  }
)
</script>

<style module>
.inner {
  display: flex;
}

.time {
  width: 100%;
  margin-right: 4px;
}
</style>
