<template>
  <Text v-if="error" color="error">{{ errorMessage }}</Text>
</template>

<script lang="ts">
import { Text } from '@smst/ui'
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'

import { getErrorMessage } from '@/utils/errors'

export default defineComponent({
  components: { Text },
  props: {
    error: Object as PropType<unknown>,
  },
  setup(props) {
    const errorMessage = computed(() => getErrorMessage(props.error))

    return {
      errorMessage,
    }
  },
})
</script>
