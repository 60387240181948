const mainHost = location.hostname
const regionDomain = mainHost.slice(-2)
const regions = ['ru', 'by', 'uz', 'kg', 'kz']

// eslint-disable-next-line no-shadow
enum Region {
  ru = 'ru',
  by = 'by',
  uz = 'uz',
  kg = 'kg',
  kz = 'kz',
}

export const getRegionDomain = (): Region => {
  if (regions.includes(regionDomain))
    return Region[regionDomain as keyof typeof Region]
  return Region.ru
}
