import { toaster } from '@smst/ui'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import { downloadFile } from '@/utils/downloadFile'
import { getErrorMessage } from '@/utils/errors'

import { idReport } from './TableAnalysisPhones.utils'

export const initDownloadReport = () => {
  const { mutate: downloadDocument } = useMutation(
    async () => {
      return await apiClient.deliveryAnalysis.reportDownload_GET_BY(
        idReport.value,
        {},
        {
          format: 'blob',
        }
      )
    },
    {
      onSuccess: (response) => {
        void downloadFile({
          response,
          fileName: `Delivery-Analysis-SMS-Traffic-${idReport.value}`,
          extension: 'xls',
        })
      },

      onError: (error = '') => {
        toaster.error(getErrorMessage(error))
      },
    }
  )
  return downloadDocument
}
