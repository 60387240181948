import { computed, nextTick, ref } from 'vue'

import { getRegexPolitic } from '@/utils/passwordValidation'

import { startValidate } from '../Validation.utils'

import { initUserPasswordRules } from './InitUserPasswordRules'
import { getUserSchema } from './UserPasswordForm.schema'

const reload = ref(true)
const savedPolitic = ref('')

const {
  getSavePassword,
  setPolitics,
  setRegex,
  passwordRule,
  confirmPassword,
} = initUserPasswordRules()

const setInitRules = (politic: string) => {
  const regex = getRegexPolitic(politic)
  setPolitics(politic)
  setRegex(regex)
}

const schema = getUserSchema(passwordRule, confirmPassword)
const { handlerValidate, info, valid, values, clearForm } = startValidate(
  schema,
  getSavePassword
)

const data = computed(() => {
  return {
    password: values.value.password,
  }
})
const clearFormN = async () => {
  clearForm()
  reload.value = false
  // eslint-disable-next-line @typescript-eslint/await-thenable
  await nextTick()
  reload.value = true
}

// инициализируем файл
export const initUserPasswordUtils = (politic?: string | undefined) => {
  savedPolitic.value = politic ?? ''
  if (!savedPolitic.value && politic) setInitRules(politic)
  else setInitRules(savedPolitic.value)
  return {
    passwordsValidate: handlerValidate,
    info,
    formValid: valid,
    dataForSending: data,
    clearForm: clearFormN,
    reload,
  }
}
