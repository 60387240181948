<template>
  <FormControlBase v-slot="{ invalid }" :name="name" :class="fallthroughClass">
    <TagInput
      :modelValue="value"
      :label="label"
      :placeholder="placeholder"
      :invalid="invalid"
      :disabled="disabled"
      v-bind="fallthroughAttrs"
      @update:modelValue="handleChange"
      @blur="handleBlur"
    />
  </FormControlBase>
</template>

<script lang="ts">
import { TagInput } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes } from 'vue'
import { defineComponent } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formTagInputProps = {
  ...formControlProps,
  placeholder: String,
} as const

export type FormTagInputProps = ExtractPropTypes<typeof formTagInputProps>

export default defineComponent({
  components: { FormControlBase, TagInput },
  inheritAttrs: false,
  props: formTagInputProps,
  setup(props, { attrs }) {
    const { value, handleChange, handleBlur } = useField<string[]>(props.name)

    const { class: fallthroughClass, ...fallthroughAttrs } = attrs

    return {
      value,
      fallthroughClass,
      fallthroughAttrs,
      handleChange,
      handleBlur,
    }
  },
})
</script>
