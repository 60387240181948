import { addDays, subDays } from 'date-fns'
import { createEnumParam, NumberParam } from 'serialize-query-params'

import { BatchStatusField } from '@/api/__generated__/api.schema'
import type {
  BatchFileResponse,
  ParentBatchResult,
} from '@/api/__generated__/api.schema'
import type { ControlType } from '@/components/FormControls/FormControls.types'
import { i18n } from '@/i18n'
import { RangeDateParam } from '@/utils/date'
import { formatDateToRequest } from '@/utils/formatDateToRequest'

export type ImageData = { channel: string; file: BatchFileResponse }

const { t } = i18n.global

export const scheduledStatuses = [
  BatchStatusField.New,
  BatchStatusField.Processing,
  BatchStatusField.Paused,
  BatchStatusField.Premoderate,
]

export const archivedStatuses = [
  BatchStatusField.Finished,
  BatchStatusField.Canceled,
  BatchStatusField.Expired,
]

const scheduledStatusesOptions = [
  {
    value: BatchStatusField.New,
    label: t('batches.status.new'),
  },
  {
    value: BatchStatusField.Processing,
    label: t('batches.status.processing'),
  },
  {
    value: BatchStatusField.Paused,
    label: t('batches.status.paused'),
  },
  {
    value: BatchStatusField.Premoderate,
    label: t('batches.status.premoderate'),
  },
]

const archivedStatusesOptions = [
  {
    value: BatchStatusField.Finished,
    label: t('batches.status.finished'),
  },
  {
    value: BatchStatusField.Canceled,
    label: t('batches.status.canceled'),
  },
  {
    value: BatchStatusField.Expired,
    label: t('batches.status.expired'),
  },
]

export const getDefaultFilterValues = (isArchive: boolean) => {
  const daysToSubtract = isArchive ? 3 : 1
  const daysToAdd = isArchive ? 0 : 1

  return {
    date: [subDays(new Date(), daysToSubtract), addDays(new Date(), daysToAdd)],
  }
}

export const getFiltersControls = (isArchive: boolean): ControlType[] => {
  return [
    {
      control: 'datepicker',
      name: 'date',
      label: t('batches.filters.date'),
      range: true,
    },
    {
      control: 'select',
      name: 'status',
      withOptionAll: true,
      label: t('batches.filters.status'),
      options: isArchive ? archivedStatusesOptions : scheduledStatusesOptions,
    },
  ]
}

export const getQueryToRequest = (
  date: Date[] | null | undefined,
  status: BatchStatusField | null | undefined,
  isArchive: boolean
) => {
  const res: {
    dateFrom?: string | undefined
    dateTo?: string | undefined
    status?: BatchStatusField[] | undefined
  } = {}

  if (date) {
    res.dateFrom = formatDateToRequest(date[0])
    res.dateTo = formatDateToRequest(date[1])
  }

  if (status) {
    res.status = [status]
  } else {
    // Если статус не выбран в фильтрах - запрашиваем все статусы
    res.status = isArchive ? archivedStatuses : scheduledStatuses
  }

  return res
}

const StatusEnumParam = createEnumParam([
  ...scheduledStatuses,
  ...archivedStatuses,
])

export const filtersConfig = {
  date: RangeDateParam,
  status: StatusEnumParam,
  parentBatchId: NumberParam,
}

export const getImageSrc = (channel: string, imagesData: ImageData[]) => {
  const data = imagesData.find((item) => item.channel === channel)

  if (!data) {
    return
  }
  if (typeof data?.file === 'string') return data.file

  const mimeType = data.file.mimeType ?? 'image/png'

  return `data:${mimeType};base64, ${data.file.base64}`
}

export const getParentBatch = (
  filterParentBatchId: number | null | undefined,
  parentsBatches: ParentBatchResult[]
) => {
  // Если фильтр содержит ParentBatchId, возвращаем его
  if (filterParentBatchId) return filterParentBatchId

  // Если список пуст ничего не делаем
  if (parentsBatches.length === 0) return undefined

  // Если в списке parents batches есть parentBatchId: 0, возвращаем его
  if (parentsBatches.some(({ parentBatchId }) => parentBatchId === 0)) {
    return 0
  }

  // Если в списке parents batches не пуст, и нет parentBatchId: 0, возвращаем первый
  return parentsBatches[0].parentBatchId
}
