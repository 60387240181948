import type { ProfileResult } from '@/api/__generated__/api.schema'

export type AccountType =
  | 'user'
  | 'subUser'
  | 'departmentUser'
  | 'selfRegistered'

export const getAccountType = (
  profile: ProfileResult | undefined
): AccountType | undefined => {
  if (!profile) {
    return undefined
  }

  if (profile?.selfRegistered) {
    return 'selfRegistered'
  }

  if (profile?.departmentId === 0) {
    return 'user'
  }

  if (profile?.departmentUserId > 0) {
    return 'departmentUser'
  }
  return 'subUser'
}
