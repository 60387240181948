import { isDefined } from 'remeda'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import { useTableRequest } from '@/hooks/useTableRequest'
import { omitBy } from '@/utils/object'

export const idReport = ref()

type ItemReport = {
  id: number
  emails: string
  subject: string
  text: string
  sentDate: string
  files: Array<{ fileId: number; name: string }>
}

export const itemOpenedDrawer: Ref<boolean> = ref(false)
export const itemDataDrawer: Ref<ItemReport | undefined> = ref()

const addIdReport = (id: number | undefined) => {
  idReport.value = id
}
const setOpenItem = (itemData: ItemReport | undefined) => {
  itemOpenedDrawer.value = typeof itemData !== 'undefined'
  itemDataDrawer.value = itemData
  addIdReport(itemData?.id)
}

export const initReport = () => {
  const { request, paginationModel } = useTableRequest({
    filtersConfig: {},
    sortConfig: {},
  })

  const {
    data,
    isSuccess: isSuccess,
    isLoading: isLoading,
    isFetching: isFetching,
    error: error,
  } = useQuery(
    ['reportOnTable', request],
    () => {
      return apiClient.reportsOnRequest.list_GET({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        ...omitBy(request.value, isDefined),
      })
    },
    {
      keepPreviousData: false,
    }
  )

  const tableData = computed(() => data.value?.data.list ?? [])
  const showTable = ref(true)

  const totalTableItems = computed(
    () => data.value?.data.pageInfo?.totalItems ?? 0
  )
  const showEmpty = computed(
    () => tableData.value.length === 0 && isSuccess.value
  )
  return {
    paginationModel,
    showEmpty,
    totalTableItems,
    showTable,
    tableData,
    data,
    isSuccess,
    isLoading,
    isFetching,
    error,
    addIdReport,
    setOpenItem,
  }
}
