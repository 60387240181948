<template>
  <div :class="$style.root" noTouched>
    <Timepicker
      v-model="time"
      :label="timeLabel"
      :invalid="invalid"
      :disabled="disabled"
      @blur="handleBlur"
    />

    <Datepicker
      v-model="date"
      :locale="locale"
      :label="dateLabel"
      :invalid="invalid"
      :minDate="minDate"
      :maxDate="maxDate"
      :maxRange="maxRange"
      :minRange="minRange"
      :monthChangeOnScroll="false"
      :disabled="disabled"
      @blur="handleBlur"
    />
  </div>
</template>

<script setup lang="ts">
import { Datepicker, Timepicker } from '@smst/ui'
import { set, startOfToday } from 'date-fns'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { getTimeFromDate } from './FormDateTimepicker.utils'

const props = defineProps({
  modelValue: {
    type: Date,
    required: true,
  },
  defaultValue: {
    type: Date,
    default: startOfToday(),
  },
  invalid: Boolean,
  disabled: Boolean,
  timeLabel: {
    type: String,
    default: '',
  },
  dateLabel: {
    type: String,
    default: '',
  },
  minDate: {
    type: Date,
    // Дата основания компании устанавливается как минимальная возможная дата
    default: new Date(2000, 0),
  },
  maxDate: Date,
  maxRange: Number,
  minRange: Number,
})

const emit = defineEmits(['update:modelValue'])

const fillEmptyTimeTokens = (currentTime: string, defaultDate: Date) => {
  return getTimeFromDate(defaultDate)
    .split('')
    .map((token, index) => (currentTime[index] ? currentTime[index] : token))
    .join('')
}

const time = ref(getTimeFromDate(props.modelValue))
const date = ref(props.modelValue)

const { locale } = useI18n()

const handleBlur = () => {
  const [hours, minutes, seconds] = fillEmptyTimeTokens(
    time.value,
    props.defaultValue
  )
    .split(':')
    .map(Number)

  const preparedDate = set(new Date(date.value ?? props.defaultValue), {
    hours,
    minutes,
    seconds,
  })

  time.value = getTimeFromDate(preparedDate)
  date.value = preparedDate

  emit('update:modelValue', preparedDate)
}
</script>

<style module>
.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: var(--gap-16);
}
</style>
