<template>
  <Drawer
    :modelValue="isOpen"
    :closeText="t('closeHint')"
    @update:modelValue="handleClose"
  >
    <template #title>
      <Text tag="h2" :class="$style.title">{{ t('title') }}</Text>
    </template>

    <DraftForm formId="draft-create" @submit="createDraft" />

    <template #buttons>
      <Button type="submit" form="draft-create" :loading="isLoading">
        {{ t('buttons.create') }}
      </Button>
      <Button type="button" view="link" @click="handleClose">
        {{ t('buttons.cancel') }}
      </Button>
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import { Button, Drawer, Text, toaster } from '@smst/ui'
import { useMutation, useQueryClient } from 'vue-query'
import { useRoute, useRouter } from 'vue-router'

import { apiClient } from '@/api'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'
import { getErrorMessage } from '@/utils/errors'

import DraftForm from './DraftForm.vue'

const router = useRouter()
const route = useRoute()

const t = useComponentI18n('draft.create')

defineProps({
  isOpen: Boolean,
})

const queryClient = useQueryClient()

const { mutate: createDraft, isLoading } = useMutation(
  apiClient.draft.create_POST,
  {
    onSuccess: (res) => {
      void queryClient.invalidateQueries('drafts')

      if (res.data.draft) {
        toaster.success(t('success', { name: res.data.draft?.name }))
      }

      handleClose()
    },
    onError: (e) => {
      toaster.error(getErrorMessage(e))
    },
  }
)

const handleClose = () => {
  void router.push({ ...route, name: RouteNames.Drafts })
}
</script>

<style module>
.title {
  margin-bottom: var(--gap-24);
}
</style>
