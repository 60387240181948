import type { DepartmentResult } from '@/api/__generated__/api.schema'
import { handleTwoFactor } from '@/components/TwoFactorInput/TwoFactorInput.utils'
import type { TwoFactorType } from '@/components/TwoFactorInput/TwoFactorInput.utils'

export type FormValues = {
  name: string
  login: string
  twoFactorAuthPhone?: TwoFactorType
}

export const getInitialValues = (
  departmentData: DepartmentResult
): FormValues => ({
  name: departmentData.name,
  login: departmentData.login,
  twoFactorAuthPhone: handleTwoFactor(departmentData?.twoFactorAuth),
})
