import type { PassStrengthPolitics } from '@/api/__generated__/api.schema'
import type { TwoFactorType } from '@/components/TwoFactorInput/TwoFactorInput.utils'
import { twoFactorAuthPhone } from '@/components/TwoFactorInput/TwoFactorInput.utils'
import type { Role } from '@/hooks/usePermissions'
import { i18n } from '@/i18n'
import { passStrengthPolitics } from '@/utils/passwordValidation'
import { basicEntityNameLength, loginRegex } from '@/utils/validation'
import yup from '@/utils/yup'

// import { strengthSelectedValue } from '../SelectPassStrength/PassStrengthPolitics.utils'

const { t } = i18n.global

type FormValues = {
  name: string
  login: string
  defaultOriginator?: string
  twoFactorAuthPhone?: TwoFactorType
  roles?: Role[]
  countryId?: number
  timezoneId?: number
  passStrengthPolitics?: PassStrengthPolitics
}

export type CreateFormValues = FormValues & {
  password?: string
  passwordConfirmation?: string
}

export type EditFormValues = FormValues

const baseSchema: yup.SchemaOf<FormValues> = yup.object({
  name: yup
    .string()
    .min(
      basicEntityNameLength.min,
      t('validation.string.min', { number: basicEntityNameLength.min })
    )
    .max(
      basicEntityNameLength.max,
      t('validation.string.max', { number: basicEntityNameLength.max })
    )
    .requiredField('accountForm.name.label'),
  login: yup
    .string()
    .matches(loginRegex, t('validation.login'))
    .max(60, t('validation.string.max', { number: 60 }))
    .requiredField('accountForm.login.label'),
  twoFactorAuthPhone,
  defaultOriginator: yup
    .string()
    .test(
      'min',
      t('validation.string.min', { number: 2 }),
      (defaultOriginator) => !defaultOriginator || defaultOriginator.length >= 2
    )
    .test(
      'max',
      t('validation.string.max', { number: 15 }),
      (defaultOriginator) =>
        !defaultOriginator || defaultOriginator.length <= 15
    ),
  roles: yup.array<yup.SchemaOf<Role>>(yup.number()).default([]),
  countryId: yup.number(),
  timezoneId: yup.number(),
  passStrengthPolitics: yup
    .string()
    .oneOf(passStrengthPolitics, t('validation.passwordConfirmation'))
    .requiredField('accountForm.passwordConfirmation.label'),
})

export const editAccountSchema: yup.SchemaOf<EditFormValues> =
  baseSchema.concat(
    yup.object({
      countryId: yup.number().required(
        t('validation.required', {
          name: t('accountForm.timezone.country.label'),
        })
      ),
      timezoneId: yup.number().required(
        t('validation.required', {
          name: t('accountForm.timezone.timezone.label'),
        })
      ),
    })
  )
export const editDepartmentAccountSchema: yup.SchemaOf<EditFormValues> =
  baseSchema
