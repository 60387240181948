<template>
  <template v-if="hasTableData">
    <header :class="$style.header">
      <Text tag="h2">
        {{ t('title') }}
      </Text>

      <Link
        :to="{ name: RouteNames.Batches }"
        :iconRight="IconChevronNext"
        view="link"
      >
        {{ t('link') }}
      </Link>
    </header>

    <TableScrollbar :class="$style.tableWrap">
      <ScheduledBatches :data="tableData" />
    </TableScrollbar>
  </template>

  <div v-if="error" :class="$style.container">
    <ApiErrorMessage :error="error" />
  </div>
</template>

<script lang="ts" setup>
import { IconChevronNext, Text } from '@smst/ui'
import { addYears } from 'date-fns'
import { computed } from 'vue'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import { BatchStatusField, Direction } from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Link from '@/components/Link/Link.vue'
import TableScrollbar from '@/components/TableScrollbar/TableScrollbar.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import ScheduledBatches from '@/pages/Batches/components/ScheduledBatches/ScheduledBatchesTable.vue'
import { RouteNames } from '@/routeNames'
import { formatDateToRequest } from '@/utils/formatDateToRequest'

const t = useComponentI18n('dashboard.batches')
const {
  data: batches,
  error,
  isSuccess,
} = useQuery('batches', () =>
  apiClient.batches.list_GET({
    // Период выставляем в год от текущего дня
    dateFrom: formatDateToRequest(addYears(new Date(), -1)),
    //Выводим рассылки только в статусе premoderate
    status: [BatchStatusField.Premoderate],
    //Сортировка фиксированная, от старых к новым
    field: 'startDate',
    direction: Direction.Asc,
    // Показываем только 3 последние записи
    page: 1,
    size: 3,
  })
)
const tableData = computed(() => batches.value?.data.list ?? [])

const hasTableData = computed(
  () => isSuccess.value && tableData.value.length > 0
)
</script>

<style module>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableWrap {
  margin-top: var(--gap-24);
}

.container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-top: var(--gap-24);
}
</style>
