import { ref } from 'vue'

type SelectedElements<T> = {
  all: boolean
  include: T[]
  exclude: T[]
}

export const useTableSelection = <T extends string | number>() => {
  const emptySelection = {
    all: false,
    include: [],
    exclude: [],
  }
  const selectionModel = ref<SelectedElements<T>>(emptySelection)

  return {
    selectionModel,
    resetSelection: () => {
      selectionModel.value = emptySelection
    },
  }
}
