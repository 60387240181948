import type { PaginationTypes, TableTypes } from '@smst/ui'
import { isDefined } from 'remeda'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import { omitBy } from '@/utils/object'

import type { FiltersModel } from './useGetFiltersState/useGetFiltersState.types'

const messagesLogQueryKey = 'messages-log'

export type RequestMessagesLogTable = FiltersModel &
  TableTypes.ModelSort &
  PaginationTypes.Model

export const useGetMessagesLogTable = (
  request: ComputedRef<RequestMessagesLogTable>,
  isEmptyFilters: ComputedRef<boolean>
) => {
  const enabled = computed(() => !isEmptyFilters.value)
  const {
    data: messagesLog,
    error,
    isLoading,
    isFetching,
  } = useQuery(
    [messagesLogQueryKey, request],
    async () => {
      const { data } = await apiClient.messages.getMessages(
        omitBy(request.value, isDefined)
      )

      return data
    },

    {
      enabled,
      keepPreviousData: true,
    }
  )

  const tableData = computed(() => messagesLog.value?.list ?? [])
  const totalItems = computed(
    () => messagesLog.value?.pageInfo?.totalItems ?? 0
  )
  const showEmptyState = computed(
    () => isEmptyFilters.value || tableData.value.length === 0
  )
  const totalCosts = computed(
    () => messagesLog.value?.costsInfo?.totalCosts ?? 0
  )

  return {
    showEmptyState,
    tableData,
    totalItems,
    totalCosts,
    isLoadingMessagesLog: isLoading,
    isFetchingMessagesLog: isFetching,
    errorMessagesLog: error,
  }
}
