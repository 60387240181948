import {
  decodeQueryParams,
  encodeQueryParams,
  ObjectParam,
} from 'serialize-query-params'
import { computed } from 'vue'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

import { apiClient } from '@/api'
import type {
  GroupsSendSmartForm,
  GroupsSendSmsForm,
  SimpleSendSmartForm,
  SimpleSendSmsForm,
} from '@/api/__generated__/api.schema'
import { useProfile } from '@/hooks/useProfile'

import type { RoutesType } from './components/BatchForm/BatchForm.utils'

export type SelectedElements = {
  all: boolean
  include: number[]
  exclude: number[]
}

const { profile } = useProfile()

export type SelectedPhonesData = {
  groupId: number
  selectedPhones: SelectedElements
}

export const defaultRoute = { sms: ['sms'] } as RoutesType

export const selectedElementsToQueryString = (
  selectedElements: SelectedElements
) => {
  const encodedAll = String(selectedElements.all)
  const encodedInclude = selectedElements.include.join('-')
  const encodedExclude = selectedElements.exclude.join('-')

  const { object: encodedObject } = encodeQueryParams(
    {
      object: ObjectParam,
    },
    {
      object: {
        all: encodedAll,
        include: encodedInclude,
        exclude: encodedExclude,
      },
    }
  )

  return encodedObject as string
}

export const queryStringToSelectedElements = (
  queryString: string
): SelectedElements => {
  const { object: decodedObject } = decodeQueryParams(
    { object: ObjectParam },
    { object: queryString }
  )

  const decodedAll = decodedObject?.all === 'true'
  const decodedInclude = (decodedObject?.include?.split('-') ?? [])
    .filter((item) => item !== '')
    .map((item) => Number(item))
  const decodedExclude = (decodedObject?.exclude?.split('-') ?? [])
    .filter((item) => item !== '')
    .map((item) => Number(item))

  const selectedElements = {
    all: decodedAll,
    include: decodedInclude,
    exclude: decodedExclude,
  }

  return selectedElements
}

export type ShowData = {
  phonesCount: number
  phonesType: string
  phonesInput: string
  message: string
  batchName: string
  transliterate: string
  blackListId: string
  gap: string
  originator: string
}

export type SendFormCommonType =
  | SimpleSendSmsForm
  | SimpleSendSmartForm
  | GroupsSendSmsForm
  | GroupsSendSmartForm
  | ShowData

export const isSimpleSendSmsForm = (
  values: SendFormCommonType
): values is SimpleSendSmsForm => {
  // @ts-expect-error Проверка
  if (values.channels === undefined && values.selectedElements === undefined) {
    return true
  }

  return false
}

export const isSimpleSendSmartForm = (
  values: SendFormCommonType
): values is SimpleSendSmartForm => {
  // @ts-expect-error Проверка
  if (values.channels !== undefined && values.selectedElements === undefined) {
    return true
  }

  return false
}

export const isGroupsSendSmsForm = (
  values: SendFormCommonType
): values is GroupsSendSmsForm => {
  // @ts-expect-error Проверка
  if (values.channels === undefined && values.selectedElements !== undefined) {
    return true
  }

  return false
}

export const isGroupsSendSmartForm = (
  values: SendFormCommonType
): values is GroupsSendSmartForm => {
  // @ts-expect-error Проверка
  if (values.channels !== undefined && values.selectedElements !== undefined) {
    return true
  }

  return false
}

const isSmartDelivery = computed(() => {
  return profile?.value?.smartDelivery ?? null
})

const queryMap = {
  /* Номера в текстовом поле или загрузка из файла */
  simple: {
    sms: apiClient.send.postSend,
    smart: apiClient.send.smart_POST,
  },
  groups: {
    sms: apiClient.send.groupsSms_POST,
    smart: apiClient.send.groupsSmart_POST,
  },
  phones: {
    sms: apiClient.send.groupSms_POST,
    smart: apiClient.send.groupSmart_POST,
  },
}

export const getQuery = (
  values: SendFormCommonType,
  route: RouteLocationNormalizedLoaded
) => {
  if (isSimpleSendSmsForm(values)) {
    if (isSmartDelivery.value) {
      return queryMap.simple.smart(values)
    }
    return queryMap.simple.sms(values)
  }

  if (isSimpleSendSmartForm(values)) {
    if (!isSmartDelivery.value) {
      return queryMap.simple.sms(values)
    }
    return queryMap.simple.smart(values)
  }

  const getSelectedPhonesData = () => {
    const group = route.query.group as string
    const querySelectedGroups = route.query.selectedPhones as string | undefined

    if (!group || !querySelectedGroups) {
      return undefined
    }

    const result = queryStringToSelectedElements(querySelectedGroups)

    return {
      groupId: Number(group),
      selectedPhones: result,
    }
  }

  const selectedPhonesData = getSelectedPhonesData()

  if (selectedPhonesData) {
    if (!isSmartDelivery.value) {
      return queryMap.phones.sms(selectedPhonesData.groupId, values)
    }

    return queryMap.phones.smart(selectedPhonesData.groupId, values)
  }

  if (!isSmartDelivery.value) {
    return queryMap.groups.sms(values)
  }

  return queryMap.groups.smart(values)
}
