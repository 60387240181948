<template>
  <Row>
    <FormCheckbox
      :value="checkBoxess"
      :name="timeOutCheckBoxName"
      :label="t('timeout')"
      :class="$style.marginBottom24"
    />

    <FormInput
      v-show="checkBoxes?.length > 0"
      :ref="handleTimeOut"
      :name="timeOutBatchFormName"
      :label="t('timeSeconds')"
      :class="$style.marginBottom8"
    />

    <Text
      v-if="checkBoxes?.length > 0 && timeOutOursMinutes?.hours"
      tag="p"
      size="s"
      weight="medium"
      color="secondary"
      :class="$style.marginLeft"
    >
      {{ timeOutOursMinutes?.hours }}:{{ timeOutOursMinutes?.minutes }}:{{
        timeOutOursMinutes?.seconds
      }}
    </Text>
    <!-- <template #right>
        <HintCard v-html="tHint('message')" />
      </template> -->
  </Row>
</template>

<script lang="ts" setup>
import { useField, useFieldValue } from 'vee-validate'
import { computed, ref, toRaw, watch } from 'vue'

import FormCheckbox from '@/components/FormCheckbox/FormCheckbox.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import { Row } from '@/components/Grid'
import { useComponentI18n } from '@/hooks/useComponentI18n'

const props = defineProps({
  name: String,
  timeOut: Number,
  isSmsRoute: Boolean,
})

const MAX_TIME_OUT = '86400'
const MIN_TIME_OUT = '30'

const timeOutBatchFormName = computed(() => {
  return props.name ? `timeOut${props.name}` : 'timeOut'
})

const timeOutCheckBoxName = computed(() => {
  return props.name ? `checkBoxTimeOut-${props.name}` : 'checkBoxTimeOut'
})
const {
  value: timeOutBatch,
  setValue,
  resetField,
} = useField<number>(timeOutBatchFormName.value)
const checkBoxes = useFieldValue<boolean[]>(timeOutCheckBoxName.value)
const { setValue: setTimeOutChannels } = useField(
  `channels.${props.name}.timeout`
)

watch(
  () => checkBoxes.value,
  (newVal) => {
    // console.log('checkBoxes newVal', toRaw(newVal))
    const checkbox = toRaw(newVal)
    if (props?.timeOut && checkbox.length > 0) {
      setValue(props?.timeOut)
    } else {
      resetField()
    }
  }
)

watch(
  () => checkBoxes.value,
  (newVal) => {
    if (newVal.length > 0) {
      setValue(props?.timeOut)
    } else {
      resetValueTimeOut()
    }
  }
)

const checkBoxess = ref(false)

const saveTimeOut = ref()
const handleTimeOuted = ref()

const handleTimeOut = (el) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  // else  handleTimeOuted.value = el.value
  if (!el?.value) return
  if (el.value === handleTimeOuted.value) return
  if (saveTimeOut.value) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(saveTimeOut.value)
  }
  if (el?.value && !Number.isInteger(Number(el.value))) {
    // console.log('jkjk');
    if (el.value.length === 0) {
      saveTimeOut.value = setTimeout(() => (el.value = MIN_TIME_OUT), 2000)
      // handleTimeOuted.value = el.value
    } else {
      el.value = el.value
        .split('')
        .filter((item: number | string) => Number.isInteger(Number(item)))
        .join('')
      if (el.value.length === 0)
        saveTimeOut.value = setTimeout(() => (el.value = MIN_TIME_OUT), 2000)
      if (el?.value && Number(el.value) > Number(MAX_TIME_OUT)) {
        saveTimeOut.value = setTimeout(() => (el.value = MAX_TIME_OUT), 1300)
      }
      if (el?.value && Number(el.value) < Number(MIN_TIME_OUT)) {
        saveTimeOut.value = setTimeout(() => (el.value = MIN_TIME_OUT), 2000)
      }
      handleTimeOuted.value = el.value
      return
    }
    return
  } else {
    if (el?.value && Number(el.value) > Number(MAX_TIME_OUT)) {
      saveTimeOut.value = setTimeout(() => (el.value = MAX_TIME_OUT), 1300)
      // handleTimeOuted.value = el.value
    }
    if (el?.value && Number(el.value) < Number(MIN_TIME_OUT)) {
      saveTimeOut.value = setTimeout(() => (el.value = MIN_TIME_OUT), 2000)
    }
    handleTimeOuted.value = el.value
    return
  }
}

const timeOutOursMinutes = ref()

const handleOursMinutes = (timestamp: number) => {
  const hours = Math.floor(timestamp / 60 / 60)
  const minutes = Math.floor(timestamp / 60) - hours * 60
  const seconds = timestamp % 60
  return {
    hours: hours < 10 ? `0${hours}` : hours,
    minutes: minutes < 10 ? `0${minutes}` : minutes,
    seconds: seconds < 10 ? `0${seconds}` : seconds,
  }
}
watch(
  () => timeOutBatch.value,
  (nawVal) => {
    timeOutOursMinutes.value = handleOursMinutes(Number(nawVal))
    setTimeOutChannels(nawVal)
    setValue(nawVal)
  }
)

const t = useComponentI18n('batchCreate.form')
</script>

<style module>
.marginBottom8 {
  margin-bottom: var(--gap-8);
}

.marginTop24 {
  margin-top: var(--gap-24);
}

.marginBottom24 {
  margin-bottom: var(--gap-24);
}

.channelCard {
  margin-top: var(--gap-4);
}

.channelTitle {
  margin-bottom: var(--gap-12);
}

.marginLeft {
  margin-left: 12px;

  font-size: 12px;
}
</style>
