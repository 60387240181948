import { parseISO } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

export type DateType = string | number | Date

export const serverTimeToUserTime = (
  date: DateType,
  userTimezone = 'Europe/Moscow'
) => {
  /**
   * Moscow time to UTC -> UTC to local timezone
   */

  const dateObj = typeof date === 'string' ? parseISO(date) : new Date(date)
  const utcDate = zonedTimeToUtc(dateObj, 'Europe/Moscow')
  const localTime = utcToZonedTime(utcDate, userTimezone)

  return localTime
}

export const userTimeToServerTime = (
  date: DateType | string,
  userTimezone = 'Europe/Moscow'
) => {
  /**
   * Бэкэнд работает только с Moscow timezone
   * Local time to UTC -> UTC to Moscow timezone
   *
   */

  const dateObj = typeof date === 'string' ? parseISO(date) : new Date(date)
  const utcLocalDate = zonedTimeToUtc(dateObj, userTimezone)
  const apiTime = utcToZonedTime(utcLocalDate, 'Europe/Moscow')

  return apiTime
}
