import type { SelectTypes } from '@smst/ui'
import { times } from 'remeda'

import type { AccountSecurityResult } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'

type Option = SelectTypes.Option

export type FormValues = {
  inactivityPeriod: number
  authTimeout: number
  passwordPeriod: number
  allowedGates: number
  maxSessions: number
}

export const formFields = [
  'inactivityPeriod',
  'authTimeout',

  'passwordPeriod',
  'allowedGates',
  'maxSessions',
] as const

type ConfigRule = {
  base: number
  step: number
  number: number
}

const fieldsConfig = {
  inactivityPeriod: {
    rules: [
      {
        base: 1,
        step: 1,
        number: 9,
      },
      {
        base: 10,
        step: 5,
        number: 4,
      },
      {
        base: 30,
        step: 10,
        number: 7,
      },
    ],
    units: 'day',
  },
  authTimeout: {
    rules: [
      {
        base: 3,
        step: 1,
        number: 13,
      },
    ],
    units: 'minute',
  },
  passwordPeriod: {
    rules: [
      {
        base: 30,
        step: 15,
        number: 5,
      },
    ],
    units: 'day',
  },
  maxSessions: {
    rules: [
      {
        base: 1,
        step: 1,
        number: 10,
      },
    ],
    units: null,
  },
}

const allowedGatesValues = {
  lkOnly: 8,
  apiOnly: 247,
  defaultValue: 255,
} as const

export const getFieldsOptions = () => {
  const t = useComponentI18n('editAccount.sections.security.select')
  const tUnits = useComponentI18n('units')

  const defaultOption = { value: 0, label: t('defaultValue') }

  const initialConfigurableFieldsValue = {
    inactivityPeriod: [],
    authTimeout: [],
    passwordPeriod: [],
    maxSessions: [],
  }

  const configurableFields = Object.keys(fieldsConfig) as Array<
    keyof typeof fieldsConfig
  >

  type ConfigurableFieldsKeys = (typeof configurableFields)[number]
  type ConfigurableFieldsOptions = Record<ConfigurableFieldsKeys, Option[]>

  const configurableFieldsOptions =
    configurableFields.reduce<ConfigurableFieldsOptions>((acc, fieldName) => {
      const fieldConfig = fieldsConfig[fieldName]

      const getOptionsByRule = (units: string | null) => (rule: ConfigRule) =>
        times(rule.number, (i) => {
          const n = rule.base + rule.step * i
          const u = units ? ' ' + tUnits(units, n) : ''

          return {
            value: n,
            label: `${n}${u}`,
          }
        })

      const options = fieldConfig.rules
        .map(getOptionsByRule(fieldConfig.units))
        .flat()

      options.unshift(defaultOption)

      return { ...acc, [fieldName]: options }
    }, initialConfigurableFieldsValue)

  const allowedGatesOptions = [
    { value: allowedGatesValues.defaultValue, label: t('defaultValue') },
    { value: allowedGatesValues.lkOnly, label: t('allowedGatesValues.lkOnly') },
    {
      value: allowedGatesValues.apiOnly,
      label: t('allowedGatesValues.apiOnly'),
    },
  ]

  return { ...configurableFieldsOptions, allowedGates: allowedGatesOptions }
}

export const getInitialValues = (securityData: AccountSecurityResult) => {
  const defaultValue = 0

  return {
    inactivityPeriod: securityData.inactivityPeriod ?? defaultValue,
    authTimeout: securityData.authTimeout ?? defaultValue,
    passwordPeriod: securityData.passwordPeriod ?? defaultValue,
    allowedGates: securityData.allowedGates ?? allowedGatesValues.defaultValue,
    maxSessions: securityData.maxSessions ?? defaultValue,
  }
}
