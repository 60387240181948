<template>
  <FormControlBase :name="name">
    <div :class="$style.timeInner">
      <div :class="$style.leftInner">
        <div>{{ label }}</div>
        <div>{{ gapTime }}</div>
      </div>
      <div :class="$style.selectInner">
        <FormSelectNormal
          name="garTimeOurs"
          :label="t('hours')"
          :placeholder="t('hours')"
          :searchable="true"
          :disabled="disabled"
          :options="ours ?? []"
          :class="$style.timeSelect"
          :locale="{
            noOptionsText: $t('timezone.timezone.noOptionsText'),
            noResultsText: $t('form.multiselect.noResultsText'),
          }"
        />
        <div v-if="!disabled">:</div>
        <FormSelectNormal
          name="gapTimeMinutes"
          :label="t('minutes')"
          :placeholder="t('minutes')"
          :searchable="true"
          :disabled="disabled"
          :options="minutes ?? []"
          :class="$style.timeSelect"
          :locale="{
            noOptionsText: $t('timezone.timezone.noOptionsText'),
            noResultsText: $t('form.multiselect.noResultsText'),
          }"
        />
      </div>
    </div>
  </FormControlBase>
</template>

<script lang="ts" setup>
// import { Select } from '@smst/ui'
import { useField } from 'vee-validate'
import { watch } from 'vue'

import FormControlBase from '@/components/FormControlBase/FormControlBase.vue'
import FormSelectNormal from '@/components/FormSelectNormal/FormSelectNormal.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

import { minutes, ours } from './FormTimeGap.utils'

const t = useComponentI18n('dateTimeForm')

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  disabled: Boolean,
})

const { handleChange: handleGapTime, value: gapTime } = useField<string>(
  props.name,
  {},
  {
    initialValue: '00',
  }
)

const { value: Ours } = useField<string>(
  'garTimeOurs',
  {},
  {
    initialValue: '00',
  }
)
const { value: Minutes } = useField<string>(
  'gapTimeMinutes',
  {},
  {
    initialValue: '00',
  }
)
watch(
  () => [Ours.value, Minutes.value],
  (newValue) => {
    const oursMinutes = `${newValue.join(':')}:00`
    handleGapTime(oursMinutes)
  }
)
</script>

<style module>
.timeInner {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.timeSelect {
  width: 100px;
}

.leftInner {
  display: flex;
  flex-direction: column;
}

.selectInner {
  display: flex;
  align-items: center;
}
</style>
