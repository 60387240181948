import * as yup from 'yup'

import { i18n } from '@/i18n'

const { t } = i18n.global

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function requiredField(this: any, message: string) {
  return this.required(t('validation.required', { name: t(message) }))
}

yup.addMethod(yup.string, 'requiredField', requiredField)

// eslint-disable-next-line import/no-default-export
export default yup
