<template>
  <FormControlBase v-slot="{ invalid }" :name="name">
    <FileInput
      v-model="file"
      :invalid="invalid"
      :locale="locale"
      :accept="accept"
      :maxSize="maxSize"
    >
      <template v-if="descriptions && descriptions.length > 0">
        <Text
          v-for="description in descriptions"
          :key="description"
          tag="p"
          color="secondary"
        >
          {{ description }}
        </Text>
      </template>
    </FileInput>
  </FormControlBase>
</template>

<script setup lang="ts">
import type { FileInputTypes } from '@smst/ui'
import { FileInput, Text, toaster } from '@smst/ui'
import { useField } from 'vee-validate'
import type { PropType } from 'vue'
import { ref, watch, watchEffect } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import FormControlBase from '@/components/FormControlBase/FormControlBase.vue'
import { getErrorMessage } from '@/utils/errors'

type Value = {
  file: File
}

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  useUrl: Boolean,
  locale: {
    type: Object as PropType<FileInputTypes.Locale>,
    required: true,
  },
  accept: String,
  maxSize: Number,
  descriptions: Array as PropType<string[]>,
})

const file = ref<File | undefined>()

const { handleChange, value: formValue } = useField<string>(props.name)

const useUrl = props?.useUrl ?? false

const { mutate: uploadFile } = useMutation(
  (value: Value) => apiClient.files.imagesUpload_POST(value, { useUrl }),
  {
    onSuccess: (response) => {
      if (useUrl) handleChange(response.data.data?.fileUrl)
      else handleChange(response.data.data?.fileName)
    },
    onError: (e) => {
      toaster.error(getErrorMessage(e))
      file.value = undefined
    },
  }
)

watchEffect(() => {
  if (file.value) {
    const formData = new FormData()
    formData.set('file', file.value)

    uploadFile(formData as unknown as Value)
  } else {
    handleChange(undefined)
  }
})

// для сброса состояния при resetForm
watch(
  () => formValue.value,
  (newValue) => {
    if (!newValue) {
      file.value = undefined
    }
  }
)
</script>
