import { ref } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'

const isCodeValid = ref<boolean>(true)

export const setCodeValid = (codeValue: boolean) => {
  isCodeValid.value = codeValue
}

export const generateInit = (sessionId: string) => {
  const {
    mutate: generate,
    isLoading,
    error,
  } = useMutation(apiClient.auth.generateSecreteCode_POST, {
    onSuccess: ({ data }) => {
      isCodeValid.value = true
      // New 2FA code
      data.secreteCode && console.info(`New 2FA code: `, data.secreteCode)
    },
  })

  const handleGenerate = () => {
    generate({
      sessionId,
    })
    setCodeValid(true)
  }
  return {
    handleGenerate,
    isLoading,
    error,
    isCodeValid,
    setCodeValid,
  }
}
