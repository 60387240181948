import { InitPasswordRules } from '../PasswordRules'

const init = new InitPasswordRules()

export const initOldPasswordRules = () => {
  return {
    passwordRule: init.passwordRule.bind(init),
    oldPasswordRule: init.oldPasswordRule.bind(init),
    confirmPassword: init.confirmPassword.bind(init),
    getSavePassword: init.getSavePassword.bind(init),
    setPolitics: init.setPolitics.bind(init),
    setRegex: init.setRegex.bind(init),
  }
}
