import { initLocale } from '@/utils/reactiveLocale'
import yup from '@/utils/yup'

const locale = initLocale()
const MAX_MESSAGE_LENGTH = 1000

export const templatesCheckSchema: yup.SchemaOf<{ message: string }> =
  yup.object({
    message: yup
      .string()
      .max(
        MAX_MESSAGE_LENGTH,
        locale('validation.string.max', { number: MAX_MESSAGE_LENGTH })
      )
      .min(1, locale('templatesCheck.label')),
  })
