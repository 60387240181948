import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { i18n } from '@/i18n'
import { originatorOptions } from '@/utils/validation'
import yup from '@/utils/yup'

import type { CreateOriginatorsForm } from './CreateOriginatorsForm.types'

const { t } = i18n.global

export const createOriginatorsFormSchema: ComputedRef<
  yup.SchemaOf<CreateOriginatorsForm>
> = computed(() =>
  yup.object({
    originator: yup
      .string()
      .min(
        originatorOptions.minLength,
        t('validation.string.min', {
          number: originatorOptions.minLength,
        })
      )
      .max(
        originatorOptions.maxLength,
        t('validation.string.max', {
          number: originatorOptions.maxLength,
        })
      )
      .matches(originatorOptions.regExp, t('validation.originatorRegExp'))
      .requiredField('originators.createOriginator.controls.originator'),
    companyName: yup
      .string()
      .min(5, t('validation.string.min', { number: 5 }))
      .requiredField('originators.createOriginator.controls.companyName'),
    inn: yup
      .string()
      .requiredField('originators.createOriginator.controls.inn'),
    operatorId: yup.number().required(
      t('validation.required', {
        name: t('originators.createOriginator.controls.operatorId'),
      })
    ),
  })
)
