import { add, differenceInDays } from 'date-fns'
import type { RouteLocationNormalized } from 'vue-router'

import { getStoredAuthTokens } from '@/api/authTokens'
import { usePermissions } from '@/hooks/usePermissions'
import { useProfile } from '@/hooks/useProfile'
import { RouteNames } from '@/routeNames'
import { globalTime } from '@/utils/localTimeFromServer/globalLocalTime'

import { formatDateToRequest } from './formatDateToRequest'
import { getlocalStorage, saveTolocalStorage } from './localStoreRouter'
import { ProfileData } from './profile/profile'

const redirectToHome = '/auth'
const passwordExpiredPage = '/password_expired'

export const routerHandel = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const fullPathTo = to.fullPath
  const pathTo = to.path
  const pathName = to.name
  const queryPathFrom = from.query?.selectedGroups

  if (
    pathName === RouteNames.Auth ||
    pathName === RouteNames.Registration ||
    pathName === RouteNames.ToOldFront ||
    pathName === RouteNames.ForgotPassword ||
    pathName === RouteNames.ResetPassword ||
    pathName === RouteNames.LoginPrivateArea ||
    pathName === RouteNames.PhoneConfirmation ||
    pathName === RouteNames.PasswordConfirmation
  ) {
    return true
  }
  // если нету токена перебрасывать на логин
  const tokens = getStoredAuthTokens()
  if (!tokens) return 'auth'

  // получаем профиль с сервера заранее
  await ProfileData.fetchProfileData()

  if (tokens) {
    const permissions = usePermissions()
    const accountsAndDepartments =
      permissions?.value?.accountsAndDepartments?.canView
    // Пользователь без роли 1 не должен иметь возможность заходить на страницы: /accounts/ /departments/
    if (
      pathName === 'DepartmentSettings' &&
      !permissions?.value?.accountsAndDepartments?.canView
    ) {
      return '/auth'
    }

    const { profile } = useProfile()
    globalTime.setGlobalTime()
    // Принудительная смена пароля
    if (
      profile?.value?.passwordExpired &&
      pathName !== RouteNames.PasswordExpired
    ) {
      return passwordExpiredPage
    }
    // end

    if (pathName === 'Accounts' || pathName === 'Departments') {
      if (!accountsAndDepartments) return redirectToHome
    }
    // redirect if Пользователь департамента не должен иметь возможность попасть на страницу редактирования самого себя:
    if (pathName === 'DepartmentAccountSettings') {
      const accountId = to.params?.accountId
      const departmentId = to.params?.departmentId
      if (!accountsAndDepartments) return redirectToHome
      if (
        profile?.value?.departmentId === Number(departmentId) &&
        Number(accountId) === profile?.value?.departmentUserId
      ) {
        return redirectToHome
      }
    }
    if (pathName === 'ReportsOnRequest') {
      if (!profile?.value?.showReportsOnRequest) return redirectToHome
    }
    // redirect if Субучетка не должна иметь возможность попасть на страницу редактирования самой себя
    if (pathName === 'AccountSettings') {
      if (!accountsAndDepartments) return redirectToHome
      if (Number(to.params?.accountId) === profile?.value?.departmentId) {
        return redirectToHome
      }
    }
  }
  if (fullPathTo === '/batches') {
    return batchesRedirectPath('batches', -1, 3)
  }
  if (fullPathTo === '/batches/archive') {
    return batchesRedirectPath('batches/archive', -1, 3)
  }
  if (fullPathTo === '/transactions-history') {
    return batchesRedirectPath('transactions-history', -91, 0)
  }
  if (pathTo === '/messages-log') {
    return messagesLogHandle(pathTo, fullPathTo)
  }

  if (fullPathTo === '/batch-create' && queryPathFrom) {
    return false
  }
  return true
}

const batchesRedirectPath = (
  path: string,
  beforeDays: number,
  afterDays: number
) => {
  const formatBeforeDay = formatDays(beforeDays) // X дней раньше сегодня
  const formatAfterDays = formatDays(afterDays) // Х дней после сегодня

  return `/${path}?date=${formatBeforeDay},${formatAfterDays}&page=1&size=10`
}

const formatDays = (days: number) => {
  return formatDateToRequest(
    add(new Date(), {
      days,
    })
  )
}
const messagesLogHandle = (pathIs: string, fullPath: string) => {
  const path = pathIs ? pathIs + 'Time' : ''
  const time = new Date()
  try {
    const pathStore = path ? getlocalStorage(path) : ''
    const getPathStore = pathStore ? JSON.parse(pathStore) : ''

    const timeIs = getPathStore?.time
    const differenceDays = differenceInDays(time, new Date(timeIs))
    if (differenceDays > 0) {
      localStorage.removeItem(path)
      return true
    }
    if (pathIs === fullPath && getPathStore?.fullPath) {
      return getPathStore.fullPath
    }
    if (pathIs === fullPath && !getPathStore?.fullPath) {
      return true
    }
    if (pathIs !== fullPath) {
      saveTolocalStorage(path, JSON.stringify({ fullPath, time }))
      return true
    }
  } catch (err) {
    return true
  }
}
