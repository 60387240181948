<template>
  <form :id="id" @submit="onSubmit">
    <FormControls :controls="controls" />
  </form>
</template>

<script lang="ts">
import { useForm } from 'vee-validate'
import { computed, defineComponent, watchEffect } from 'vue'

import FormControls from '@/components/FormControls/FormControls.vue'
import { hasValues } from '@/utils/object'

import { filtersProps } from './Filters.types'

export default defineComponent({
  components: { FormControls },
  props: {
    id: String,
    ...filtersProps,
  },
  emits: ['update:modelValue', 'submit'],
  setup(props, { emit }) {
    const isModelValueNotEmpty = computed(() => hasValues(props.modelValue))

    const { values: formValues, handleSubmit } = useForm({
      validationSchema: props.validationSchema,
      initialValues: isModelValueNotEmpty.value
        ? props.modelValue
        : props.defaultValues,
    })

    watchEffect(
      () => {
        emit('update:modelValue', { ...formValues })
      },
      {
        // Чтобы не мешать анимации открытия ящика
        flush: 'post',
      }
    )

    const onSubmit = handleSubmit((values) => {
      emit('submit', values)
    })

    return {
      onSubmit,
    }
  },
})
</script>
