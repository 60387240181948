import type {
  AccountPrivacyResult,
  AccountShortResult,
  DepartmentResult,
} from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { Role } from '@/hooks/usePermissions'
import { useProfile } from '@/hooks/useProfile'

const { profile } = useProfile()

export type FormValues = {
  viewMessages: number[]
  approveBatches: number[]
  editBatches: number[]
  viewGroups: number[]
}

export const getInitialValues = (
  privacyData: AccountPrivacyResult
): FormValues => ({
  viewMessages: privacyData.viewMessages.map((item) => item.departmentId),
  approveBatches: privacyData.approveBatches.map((item) => item.departmentId),
  editBatches: privacyData.editBatches.map((item) => item.departmentId),
  viewGroups: privacyData.viewGroups.map((item) => item.departmentId),
})

export const getOptions = (
  accountsList: AccountShortResult[],
  departmentsList: DepartmentResult[]
) => {
  const result = []

  const t = useComponentI18n('editAccount.sections.privacy.multiselect.groups')

  const accountsOptions = accountsList.map((account) => ({
    value: account.id,
    label: account.name,
  }))

  if (profile?.value?.clientLogin && accountsOptions.length > 0)
    accountsOptions.unshift({
      value: 0,
      label: `${t('mainAccount')} - ${profile.value.clientLogin}`,
    })

  if (accountsOptions.length > 0) {
    result.push({
      label: t('accounts'),
      options: accountsOptions,
    })
  }

  const departmentsOptions = departmentsList.map((department) => ({
    value: department.id,
    label: department.name,
  }))

  if (departmentsOptions.length > 0) {
    result.push({
      label: t('departments'),
      options: departmentsOptions,
    })
  }

  return result
}

export const isControlDisabled = (
  name: keyof FormValues,
  roles: number[] | undefined
) => {
  if (name === 'viewMessages') {
    if (!roles?.includes(Role.ViewPosts)) {
      return true
    }
  }

  if (name === 'approveBatches' || name === 'editBatches') {
    if (roles?.includes(Role.CreateBatchesOnly)) {
      return true
    }
  }

  return false
}

export const getDescriptions = (
  formValues: FormValues,
  roles: number[] | undefined,
  isDepUser: boolean
) => {
  const t = useComponentI18n('editAccount.sections.privacy.form')

  const result = {
    viewMessages: t('viewMessages.description.filled'),
    approveBatches: t('approveBatches.description.filled'),
    editBatches: t('editBatches.description.filled'),
    viewGroups: t('viewGroups.description.filled'),
  }

  if (isControlDisabled('viewMessages', roles)) {
    result.viewMessages = t('viewMessages.description.disabled')
  } else if (formValues.viewMessages.length === 0) {
    if (isDepUser) {
      result.viewMessages = t('viewMessages.description.empty.depUser')
    } else {
      result.viewMessages = t('viewMessages.description.empty.subUser')
    }
  }

  if (isControlDisabled('approveBatches', roles)) {
    result.approveBatches = t('approveBatches.description.disabled')
  } else if (formValues.approveBatches.length === 0) {
    result.approveBatches = t('approveBatches.description.empty')
  }

  if (isControlDisabled('editBatches', roles)) {
    result.editBatches = t('editBatches.description.disabled')
  } else if (formValues.editBatches.length === 0) {
    result.editBatches = t('editBatches.description.empty')
  }

  if (formValues.viewGroups.length === 0) {
    if (isDepUser) {
      result.viewGroups = t('viewGroups.description.empty.depUser')
    } else {
      result.viewGroups = t('viewGroups.description.empty.subUser')
    }
  }

  return result
}
