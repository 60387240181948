<template>
  <FormControlBase v-slot="{ invalid }" :name="name">
    <FileInput
      :modelValue="value"
      :invalid="invalid"
      :locale="locale"
      :accept="accept"
      :maxSize="maxSize"
      @update:modelValue="handleChange"
    >
      <template v-if="descriptions && descriptions.length > 0">
        <Text
          v-for="description in descriptions"
          :key="description"
          tag="p"
          color="secondary"
        >
          {{ description }}
        </Text>
      </template>
    </FileInput>
  </FormControlBase>
</template>

<script lang="ts">
import type { FileInputTypes } from '@smst/ui'
import { FileInput, Text } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes, PropType } from 'vue'
import { defineComponent } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

const formFileInputProps = {
  ...formControlProps,
  locale: {
    type: Object as PropType<FileInputTypes.Locale>,
    required: true,
  },
  accept: String,
  maxSize: Number,
  descriptions: Array as PropType<string[]>,
} as const

export type FormFileInputProps = ExtractPropTypes<typeof formFileInputProps>

export default defineComponent({
  components: { FormControlBase, FileInput, Text },
  props: formFileInputProps,
  setup(props) {
    const { value, handleChange } = useField<File | undefined>(props.name)

    return {
      value,
      handleChange,
    }
  },
})
</script>
