<template>
  <component :is="view" v-bind="viewProps" @changeMode="changeMode" />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import DraftEdit from './DraftEdit.vue'
import DraftView from './DraftView.vue'

type Mode = 'view' | 'edit'

const props = defineProps({
  id: Number,
})

const mode = ref<Mode>('view')
const isOpen = computed(() => Boolean(props.id))

const view = computed(() => (mode.value === 'view' ? DraftView : DraftEdit))
const viewProps = computed(() => ({ isOpen: isOpen.value, id: props.id }))

const changeMode = (m: Mode) => {
  mode.value = m
}
</script>
