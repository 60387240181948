<template>
  <Link
    :to="{ name: RouteNames.Departments }"
    :class="$style.link"
    view="text"
    :iconLeft="IconChevron"
  >
    {{ t('link') }}
  </Link>

  <template v-if="isSuccess">
    <Text tag="h1" :class="$style.title">
      {{ t('title') }}
      {{ departmentData?.name }}
    </Text>

    <RouterTabs :items="tabsItems" :class="$style.tabs" />

    <router-view
      :departmentData="departmentData"
      :isDepartmentDataFetching="isFetching"
    />
  </template>

  <div v-else :class="$style.container">
    <Loader v-if="isLoading" size="m" />

    <ApiErrorMessage :error="error" />
  </div>
</template>

<script lang="ts" setup>
import type { TabsTypes } from '@smst/ui'
import { IconChevron, Loader, Text } from '@smst/ui'
import { useHead } from '@vueuse/head'
import { computed, toRef } from 'vue'
import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Link from '@/components/Link/Link.vue'
import RouterTabs from '@/components/RouterTabs/RouterTabs.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'

const props = defineProps({
  departmentId: {
    type: String,
    required: true,
  },
})

const t = useComponentI18n('editDepartment')

const tabsItems: TabsTypes.Item[] = [
  {
    value: RouteNames.DepartmentSettings,
    label: t('tabs.settings'),
  },
  {
    value: RouteNames.DepartmentAccounts,
    label: t('tabs.accounts'),
  },
]

const { isLoading, isSuccess, isFetching, data, error } = useQuery(
  ['department', toRef(props, 'departmentId')],
  () => apiClient.departments.departments_GET_BY(Number(props.departmentId))
)

const departmentData = computed(() => data.value?.data.data)

useHead({
  title: computed(() => `${t('title')} ${departmentData.value?.name ?? ''}`),
})
</script>

<style module>
.link {
  align-self: flex-start;
  margin-bottom: var(--gap-20);
}

.title {
  margin-bottom: var(--gap-24);
}

.tabs {
  align-self: flex-start;
  margin-bottom: var(--gap-24);
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
