import { format, parse } from 'date-fns'

const dateFormatWithTime = 'yyyy-MM-dd HH:mm:ss'
const dateFormatTime = 'HH:mm:ss'
const dateFormatWithoutTime = 'yyyy-MM-dd'

export const getDateTime = (dateTime: string | undefined) => {
  const dateParsed = dateTime
    ? parse(dateTime, dateFormatWithTime, new Date())
    : ''

  const dated = dateParsed ? format(dateParsed, dateFormatWithoutTime) : ''
  const timed = dateParsed ? format(dateParsed, dateFormatTime) : ''
  return { date: dated, time: timed }
}
